import { gql } from "@apollo/client";
import Field from "controls/Field";
import DateInput from "inputs/DateInput";
import DateTimeInput from "inputs/DateTimeInput";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const RecurringRecordingFormFragment = gql`
  fragment RecurringRecordingFormFragment on RecurringRecording {
    id
    court {
      id
    }
    league {
      id
    }
    homeTeam {
      id
    }
    awayTeam {
      id
    }
    cancelled
    startAt
    durationMinutes
    recurringType
    recurringInterval
    recurringUntil
  }
`;

export default function RecurringRecordingForm({ recurringRecording = {} }) {
  recurringRecording = {
    durationMinutes: 120,
    recurringType: "oneoff",
    recurringInterval: 1,
    court: null,
    league: null,
    homeTeam: null,
    awayTeam: null,
    ...recurringRecording,
  };

  const [recurringType, recurringTypeSet] = useState(recurringRecording.recurringType);
  const [league, leagueSet] = useState(recurringRecording.league);

  return (
    <>
      <Field
        name="court"
        defaultValue={recurringRecording.court}
        required
        label="Court"
        input={<ReadminObjectInput optionsTypename="Court" />}
      />
      <Field
        name="startAt"
        defaultValue={recurringRecording.startAt}
        required
        label="Start At"
        input={<DateTimeInput />}
      />
      <Field
        name="durationMinutes"
        defaultValue={recurringRecording.durationMinutes}
        required
        label="Duration (minutes)"
        input={<NumberInput min={30} max={360} step={30} />}
      />
      {recurringRecording.id && (
        <Field name="cancelled" defaultValue={recurringRecording.cancelled} label="Cancelled" input={<SwitchInput />} />
      )}
      <Field
        name="recurringType"
        value={recurringType}
        onChange={recurringTypeSet}
        required
        label="Recurrence"
        input={<SelectInput options={["oneoff", "hourly", "daily", "weekly"]} />}
      />
      {recurringType !== "oneoff" && (
        <>
          <Field
            name="recurringInterval"
            defaultValue={recurringRecording.recurringInterval}
            required
            formField
            label="Interval"
            input={<NumberInput min={1} />}
            min="1"
          />
          <Field
            name="recurringUntil"
            defaultValue={recurringRecording.recurringUntil}
            required
            formField
            label="Until"
            input={<DateInput />}
          />
        </>
      )}
      <Field
        name="league"
        value={league}
        onChange={leagueSet}
        label="League"
        input={<ReadminObjectInput optionsTypename="League" />}
      />
      <Field
        name="homeTeam"
        defaultValue={recurringRecording.homeTeam}
        label="Home Team"
        disabled={!league}
        input={
          <ReadminObjectInput
            optionsTypename="Team"
            optionsFilters={{
              in_league_id: league?.id,
            }}
          />
        }
      />
      <Field
        name="awayTeam"
        defaultValue={recurringRecording.awayTeam}
        label="Away Team"
        disabled={!league}
        input={
          <ReadminObjectInput
            optionsTypename="Team"
            optionsFilters={{
              in_league_id: league?.id,
            }}
          />
        }
      />
    </>
  );
}

import AddAdAssetDialog from "readmin_pages/ad_assets/AddAdAsset";
import { AdAssetsDeleteDialog } from "readmin_pages/ad_assets/dialogs";
import AdAssetEditDialog from "readmin_pages/ad_assets/EditDialog";
import AdAssetsPreviewEmailDialog from "readmin_pages/ad_assets/PreviewEmailDialog";
import AddAdPresetDialog from "readmin_pages/ad_presets/AddAdPresetDialog";
import { AdPresetsDeleteDialog } from "readmin_pages/ad_presets/dialogs";
import AdPresetsEditDialog from "readmin_pages/ad_presets/EditDialog";
import ApiProfileReImportDialog from "readmin_pages/api_profiles/ReImportDialog";
import AddCameraModelDialog from "readmin_pages/camera_models/AddCameraModelDialog";
import CameraModelsEditDialog from "readmin_pages/camera_models/EditDialog";
import AddCameraDialog from "readmin_pages/cameras/AddCameraDialog";
import CamerasEditDialog from "readmin_pages/cameras/EditDialog";
import CameraFilterConfigDialog from "readmin_pages/cameras/FilterConfigDialog";
import AddCityDialog from "readmin_pages/cities/AddCityDialog";
import { CitiesEditDialog } from "readmin_pages/cities/EditDialog";
import AddClubAdminDialog from "readmin_pages/club_admins/AddClubAdminDialog";
import { ClubAdminsRemoveDialog } from "readmin_pages/club_admins/dialogs";
import ClubAdminsEditDialog from "readmin_pages/club_admins/EditDialog";
import AddClubDialog from "readmin_pages/clubs/AddClubDialog";
import { ClubsRefetchFromGameDayDialog } from "readmin_pages/clubs/dialogs";
import ClubsEditDialog from "readmin_pages/clubs/EditDialog";
import AddCourtApiMappingDialog from "readmin_pages/court_api_mappings/AddCourtApiMappingDialog";
import { CourtApiMappingRemoveDialog } from "readmin_pages/court_api_mappings/dialogs";
import AddCourtDialog from "readmin_pages/courts/AddCourtDialog";
import CourtsEditDialog from "readmin_pages/courts/EditDialog";
import EventsDownloadVideoDialog from "readmin_pages/events/DownloadVideoDialog";
import AddGamePersonDialog from "readmin_pages/game_people/AddGamePersonDialog";
import { GamePersonRemoveDialog } from "readmin_pages/game_people/dialogs";
import AddGameDialog from "readmin_pages/games/AddGameDialog";
import AddGameRefereeDialog from "readmin_pages/games/AddGameRefereeDialog";
import CutNewFootageDialog from "readmin_pages/games/CutNewFootageDialog";
import { GamesDisablePotwDialog, GamesEnablePotwDialog } from "readmin_pages/games/dialogs";
import EditGameScoreDialog from "readmin_pages/games/EditGameScoreDialog";
import GameDownloadScoresheetDialog from "readmin_pages/games/GameDownloadScoresheetDialog";
import AddGdAssociationDialog from "readmin_pages/gd_associations/AddDialog";
import GdAssociationEditDialog from "readmin_pages/gd_associations/EditDialog";
import AddGdVenueDialog from "readmin_pages/gd_venues/AddDialog";
import GdVenueEditDialog from "readmin_pages/gd_venues/EditDialog";
import AddIncidentNoteDialog from "readmin_pages/incident_notes/AddIncidentNoteDialog";
import AddIncidentDialog from "readmin_pages/incidents/AddIncidentDialog";
import IncidentEditDialog from "readmin_pages/incidents/IncidentEditDialog";
import AddLeagueMemberDialog from "readmin_pages/league_members/AddLeagueMemberDialog";
import { LeagueMembersRemoveDialog } from "readmin_pages/league_members/dialogs";
import AddLeagueTeamDialog from "readmin_pages/league_teams/AddLeagueTeamDialog";
import { LeagueTeamsRemoveDialog } from "readmin_pages/league_teams/dialogs";
import AddLeagueDialog from "readmin_pages/leagues/AddLeagueDialog";
import {
  LeaguesChangeToMiniballDialog,
  LeaguesRemoveAllPlayersFromTeamsDialog,
  LeaguesRestoreTeamsApiRosterDialog,
} from "readmin_pages/leagues/dialogs";
import LeaguesEditDialog from "readmin_pages/leagues/EditDialog";
import LeagueEditScorekeeperSettingsDialog from "readmin_pages/leagues/EditScorekeeperSettingsDialog";
import AddLiveStreamCustomOutputDialog from "readmin_pages/live_stream_custom_output/AddLiveStreamCustomOutputDialog";
import { LiveStreamCustomOutputsRemoveDialog } from "readmin_pages/live_stream_custom_output/dialogs";
import LiveStreamCustomOutputsEditDialog from "readmin_pages/live_stream_custom_output/EditDialog";
import AddLiveStreamFbOutputDialog from "readmin_pages/live_stream_fb_output/AddLiveStreamFbOutputDialog";
import { LiveStreamFbOutputsRemoveDialog } from "readmin_pages/live_stream_fb_output/dialogs";
import LiveStreamFbOutputsEditDialog from "readmin_pages/live_stream_fb_output/EditDialog";
import AddLiveStreamScorerDialog from "readmin_pages/live_stream_scorer/AddLiveStreamScorerDialog";
import { LiveStreamScorersRemoveDialog } from "readmin_pages/live_stream_scorer/dialogs";
import LiveStreamScorersEditDialog from "readmin_pages/live_stream_scorer/EditDialog";
import AddLiveStreamYtOutputDialog from "readmin_pages/live_stream_yt_output/AddLiveStreamFbOutputDialog";
import { LiveStreamYtOutputsRemoveDialog } from "readmin_pages/live_stream_yt_output/dialogs";
import AddLiveStreamDialog from "readmin_pages/live_streams/AddLiveStreamDialog";
import LiveStreamsEditDialog from "readmin_pages/live_streams/EditDialog";
import AddMotioncropTestDialog from "readmin_pages/motioncrop_tests/AddMotioncropTestDialog";
import MotioncropTestsEditDialog from "readmin_pages/motioncrop_tests/EditDialog";
import AddNetworkDeviceDialog from "readmin_pages/network_devices/AddNetworkDeviceDialog";
import NetworkDevicesEditDialog from "readmin_pages/network_devices/EditDialog";
import AddOnsenPopupPromoDialog from "readmin_pages/onsen_popup_promos/AddOnsenPopupPromoDialog";
import { OnsenPopupPromosRemoveDialog } from "readmin_pages/onsen_popup_promos/dialogs";
import OnsenPopupPromosEditDialog from "readmin_pages/onsen_popup_promos/EditDialog";
import CheckOpsWarningsDialog from "readmin_pages/ops_warnings/CheckOpsWarningsDialog";
import AddOriginalVideoRequestDialog from "readmin_pages/original_video_requests/AddOriginalVideoRequestDialog";
import { OriginalVideoRequestsEditDialog } from "readmin_pages/original_video_requests/EditDialog";
import AddPersonDialog from "readmin_pages/people/AddPersonDialog";
import { PeopleSendWelcomeEmailDialog } from "readmin_pages/people/dialogs";
import PeopleEditDialog from "readmin_pages/people/EditDialog";
import PersonGetPlayerPortalLinkDialog from "readmin_pages/people/GetPlayerPortalLinkDialog";
import PersonMergeWithOtherDialog from "readmin_pages/people/MergeWithOtherDialog";
import AddPersonSupporterDialog from "readmin_pages/person_supporters/AddPersonSupportDialog";
import PersonSupporterRemoveDialog from "readmin_pages/person_supporters/RemoveDialog";
import PlayHqOrganisationEditDialog from "readmin_pages/play_hq_organisations/EditDialog";
import { PotwEntriesRemoveDialog } from "readmin_pages/potw_entries/dialogs";
import { PotwRatingsRemoveDialog } from "readmin_pages/potw_ratings/dialogs";
import AddPublicLeaguePageDialog from "readmin_pages/public_league_pages/AddPublicLeaguePageDialog";
import PublicLeaguePagesEditDialog from "readmin_pages/public_league_pages/EditDialog";
import AddRecordingBlockExceptionDateDialog from "readmin_pages/recording_block_exception_dates/AddRecordingBlockExceptionDateDialog";
import { RecordingBlockExceptionDateRemoveDialog } from "readmin_pages/recording_block_exception_dates/dialogs";
import AddRecordingBlockDialog from "readmin_pages/recording_blocks/AddRecordingBlockDialog";
import { RecordingBlockRemoveDialog } from "readmin_pages/recording_blocks/dialogs";
import RecordingBlockEditDialog from "readmin_pages/recording_blocks/EditDialog";
import RecurringRecordingOccurrenceAddMissingGameDialog from "readmin_pages/recurring_recording_occurrences/AddMissingGameDialog";
import RecurringRecordingOccurrenceEditDialog from "readmin_pages/recurring_recording_occurrences/EditDialog";
import AddRecurringRecordingDialog from "readmin_pages/recurring_recordings/AddRecurringRecordingDialog";
import AddScorekeeperUserDialog from "readmin_pages/scorekeeper_users/AddScorekeeperUserDialog";
import { ScorekeeperUsersRemoveDialog } from "readmin_pages/scorekeeper_users/dialogs";
import ScorekeeperUsersEditDialog from "readmin_pages/scorekeeper_users/EditDialog";
import AddSurfaceDialog from "readmin_pages/surfaces/AddSurfaceDialog";
import SurfaceEditDialog from "readmin_pages/surfaces/EditDialog";
import {
  TeamAddCoachDialog,
  TeamAddPlayerDialog,
  TeamAddSupporterDialog,
} from "readmin_pages/team_members/AddTeamMemberDialogs";
import TeamMemberEditDialog from "readmin_pages/team_members/EditDialog";
import TeamMemberRemoveDialog from "readmin_pages/team_members/RemoveDialog";
import { TeamRegistrationsRemoveDialog, TeamRegistrationsSendDialog } from "readmin_pages/team_registrations/dialogs";
import AddTeamDialog from "readmin_pages/teams/AddTeamDialog";
import { TeamRemoveAllPlayersDialog, TeamsRestoreApiRosterDialog } from "readmin_pages/teams/dialogs";
import TeamsEditDialog from "readmin_pages/teams/EditDialog";
import TeamsEnableRegistrationDialog from "readmin_pages/teams/EnableRegistrationDialog";
import AddUserDialog from "readmin_pages/users/AddUserDialog";
import UserEditDialog from "readmin_pages/users/EditDialog";
import UserResetPasswordDialog from "readmin_pages/users/ResetPasswordDialog";
import AddVenueAdminDialog from "readmin_pages/venue_admins/AddVenueAdminDialog";
import { VenueAdminsRemoveDialog } from "readmin_pages/venue_admins/dialogs";
import VenueAdminsEditDialog from "readmin_pages/venue_admins/EditDialog";
import VenueServerCommandCancelDialog from "readmin_pages/venue_server_commands/CancelDialog";
import { VenueServersCheckIssuesDialog } from "readmin_pages/venue_servers/dialog";
import VenueServersEditDialog from "readmin_pages/venue_servers/EditDialog";
import GenerateVenueServerSshConfigDialog from "readmin_pages/venue_servers/GenerateVenueServerSshConfigDialog";
import VenueServersRunCommandDialog from "readmin_pages/venue_servers/RunCommandDialog";
import AddVenueDialog from "readmin_pages/venues/AddVenueDialog";
import VenuesEditDialog from "readmin_pages/venues/EditDialog";
import {
  VideoVersionsDeleteFootageDialog,
  VideoVersionsDisableDialog,
  VideoVersionsEnableDialog,
} from "readmin_pages/video_versions/dialogs";
import {
  VideosDisableDialog,
  VideosEnableDialog,
  VideosReCutDialog,
  VideosRestoreOriginalVideoDialog,
} from "readmin_pages/videos/dialogs";
import VideoDownloadOriginalVideoDialog from "readmin_pages/videos/DownloadOriginalVideoDialog";
import VideosReEncodeDialog from "readmin_pages/videos/ReEncodeDialog";

const READMIN_OBJECT_DIALOGS = {
  AdAsset: [AdAssetEditDialog],
  Camera: [CameraFilterConfigDialog],
  Surface: [SurfaceEditDialog],
  League: [LeagueEditScorekeeperSettingsDialog],
  Game: [EditGameScoreDialog, CutNewFootageDialog, AddGameRefereeDialog, GameDownloadScoresheetDialog],
  GamePerson: [GamePersonRemoveDialog],
  Team: [TeamRemoveAllPlayersDialog],
  TeamMember: [TeamMemberEditDialog, TeamMemberRemoveDialog],
  Video: [AddMotioncropTestDialog, VideoDownloadOriginalVideoDialog],
  Incident: [IncidentEditDialog],
  RecurringRecordingOccurrence: [
    RecurringRecordingOccurrenceEditDialog,
    RecurringRecordingOccurrenceAddMissingGameDialog,
  ],
  User: [UserEditDialog, UserResetPasswordDialog],
  VenueServerCommand: [VenueServerCommandCancelDialog],
  Person: [PersonGetPlayerPortalLinkDialog, PersonMergeWithOtherDialog],
  PersonSupporter: [PersonSupporterRemoveDialog],
  CourtApiMapping: [CourtApiMappingRemoveDialog],
  GdVenue: [GdVenueEditDialog],
  GdAssociation: [GdAssociationEditDialog],
  PlayHqOrganisation: [PlayHqOrganisationEditDialog],
  ApiProfile: [ApiProfileReImportDialog],
  RecordingBlock: [RecordingBlockEditDialog, RecordingBlockRemoveDialog],
  RecordingBlockExceptionDate: [RecordingBlockExceptionDateRemoveDialog],
};

export const READMIN_OBJECT_BATCH_DIALOGS = {
  AdAsset: [AdAssetsDeleteDialog, AdAssetsPreviewEmailDialog],
  AdPreset: [AdPresetsDeleteDialog, AdPresetsEditDialog],
  Club: [ClubsEditDialog, ClubsRefetchFromGameDayDialog],
  ClubAdmin: [ClubAdminsEditDialog, ClubAdminsRemoveDialog],
  Video: [
    VideosEnableDialog,
    VideosDisableDialog,
    VideosReCutDialog,
    VideosReEncodeDialog,
    VideosRestoreOriginalVideoDialog,
  ],
  Camera: [CamerasEditDialog],
  CameraModel: [CameraModelsEditDialog],
  City: [CitiesEditDialog],
  Court: [CourtsEditDialog],
  Game: [GamesEnablePotwDialog, GamesDisablePotwDialog],
  VideoVersion: [VideoVersionsDeleteFootageDialog, VideoVersionsDisableDialog, VideoVersionsEnableDialog],
  League: [
    LeaguesEditDialog,
    LeaguesChangeToMiniballDialog,
    LeaguesRemoveAllPlayersFromTeamsDialog,
    LeaguesRestoreTeamsApiRosterDialog,
  ],
  LeagueTeam: [LeagueTeamsRemoveDialog],
  LeagueMember: [LeagueMembersRemoveDialog],
  MotioncropTest: [MotioncropTestsEditDialog],
  NetworkDevice: [NetworkDevicesEditDialog],
  VenueAdmin: [VenueAdminsRemoveDialog, VenueAdminsEditDialog],
  VenueServer: [VenueServersEditDialog, VenueServersCheckIssuesDialog, VenueServersRunCommandDialog],
  Venue: [VenuesEditDialog],
  OnsenPopupPromo: [OnsenPopupPromosRemoveDialog, OnsenPopupPromosEditDialog],
  Person: [PeopleEditDialog, PeopleSendWelcomeEmailDialog],
  PotwEntry: [PotwEntriesRemoveDialog],
  PotwRating: [PotwRatingsRemoveDialog],
  PublicLeaguePage: [PublicLeaguePagesEditDialog],
  Team: [TeamsEditDialog, TeamsEnableRegistrationDialog, TeamsRestoreApiRosterDialog],
  TeamRegistration: [TeamRegistrationsSendDialog, TeamRegistrationsRemoveDialog],
  Event: [EventsDownloadVideoDialog],
  ScorekeeperUser: [ScorekeeperUsersEditDialog, ScorekeeperUsersRemoveDialog],
  LiveStream: [LiveStreamsEditDialog],
  LiveStreamCustomOutput: [LiveStreamCustomOutputsRemoveDialog, LiveStreamCustomOutputsEditDialog],
  LiveStreamFbOutput: [LiveStreamFbOutputsEditDialog, LiveStreamFbOutputsRemoveDialog],
  LiveStreamYtOutput: [LiveStreamYtOutputsRemoveDialog],
  LiveStreamScorer: [LiveStreamScorersEditDialog, LiveStreamScorersRemoveDialog],
  OriginalVideoRequest: [OriginalVideoRequestsEditDialog],
};

export const READMIN_OBJECTS_INDEX_DIALOGS = {
  AdAsset: [AddAdAssetDialog],
  AdPreset: [AddAdPresetDialog],
  GdVenue: [AddGdVenueDialog],
  GdAssociation: [AddGdAssociationDialog],
  Game: [AddGameDialog],
  Camera: [AddCameraDialog],
  CameraModel: [AddCameraModelDialog],
  Club: [AddClubDialog],
  ClubAdmin: [AddClubAdminDialog],
  Court: [AddCourtDialog],
  CourtApiMapping: [AddCourtApiMappingDialog],
  City: [AddCityDialog],
  GamePerson: [AddGamePersonDialog],
  MotioncropTest: [AddMotioncropTestDialog],
  NetworkDevice: [AddNetworkDeviceDialog],
  PersonSupporter: [AddPersonSupporterDialog],
  Person: [AddPersonDialog],
  PublicLeaguePage: [AddPublicLeaguePageDialog],
  Team: [AddTeamDialog],
  TeamMember: [TeamAddCoachDialog, TeamAddPlayerDialog, TeamAddSupporterDialog],
  VenueAdmin: [AddVenueAdminDialog],
  User: [AddUserDialog],
  Incident: [AddIncidentDialog],
  IncidentNote: [AddIncidentNoteDialog],
  OnsenPopupPromo: [AddOnsenPopupPromoDialog],
  OpsWarning: [CheckOpsWarningsDialog],
  VenueServer: [GenerateVenueServerSshConfigDialog],
  Venue: [AddVenueDialog],
  RecurringRecordingOccurrence: [AddRecurringRecordingDialog],
  League: [AddLeagueDialog],
  LeagueTeam: [AddLeagueTeamDialog],
  LeagueMember: [AddLeagueMemberDialog],
  RecordingBlock: [AddRecordingBlockDialog],
  RecordingBlockExceptionDate: [AddRecordingBlockExceptionDateDialog],
  ScorekeeperUser: [AddScorekeeperUserDialog],
  LiveStream: [AddLiveStreamDialog],
  LiveStreamCustomOutput: [AddLiveStreamCustomOutputDialog],
  LiveStreamFbOutput: [AddLiveStreamFbOutputDialog],
  LiveStreamYtOutput: [AddLiveStreamYtOutputDialog],
  LiveStreamScorer: [AddLiveStreamScorerDialog],
  Surface: [AddSurfaceDialog],
  OriginalVideoRequest: [AddOriginalVideoRequestDialog],
};

export const READMIN_OBJECT_DIALOG_PARAM_NAMES = {
  AdAsset: "adAssetId",
  AdPreset: "adPresetId",
  ApiProfile: "apiProfileId",
  Game: "gameId",
  GamePerson: "gamePersonId",
  Team: "teamId",
  TeamMember: "teamMemberId",
  League: "leagueId",
  Video: "videoId",
  Incident: "incidentId",
  IncidentNote: "incidentNoteId",
  RecurringRecordingOccurrence: "recurringRecordingOccurrenceId",
  Camera: "cameraId",
  CameraModel: "cameraModelId",
  City: "cityId",
  Club: "clubId",
  Court: "courtId",
  CourtApiMapping: "courtApiMappingId",
  MotioncropTest: "motioncropTestId",
  User: "userId",
  VenueServer: "venueServerId",
  VenueServerCommand: "venueServerCommandId",
  Venue: "venueId",
  VenueAdmin: "venueAdminId",
  OnsenPopupPromo: "onsenPopupPromoId",
  Person: "personId",
  PersonSupporter: "personSupporterId",
  GdVenue: "gdVenueId",
  GdAssociation: "gdAssociationId",
  PlayHqOrganisation: "playHqOrganisationId",
  Event: "eventId",
  RecordingBlock: "recordingBlockId",
  RecordingBlockExceptionDate: "recordingBlockExceptionDateId",
  ScorekeeperUser: "scorekeeperUserId",
  LiveStream: "liveStreamId",
  LiveStreamCustomOutput: "liveStreamCustomOutputId",
  LiveStreamYtOutput: "liveStreamYtOutputId",
  LiveStreamFbOutput: "liveStreamFbOutputId",
  LiveStreamScorer: "liveStreamScorerId",
  Surface: "surfaceId",
  OriginalVideoRequest: "originalVideoRequestId",
};

export const READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES = {
  AdAsset: "adAssetIds",
  AdPreset: "adPresetIds",
  Camera: "cameraIds",
  CameraModel: "cameraModelIds",
  Club: "clubIds",
  ClubAdmin: "clubAdminIds",
  City: "cityIds",
  Court: "courtIds",
  Game: "gameIds",
  Video: "videoIds",
  VideoVersion: "videoVersionIds",
  VenueServer: "venueServerIds",
  VenueAdmin: "venueAdminIds",
  Venue: "venueIds",
  OnsenPopupPromo: "onsenPopupPromoIds",
  Person: "personIds",
  PublicLeaguePage: "publicLeaguePageIds",
  PotwEntry: "potwEntryIds",
  PotwRating: "potwRatingIds",
  League: "leagueIds",
  LeagueTeam: "leagueTeamIds",
  LeagueMember: "leagueMemberIds",
  MotioncropTest: "motioncropTestIds",
  NetworkDevice: "networkDeviceIds",
  Team: "teamIds",
  TeamRegistration: "teamRegistrationIds",
  Event: "eventIds",
  ScorekeeperUser: "scorekeeperUserIds",
  LiveStream: "liveStreamIds",
  LiveStreamCustomOutput: "liveStreamCustomOutputIds",
  LiveStreamYtOutput: "liveStreamYtOutputIds",
  LiveStreamFbOutput: "liveStreamFbOutputIds",
  LiveStreamScorer: "liveStreamScorerIds",
  Surface: "surfaceIds",
  OriginalVideoRequest: "originalVideoRequestIds",
};

export default READMIN_OBJECT_DIALOGS;

import { gql } from "@apollo/client";
import DialogsToolbar from "controls/DialogsToolbar";
import apolloClient from "helpers/apolloClient";
import READMIN_OBJECT_TYPES from "helpers/READMIN_OBJECT_TYPES";
import useGotoUrl from "hooks/useGotoUrl";
import { progressBar } from "hooks/useProgressBar";
import React from "react";

import READMIN_OBJECT_DIALOGS, {
  READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES,
  READMIN_OBJECT_BATCH_DIALOGS,
  READMIN_OBJECT_DIALOG_PARAM_NAMES,
} from "./READMIN_OBJECT_DIALOGS";
import { ReadminObjectLabelDescriptionsFragment } from "./ReadminObjectLabelDescriptions";
import ViewPaperTrailVersionsDialog from "./ViewPaperTrailVersionsDialog";

export default function ReadminObjectDialogToolbar({ id, typename, onSubmit, gotoReadminObjectUrl = false }) {
  const gotoUrl = useGotoUrl();
  const dialogs = [
    //
    ...(READMIN_OBJECT_DIALOGS[typename] || []).map((DialogComponent) => (
      <DialogComponent
        {...{
          [READMIN_OBJECT_DIALOG_PARAM_NAMES[typename]]: id,
        }}
      />
    )),
    ...(READMIN_OBJECT_BATCH_DIALOGS[typename] || []).map((DialogComponent) => (
      <DialogComponent
        {...{
          [READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES[typename]]: [id],
        }}
      />
    )),
    <ViewPaperTrailVersionsDialog id={id} typename={typename} />,
  ];

  return (
    <DialogsToolbar
      dialogs={dialogs}
      onSubmit={async (dialogResult) => {
        await progressBar(() =>
          apolloClient.query({
            query: gql`
              query ($id: ID!, $typename: String!) {
                readminObject(id: $id, typename: $typename) {
                  id
                  ...ReadminObjectLabelDescriptionsFragment
                }
              }
              ${ReadminObjectLabelDescriptionsFragment}
            `,
            variables: {
              id,
              typename,
            },
            fetchPolicy: "network-only",
          }),
        );
        let resultReadminObject = null;
        if (
          dialogResult?.constructor === Array &&
          dialogResult.length === 1 &&
          READMIN_OBJECT_TYPES.includes(dialogResult[0]?.__typename)
        ) {
          resultReadminObject = dialogResult[0];
        }
        if (dialogResult?.__typename && READMIN_OBJECT_TYPES.includes(dialogResult?.__typename)) {
          resultReadminObject = dialogResult;
        }

        if (gotoReadminObjectUrl && resultReadminObject) {
          //  const  READMIN_OBJECT_TYPES.includes(dialogResult?.__typename)) {
          const result = await progressBar(() =>
            apolloClient.query({
              query: gql`
                query ReadminObjectsPage_ReadminObjectsDialog($id: ID!, $typename: String!) {
                  readminObject(id: $id, typename: $typename) {
                    id
                    readminUrl
                  }
                }
              `,
              variables: {
                id: resultReadminObject.id,
                typename: resultReadminObject.__typename,
              },
            }),
          );
          const readminUrl = result?.data?.readminObject?.readminUrl;
          if (readminUrl) return await gotoUrl(readminUrl);
        }

        await onSubmit?.(dialogResult);
      }}
    />
  );
}

import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import VenueAdminsForm from "./Form";

AddVenueAdminDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Venue Admin",
  };
};

export default function AddVenueAdminDialog({ venueId, onClose = noop, onSubmit = noop }) {
  const addVenueAdmin = useActionFragment("addVenueAdmin");

  return (
    <FormDialog
      header="Add Venue Admin"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addVenueAdmin({
          input: formData,
        });
        await onSubmit();
      }}
    >
      <VenueAdminsForm
        venueAdmins={[
          {
            venue: venueId && { id: venueId, __typename: "Venue" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { InputAdornment, TextField } from "@mui/material";
import { isEqual } from "lodash-es";
import React, { useState } from "react";

import Field from "./Field";

export default function MultiValuesField({
  label,
  helperText,
  name,
  input,
  defaultValues,
  onChange,
  disabled = false,
  required = false,
  ...others
}) {
  const inputDefaultValue = input.type.defaultValue === undefined ? null : input.type.defaultValue;
  const defaultValue = (defaultValues || [])[0];
  const indeterminate = !defaultValues?.length || !defaultValues.every((value) => isEqual(value, defaultValue));
  const [reset, resetSet] = useState(false);

  if (indeterminate && !reset)
    return (
      <TextField
        label={label}
        helperText={helperText}
        value="Multiple values"
        InputProps={{
          disabled: true,
          endAdornment: (
            <InputAdornment position="end" style={{ gap: 10 }}>
              <a
                href="#"
                onClick={() => {
                  resetSet(true);
                  onChange?.(inputDefaultValue);
                }}
              >
                reset value
              </a>
            </InputAdornment>
          ),
        }}
      />
    );

  return (
    <Field
      name={name}
      label={label}
      disabled={disabled}
      required={required}
      helperText={helperText}
      input={input}
      defaultValue={reset ? inputDefaultValue : defaultValue}
      onChange={onChange}
      {...others}
    />
  );
}

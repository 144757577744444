import { gql } from "@apollo/client";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Plus } from "mdi-material-ui";
import React, { useState } from "react";
import SelectRefereeDialog from "readmin_pages/people/SelectRefereeDialog";

AddGameRefereeDialog.buttonProps = {
  icon: <Plus />,
  content: "Add referee",
};

export default function AddGameRefereeDialog({ gameId, onSubmit = noop, ...others }) {
  const [loading, loadingSet] = useState(false);
  const [data] = useData(
    gql`
      query AddGameRefereeDialog($gameId: ID!) {
        game(id: $gameId) {
          id
          club {
            id
          }
        }
      }
    `,
    { gameId },
  );
  const addGamePerson = useActionFragment("addGamePerson");

  return (
    <>
      <SelectRefereeDialog
        {...others}
        clubId={data?.game.club.id}
        personFragment="id fullName isReferee"
        isAssign={true}
        loading={loading}
        onSubmit={async (person) => {
          loadingSet(true);
          await addGamePerson({
            input: {
              game: { id: gameId, typename: "Game" },
              role: "referee",
              person: { id: person.id, typename: "Person" },
            },
          });
          await onSubmit();
          loadingSet(false);
        }}
      />
    </>
  );
}

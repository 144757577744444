import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import CitiesForm from "./Form";

AddCityDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add City",
  };
};

export default function AddCityDialog({ onSubmit, onClose }) {
  const addCity = useActionFragment("addCity", "city { id }");

  return (
    <FormDialog
      header="Add City"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addCity({ input: formData });
        await onSubmit?.(result.addCity.city);
      }}
    >
      <CitiesForm />
    </FormDialog>
  );
}

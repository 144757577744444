import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import VenuesForm, { VenuesFormFragment } from "./Form";

VenuesEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    content: "Edit",
    icon: <Pencil />,
    hidden: !isAdmin,
  };
};

export default function VenuesEditDialog({ venueIds, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query VenueEditDialogQuery($ids: [ID!]!) {
        venues(ids: $ids) {
          id
          ...VenuesFormFragment
        }
      }
      ${VenuesFormFragment}
    `,
    { ids: venueIds },
  );
  const venuesUpdate = useActionFragment("venuesUpdate");

  return (
    <FormDialog
      header="Edit Venues"
      onClose={onClose}
      onSubmit={async (formData) => {
        await venuesUpdate({
          input: {
            venueIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
      loading={dataMeta.loading}
    >
      <VenuesForm venues={data?.venues} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const ClubAdminsFormFragment = gql`
  fragment ClubAdminsFormFragment on ClubAdmin {
    id
    club {
      id
    }
    email
    sendNightlyGamesheet
    notifyOutages
  }
`;

export default function ClubAdminsForm({ clubAdmins = [{}] }) {
  clubAdmins = clubAdmins.map((ca) => ({
    sendNightlyGamesheet: true,
    notifyOutages: true,
    ...ca,
  }));

  return (
    <>
      <MultiValuesField
        label="Club"
        name="club"
        required
        defaultValues={clubAdmins.map((ca) => ca.club)}
        disabled={!!clubAdmins.find((ca) => ca.id)}
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <MultiValuesField
        label="Email"
        name="email"
        required
        defaultValues={clubAdmins.map((ca) => ca.email)}
        disabled={!!clubAdmins.find((ca) => ca.id)}
        input={<TextInput autoComplete="email" type="email" />}
      />
      <MultiValuesField
        label="Nightly gamesheets"
        name="sendNightlyGamesheet"
        defaultValues={clubAdmins.map((ca) => ca.sendNightlyGamesheet)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        label="Notify Outages"
        name="notifyOutages"
        defaultValues={clubAdmins.map((ca) => ca.notifyOutages)}
        input={<SwitchInput />}
        helperText="Send an outage email when any device has outage for more than 1 hour affecting any club courts."
      />
    </>
  );
}

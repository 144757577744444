import { FormControl, FormControlLabel, FormHelperText, Switch } from "@mui/material";
import React from "react";

SwitchInput.defaultValue = false;

export default function SwitchInput({ value, onChange, label, helperText, ...others }) {
  return (
    <FormControl fullWidth>
      <FormControlLabel
        control={<Switch checked={value} onChange={(event) => onChange(event.target.checked)} {...others} />}
        label={label}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
}

import { gql } from "@apollo/client";
import { Dialog, Paper } from "@mui/material";
import VideoPlayer from "controls/VideoPlayer";
import { dialogContext } from "helpers/contexts";
import useData from "hooks/useData";
import React, { useState } from "react";

export default function LiveStreamThumbnail({ id, typename, ...others }) {
  const [dialogOpen, dialogOpenSet] = useState(false);

  const [data] = useData(
    gql`
      query LiveStreamThumbnail($id: ID!) {
        liveStream(id: $id) {
          id
          liveHlsUrl
          widgetEmbedCode
        }
      }
    `,
    { id },
  );

  const liveHlsUrl = data?.liveStream?.liveHlsUrl;
  const widgetEmbedCode = data?.liveStream?.widgetEmbedCode;

  if (!liveHlsUrl) return null;

  return (
    <Paper {...others} style={{ position: "relative", ...others.style }}>
      <VideoPlayer
        style={{ width: "100%", height: "100%", cursor: "pointer" }}
        m3u8Url={liveHlsUrl}
        onClick={() => dialogOpenSet(true)}
        muted
        onMouseEnter={(event) => {
          event.target.play();
        }}
        onMouseLeave={(event) => {
          event.target.pause();
          event.target.currentTime = 0;
        }}
      />
      <dialogContext.Provider value={{ isInDialog: true }}>
        <Dialog open={dialogOpen} onClose={() => dialogOpenSet(false)} fullWidth maxWidth="lg">
          {widgetEmbedCode && (
            <div
              style={{
                aspectRatio: "16/9",
              }}
              dangerouslySetInnerHTML={{ __html: widgetEmbedCode }}
            ></div>
          )}
        </Dialog>
      </dialogContext.Provider>
    </Paper>
  );
}

import { useEffect } from "react";

export default function useDOMEvent(eventName, handler, deps = [], getElement = () => window, options) {
  useEffect(() => {
    const element = getElement();
    element.addEventListener(eventName, handler, options);

    return () => {
      element.removeEventListener(eventName, handler, options);
    };
  }, deps);
}

import { Alert, AlertTitle, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar } from "@mui/material";
import React, { useImperativeHandle, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import useSingleton from "./useSingleton";

export default function useShowMessage() {
  const ref = useSingleton("MessagesRenderer", <MessagesRenderer />);
  return (...args) => ref.current?.showMessage(...args);
}

const MessagesRenderer = React.forwardRef(function MessagesRenderer(props, ref) {
  const [messages, messagesSet] = useState([]);

  useImperativeHandle(ref, () => ({
    showMessage: async (...args) => {
      const options = {};
      for (const arg of args) {
        if (arg.constructor === Object) Object.assign(options, arg);
        if (arg.constructor === String) Object.assign(options, { content: arg });
      }

      const { content, header, modal = false } = options;

      const id = uuidv4();
      await new Promise((resolve) => {
        const message = {
          id,
          header,
          content,
          modal,
          dismiss: () => {
            messagesSet((messages) => messages.map((m) => (m.id === id ? { ...m, dismissed: true } : m)));
            resolve();
          },
        };
        if (content instanceof Error) {
          const error = content;
          if (error.description || error.list)
            Object.assign(message, {
              header: error.message,
              content: (
                <>
                  {error.description}
                  {error.list && (
                    <ul>
                      {error.list.map((item, itemIndex) => (
                        <li key={itemIndex}>{item}</li>
                      ))}
                    </ul>
                  )}
                </>
              ),
            });
          else
            Object.assign(message, {
              header: "Error",
              content: error.message,
            });
        }
        messagesSet((messages) => [...messages, message]);
      });
    },
  }));

  return (
    <>
      {messages
        .filter((m) => m.modal)
        .map((message) => (
          <Dialog key={message.id} open={!message.dismissed} onClose={message.dismiss} scorll="body" {...message.props}>
            {message.header && <DialogTitle>{message.header}</DialogTitle>}
            <DialogContent>{message.content}</DialogContent>
            <DialogActions>
              <Button onClick={message.dismiss}>Dismiss</Button>
            </DialogActions>
          </Dialog>
        ))}
      {messages
        .filter((m) => !m.modal)
        .map((message) => (
          <Snackbar key={message.id} open={!message.dismissed} autoHideDuration={5000} onClose={message.dismiss}>
            <Alert severity="info" onClose={message.dismiss}>
              {message.header && <AlertTitle>{message.header}</AlertTitle>}
              {message.content}
            </Alert>
          </Snackbar>
        ))}
    </>
  );
});

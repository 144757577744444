import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const LiveStreamYtOutputsRemoveDialog = makeConfirmActionDialog({
  mutationName: "liveStreamYtOutputsRemove",
  action: "Remove YouTube Output",
  paramName: "liveStreamYtOutputIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import AdPresetForm from "./Form";

AddAdPresetDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Ad Preset",
  };
};

export default function AddAdPresetDialog({ clubId, onSubmit, onClose }) {
  const addAdPreset = useActionFragment("addAdPreset", "adPreset { id }");

  return (
    <FormDialog
      header="Add Ad Preset"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addAdPreset({ input: formData });
        await onSubmit?.(result.addAdPreset.adPreset);
      }}
    >
      <AdPresetForm
        adPresets={[
          {
            club: clubId && { id: clubId, __typename: "Club" },
          },
        ]}
      />
    </FormDialog>
  );
}

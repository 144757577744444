import { gql } from "@apollo/client";
import DataTable, { DataTableSelectedAction } from "controls/DataTable";
import { getTime, parseISO } from "date-fns";
import apolloClient from "helpers/apolloClient";
import { readminPageContext } from "helpers/contexts";
import noop from "helpers/noop";
import useFormDialogs from "hooks/useFormDialogs";
import { progressBar } from "hooks/useProgressBar";
import { every, isEqual, pick, reject, times } from "lodash-es";
import React, { useContext, useState } from "react";

import READMIN_OBJECT_DIALOGS, {
  READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES,
  READMIN_OBJECT_BATCH_DIALOGS,
  READMIN_OBJECT_DIALOG_PARAM_NAMES,
} from "./READMIN_OBJECT_DIALOGS";
import ReadminAttributeValue, { ReadminAttributeValueFragment } from "./ReadminAttributeValue";
import ReadminObjectActionsDropdown from "./ReadminObjectActionsDropdown";
import { ReadminObjectLabelDescriptionsFragment } from "./ReadminObjectLabelDescriptions";
import ReadminObjectLabels from "./ReadminObjectLabels";
import ReadminObjectThumbnail from "./ReadminObjectThumbnail";

export const ReadminObjectsTableFragment = gql`
  fragment ReadminObjectsTableFragment on ReadminObject {
    id
    readminUrl
    readminSummaryAttributeEntries {
      label
      type
      readminObjects {
        id
        __typename
      }
      ...ReadminAttributeValueFragment
    }
  }
  ${ReadminAttributeValueFragment}
`;

function isDescending(array) {
  return every(array, (value, index, array) => index === 0 || value <= array[index - 1]);
}

function isAscending(array) {
  return every(array, (value, index, array) => index === 0 || value >= array[index - 1]);
}

export default function ReadminObjectsTable({
  typename,
  rows,
  ignoreAssociationObjects = [],
  getDefaultAction = noop,
  onSubmit = noop,
  ...others
}) {
  const { clubId, apiPartnerId } = useContext(readminPageContext);
  if (clubId) ignoreAssociationObjects = [...ignoreAssociationObjects, { id: clubId, __typename: "Club" }];
  if (apiPartnerId)
    ignoreAssociationObjects = [...ignoreAssociationObjects, { id: apiPartnerId, __typename: "ApiPartner" }];

  let attributeLabels = (rows && rows[0]?.readminSummaryAttributeEntries?.map((e) => e.label)) || [];

  // Filter out association objects that are ignored or empty
  attributeLabels = reject(attributeLabels, (label) =>
    every(rows, (row) => {
      const attributeEntry = row.readminSummaryAttributeEntries.find((e) => e.label === label);
      return (
        attributeEntry?.readminObjects &&
        every(attributeEntry?.readminObjects, (readminObject) =>
          ignoreAssociationObjects.find((i) =>
            isEqual(pick(i, ["id", "__typename"]), pick(readminObject, ["id", "__typename"])),
          ),
        )
      );
    }),
  );

  // first column with all rows having datetime type
  const firstColumnEntries = rows?.map((row) =>
    row.readminSummaryAttributeEntries?.find((e) => e.label === attributeLabels[0]),
  );

  let datetimeLabel = null;

  if (firstColumnEntries && every(firstColumnEntries, (entry) => entry?.type === "datetime")) {
    const firstColumnDates = firstColumnEntries.map((entry) => getTime(parseISO(entry.value)));

    if (isDescending(firstColumnDates) || isAscending(firstColumnDates)) {
      datetimeLabel = attributeLabels[0];
    }
  }

  let [selectedIds, selectedIdsSet] = useState([]);
  selectedIds = selectedIds.filter((id) => rows?.find((row) => row.id === id));

  return (
    <>
      <DataTable
        {...others}
        selectable
        selectedKeys={selectedIds}
        onSelectedKeysChange={selectedIdsSet}
        selectedActions={
          <>
            {selectedIds.length > 0 && typename && (
              <BatchSelectedActions typename={typename} selectedIds={selectedIds} onSubmit={onSubmit} />
            )}
            {selectedIds.length === 1 && typename && (
              <SingularSelectedActions typename={typename} id={selectedIds[0]} onSubmit={onSubmit} />
            )}
          </>
        }
        columns={rows ? [...attributeLabels] : times(5, () => null)}
        rows={rows
          ?.map((record) => ({
            ...record,
            defaultAction: getDefaultAction(record),
          }))
          .map(({ defaultAction, ...record }) => ({
            key: record.id,
            thumbnail: <ReadminObjectThumbnail id={record.id} typename={record.__typename} />,
            datetime:
              datetimeLabel && record.readminSummaryAttributeEntries.find((e) => e.label === datetimeLabel)?.value,
            cells: [
              ...attributeLabels
                .map((label) => record.readminSummaryAttributeEntries.find((e) => e.label === label))
                .map(
                  (attributeEntry) =>
                    attributeEntry && (
                      <ReadminAttributeValue
                        {...attributeEntry}
                        dense
                        date={
                          datetimeLabel &&
                          record.readminSummaryAttributeEntries.find((e) => e.label === datetimeLabel)?.value
                        }
                      />
                    ),
                ),
            ],
            actionsDropdown: (
              <ReadminObjectActionsDropdown
                id={record.id}
                typename={record.__typename}
                onSubmit={() => onSubmit()}
                {...(defaultAction && {
                  defaultAction,
                })}
              />
            ),
            labels: <ReadminObjectLabels id={record.id} typename={record.__typename} />,
          }))}
      />
    </>
  );
}

function DialogsSelectedActions({ dialogs, ids, typename, onSubmit }) {
  dialogs = dialogs.map((component) => {
    const buttonProps = {
      content: component.type.name || "UNKNOWN DIALOG",
      ...component.type.buttonProps,
      ...(component.type.useButtonProps && component.type.useButtonProps(component.props)),
    };
    return {
      component,
      buttonProps,
    };
  });
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <>
      {formDialogs}
      {dialogs.map(({ component, buttonProps }, dialogIndex) => (
        <DataTableSelectedAction
          key={dialogIndex}
          onClick={async () => {
            await formDialogsOpen(component);
            await progressBar(() =>
              apolloClient.query({
                query: gql`
                  query ($ids: [ID!]!, $typename: String!) {
                    readminObjects(ids: $ids, typename: $typename) {
                      id
                      ...ReadminObjectLabelDescriptionsFragment
                    }
                  }
                  ${ReadminObjectLabelDescriptionsFragment}
                `,
                variables: {
                  ids,
                  typename,
                },
                fetchPolicy: "network-only",
              }),
            );
            await onSubmit?.();
          }}
          {...buttonProps}
        />
      ))}
    </>
  );
}

function BatchSelectedActions({ typename, selectedIds, onSubmit }) {
  const batchDialogs = (READMIN_OBJECT_BATCH_DIALOGS[typename] || []).map((DialogComponent) => (
    <DialogComponent {...{ [READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES[typename]]: selectedIds }} />
  ));

  return <DialogsSelectedActions dialogs={batchDialogs} ids={selectedIds} onSubmit={onSubmit} typename={typename} />;
}

function SingularSelectedActions({ typename, id, onSubmit }) {
  const dialogs = (READMIN_OBJECT_DIALOGS[typename] || []).map((DialogComponent) => (
    <DialogComponent {...{ [READMIN_OBJECT_DIALOG_PARAM_NAMES[typename]]: id }} />
  ));

  return <DialogsSelectedActions dialogs={dialogs} ids={[id]} onSubmit={onSubmit} typename={typename} />;
}

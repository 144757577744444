import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import React from "react";
import ReactJson from "react-json-view";

GameSubmissionDataTab.useTabOptions = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
  };
};

export default function GameSubmissionDataTab({ gameSubmissionId }) {
  const [data] = useData(
    gql`
      query GameSubmissionDataTab($gameSubmissionId: ID!) {
        gameSubmission(id: $gameSubmissionId) {
          id
          dataParsed
        }
      }
    `,
    { gameSubmissionId },
  );

  return (
    <Paper style={{ padding: 10 }}>
      <ReactJson name={false} src={data?.gameSubmission.dataParsed} collapsed />
    </Paper>
  );
}

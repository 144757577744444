import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import TextInput from "inputs/TextInput";
import { Plus } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const TeamAddPlayerDialog = makeDialog("player", "Player");
export const TeamAddCoachDialog = makeDialog("coach", "Coach");
export const TeamAddSupporterDialog = makeDialog("supporter", "Supporter");

function makeDialog(role, roleLabel) {
  TeamAddRoleDialog.useButtonProps = ({ teamId }) => {
    return {
      hidden: !teamId,
      icon: <Plus />,
      content: `Add ${roleLabel}`,
    };
  };

  function TeamAddRoleDialog({ personId, teamId, onSubmit = noop, onClose }) {
    const addTeamMember = useActionFragment("addTeamMember");

    return (
      <FormDialog
        onClose={onClose}
        onSubmit={async (formData) => {
          await addTeamMember({
            input: {
              role,
              ...formData,
            },
          });
          await onSubmit();
        }}
        header="Add Team Member"
      >
        <Message content={<>New team member will have access to all previous and future games.</>} />
        <Field
          name="team"
          defaultValue={teamId && { id: teamId, __typename: "Team" }}
          required
          label="Team"
          input={<ReadminObjectInput optionsTypename="Team" />}
        />
        <Field
          name="person"
          defaultValue={personId && { id: personId, __typename: "Person" }}
          required
          label="Person"
          input={<ReadminObjectInput optionsTypename="Person" />}
        />
        {role === "player" && <Field name="playerNumber" label="Player Number" input={<TextInput />} />}
      </FormDialog>
    );
  }

  return TeamAddRoleDialog;
}

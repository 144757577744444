import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import LiveStreamFbOutputsForm from "./Form";

AddLiveStreamFbOutputDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Facebook Output",
  };
};

export default function AddLiveStreamFbOutputDialog({ liveStreamId = null, onSubmit, onClose }) {
  const addLiveStreamFbOutput = useActionFragment("addLiveStreamFbOutput", "liveStreamFbOutput { id }");

  return (
    <FormDialog
      header="Add Live Stream Facebook Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLiveStreamFbOutput({
          input: formData,
        });
        await onSubmit?.(result.addLiveStreamFbOutput.liveStreamFbOutput.id);
      }}
    >
      <LiveStreamFbOutputsForm
        liveStreamFbOutputs={[
          {
            liveStream: liveStreamId && { id: liveStreamId, __typename: "LiveStream" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import useData from "hooks/useData";

export default function useIsAdmin() {
  const [data] = useData(gql`
    query useIsAdmin {
      currentUser {
        id
        isAdmin
      }
    }
  `);
  const isAdmin = data?.currentUser?.isAdmin;
  return isAdmin;
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import AdAssetForm, { AdAssetFormFragment } from "./Form";

AdAssetEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function AdAssetEditDialog({ adAssetId, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query AdAssetEditDialog($adAssetId: ID!) {
        adAsset(id: $adAssetId) {
          id
          ...AdAssetFormFragment
        }
      }
      ${AdAssetFormFragment}
    `,
    { adAssetId },
  );

  const adAssetUpdate = useActionFragment("adAssetUpdate");

  return (
    <FormDialog
      header="Edit Ad Asset"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await adAssetUpdate({
          input: {
            adAssetId,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <AdAssetForm adAsset={data?.adAsset} />
    </FormDialog>
  );
}

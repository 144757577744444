import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import ClubAdminsForm from "./Form";

AddClubAdminDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Club Admin",
  };
};

export default function AddClubAdminDialog({ clubId, onClose, onSubmit }) {
  const addClubAdmin = useActionFragment("addClubAdmin");

  return (
    <FormDialog
      header="Add Club Admin"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addClubAdmin({ input: formData });
        await onSubmit?.();
      }}
    >
      <ClubAdminsForm
        clubAdmins={[
          {
            club: clubId && {
              id: clubId,
              __typename: "Club",
            },
          },
        ]}
      />
    </FormDialog>
  );
}

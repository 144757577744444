import { InputAdornment, Slider, TextField } from "@mui/material";
import React, { useId, useLayoutEffect, useState } from "react";

NumberInput.defaultValue = null;

function format(num) {
  return num === null ? "" : String(num);
}
function parse(str) {
  if (str === "") return null;
  const num = Number(str);
  return Number.isNaN(num) ? null : num;
}

export default function NumberInput({
  value,
  onChange,
  readOnly,
  helperText,
  unit,
  min,
  max,
  step = 1,
  initValue,
  ...others
}) {
  const id = useId();
  const [str, strSet] = useState("");
  const strValue = parse(str);

  useLayoutEffect(() => {
    if (value !== strValue) strSet(format(value));
  }, [value, strValue]);

  return (
    <>
      <TextField
        fullWidth
        id={id}
        value={str}
        inputProps={{
          readOnly,
          min,
          max,
          step,
        }}
        onChange={(event) => {
          const strNew = event.target.value;
          strSet(strNew);
          const valueNew = parse(strNew);
          if (format(valueNew) === strNew) onChange(valueNew);
        }}
        type="number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" style={{ gap: 10 }}>
              {value === null && ![null, undefined].includes(initValue) && (
                <a href="#" onClick={() => onChange(initValue)}>
                  init
                </a>
              )}
              {value !== null && (
                <a href="#" onClick={() => onChange(null)}>
                  clear
                </a>
              )}
              {unit && <span>{unit}</span>}
              {min !== undefined && <span>≥ {min}</span>}
              {max !== undefined && <span>≤ {max}</span>}
              {step !== 1 && <span>∆ {step}</span>}
            </InputAdornment>
          ),
        }}
        helperText={
          <>
            {![null, undefined].includes(min) &&
              ![null, undefined].includes(max) &&
              ![null, undefined].includes(value) && (
                <Slider
                  size="small"
                  value={value}
                  min={min}
                  max={max}
                  step={step}
                  marks={(max - min) / step < 20}
                  onChange={(_, valueNew) => onChange(valueNew)}
                />
              )}
            {helperText}
          </>
        }
        {...others}
      />
    </>
  );
}

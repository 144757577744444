import { gql } from "@apollo/client";
import apolloClient from "helpers/apolloClient";
import { useEffect } from "react";

import { useActionFragment } from "./useAction";
import useData from "./useData";
import useGotoUrl from "./useGotoUrl";

export default function useEnforceAuth({ enforceUnauthenticated = false, enforceAuthenticated = false } = {}) {
  const [data, dataMeta] = useData(
    gql`
      query useEnforceAuth {
        currentUser {
          id
          defaultUrl
        }
        onsenUrl
      }
    `,
    null,
    {
      skip: !enforceUnauthenticated && !enforceAuthenticated,
    },
  );

  const gotoUrl = useGotoUrl();
  const logout = useActionFragment("logout");

  useEffect(() => {
    const currentUser = data?.currentUser;
    if (currentUser && enforceUnauthenticated)
      Promise.resolve().then(async () => {
        let redirectToPath = location.search.match(/redirect=([^&]+)/)?.[1];
        if (redirectToPath) redirectToPath = decodeURIComponent(redirectToPath);
        redirectToPath = redirectToPath || currentUser.defaultUrl;

        if (!redirectToPath) {
          await logout({ input: {} });
          await apolloClient.clearStore();
          gotoUrl(data?.onsenUrl, { replace: true });
        } else gotoUrl(redirectToPath, { replace: true });
      });
    if (data && !data.currentUser && enforceAuthenticated) {
      gotoUrl(`/readmin/login?redirect=${encodeURIComponent(window.location.pathname)}`, { replace: true });
    }
  }, [data]);

  const enforceAuth = {
    loading: !data,
    refetch: dataMeta.refetch,
  };
  return enforceAuth;
}

import { createTheme } from "@mui/material";

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#1F2F74",
    },
    secondary: {
      main: "#F1394B",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

export default muiTheme;

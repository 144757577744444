import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import CamerasForm from "./Form";

AddCameraDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    icon: <Plus />,
    content: "Add Camera",
    hidden: !isAdmin,
  };
};

export default function AddCameraDialog({ venueServerId, cameraModelId, onSubmit, onClose }) {
  const addCamera = useActionFragment("addCamera", `camera { id }`);

  return (
    <FormDialog
      header="Add Camera"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addCamera({
          input: formData,
        });
        await onSubmit?.(result.addCamera.camera);
      }}
    >
      <CamerasForm
        cameras={[
          {
            venueServer: venueServerId && { id: venueServerId, __typename: "VenueServer" },
            cameraModel: cameraModelId && { id: cameraModelId, __typename: "CameraModel" },
          },
        ]}
      />
    </FormDialog>
  );
}

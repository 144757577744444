import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { ChevronDown } from "mdi-material-ui";
import React from "react";

import Message from "./Message";

export default function FormSection({ header, description, children, defaultExpanded = false }) {
  return (
    <div>
      <Accordion disableGutters defaultExpanded={defaultExpanded} variant="outlined">
        <AccordionSummary expandIcon={<ChevronDown />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography>{header}</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{
            padding: 10,
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
          }}
        >
          {description && <Message content={description} />}
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export function FormSectionText({ children }) {
  return (
    <div
      style={{
        padding: "0 10px",
      }}
    >
      {children}
    </div>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import AdPresetsForm, { AdPresetsFormFragment } from "./Form";

AdPresetsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function AdPresetsEditDialog({ adPresetIds, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query AdPresetsEditDialog($adPresetIds: [ID!]!) {
        adPresets(ids: $adPresetIds) {
          id
          ...AdPresetsFormFragment
        }
      }
      ${AdPresetsFormFragment}
    `,
    { adPresetIds },
  );

  const adPresetsUpdate = useActionFragment("adPresetsUpdate");

  return (
    <FormDialog
      header="Edit Ad Preset"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await adPresetsUpdate({
          input: {
            adPresetIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <AdPresetsForm adPresets={data?.adPresets} />
    </FormDialog>
  );
}

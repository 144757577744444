import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import DataTable from "controls/DataTable";
import DateTime from "controls/DateTime";
import DateRangeFilter, { TODAY } from "filters/DateRangeFilter";
import FiltersBar from "filters/FiltersBar";
import useData from "hooks/useData";
import useRouteState from "hooks/useRouteState";
import useTimeZone from "hooks/useTimeZone";
import React from "react";

export default function CameraRecordingSchedulesTab({ cameraId }) {
  const [dateRange, dateRangeSet] = useRouteState("dateRange", TODAY);
  const timeZone = useTimeZone();

  const [data] = useData(
    gql`
      query CameraRecordingSchedulesTab($cameraId: ID!, $dateRange: DateRange!, $timeZone: String!) {
        camera(id: $cameraId) {
          id
          recordingSchedules(dateRange: $dateRange, timeZone: $timeZone) {
            startTime
            endTime
            sources
          }
        }
      }
    `,
    {
      cameraId,
      dateRange,
      timeZone,
    },
  );

  return (
    <>
      <FiltersBar>
        <DateRangeFilter value={dateRange} onChange={dateRangeSet} future required />
      </FiltersBar>
      <Paper>
        <DataTable
          columns={["Start Time", "End Time", "Sources"]}
          rows={data?.camera?.recordingSchedules.map((recordingSchedule, recordingScheduleIndex) => ({
            key: recordingScheduleIndex,
            datetime: recordingSchedule.startTime,
            cells: [
              <DateTime value={recordingSchedule.startTime} variant="time" />,
              <DateTime value={recordingSchedule.endTime} variant="time" />,
              <>{recordingSchedule.sources.map((source) => `${source.name}/${source.id}`).join(", ")}</>,
            ],
          }))}
        />
      </Paper>
    </>
  );
}

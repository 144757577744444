import { gql, useMutation } from "@apollo/client";
import Message from "controls/Message";
import apolloClient from "helpers/apolloClient";
import getApolloErrors from "helpers/getApolloErrors";
import React from "react";
import { classify } from "underscore.string";

import useProgressBar from "./useProgressBar";
import useShowMessage from "./useShowMessage";

export default function useAction(mutation, defaultOptions) {
  const showMessage = useShowMessage();
  const [mutate, { loading, error }] = useMutation(mutation, {
    client: apolloClient,
  });

  useProgressBar(loading);

  const action = async (variables, actionOptions) => {
    const options = {
      ...(defaultOptions?.constructor === Function ? defaultOptions(variables) : defaultOptions),
      ...actionOptions,
    };

    let result;
    try {
      result = await mutate({
        ...options,
        variables,
      });
    } catch (apolloError) {
      const errors = getApolloErrors(apolloError);
      for (const error of errors) {
        if (error.apolloErrorType === "GraphQLError")
          // eslint-disable-next-line no-console
          console.error(`[GraphQLError/${error.path}]`, error.message);
      }
      await showMessage("We are unable to complete request", {
        modal: true,
        header: "Unable to Complete Request",
        content: (
          <>
            We are unable to complete request for the reason(s) listed below:
            <ul>
              {errors.map((error, errorIndex) => (
                <li key={errorIndex}>
                  {error.message}
                  {error.uuid && <span> (UUID: {error.uuid})</span>}
                </li>
              ))}
            </ul>
            <Message
              type="info"
              content={
                <>
                  If you think this is an error please notify us at{" "}
                  <a href="mailto:support@gloryleague.com">support@gloryleague.com</a> with details and screenshot.
                </>
              }
            />
          </>
        ),
      });
      throw apolloError;
    }

    return result.data;
  };

  action.loading = loading;
  action.error = error;
  return action;
}

export function useActionFragment(actionName, fragment) {
  const actionClassName = classify(actionName);
  fragment ||= `
    clientMutationId
  `;

  return useAction(gql`
    mutation ${actionName}($input: ${actionClassName}Input!) {
      ${actionName}(input: $input) {
        ${fragment}
      }
    }
  `);
}

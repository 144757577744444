import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { Reload, TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const TeamRemoveAllPlayersDialog = makeConfirmActionDialog({
  mutationName: "teamRemoveAllPlayers",
  paramName: "teamId",
  action: "Remove All Players",
  useButtonProps: ({ teamId }) => {
    const [data] = useData(
      gql`
        query TeamRemoveAllPlayersDialog($teamId: ID!) {
          team(id: $teamId) {
            id
            playersCount: teamMembersCount(role: "player")
          }
        }
      `,
      { teamId },
    );

    return {
      disabled: !data?.team?.playersCount,
      icon: <TrashCan />,
      content: "Remove All Players",
    };
  },
});

export const TeamsRestoreApiRosterDialog = makeConfirmActionDialog({
  mutationName: "teamsRestoreApiRoster",
  paramName: "teamIds",
  useConfirmFormDialogProps: ({ teamIds }) => {
    const [data] = useData(
      gql`
        query TeamsRestoreApiRosterDialog($teamIds: [ID!]!) {
          teams(ids: $teamIds) {
            id
            name
            apiPartner {
              id
              name
            }
            apiRosterEntries
          }
        }
      `,
      { teamIds },
    );

    const apiPartnerName = data?.teams?.[0]?.apiPartner?.name;

    return {
      action: `Restore ${apiPartnerName} Roster`,
      children: (
        <>
          {data?.teams.map((team) => (
            <div key={team.id}>
              {apiPartnerName} Roster for {team.name} will be restored to:
              <ul>
                {!team.apiRosterEntries.length && <li>(empty)</li>}
                {team.apiRosterEntries.map((entry) => (
                  <li key={entry}>{entry}</li>
                ))}
              </ul>
            </div>
          ))}
        </>
      ),
    };
  },
  useButtonProps: ({ teamIds }) => {
    const [data] = useData(
      gql`
        query TeamsRestoreApiRosterDialog($teamIds: [ID!]!) {
          teams(ids: $teamIds) {
            id
            name
            apiPartner {
              id
              name
            }
          }
        }
      `,
      { teamIds },
    );

    const apiPartnerName = data?.teams?.[0]?.apiPartner?.name;

    return {
      hidden: !apiPartnerName,
      icon: <Reload />,
      content: `Restore ${apiPartnerName} Roster`,
    };
  },
});

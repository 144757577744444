import { Button } from "@mui/material";
import React, { useState } from "react";

export default function FetchMoreButton({ fetchMore, rows, rowsCount = null, ...others }) {
  const [offset, offsetSet] = useState();
  const [fetchingMore, fetchingMoreSet] = useState(false);
  const endOfPage = rowsCount != null ? rows?.length >= rowsCount : offset === rows?.length;

  return (
    <div {...others} style={{ padding: 5, ...others.style }}>
      <Button
        variant="outlined"
        onClick={async () => {
          fetchingMoreSet(true);
          try {
            await fetchMore({
              variables: {
                offset: rows.length,
              },
            });
            offsetSet(rows.length);
          } finally {
            fetchingMoreSet(false);
          }
        }}
        disabled={fetchingMore || !rows || endOfPage}
      >
        {endOfPage && <>End of results</>}
        {fetchingMore && <>Loading...</>}
        {!fetchingMore && !!rows && !endOfPage && <>Load More Results</>}
        {!!rows && (
          <span style={{ margin: "0 .5em", opacity: 0.5, fontSize: "0.8em" }}>
            (showing {rows.length}/{rowsCount === null ? "?" : rowsCount})
          </span>
        )}
      </Button>
    </div>
  );
}

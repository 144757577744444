import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { LinkOff } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const CurrentUserRemoveLiveStreamFbAuthDialog = makeConfirmActionDialog({
  mutationName: "currentUserRemoveLiveStreamFbAuth",
  action: "Disconnect Facebook Account",
  useButtonProps: () => {
    const [data] = useData(gql`
      query CurrentUserRemoveLiveStreamFbAuthDialog {
        currentUser {
          liveStreamFbAuth {
            id
          }
        }
      }
    `);

    return {
      content: "Disconnect Facebook Account",
      hidden: !data?.currentUser?.liveStreamFbAuth,
      icon: <LinkOff />,
    };
  },
});

export const CurrentUserRemoveLiveStreamYtAuthDialog = makeConfirmActionDialog({
  mutationName: "currentUserRemoveLiveStreamYtAuth",
  action: "Disconnect YouTube Account",
  useButtonProps: () => {
    const [data] = useData(gql`
      query CurrentUserRemoveLiveStreamYtAuthDialog {
        currentUser {
          liveStreamYtAuth {
            id
          }
        }
      }
    `);

    return {
      content: "Disconnect YouTube Account",
      hidden: !data?.currentUser?.liveStreamYtAuth,
      icon: <LinkOff />,
    };
  },
});

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";
import RecurringRecordingForm, {
  RecurringRecordingFormFragment,
} from "readmin_pages/recurring_recordings/RecurringRecordingForm";

RecurringRecordingOccurrenceEditDialog.useButtonProps = ({ recurringRecordingOccurrenceId }) => {
  const [data] = useData(
    gql`
      query RecurringRecordingOccurrenceEditDialogButton($recurringRecordingOccurrenceId: ID!) {
        recurringRecordingOccurrence(id: $recurringRecordingOccurrenceId) {
          id
          external
        }
      }
    `,
    { recurringRecordingOccurrenceId },
  );

  return {
    disabled: !data,
    hidden: data?.recurringRecordingOccurrence.external,
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function RecurringRecordingOccurrenceEditDialog({
  recurringRecordingOccurrenceId,
  onSubmit = noop,
  ...others
}) {
  const [data] = useData(
    gql`
      query RecurringRecordingOccurrenceEditDialog($recurringRecordingOccurrenceId: ID!) {
        recurringRecordingOccurrence(id: $recurringRecordingOccurrenceId) {
          id
          recurringRecording {
            id
            ...RecurringRecordingFormFragment
          }
        }
      }
      ${RecurringRecordingFormFragment}
    `,
    { recurringRecordingOccurrenceId },
  );

  const recurringRecordingUpdate = useActionFragment("recurringRecordingUpdate");

  return (
    <FormDialog
      header="Edit Scheduled Game"
      loading={!data}
      onSubmit={async (formData) => {
        await recurringRecordingUpdate({
          input: {
            recurringRecordingId: data.recurringRecordingOccurrence.recurringRecording.id,
            ...formData,
          },
        });
        await onSubmit();
      }}
      {...others}
    >
      <RecurringRecordingForm recurringRecording={data?.recurringRecordingOccurrence.recurringRecording} />
    </FormDialog>
  );
}

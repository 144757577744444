import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import OriginalVideoRequestsForm, { OriginalVideoRequestsFormFragment } from "./Form";

OriginalVideoRequestsEditDialog.useButtonProps = () => {
  return {
    content: "Edit",
    icon: <Pencil />,
  };
};

export function OriginalVideoRequestsEditDialog({ originalVideoRequestIds, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query CityEditDialogQuery($ids: [ID!]!) {
        originalVideoRequests(ids: $ids) {
          id
          ...OriginalVideoRequestsFormFragment
        }
      }
      ${OriginalVideoRequestsFormFragment}
    `,
    { ids: originalVideoRequestIds },
  );
  const originalVideoRequestsUpdate = useActionFragment("originalVideoRequestsUpdate");

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit OriginalVideoRequests"
      onClose={onClose}
      onSubmit={async (formData) => {
        await originalVideoRequestsUpdate({
          input: {
            originalVideoRequestIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <OriginalVideoRequestsForm originalVideoRequests={data?.originalVideoRequests} />
    </FormDialog>
  );
}

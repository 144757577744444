import { Divider, FormHelperText, IconButton } from "@mui/material";
import Field from "controls/Field";
import Form, { FormSubmitButton } from "controls/Form";
import apolloClient from "helpers/apolloClient";
import { OAUTH_LOGIN_ENABLED } from "helpers/constants";
import oauthAuthorise from "helpers/oauthAuthorise";
import { useActionFragment } from "hooks/useAction";
import useEnforceAuth from "hooks/useEnforceAuth";
import { progressBar } from "hooks/useProgressBar";
import TextInput from "inputs/TextInput";
import { Google } from "mdi-material-ui";
import React, { useState } from "react";

import PublicForm from "./PublicForm";

export default function LoginPage() {
  const login = useActionFragment("login", "currentUser { id }");
  const enforceAuth = useEnforceAuth({ enforceUnauthenticated: true });
  const [invalidAuth, invalidAuthSet] = useState(false);

  if (enforceAuth.loading) {
    return <PublicForm dense loading />;
  }

  return (
    <PublicForm
      dense
      title="Glory League Administrator Login"
      paperProps={{
        component: Form,
        onSubmit: async (formData) => {
          invalidAuthSet(false);
          const result = await login({
            input: {
              ...formData,
            },
          });
          if (!result.login.currentUser) {
            return invalidAuthSet(true);
          }
          await progressBar(() => apolloClient.refetchQueries({ include: "active" }));
        },
      }}
    >
      <Field
        name="email"
        label="Email"
        input={<TextInput error={invalidAuth} autoFocus type="email" autoComplete="email" />}
        required
      />
      <Field
        name="password"
        label="Password"
        input={
          <TextInput
            error={invalidAuth}
            type="password"
            autoComplete="current-password"
            helperText={invalidAuth && "Invalid email or password."}
          />
        }
        required
      />
      <FormSubmitButton content="Login" />
      {OAUTH_LOGIN_ENABLED && (
        <>
          <Divider>or</Divider>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1em",
            }}
          >
            <span>Login with:</span>
            <IconButton onClick={() => oauthAuthorise("google_login")}>
              <Google />
            </IconButton>
          </div>
        </>
      )}
      <FormHelperText>
        Don&apos;t have a new password or forgot your password? <a href="/readmin/reset-password">reset password</a>
      </FormHelperText>
    </PublicForm>
  );
}

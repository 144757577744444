import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import DateTimeInput from "inputs/DateTimeInput";
import { Plus } from "mdi-material-ui";
import React from "react";

TeamsEnableRegistrationDialog.useButtonProps = ({ teamIds }) => {
  const [data] = useData(
    gql`
      query EnableRegistrationDialogButton($teamIds: [ID!]!) {
        teams(ids: $teamIds) {
          id
          teamRegistration {
            id
          }
        }
      }
    `,
    { teamIds },
  );

  return {
    content: "Enable Registration Form",
    icon: <Plus />,
    hidden: !data || !!data.teams.every((t) => t.teamRegistration),
  };
};

export default function TeamsEnableRegistrationDialog({ teamIds, onSubmit = noop, ...others }) {
  const teamsEnableRegistration = useActionFragment("teamsEnableRegistration", "teams { id teamRegistration { id } }");

  return (
    <FormDialog
      header="Enable registration form for team"
      onSubmit={async (formData) => {
        await teamsEnableRegistration({
          input: {
            teamIds,
            ...formData,
          },
        });
        await onSubmit();
      }}
      {...others}
    >
      <Message content="Enable coaches to manage team rosters in Glory League" />
      <Field name="expiresAt" label="Expires At" required input={<DateTimeInput autoFocus />} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Plus } from "mdi-material-ui";
import React from "react";

import LeaguesForm from "./Form";

AddLeagueDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add League",
  };
};

export default function AddLeagueDialog({ clubId = null, onSubmit, onClose }) {
  const addLeague = useActionFragment("addLeague", "league { id }");
  const [data, dataMeta] = useData(
    gql`
      query AddLeagueDialogQuery($clubId: ID) {
        club(id: $clubId) {
          id
          defaultForNewLeagueAdPreset {
            id
          }
          primarySport
        }
      }
    `,
    { clubId },
  );

  return (
    <FormDialog
      header="Add League"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLeague({
          input: formData,
        });
        await onSubmit?.(result.addLeague.league);
      }}
    >
      <LeaguesForm
        leagues={[
          {
            active: true,
            club: clubId && { id: clubId, __typename: "Club" },
            sport: {
              basketball: "Basketball",
              futsal: "Futsal",
            }[data?.club?.primarySport],
            adPreset: data?.club?.defaultForNewLeagueAdPreset,
            pointsForWin: 2,
            pointsForDraw: 1,
            pointsForLoss: 0,
          },
        ]}
      />
    </FormDialog>
  );
}

import { Skeleton } from "@mui/material";
import React from "react";

export default function Duration({ seconds }) {
  if (seconds === undefined || seconds === null) return <Skeleton variant="text" />;
  const days = Math.floor(seconds / 86400);
  const hh = Math.floor((seconds % 86400) / 3600)
    .toString()
    .padStart(2, "0");
  const mm = Math.floor((seconds % 3600) / 60)
    .toString()
    .padStart(2, "0");
  const ss = Math.floor(seconds % 60)
    .toString()
    .padStart(2, "0");

  return (
    <>
      {days ? <>{days} days</> : null}
      {hh}:{mm}:{ss}
    </>
  );
}

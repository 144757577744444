import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import SelectInput from "inputs/SelectInput";
import { Plus } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddLeagueMemberDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add League Member",
  };
};

export default function AddLeagueMemberDialog({ leagueId, onClose, onSubmit }) {
  const addLeagueMember = useActionFragment("addLeagueMember");

  return (
    <FormDialog
      header="Add League Member"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addLeagueMember({ input: formData });
        await onSubmit?.();
      }}
    >
      <Field
        name="league"
        label="League"
        required
        defaultValue={leagueId && { id: leagueId, __typename: "League" }}
        input={<ReadminObjectInput optionsTypename="League" />}
      />
      <Field name="person" label="Person" required input={<ReadminObjectInput optionsTypename="Person" />} />
      <Field
        name="role"
        label="Role"
        required
        input={
          <SelectInput
            options={[
              ["Official", "official"],
              ["Supporter", "supporter"],
            ]}
          />
        }
      />
    </FormDialog>
  );
}

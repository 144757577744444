import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddLeagueTeamDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Team to League",
  };
};

export default function AddLeagueTeamDialog({ leagueId, teamId, onSubmit, onClose }) {
  const addLeagueTeam = useActionFragment("addLeagueTeam");

  return (
    <FormDialog
      header="Add Team to League"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addLeagueTeam({ input: formData });
        await onSubmit?.();
      }}
    >
      <Field
        name="league"
        required
        label="League"
        defaultValue={leagueId && { id: leagueId, __typename: "League" }}
        input={<ReadminObjectInput optionsTypename="League" />}
      />
      <Field
        name="team"
        required
        label="Team"
        defaultValue={teamId && { id: teamId, __typename: "Team" }}
        input={<ReadminObjectInput optionsTypename="Team" />}
      />
    </FormDialog>
  );
}

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import EditRawJSONDialog from "controls/EditRawJSONDialog";
import FormFieldContainer from "controls/FormFieldContainer";
import Message from "controls/Message";
import ZoomPan from "controls/ZoomPan";
import useLoadedImage from "helpers/useLoadedImage";
import useFormDialogs from "hooks/useFormDialogs";
import React, { useEffect, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { useComponentSize } from "react-use-size";

export default function PolygonInput({
  value,
  onChange,
  disabled = false,
  fill = false,
  stroke = false,
  backgroundUrl,
  ...others
}) {
  const [backgroundImage, backgroundLoading] = useLoadedImage(backgroundUrl);
  const backgroundImageAspectRatio = backgroundImage ? backgroundImage.width / backgroundImage.height : 1;
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  let points = [];
  let valid = false;
  if ([null, undefined].includes(value)) {
    valid = true;
  } else if (Array.isArray(value) && value.every((v) => typeof v.x === "number" && typeof v.y === "number")) {
    points = value.map((v) => [v.x, v.y]);
    valid = true;
  }
  const [editing, editingSet] = useState(false);
  const [zoom, zoomSet] = useState(1);

  return (
    <FormFieldContainer disabled={disabled} {...others}>
      {formDialogs}
      <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: 5, padding: 5 }}>
        <Button
          variant="outlined"
          color="inherit"
          disabled={disabled}
          {...(!valid && { color: "error" })}
          onClick={async () => {
            const valueNew = await formDialogsOpen(<EditRawJSONDialog value={value} />);
            onChange(valueNew);
          }}
        >
          Raw JSON
        </Button>
        <Button variant="outlined" disabled={!backgroundImage || disabled} onClick={() => editingSet((e) => !e)}>
          Edit
        </Button>
        {backgroundLoading && <CircularProgress />}
        {!!backgroundUrl && !backgroundLoading && !backgroundImage && <>Failed to load preview</>}
        {!!backgroundImage && (
          <ImageCanvas
            image={backgroundImage}
            style={{
              ...(editing
                ? {
                    width: "100%",
                  }
                : {
                    height: "3em",
                  }),
            }}
            points={points}
            fill={fill}
            stroke={stroke}
            disabled={disabled || !editing}
            onPointsChange={(pointsNew) => {
              const valueNew = pointsNew.length ? pointsNew.map(([x, y]) => ({ x, y })) : null;
              onChange(valueNew);
            }}
          />
        )}
        <Dialog open={editing} onClose={() => editingSet(false)} maxWidth="xl" fullWidth>
          <DialogTitle>Edit {others.label}</DialogTitle>
          <DialogContent style={{ display: "flex", flexFlow: "column nowrap", gap: 10 }}>
            <Message content="Click on the image to add points. Drag points to move them." />
            <div
              style={{
                display: "flex",
                flexFlow: "row wrap",
                gap: 10,
              }}
            >
              <Button variant="outlined" disabled={!points.length} onClick={() => onChange(null)}>
                Clear
              </Button>
              <Button
                variant="outlined"
                disabled={!points.length}
                onClick={() => onChange(points.slice(0, -1).map(([x, y]) => ({ x, y })))}
              >
                Remove Last Point
              </Button>
              <Button variant="outlined" onClick={() => zoomSet((z) => z * 2)}>
                Zoom In
              </Button>
              <Button variant="outlined" disabled={zoom === 1} onClick={() => zoomSet(1)}>
                Reset
              </Button>
            </div>
            <ZoomPan zoom={zoom} aspectRatio={backgroundImageAspectRatio}>
              {(width, height) => (
                <ImageCanvas
                  style={{
                    width,
                    height,
                  }}
                  image={backgroundImage}
                  points={points}
                  fill={fill}
                  stroke={stroke}
                  onPointsChange={(pointsNew) => {
                    const valueNew = pointsNew.length ? pointsNew.map(([x, y]) => ({ x, y })) : null;
                    onChange(valueNew);
                  }}
                />
              )}
            </ZoomPan>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => editingSet(false)}>Done</Button>
          </DialogActions>
        </Dialog>
      </div>
    </FormFieldContainer>
  );
}

function ImageCanvas({ image, fill, stroke, points, onPointsChange, disabled, ...others }) {
  const canvasRef = useRef();
  useEffect(() => {
    const ctx = canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, image.width, image.height);
    ctx.drawImage(image, 0, 0);
  }, [image]);
  const { width, height, ref } = useComponentSize();

  return (
    <div
      {...others}
      ref={ref}
      style={{
        ...others.style,
        aspectRatio: image.width / image.height,
        position: "relative",
        outline: "1px solid red",
      }}
    >
      <canvas
        ref={canvasRef}
        width={image?.width}
        height={image?.height}
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width,
          height,
          cursor: disabled ? undefined : "crosshair",
        }}
        onDragOver={(event) => {
          event.preventDefault();
          event.dataTransfer.dropEffect = "move";
        }}
        onClick={(event) => {
          if (disabled) return;
          const rect = event.target.getBoundingClientRect();
          const offset = [event.clientX - rect.left, event.clientY - rect.top];
          const newPoint = [
            Math.round((offset[0] / rect.width) * image.width),
            Math.round((offset[1] / rect.height) * image.height),
          ];
          onPointsChange([...points, newPoint]);
        }}
      />
      <svg
        viewBox={`0 0 ${image.width} ${image.height}`}
        style={{ position: "absolute", top: 0, left: 0, width, height, pointerEvents: "none" }}
      >
        {stroke && (
          <polygon
            points={points.map((p) => p.join(",")).join(" ")}
            fill={fill ? "rgba(255, 0, 0, 0.2)" : "rgba(0, 0, 0, 0)"}
            stroke="red"
            strokeWidth="2"
            style={{ pointerEvents: "none" }}
          />
        )}
      </svg>
      {points.map(([x, y], i) => (
        <Rnd
          key={i}
          disableDragging={disabled}
          enableResizing={false}
          bounds="parent"
          style={{
            backgroundColor: "rgba(255, 255, 0, 0.8)",
            borderRadius: "50%",
            fontSize: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: disabled ? undefined : "move",
            userSelect: "none",
          }}
          position={{
            x: (x / image.width) * width - 5,
            y: (y / image.height) * height - 5,
          }}
          size={{ width: 10, height: 10 }}
          onDragStop={(event, position) => {
            const pointNew = [
              Math.round(((position.x + 5) / width) * image.width),
              Math.round(((position.y + 5) / height) * image.height),
            ];
            onPointsChange([...points.slice(0, i), pointNew, ...points.slice(i + 1)]);
          }}
        >
          {i + 1}
        </Rnd>
      ))}
    </div>
  );
}

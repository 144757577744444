import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import LiveStreamsForm, { LiveStreamsFormFragment } from "./Form";

LiveStreamsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function LiveStreamsEditDialog({ liveStreamIds, onClose, onSubmit }) {
  const liveStreamsUpdate = useActionFragment("liveStreamsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query LiveStreamsEditDialogQuery($liveStreamIds: [ID!]!) {
        liveStreams(ids: $liveStreamIds) {
          id
          ...LiveStreamsFormFragment
        }
      }
      ${LiveStreamsFormFragment}
    `,
    { liveStreamIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Custom Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        await liveStreamsUpdate({
          input: {
            liveStreamIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <LiveStreamsForm liveStreams={data?.liveStreams} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import DateTimeInput from "inputs/DateTimeInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const OriginalVideoRequestsFormFragment = gql`
  fragment OriginalVideoRequestsFormFragment on OriginalVideoRequest {
    game {
      id
    }
    expiresAt
  }
`;

export default function OriginalVideoRequestsForm({ originalVideoRequests = [{}] }) {
  return (
    <>
      <MultiValuesField
        name="game"
        label="Game"
        required
        defaultValues={originalVideoRequests.map((o) => o.game)}
        input={<ReadminObjectInput optionsTypename="Game" />}
      />
      <MultiValuesField
        name="expiresAt"
        label="Expires At"
        required
        defaultValues={originalVideoRequests.map((o) => o.expiresAt)}
        input={<DateTimeInput />}
      />
    </>
  );
}

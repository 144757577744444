import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import TextInput from "inputs/TextInput";
import { Cog } from "mdi-material-ui";
import React, { Fragment, useState } from "react";

VenueServersRunCommandDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    icon: <Cog />,
    content: "Run Command",
  };
};

export default function VenueServersRunCommandDialog({ venueServerIds, onClose, onSubmit }) {
  const venueServersRunCommand = useActionFragment("venueServersRunCommand", "venueServerCommands { id }");
  const [content, contentSet] = useState("");

  return (
    <FormDialog
      header="Run Command on Venue Server"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await venueServersRunCommand({
          input: {
            venueServerIds,
            ...formData,
          },
        });
        await onSubmit?.(result.venueServersRunCommand.venueServerCommands);
      }}
    >
      <Field
        autoFocus
        label="Content"
        name="content"
        required
        value={content}
        onChange={contentSet}
        input={<TextInput autoFocus multiline minRows={5} />}
        helperText={
          <>
            Common commands:
            <br />
            {[
              //
              "/sbin/reboot",
              "/apps/gl_chef/entrypoint",
              "/apps/internet-speed-test.sh",
              "systemctl status",
              "pstree -aAlpt",
            ].map((command) => (
              <Fragment key={command}>
                <a href="#" onClick={() => contentSet(command)}>
                  <code>{command}</code>
                </a>
                <br />
              </Fragment>
            ))}
          </>
        }
      />
    </FormDialog>
  );
}

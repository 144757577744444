import { gql } from "@apollo/client";
import DialogsToolbar from "controls/DialogsToolbar";
import apolloClient from "helpers/apolloClient";
import { readminPageContext } from "helpers/contexts";
import READMIN_OBJECT_TYPES from "helpers/READMIN_OBJECT_TYPES";
import useGotoUrl from "hooks/useGotoUrl";
import { progressBar } from "hooks/useProgressBar";
import React, { useContext } from "react";

import { READMIN_OBJECT_DIALOG_PARAM_NAMES, READMIN_OBJECTS_INDEX_DIALOGS } from "./READMIN_OBJECT_DIALOGS";

export default function ReadminObjectsDialogToolbar({
  typename,
  parentTypename = null,
  parentId,
  onSubmit,
  gotoReadminObjectUrl = false,
}) {
  const gotoUrl = useGotoUrl();
  const { clubId, apiPartnerId } = useContext(readminPageContext);
  const dialogs = READMIN_OBJECTS_INDEX_DIALOGS[typename]?.map((DialogComponent) => (
    <DialogComponent
      {...{
        ...(clubId && { clubId }),
        ...(apiPartnerId && { apiPartnerId }),
        [READMIN_OBJECT_DIALOG_PARAM_NAMES[parentTypename]]: parentId,
      }}
    />
  ));

  if (!dialogs) return null;

  return (
    <DialogsToolbar
      dialogs={dialogs}
      onSubmit={async (dialogResult) => {
        if (gotoReadminObjectUrl && READMIN_OBJECT_TYPES.includes(dialogResult?.__typename)) {
          const result = await progressBar(() =>
            apolloClient.query({
              query: gql`
                query ReadminObjectsPage_ReadminObjectsDialog($id: ID!, $typename: String!) {
                  readminObject(id: $id, typename: $typename) {
                    id
                    readminUrl
                  }
                }
              `,
              variables: {
                id: dialogResult.id,
                typename: dialogResult.__typename,
              },
            }),
          );
          const readminUrl = result?.data?.readminObject?.readminUrl;
          if (readminUrl) return await gotoUrl(readminUrl);
        }
        await onSubmit?.(dialogResult);
      }}
    />
  );
}

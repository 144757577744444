import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import RecordingBlockForm from "./Form";

AddRecordingBlockDialog.buttonProps = {
  icon: <Plus />,
  content: "Add Recording Block",
};

export default function AddRecordingBlockDialog({ venueId, onSubmit, onClose }) {
  const addRecordingBlock = useActionFragment("addRecordingBlock", "recordingBlock { id }");

  return (
    <FormDialog
      header="Add Recording Block"
      onSubmit={async (formData) => {
        const result = await addRecordingBlock({ input: formData });
        await onSubmit?.(result.addRecordingBlock.recordingBlock);
      }}
      onClose={onClose}
    >
      <RecordingBlockForm recordingBlock={{ venue: venueId && { id: venueId, __typename: "Venue" } }} />
    </FormDialog>
  );
}

import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const GamePersonRemoveDialog = makeConfirmActionDialog({
  mutationName: "gamePersonRemove",
  paramName: "gamePersonId",
  action: "Remove Game Role",
  useButtonProps: () => ({
    icon: <TrashCan />,
    content: "Remove Game Role",
  }),
});

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import LiveStreamCustomOutputsForm from "./Form";

AddLiveStreamDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Live Stream",
  };
};

export default function AddLiveStreamDialog({ clubId = null, onSubmit, onClose }) {
  const addLiveStream = useActionFragment("addLiveStream", "liveStream { id }");

  return (
    <FormDialog
      header="Add LiveStream"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLiveStream({
          input: formData,
        });
        await onSubmit?.(result.addLiveStream.liveStream);
      }}
    >
      <LiveStreamCustomOutputsForm
        liveStreams={[
          {
            club: clubId && { id: clubId, __typename: "Club" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const RecordingBlockExceptionDateRemoveDialog = makeConfirmActionDialog({
  mutationName: "recordingBlockExceptionDateRemove",
  paramName: "recordingBlockExceptionDateId",
  action: "Remove Exception Date",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

import { gql } from "@apollo/client";
import { AppBar, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, ThemeProvider } from "@mui/material";
import apolloClient from "helpers/apolloClient";
import muiTheme from "helpers/muiTheme";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { progressBar } from "hooks/useProgressBar";
import imgLogoNew from "images/logo-new.png";
import { Account, Logout, MenuDown, Refresh, SwapHorizontal } from "mdi-material-ui";
import React, { useState } from "react";

export default function ReadminMenu({ clubId, apiPartnerId }) {
  const isAdmin = useIsAdmin();

  const [data] = useData(
    gql`
      query ReadminMenu($clubId: ID, $apiPartnerId: ID) {
        currentUser {
          id
          email
          defaultUrl
          administeredClubsAny
          administeredVenuesAny
          canAccessReadmin
        }
        onsenUrl
        apiPartners: readminObjects(typename: "ApiPartner") {
          id
          readminName
        }
        club(id: $clubId) {
          id
          name
          logoUrl
          usePublicLeaguePages
        }
        apiPartner(id: $apiPartnerId) {
          id
          name
        }
      }
    `,
    { clubId, apiPartnerId },
  );

  const club = data?.club;
  const apiPartner = data?.apiPartner;

  return (
    <ThemeProvider theme={muiTheme}>
      <AppBar position="sticky" style={{ zIndex: 100, display: "flex", flexFlow: "row wrap" }}>
        <ReadminMenuImageItem src={imgLogoNew} href={data?.currentUser?.defaultUrl || "/"} label="Glory League" />
        {club && (
          <>
            {club.logoUrl ? (
              <ReadminMenuImageItem src={club.logoUrl} label={club.name} href={`/readmin/clubs/${clubId}`} />
            ) : (
              <ReadminMenuItem label={club.name} href={`/readmin/clubs/${clubId}`} />
            )}

            <ReadminMenuDropdown label="Competitions">
              <ReadminMenuDropdownItem label="Leagues" href={`/readmin/clubs/${clubId}/leagues`} />
              <ReadminMenuDropdownItem label="Teams" href={`/readmin/clubs/${clubId}/teams`} />
              <ReadminMenuDropdownItem
                label="Team Registration Forms"
                href={`/readmin/clubs/${clubId}/team_registrations`}
              />
              <ReadminMenuDropdownItem label="People" href={`/readmin/clubs/${clubId}/people`} />
              <ReadminMenuDropdownItem
                label="Scheduled Games"
                href={`/readmin/clubs/${clubId}/recurring_recording_occurrences`}
              />
            </ReadminMenuDropdown>
            <ReadminMenuDropdown label="Video">
              <ReadminMenuDropdownItem label="Games" href={`/readmin/clubs/${clubId}/games`} />
              <ReadminMenuDropdownItem label="Game Submissions" href={`/readmin/clubs/${clubId}/game_submissions`} />
              <ReadminMenuDropdownItem label="Live Streams" href={`/readmin/clubs/${clubId}/live_streams`} />
              <ReadminMenuDropdownItem label="Videos" href={`/readmin/clubs/${clubId}/videos`} />
              <ReadminMenuDropdownItem label="Deliveries" href={`/readmin/clubs/${clubId}/deliveries`} />
              <ReadminMenuDropdownItem label="Highlights" href={`/readmin/clubs/${clubId}/events`} />
              <ReadminMenuDropdownItem label="Incidents" href={`/readmin/clubs/${clubId}/incidents`} />
              <ReadminMenuDropdownItem label="Referee Access" href={`/readmin/clubs/${clubId}/games/manage_referees`} />
              <ReadminMenuDropdownItem label="Recordings" href={`/readmin/clubs/${clubId}/recordings`} />
            </ReadminMenuDropdown>
            <ReadminMenuDropdown label="Plays of the Week">
              <ReadminMenuDropdownItem label="POTW Competitions" href={`/readmin/clubs/${clubId}/potw_competitions`} />
              <ReadminMenuDropdownItem label="POTW Entries" href={`/readmin/clubs/${clubId}/potw_entries`} />
              <ReadminMenuDropdownItem label="POTW Ratings" href={`/readmin/clubs/${clubId}/potw_ratings`} />
            </ReadminMenuDropdown>
            <ReadminMenuDropdown label="Settings">
              <ReadminMenuDropdownItem label="Courts" href={`/readmin/clubs/${clubId}/courts`} />
              <ReadminMenuDropdownItem label="Ad Assets" href={`/readmin/clubs/${clubId}/ad_assets`} />
              <ReadminMenuDropdownItem label="Ad Presets" href={`/readmin/clubs/${clubId}/ad_presets`} />
              {club.usePublicLeaguePages && (
                <ReadminMenuDropdownItem label="Public Leagues" href={`/readmin/clubs/${clubId}/public_league_pages`} />
              )}
              <ReadminMenuDropdownItem label="Club Admins" href={`/readmin/clubs/${clubId}/club_admins`} />
              <ReadminMenuDropdownItem label="Scorekeeper Users" href={`/readmin/clubs/${clubId}/scorekeeper_users`} />
              <ReadminMenuDropdownItem label="System Notes" href={`/readmin/clubs/${clubId}/notes`} />
            </ReadminMenuDropdown>
            <ReadminMenuDropdown label="Reports">
              <ReadminMenuDropdownItem label="Usage Report" href={`/readmin/clubs/${clubId}/usage_report`} />
            </ReadminMenuDropdown>
          </>
        )}
        {apiPartner && (
          <>
            <ReadminMenuItem label={apiPartner.name} href={`/readmin/api_partners/${apiPartnerId}`} />
            <ReadminMenuDropdown label="Records">
              <ReadminMenuDropdownItem
                label="Organisations"
                href={`/readmin/api_partners/${apiPartnerId}/api_organisations`}
              />
              <ReadminMenuDropdownItem label="Courts" href={`/readmin/api_partners/${apiPartnerId}/api_courts`} />
              <ReadminMenuDropdownItem
                label="Competitions"
                href={`/readmin/api_partners/${apiPartnerId}/api_competitions`}
              />
              <ReadminMenuDropdownItem
                label="Game Schedules"
                href={`/readmin/api_partners/${apiPartnerId}/api_game_schedules`}
              />
              <ReadminMenuDropdownItem label="Teams" href={`/readmin/api_partners/${apiPartnerId}/api_teams`} />
              <ReadminMenuDropdownItem label="Profiles" href={`/readmin/api_partners/${apiPartnerId}/api_profiles`} />
            </ReadminMenuDropdown>
            <ReadminMenuItem label="GraphiQL" href={`/readmin/api_partners/${apiPartnerId}/graphiql`} />
            <ReadminMenuItem
              label="GraphQL Requests"
              href={`/readmin/api_partners/${apiPartnerId}/api_graphql_requests`}
            />
          </>
        )}
        {!clubId && !apiPartnerId && (
          <>
            {isAdmin && (
              <ReadminMenuDropdown label="API Partners">
                <ReadminMenuDropdownItem label="API Partners" href="/readmin/api_partners" />
                {data?.apiPartners?.map((apiPartner) => (
                  <ReadminMenuDropdownItem
                    key={apiPartner.id}
                    label={apiPartner.readminName}
                    href={`/readmin/api_partners/${apiPartner.id}`}
                  />
                ))}
              </ReadminMenuDropdown>
            )}
            {isAdmin && (
              <ReadminMenuDropdown label="Club Management">
                <ReadminMenuDropdownItem label="Ad Assets" href="/readmin/ad_assets" />
                <ReadminMenuDropdownItem label="Ad Presets" href="/readmin/ad_presets" />
                <ReadminMenuDropdownItem label="Clubs" href="/readmin/clubs" />
                <ReadminMenuDropdownItem label="Club Admins" href="/readmin/club_admins" />
                <ReadminMenuDropdownItem label="Courts" href="/readmin/courts" />
                <ReadminMenuDropdownItem label="Deliveries" href="/readmin/deliveries" />
                <ReadminMenuDropdownItem label="Games" href="/readmin/games" />
                <ReadminMenuDropdownItem label="Game Submissions" href="/readmin/game_submissions" />
                <ReadminMenuDropdownItem label="Highlights" href="/readmin/events" />
                <ReadminMenuDropdownItem label="Incidents" href="/readmin/incidents" />
                <ReadminMenuDropdownItem label="Leagues" href="/readmin/leagues" />
                <ReadminMenuDropdownItem label="Live Streams" href="/readmin/live_streams" />
                <ReadminMenuDropdownItem label="People" href="/readmin/people" />
                <ReadminMenuDropdownItem label="POTW Competitions" href="/readmin/potw_competitions" />
                <ReadminMenuDropdownItem label="POTW Entries" href="/readmin/potw_entries" />
                <ReadminMenuDropdownItem label="POTW Ratings" href="/readmin/potw_ratings" />
                <ReadminMenuDropdownItem label="Public League Pages" href="/readmin/public_league_pages" />
                <ReadminMenuDropdownItem label="Scheduled Games" href="/readmin/recurring_recording_occurrences" />
                <ReadminMenuDropdownItem label="Scorekeeper Users" href="/readmin/scorekeeper_users" />
                <ReadminMenuDropdownItem label="Teams" href="/readmin/teams" />
                <ReadminMenuDropdownItem label="Team Registration Form" href="/readmin/team_registrations" />
                <ReadminMenuDropdownItem label="Videos" href="/readmin/videos" />
              </ReadminMenuDropdown>
            )}
            {data?.currentUser?.administeredVenuesAny && (
              <ReadminMenuDropdown label="Venue Management">
                <ReadminMenuDropdownItem label="Cameras" href="/readmin/cameras" />
                <ReadminMenuDropdownItem label="Camera Models" href="/readmin/camera_models" />
                <ReadminMenuDropdownItem label="Network Devices" href="/readmin/network_devices" />
                <ReadminMenuDropdownItem label="Recording Blocks" href="/readmin/recording_blocks" />
                <ReadminMenuDropdownItem label="Surfaces" href="/readmin/surfaces" />
                <ReadminMenuDropdownItem label="Venues" href="/readmin/venues" />
                <ReadminMenuDropdownItem label="Venue Admins" href="/readmin/venue_admins" />
                <ReadminMenuDropdownItem label="Venue Servers" href="/readmin/venue_servers" />
              </ReadminMenuDropdown>
            )}
            {isAdmin && (
              <ReadminMenuDropdown label="Admin">
                <ReadminMenuDropdownItem label="Active Admin" href="/active_admin" />
                <ReadminMenuDropdownItem label="Audit Events" href="/readmin/paper_trail_versions" />
                <ReadminMenuDropdownItem label="Beta Features" href="/readmin/beta_features" />
                <ReadminMenuDropdownItem label="Cities" href="/readmin/cities" />
                <ReadminMenuDropdownItem label="Device Port Mappings" href="/readmin/device_port_mappings" />
                <ReadminMenuDropdownItem label="Event Videos" href="/readmin/event_videos" />
                <ReadminMenuDropdownItem label="GameDay Associations" href="/readmin/gd_associations" />
                <ReadminMenuDropdownItem label="GameDay Venues" href="/readmin/gd_venues" />
                <ReadminMenuDropdownItem label="Motioncrop Tests" href="/readmin/motioncrop_tests" />
                <ReadminMenuDropdownItem label="Motioncrop Test Reports" href="/readmin/motioncrop_test_reports" />
                <ReadminMenuDropdownItem label="Original Video Request" href="/readmin/original_video_requests" />
                <ReadminMenuDropdownItem label="Onsen Popup Promos" href="/readmin/onsen_popup_promos" />
                <ReadminMenuDropdownItem label="Operation Warnings" href="/readmin/ops_warnings" />
                <ReadminMenuDropdownItem label="PgHero" href="/pghero" />
                <ReadminMenuDropdownItem label="PlayHQ Organisations" href="/readmin/play_hq_organisations" />
                <ReadminMenuDropdownItem label="POTW Videos" href="/readmin/potw_videos" />
                <ReadminMenuDropdownItem label="Scorekeeper Devices" href="/readmin/scorekeeper_devices" />
                <ReadminMenuDropdownItem label="SendGrid Events" href="/readmin/sendgrid_events" />
                <ReadminMenuDropdownItem label="Sidekiq" href="/sidekiq" />
                <ReadminMenuDropdownItem label="System Notes" href="/readmin/notes" />
                <ReadminMenuDropdownItem label="Users" href="/readmin/users" />
                <ReadminMenuDropdownItem label="Video Cut Attempts" href="/readmin/video_cut_attempts" />
                <ReadminMenuDropdownItem label="Video Objects" href="/readmin/video_objects" />
                <ReadminMenuDropdownItem label="Venue Server Chef Runs" href="/readmin/venue_server_chef_runs" />
                <ReadminMenuDropdownItem label="Venue Server Commands" href="/readmin/venue_server_commands" />
              </ReadminMenuDropdown>
            )}
          </>
        )}
        <div style={{ flex: "1 1 auto" }} />
        {data?.currentUser && (
          <ReadminMenuDropdown alignOnRight icon={<Account />} label={data.currentUser.email}>
            <ReadminMenuDropdownItem icon={<SwapHorizontal />} label="Switch to Player Portal" href={data.onsenUrl} />
            <ReadminMenuDropdownItem icon={<Account />} label="My Account" href="/readmin/account/edit" />
            <ReadminMenuDropdownItem icon={<Logout />} label="Logout" href="/readmin/logout" />
          </ReadminMenuDropdown>
        )}
        {data && !data.currentUser && <ReadminMenuItem label="Login" href="/readmin/login" />}
        <IconButton
          className="progressbar-spinner"
          color="inherit"
          onClick={() => progressBar(() => apolloClient.refetchQueries({ include: "active" }))}
        >
          <Refresh />
        </IconButton>
      </AppBar>
    </ThemeProvider>
  );
}

function ReadminMenuImageItem({ src, ...others }) {
  return (
    <Button color="inherit" {...others}>
      <img src={src} alt="" style={{ height: "2.5em", width: "auto" }} />
    </Button>
  );
}

function ReadminMenuItem({ icon, label, ...others }) {
  return (
    <Button color="inherit" {...others}>
      {icon}
      {label}
    </Button>
  );
}

function ReadminMenuDropdown({ icon, label, alignOnRight = false, children, ...others }) {
  const [anchorEl, anchorElSet] = useState(null);
  return (
    <>
      <Button color="inherit" {...others} onClick={(event) => anchorElSet(event.currentTarget)}>
        {icon}
        {label}
        <MenuDown />
      </Button>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: alignOnRight ? "right" : "left" }}
        transformOrigin={{ vertical: "top", horizontal: alignOnRight ? "right" : "left" }}
        open={!!anchorEl}
        onClose={() => anchorElSet(null)}
        anchorEl={anchorEl}
        component="div"
        keepMounted
      >
        {children}
      </Menu>
    </>
  );
}

function ReadminMenuDropdownItem({ icon, label, ...others }) {
  return (
    <MenuItem component="a" {...others}>
      {icon && <ListItemIcon>{icon}</ListItemIcon>}
      <ListItemText primary={label} />
    </MenuItem>
  );
}

import { TextField } from "@mui/material";
import React, { forwardRef, useId } from "react";

const InputComponent = forwardRef(({ id, children }, ref) => {
  return (
    <div ref={ref} style={{ padding: 5, flex: "1 1 auto", maxWidth: "100%", cursor: "initial" }} id={id}>
      {children}
    </div>
  );
});

export default function FormFieldContainer({ children, ...others }) {
  const id = useId();
  return (
    <TextField
      id={id}
      fullWidth
      size="large"
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        inputProps: { children },
      }}
      {...others}
    />
  );
}

import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const PotwRatingsRemoveDialog = makeConfirmActionDialog({
  mutationName: "potwRatingsRemove",
  paramName: "potwRatingIds",
  action: "Remove POTW Ratings",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

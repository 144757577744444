import formSerialize from "form-serialize";
import { mapValues } from "lodash-es";

export default function getFormData(form) {
  let formData = formSerialize(form, {
    hash: true,
    empty: true,
  });

  formData = mapValues(formData, (valueJSON) => {
    const { value, required, label } = JSON.parse(valueJSON);
    if (required) {
      if (
        [
          //
          null,
          undefined,
          "",
        ].includes(value) ||
        (value.constructor === Array && value.length === 0)
      )
        throw new Error(`${label} is required`);
    }

    return value;
  });
  return formData;
}

import { gql } from "@apollo/client";
import LoadingFormDialog from "controls/LoadingFormDialog";
import useData from "hooks/useData";
import useGotoUrl from "hooks/useGotoUrl";
import { Download } from "mdi-material-ui";
import React from "react";

VideoDownloadOriginalVideoDialog.useButtonProps = ({ videoId }) => {
  const [data] = useData(
    gql`
      query VideoDownloadOriginalVideoDialog($videoId: ID!) {
        video(id: $videoId) {
          id
          originalVideoAvailable
        }
      }
    `,
    { videoId },
  );

  return {
    disabled: !data?.video.originalVideoAvailable,
    icon: <Download />,
    content: "Download Original Video",
  };
};

export default function VideoDownloadOriginalVideoDialog({ videoId, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query VideoDownloadOriginalVideoDialog($videoId: ID!) {
        video(id: $videoId) {
          id
          originalVideoUrl
        }
      }
    `,
    { videoId },
  );

  const gotoUrl = useGotoUrl();

  return (
    <LoadingFormDialog
      title="Download Original Video"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async () => {
        const originalVideoUrl = data?.video?.originalVideoUrl;
        if (originalVideoUrl) await gotoUrl(originalVideoUrl);
        await onSubmit?.();
      }}
    />
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import LeaguesForm, { LeaguesFormFragment } from "./Form";

LeaguesEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit Settings",
  };
};

export default function LeaguesEditDialog({ leagueIds, onSubmit, onClose }) {
  const leaguesUpdate = useActionFragment("leaguesUpdate");
  const [data, dataMeta] = useData(
    gql`
      query LeagueBatchEditDialogQuery($leagueIds: [ID!]!) {
        leagues(ids: $leagueIds) {
          id
          ...LeaguesFormFragment
        }
      }
      ${LeaguesFormFragment}
    `,
    { leagueIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit League Settings"
      onClose={onClose}
      onSubmit={async (formData) => {
        await leaguesUpdate({
          input: {
            leagueIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <LeaguesForm leagues={data?.leagues} />
    </FormDialog>
  );
}

import ExtendedError from "./ExtendedError";

class LoadImageError extends ExtendedError {}

export default async function loadImage(src, { loadVideo = true, throwError = true, videoTime } = {}) {
  let image = await tryLoadImageUsingImgTag(src);
  if (image) return image;
  if (loadVideo) {
    image = await tryLoadImageUsingVideoTag(src, { videoTime });
    if (image) return image;
  }
  if (throwError) {
    throw new LoadImageError(`Failed to load resource: ${src}`);
  } else {
    // eslint-disable-next-line no-console
    console.error(`Failed to load resource: ${src}`);
  }
  return null;
}

async function tryLoadImageUsingImgTag(src) {
  return await new Promise((resolve) => {
    const img = new Image();
    img.addEventListener("load", () => resolve(img));
    img.addEventListener("error", () => resolve(null));
    img.src = src;
  });
}

async function tryLoadImageUsingVideoTag(src, { videoTime } = {}) {
  const video = await new Promise((resolve) => {
    const vid = document.createElement("video");
    vid.addEventListener("loadeddata", () => {
      vid.currentTime = videoTime;
    });
    vid.addEventListener("seeked", () => resolve(vid));
    vid.addEventListener("error", () => resolve(null));
    vid.src = src;
    vid.muted = true;
  });

  return video;
}

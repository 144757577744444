import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import LoadingFormDialog from "controls/LoadingFormDialog";
import FilterButton from "filters/FilterButton";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import { readminPageContext } from "helpers/contexts";
import downloadBlob from "helpers/downloadBlob";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import useRouteState from "hooks/useRouteState";
import { Download } from "mdi-material-ui";
import React, { useContext } from "react";
import ReadminPage from "readmin_pages/ReadminPage";

import ReadminObjectsDialogToolbar from "./ReadminObjectsDialogToolbar";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "./ReadminObjectsTable";
import useReadminObjectTypedef from "./useReadminObjectTypedef";

export default function makeReadminObjectsPage(
  typename,
  typePlural,
  { title = null, tableDefaultMode = "table" } = {},
) {
  function PageContent() {
    const { apiPartnerId, clubId } = useContext(readminPageContext);
    const [limit, limitSet] = useRouteState("limit", 10);

    const readminObjectTypedef = useReadminObjectTypedef(typename, {
      loadFilterBar: true,
    });

    const [countData, countDataMeta] = useData(
      gql`
        query ReadminObjectsPageContent_${typename}_count(
          $apiPartnerId: ID
          $clubId: ID
          $typename: String!
          $filterBar: ReadminFilterBar
        ) {
          readminObjectsCount(
            apiPartnerId: $apiPartnerId
            clubId: $clubId
            typename: $typename
            filterBar: $filterBar
          )
        }
      `,
      {
        apiPartnerId,
        clubId,
        typename,
        filterBar: readminObjectTypedef?.filterBarVariables,
      },
      { skip: !readminObjectTypedef },
    );

    const [data, dataMeta] = useData(
      gql`
        query ReadminObjectsPageContent_${typename}(
          $apiPartnerId: ID
          $clubId: ID
          $typename: String!
          $filterBar: ReadminFilterBar
          $limit: Int!
          $offset: Int
        ) {
          readminObjects(
            apiPartnerId: $apiPartnerId
            clubId: $clubId
            typename: $typename
            filterBar: $filterBar
            limit: $limit
            offset: $offset
          ) {
            id
            ...ReadminObjectsTableFragment
          }
        }
        ${ReadminObjectsTableFragment}
      `,
      {
        apiPartnerId,
        clubId,
        typename,
        filterBar: readminObjectTypedef?.filterBarVariables,
        limit,
      },
      {
        skip: !readminObjectTypedef,
      },
    );
    const [formDialogs, formDialogsOpen] = useFormDialogs();
    const exportReadminObjectsCsv = useActionFragment("exportReadminObjectsCsv", "csvStr");

    return (
      <>
        {formDialogs}
        <ReadminObjectsDialogToolbar
          typename={typename}
          gotoReadminObjectUrl
          onSubmit={async () => {
            if (countData) await countDataMeta.refetch();
            if (data) await dataMeta.refetch();
          }}
        />
        <FiltersBar>
          {readminObjectTypedef?.filterBarContent}
          <PageSizeFilter value={limit} onChange={limitSet} />
          <FilterButton
            icon={<Download />}
            content="Download as CSV"
            onClick={() =>
              formDialogsOpen(
                <LoadingFormDialog
                  onSubmit={async () => {
                    const result = await exportReadminObjectsCsv({
                      input: {
                        clubId,
                        apiPartnerId,
                        typename,
                        filterBar: readminObjectTypedef?.filterBarVariables,
                      },
                    });
                    const blob = new Blob([result.exportReadminObjectsCsv.csvStr], {
                      type: "text/plain;charset=utf-8",
                    });
                    await downloadBlob(blob, `Glory League - ${title || typePlural}.csv`);
                  }}
                />,
              )
            }
          />
        </FiltersBar>
        <Paper>
          <ReadminObjectsTable
            defaultMode={tableDefaultMode}
            typename={typename}
            dataMeta={dataMeta}
            rowsCount={countData?.readminObjectsCount}
            rows={data?.readminObjects}
            onSubmit={async () => {
              if (countData) await countDataMeta.refetch();
              if (data)
                dataMeta.refetch({
                  offset: 0,
                  limit: data.readminObjects.length,
                });
            }}
          />
        </Paper>
      </>
    );
  }

  return function ReadminObjectsPage({ apiPartnerId, clubId, ...others }) {
    const [data] = useData(
      gql`
        query ReadminObjectsPage($apiPartnerId: ID, $clubId: ID) {
          apiPartner(id: $apiPartnerId) {
            id
            name
          }
          club(id: $clubId) {
            id
            name
          }
        }
      `,
      {
        apiPartnerId,
        clubId,
      },
    );

    return (
      <ReadminPage
        apiPartnerId={apiPartnerId}
        clubId={clubId}
        title={title || typePlural}
        subheader={
          <>
            All {typePlural} in {data?.apiPartner?.name || data?.club?.name || "Glory League"}
          </>
        }
        {...others}
      >
        <PageContent />
      </ReadminPage>
    );
  };
}

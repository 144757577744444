import { gql } from "@apollo/client";
import { readminPageContext } from "helpers/contexts";
import useData from "hooks/useData";
import React, { useContext } from "react";

export default function ReadminLink({
  id,
  typename,
  to,
  component: Component,
  children = null,
  dense = false,
  annotation = null,
  ...others
}) {
  const [data] = useData(
    gql`
      query ReadminLink($id: ID!, $typename: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          ...ReadminLinkFragment
        }
      }
      ${ReadminLinkFragment}
    `,
    { id, typename },
    {
      skip: to || !id || !typename,
    },
  );
  to ||= data?.readminObject;
  Component ||= to?.readminUrl ? "a" : "span";

  if (!children && to) {
    const text = [to.readminName, annotation && `(${annotation})`].filter(Boolean).join(" ");
    children = (
      <>
        {to.readminLogoUrl && (
          <img
            src={to.readminLogoUrl}
            style={{ height: "2em", margin: "0 .5em .3em", verticalAlign: "middle" }}
            title={text}
          />
        )}
        {dense && !to.readminLogoUrl && (
          <span
            title={text}
            style={{
              display: "inline-block",
              whiteSpace: "nowrap",
              maxWidth: "20em",
              overflow: "hidden",
              textOverflow: "ellipsis",
              // inline-block breaks the underline, re-applying it here
              ...(to.readminUrl && { textDecoration: "underline" }),
            }}
          >
            {text}
          </span>
        )}
        {!dense && text}
      </>
    );
  }
  const { openViewDetailsDialog } = useContext(readminPageContext);

  return (
    to && (
      <Component
        href={to.readminUrl}
        {...(openViewDetailsDialog && {
          target: "_blank",
          onClick: (event) => {
            if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
            event.preventDefault();
            openViewDetailsDialog(to);
          },
        })}
        {...others}
      >
        {children}
      </Component>
    )
  );
}

export const ReadminLinkFragment = gql`
  fragment ReadminLinkFragment on ReadminObject {
    readminName
    readminUrl
    readminLogoUrl
  }
`;

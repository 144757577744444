import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import GdVenueForm from "./Form";

AddGdAssociationDialog.buttonProps = {
  icon: <Plus />,
  content: "Add GameDay Association",
};

export default function AddGdAssociationDialog({ onSubmit = noop, ...others }) {
  const addGdAssociation = useActionFragment("addGdAssociation");

  return (
    <FormDialog
      {...others}
      header="Add GameDay Association"
      onSubmit={async (formData) => {
        await addGdAssociation({ input: formData });
        await onSubmit();
      }}
    >
      <GdVenueForm />
    </FormDialog>
  );
}

import useIsAdmin from "hooks/useIsAdmin";
import { Cog } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const VenueServersCheckIssuesDialog = makeConfirmActionDialog({
  mutationName: "venueServersUpdateIssues",
  paramName: "venueServerIds",
  action: "Check Issues",
  useButtonProps: () => {
    const isAdmin = useIsAdmin();
    return {
      hidden: !isAdmin,
      icon: <Cog />,
      content: "Check Issues",
    };
  },
});

import { gql } from "@apollo/client";
import Field from "controls/Field";
import useData from "hooks/useData";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import React from "react";

export const GdAssociationFormFragment = gql`
  fragment GdAssociationFormFragment on GdAssociation {
    id
    gdAssociationId
    name
    gdSetupKey
    timeZone
  }
`;

export default function GdAssociationForm({ gdAssociation = {} }) {
  const [data] = useData(
    gql`
      query GdAssociationForm {
        timeZones
      }
    `,
    null,
  );

  return (
    <>
      <Field
        name="gdAssociationId"
        label="GameDay Association ID"
        disabled={!!gdAssociation.id}
        input={<NumberInput />}
        helperText={
          <>
            There is no easy way to tell Association ID in GameDay interface. But if the Setup Key is in the form of{" "}
            <b>SS123456</b>, <b>CS123456</b>, it is likely the numeric part, i.e <b>123456</b>.
          </>
        }
        required
        defaultValue={gdAssociation.gdAssociationId}
      />
      <Field
        name="name"
        label="Name"
        required
        defaultValue={gdAssociation.name}
        input={<TextInput />}
        helperText="Name of the association in GameDay. The value will be corrected when importing Courtside data from GameDay"
      />
      <Field
        name="gdSetupKey"
        label="GameDay Setup Key"
        input={<TextInput />}
        helperText="From GameDay admin portal. Dashboard => Details => Stadium Scoring Setup Key."
        required
        defaultValue={gdAssociation.gdSetupKey}
      />
      <Field
        name="timeZone"
        label="Time Zone"
        required
        defaultValue={gdAssociation.timeZone}
        helperText="Time zone of the association set in GameDay. The value will be corrected when importing Courtside data from GameDay"
        input={<SelectInput options={data?.timeZones} />}
      />
    </>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import LiveStreamCustomOutputsForm from "./Form";

AddLiveStreamCustomOutputDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add RTMP Output",
  };
};

export default function AddLiveStreamCustomOutputDialog({ liveStreamId = null, onSubmit, onClose }) {
  const addLiveStreamCustomOutput = useActionFragment("addLiveStreamCustomOutput", "liveStreamCustomOutput { id }");

  return (
    <FormDialog
      header="Add Live Stream RTMP Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLiveStreamCustomOutput({
          input: formData,
        });
        await onSubmit?.(result.addLiveStreamCustomOutput.liveStreamCustomOutput.id);
      }}
    >
      <LiveStreamCustomOutputsForm
        liveStreamCustomOutputs={[
          {
            liveStream: liveStreamId && { id: liveStreamId, __typename: "LiveStream" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { Mail } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const PeopleSendWelcomeEmailDialog = makeConfirmActionDialog({
  mutationName: "peopleSendWelcomeEmail",
  paramName: "personIds",
  action: "Send Welcome Email",
  useButtonProps: () => {
    return {
      icon: <Mail />,
      content: "Send Welcome Email",
    };
  },
});

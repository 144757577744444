import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import { FONT_FAMILY_ROBOTO_MONO } from "helpers/fonts";
import useData from "hooks/useData";
import React from "react";

export default function VenueServerSmartStateTab({ venueServerId }) {
  const [data] = useData(
    gql`
      query SmartStateTab($venueServerId: ID!) {
        venueServer(id: $venueServerId) {
          id
          smartState
        }
      }
    `,
    { venueServerId },
  );

  return (
    <Paper style={{ padding: 10, whiteSpace: "pre-wrap", fontFamily: FONT_FAMILY_ROBOTO_MONO }}>
      {data?.venueServer.smartState}
    </Paper>
  );
}

import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import { CurrentUserLiveStreamYtAuthStatus } from "readmin_pages/current_user/CurrentUserPage";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LiveStreamYtOutputsFormFragment = gql`
  fragment LiveStreamYtOutputsFormFragment on LiveStreamYtOutput {
    id
    liveStream {
      id
    }
    title
    description
    unlisted
    ytChannelId
  }
`;

export default function LiveStreamYtOutputsForm({ liveStreamYtOutputs }) {
  const created = liveStreamYtOutputs?.some((liveStreamYtOutput) => liveStreamYtOutput.id);

  const [data] = useData(
    gql`
      query LiveStreamYtOutputsForm($ytChannelIds: [String!]) {
        currentUser {
          id
          liveStreamYtAuth {
            id
            manageYtChannels
            readYtChannels(ytChannelIds: $ytChannelIds)
          }
        }
      }
    `,
    { ytChannelIds: liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.ytChannelId) },
  );

  return (
    <>
      <MultiValuesField
        label="LiveStream"
        name="liveStream"
        required
        disabled={liveStreamYtOutputs?.some((liveStreamYtOutput) => liveStreamYtOutput.id)}
        defaultValues={liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.liveStream)}
        input={<ReadminObjectInput optionsTypename="LiveStream" />}
      />
      <FormSection header="YouTube Account" defaultExpanded>
        <CurrentUserLiveStreamYtAuthStatus />
      </FormSection>
      <MultiValuesField
        label="Publish to YouTube Channel"
        name="ytChannelId"
        required
        disabled={created}
        defaultValues={liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.ytChannelId)}
        input={
          <SelectInput
            options={[
              ...(data?.currentUser?.liveStreamYtAuth?.manageYtChannels || []),
              ...(data?.currentUser?.liveStreamYtAuth?.readYtChannels || []),
            ].map((fbChannel) => ({
              avatar: fbChannel.picture,
              text: fbChannel.name,
              value: fbChannel.id,
            }))}
          />
        }
      />
      <MultiValuesField
        name="title"
        label="YouTube Broadcast Title"
        required
        input={<TextInput />}
        defaultValues={liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.title)}
      />
      <MultiValuesField
        name="description"
        label="YouTube Broadcast Description"
        input={<TextInput multiline minRows={5} />}
        defaultValues={liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.description)}
      />
      <MultiValuesField
        name="unlisted"
        label="Publish as Unlisted"
        input={<SwitchInput />}
        defaultValues={liveStreamYtOutputs?.map((liveStreamYtOutput) => liveStreamYtOutput.unlisted)}
      />
    </>
  );
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import SwitchInput from "inputs/SwitchInput";
import { Pencil } from "mdi-material-ui";
import React from "react";

PlayHqOrganisationEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    icon: <Pencil />,
    content: "Edit",
    hidden: !isAdmin,
  };
};

export default function PlayHqOrganisationEditDialog({ playHqOrganisationId, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query PlayHqOrganisationEditDialogQuery($playHqOrganisationId: ID!) {
        playHqOrganisation(id: $playHqOrganisationId) {
          id
          apiEnabled
        }
      }
    `,
    { playHqOrganisationId },
  );

  const playHqOrganisationUpdate = useActionFragment("playHqOrganisationUpdate");

  return (
    <FormDialog
      header="Edit PlayHQ Organisation"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await playHqOrganisationUpdate({ input: { playHqOrganisationId, ...formData } });
        await onSubmit?.();
      }}
    >
      <Field
        label="API Enabled"
        name="apiEnabled"
        defaultValue={data?.playHqOrganisation?.apiEnabled}
        input={<SwitchInput />}
      />
    </FormDialog>
  );
}

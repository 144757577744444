import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import TextInput from "inputs/TextInput";
import { Pencil } from "mdi-material-ui";
import React from "react";

TeamMemberEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Change Player Number",
};

export default function TeamMemberEditDialog({ teamMemberId, onClose, onSubmit = noop }) {
  const [data, dataMeta] = useData(
    gql`
      query TeamMemberEditDialog($teamMemberId: ID!) {
        teamMember(id: $teamMemberId) {
          id
          playerNumber
        }
      }
    `,
    { teamMemberId },
  );
  const teamMemberUpdate = useActionFragment("teamMemberUpdate");

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Change Player Number"
      onClose={onClose}
      onSubmit={async (formData) => {
        await teamMemberUpdate({ input: { teamMemberId, ...formData } });
        await onSubmit();
      }}
    >
      <Field
        name="playerNumber"
        label="Player Number"
        defaultValue={data?.teamMember.playerNumber}
        input={<TextInput autoFocus />}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import LiveStreamFbOutputsForm, { LiveStreamFbOutputsFormFragment } from "./Form";

LiveStreamFbOutputsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function LiveStreamFbOutputsEditDialog({ liveStreamFbOutputIds, onClose, onSubmit }) {
  const liveStreamFbOutputsUpdate = useActionFragment("liveStreamFbOutputsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query LiveStreamFbOutputsEditDialogQuery($liveStreamFbOutputIds: [ID!]!) {
        liveStreamFbOutputs(ids: $liveStreamFbOutputIds) {
          id
          ...LiveStreamFbOutputsFormFragment
        }
      }
      ${LiveStreamFbOutputsFormFragment}
    `,
    { liveStreamFbOutputIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Facebook Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        await liveStreamFbOutputsUpdate({
          input: {
            liveStreamFbOutputIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <LiveStreamFbOutputsForm liveStreamFbOutputs={data?.liveStreamFbOutputs} />
    </FormDialog>
  );
}

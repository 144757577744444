import { Mail } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const TeamRegistrationsSendDialog = makeConfirmActionDialog({
  mutationName: "teamRegistrationsSend",
  paramName: "teamRegistrationIds",
  action: "Send to Coaches",
  useButtonProps: () => {
    return {
      icon: <Mail />,
      content: "Send to Coaches",
    };
  },
});

export const TeamRegistrationsRemoveDialog = makeConfirmActionDialog({
  mutationName: "teamRegistrationsRemove",
  paramName: "teamRegistrationIds",
  action: "Remove",
  useButtonProps: () => {
    return {
      icon: <Mail />,
      content: "Remove",
    };
  },
});

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import GdAssociationForm, { GdAssociationFormFragment } from "./Form";

GdAssociationEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function GdAssociationEditDialog({ gdAssociationId, onSubmit = noop, ...others }) {
  const addGdAssociation = useActionFragment("addGdAssociation");

  const [data, dataMeta] = useData(
    gql`
      query GdAssociationEditDialog($gdAssociationId: ID!) {
        gdAssociation(id: $gdAssociationId) {
          id
          ...GdAssociationFormFragment
        }
      }
      ${GdAssociationFormFragment}
    `,
    { gdAssociationId },
  );

  return (
    <FormDialog
      {...others}
      loading={dataMeta.loading}
      header="Edit GameDay Association"
      onSubmit={async (formData) => {
        await addGdAssociation({
          input: {
            gdAssociationId: data.gdAssociation.gdAssociationId,
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      <GdAssociationForm gdAssociation={data?.gdAssociation} />
    </FormDialog>
  );
}

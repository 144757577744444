import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Refresh } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

ApiProfileReImportDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    content: "Re-import",
    icon: <Refresh />,
  };
};

export default function ApiProfileReImportDialog({ apiProfileId, onSubmit = noop, ...others }) {
  const [data] = useData(
    gql`
      query ApiProfileReImportDialog($apiProfileId: ID!) {
        apiProfile(id: $apiProfileId) {
          id
          clubs {
            id
            __typename
          }
        }
      }
    `,
    { apiProfileId },
  );
  const apiProfileReImport = useActionFragment("apiProfileReImport");

  return (
    <FormDialog
      {...others}
      header="Re-import API Profile"
      onSubmit={async (formData) => {
        await apiProfileReImport({
          input: {
            apiProfileId,
            ...formData,
          },
        });

        await onSubmit();
      }}
    >
      <Message
        type="info"
        content={
          <>
            Use this tool to re-import an API Profile into selected Club as a new Person Profile. Team roles will also
            be re-imported to ensure the new profile has neccessary access.
            <br />
            Current imported Person profile will be de-associated with this API Profile.
          </>
        }
      />
      <Field
        name="club"
        required
        label="Import to Club"
        input={<ReadminObjectInput options={data?.apiProfile?.clubs} optionsTypename="Club" />}
        helperText={<>Select Club to re-import API Profile into.</>}
      />
    </FormDialog>
  );
}

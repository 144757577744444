import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const ScorekeeperUsersRemoveDialog = makeConfirmActionDialog({
  mutationName: "scorekeeperUsersRemove",
  action: "Remove Scorekeeper User",
  paramName: "scorekeeperUserIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove Scorekeeper User",
    };
  },
});

import Field from "controls/Field";
import Form, { FormSubmitButton } from "controls/Form";
import Message from "controls/Message";
import apolloClient from "helpers/apolloClient";
import { useActionFragment } from "hooks/useAction";
import useEnforceAuth from "hooks/useEnforceAuth";
import { progressBar } from "hooks/useProgressBar";
import useShowMessage from "hooks/useShowMessage";
import TextInput from "inputs/TextInput";
import React, { useEffect, useState } from "react";

import PublicForm from "./PublicForm";

export default function ResetPasswordPage() {
  const resetPassword = useActionFragment("resetPassword");
  const verifyResetPasswordToken = useActionFragment("verifyResetPasswordToken", "valid");
  const resetPasswordToken = window.location.search.match(/reset_password_token=([^&]+)/)?.[1];
  const showMessage = useShowMessage();
  useEnforceAuth({ enforceUnauthenticated: true });

  const [valid, validSet] = useState(null);
  useEffect(() => {
    Promise.resolve().then(async () => {
      const result = await verifyResetPasswordToken({
        input: { resetPasswordToken },
      });
      const valid = result.verifyResetPasswordToken.valid;
      validSet(valid);
    });
  }, []);

  return (
    <PublicForm
      dense
      title="Reset Password"
      loading={valid === null}
      paperProps={{
        component: Form,
        onSubmit: async ({ newPassword, newPasswordConfirm }) => {
          if (newPassword !== newPasswordConfirm)
            return await showMessage({ content: "Passwords do not match.", modal: true });

          const resetPasswordToken = window.location.search.match(/reset_password_token=([^&]+)/)?.[1];
          await resetPassword({
            input: {
              resetPasswordToken,
              newPassword,
            },
          });
          await showMessage({
            modal: true,
            header: "Password Changed",
            content: "Your password has been changed successfully.",
          });
          await progressBar(() => apolloClient.refetchQueries({ include: "active" }));
        },
      }}
    >
      {valid === false && (
        <Message
          type="error"
          content={
            <>
              Invalid or expired reset password link. Please <a href="/passwords/new">request a new link</a>.
            </>
          }
        />
      )}
      <Field
        name="newPassword"
        label="New Password"
        disabled={!valid}
        input={<TextInput autoFocus autoComplete="new-password" type="password" />}
        required
      />
      <Field
        name="newPasswordConfirm"
        label="Confirm New Password"
        disabled={!valid}
        input={<TextInput autoFocus autoComplete="new-password" type="password" />}
        required
      />
      <FormSubmitButton disabled={!valid} content="Change My Password" />
    </PublicForm>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import TextInput from "inputs/TextInput";
import { Cog } from "mdi-material-ui";
import React from "react";

UserResetPasswordDialog.useButtonProps = () => {
  return {
    icon: <Cog />,
    content: "Reset User Password",
  };
};

export default function UserResetPasswordDialog({ userId, onSubmit = noop, ...others }) {
  const userResetPassword = useActionFragment("userResetPassword");

  return (
    <FormDialog
      header="Reset User Password"
      onSubmit={async (formData) => {
        await userResetPassword({
          input: {
            userId,
            ...formData,
          },
        });
        await onSubmit();
      }}
      {...others}
    >
      <Field
        name="newPassword"
        label="New Password"
        input={<TextInput autoFocus type="password" autoComplete="new-password" />}
      />
    </FormDialog>
  );
}

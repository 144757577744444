import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import TeamsForm, { TeamsFormFragment } from "./Form";

TeamsEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function TeamsEditDialog({ teamIds, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query EditTeamDialog($teamIds: [ID!]!) {
        teams(ids: $teamIds) {
          id
          ...TeamsFormFragment
        }
      }
      ${TeamsFormFragment}
    `,
    {
      teamIds,
    },
  );

  const teamsUpdate = useActionFragment("teamsUpdate");

  return (
    <FormDialog
      loading={dataMeta.loading}
      onClose={onClose}
      header="Edit Team"
      onSubmit={async (formData) => {
        await teamsUpdate({
          input: {
            teamIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <TeamsForm teams={data?.teams} />
    </FormDialog>
  );
}

import React from "react";
import { useComponentSize } from "react-use-size";

export default function HorizontalView({ ...others }) {
  const windowSize = useComponentSize();
  return (
    <div
      ref={windowSize.ref}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <div
        {...others}
        style={{
          ...(windowSize.width > windowSize.height
            ? {
                width: windowSize.width,
                height: windowSize.height,
              }
            : {
                transform: `rotate(90deg) translate(0, -${windowSize.width}px)`,
                transformOrigin: "top left",
                width: windowSize.height,
                height: windowSize.width,
              }),
          ...others.style,
        }}
      ></div>
    </div>
  );
}

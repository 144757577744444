import { Chip, ListItemText, MenuItem, TextField } from "@mui/material";
import { uniqBy } from "lodash-es";
import React, { useId } from "react";

MultiSelectInput.defaultValue = [];

export default function MultiSelectInput({ value, onChange, options = [], required = false, label, ...others }) {
  const id = useId();

  options = options.map((option) => {
    if (option?.constructor === Object) return option;
    if (option?.constructor === Array) {
      const [text, value] = option;
      return {
        text,
        value,
      };
    }
    return {
      text: option,
      value: option,
    };
  });

  options = uniqBy(
    options.map((option) => ({
      ...option,
    })),
    (o) => o.value,
  ).map((option) => ({
    ...option,
    valueStr: String(option.value),
  }));

  // const selectedOption = options.find((option) => option.value === value);

  return (
    <TextField
      id={id}
      select
      data-select-input
      fullWidth
      required={required}
      {...others}
      label={label}
      value={value}
      onChange={(event) => onChange(event.target.value)}
      InputLabelProps={{ shrink: !!value.length }}
      inputProps={{
        multiple: true,
        renderValue: (values) => (
          <>
            {values.map((value) => (
              <Chip
                style={{
                  margin: "-3px 2px",
                }}
                size="small"
                key={value}
                label={options.find((s) => s.value === value)?.text || `${value}`}
              />
            ))}
          </>
        ),
      }}
    >
      {options.map((option) => (
        <MenuItem data-select-input-option key={option.value} value={option.value}>
          <ListItemText primary={option.text} />
        </MenuItem>
      ))}
    </TextField>
  );
}

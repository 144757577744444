import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import DateRangeFilter from "filters/DateRangeFilter";
import FiltersBar from "filters/FiltersBar";
import PageSizeFilter from "filters/PageSizeFilter";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import useTimeZone from "hooks/useTimeZone";
import { Cog } from "mdi-material-ui";
import React, { useState } from "react";

import ReadminObjectsTable, { ReadminObjectsTableFragment } from "./ReadminObjectsTable";

ViewPaperTrailVersionsDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    icon: <Cog />,
    hidden: !isAdmin,
    content: "Audit Events",
  };
};

export default function ViewPaperTrailVersionsDialog({ id, typename, onClose }) {
  const [dateRange, dateRangeSet] = useState(null);
  const timeZone = useTimeZone();
  const [limit, limitSet] = useState(10);
  const [data, dataMeta] = useData(
    gql`
      query ViewPaperTrailVersionsDialog(
        $id: ID!
        $typename: String!
        $timeZone: String
        $dateRange: DateRange
        $limit: Int!
        $offset: Int
      ) {
        readminObject(id: $id, typename: $typename) {
          id
          paperTrailVersionsCount(dateRange: $dateRange)
          paperTrailVersions(dateRange: $dateRange, timeZone: $timeZone, limit: $limit, offset: $offset) {
            id
            ...ReadminObjectsTableFragment
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    { id, typename, timeZone, dateRange, limit },
  );

  return (
    <FormDialog onClose={onClose} loading={!data} maxWidth="xl" header="Audit Events">
      <FiltersBar>
        <DateRangeFilter value={dateRange} onChange={dateRangeSet} />
        <PageSizeFilter value={limit} onChange={limitSet} />
      </FiltersBar>
      <ReadminObjectsTable
        style={{ margin: "0 -10px" }}
        dataMeta={dataMeta}
        ignoreAssociationObjects={[data?.readminObject]}
        rowsCount={data?.readminObject?.paperTrailVersionsCount}
        rows={data?.readminObject?.paperTrailVersions}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import VideoPlayer from "controls/VideoPlayer";
import useLoadedImage from "helpers/useLoadedImage";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import DurationInput from "inputs/DurationInput";
import HiddenInput from "inputs/HiddenInput";
import RectInput from "inputs/RectInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import { Camera } from "mdi-material-ui";
import React, { useRef, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddMotioncropTestDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    icon: <Camera />,
    content: "Add Motioncrop Test",
  };
};

export default function AddMotioncropTestDialog({ videoId = null, onSubmit, onClose }) {
  const addMotioncropTest = useActionFragment("addMotioncropTest", "motioncropTest { id }");
  const [sourceVideo, sourceVideoSet] = useState(videoId && { id: videoId, __typename: "Video" });
  const [data] = useData(
    gql`
      query AddMotioncropTestDialogQuery($sourceVideoId: ID) {
        sourceVideo: video(id: $sourceVideoId) {
          id
          originalVideoUrl
        }
      }
    `,
    { sourceVideoId: sourceVideo?.id },
  );

  const originalVideoUrl = data?.sourceVideo?.originalVideoUrl;
  const [loadedOriginalVideo] = useLoadedImage(originalVideoUrl);
  const [sourceVideoStartMs, sourceVideoStartMsSet] = useState(0);
  const videoRef = useRef();
  const missingOriginalVideoUrl = !!data?.sourceVideo && !originalVideoUrl;

  return (
    <FormDialog
      disabled={missingOriginalVideoUrl}
      header="Add Motioncrop Test"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addMotioncropTest({
          input: {
            ...formData,
          },
        });
        await onSubmit?.(result.addMotioncropTest.motioncropTest);
      }}
    >
      <Field
        name="sourceVideo"
        label="Source Video"
        required
        value={sourceVideo}
        onChange={sourceVideoSet}
        input={<ReadminObjectInput optionsTypename="Video" />}
      />
      <Field name="name" label="Name" required input={<TextInput />} />
      <Field
        name="sport"
        label="Sport"
        required
        input={
          <SelectInput
            options={[
              { value: "basketball", text: "Basketball" },
              { value: "futsal", text: "Futsal" },
              { value: "netball", text: "Netball" },
              { value: "volleyball", text: "Volleyball" },
            ]}
          />
        }
      />
      <Field name="description" label="Description" input={<TextInput multiline minRows={3} />} />
      {missingOriginalVideoUrl && (
        <Message type="error" content="Original video unavailable, please restore it first." />
      )}
      {originalVideoUrl && (
        <>
          <Message content={<>Seek to where Virtual Cameraman did not work correctly and pause the video.</>} />
          <VideoPlayer
            videoUrl={originalVideoUrl}
            onPause={(event) => sourceVideoStartMsSet(Math.round(event.target.currentTime * 1000))}
            onSeeked={(event) => sourceVideoStartMsSet(Math.round(event.target.currentTime * 1000))}
            width="100%"
            controls
            videoRef={videoRef}
          />
          <Field
            disabled
            label="Source Video Start"
            required
            value={sourceVideoStartMs}
            input={
              <DurationInput
                min={0}
                {...(loadedOriginalVideo && {
                  max: loadedOriginalVideo.duration.duration * 1000,
                })}
                unit="milliseconds"
              />
            }
          />
          <Field name="sourceVideoStartMs" value={sourceVideoStartMs} input={<HiddenInput />} />
          <Field
            name="expectedRect"
            label="Expected Rect"
            required
            input={
              <RectInput
                enableRawJSON
                backgroundImageUrl={originalVideoUrl}
                backgroundImageVideoTime={sourceVideoStartMs / 1000}
                width={1}
                height={1}
                {...(loadedOriginalVideo && {
                  aspectRatio: loadedOriginalVideo.videoWidth / loadedOriginalVideo.videoHeight,
                })}
              />
            }
          />
        </>
      )}
    </FormDialog>
  );
}

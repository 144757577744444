import { gql } from "@apollo/client";
import { DataTableActionsDropdown } from "controls/DataTable";
import apolloClient from "helpers/apolloClient";
import { readminPageContext } from "helpers/contexts";
import noop from "helpers/noop";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import { progressBar } from "hooks/useProgressBar";
import { Information } from "mdi-material-ui";
import React, { useContext } from "react";

import READMIN_OBJECT_DIALOGS, {
  READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES,
  READMIN_OBJECT_BATCH_DIALOGS,
  READMIN_OBJECT_DIALOG_PARAM_NAMES,
} from "./READMIN_OBJECT_DIALOGS";
import { ReadminObjectLabelDescriptionsFragment } from "./ReadminObjectLabelDescriptions";
import ReadminObjectViewDetailsDialog from "./ViewDetailsDialog";
import ViewPaperTrailVersionsDialog from "./ViewPaperTrailVersionsDialog";

export default function ReadminObjectActionsDropdown({ id, typename, onSubmit = noop, ...others }) {
  const [data] = useData(
    gql`
      query ReadminObjectActionsDropdown_${typename}($id: ID!, $typename: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          readminUrl
        }
      }
    `,
    { id, typename },
  );

  let dialogs = [
    ...(READMIN_OBJECT_DIALOGS[typename] || []).map((DialogComponent) => (
      <DialogComponent {...{ [READMIN_OBJECT_DIALOG_PARAM_NAMES[typename]]: id }} onSubmit={onSubmit} />
    )),
    ...(READMIN_OBJECT_BATCH_DIALOGS[typename] || []).map((DialogComponent) => (
      <DialogComponent {...{ [READMIN_OBJECT_BATCH_DIALOG_PARAM_NAMES[typename]]: [id] }} onSubmit={onSubmit} />
    )),
    <ViewPaperTrailVersionsDialog id={id} typename={typename} />,
    <ReadminObjectViewDetailsDialog id={id} typename={typename} />,
  ];

  dialogs = dialogs.map((component) => {
    const buttonProps = {
      content: component.type.name || "UNKNOWN DIALOG",
      ...component.type.buttonProps,
      ...(component.type.useButtonProps && component.type.useButtonProps(component.props)),
    };
    return { component, buttonProps };
  });

  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const { openViewDetailsDialog } = useContext(readminPageContext);

  return (
    <>
      {formDialogs}
      <DataTableActionsDropdown
        defaultAction={
          data?.readminObject?.readminUrl && {
            content: "View",
            href: data.readminObject.readminUrl,
          }
        }
        {...(openViewDetailsDialog &&
          !data?.readminObject?.readminUrl &&
          data?.readminObject && {
            defaultAction: {
              icon: <Information />,
              content: "Details",
              onClick: (event) => {
                if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;
                event.preventDefault();
                openViewDetailsDialog(data.readminObject);
              },
            },
          })}
        moreActions={dialogs.map(({ component, buttonProps }) => ({
          ...buttonProps,
          onClick: async () => {
            await formDialogsOpen(component);
            await progressBar(() =>
              apolloClient.query({
                query: gql`
                  query ($id: ID!, $typename: String!) {
                    readminObject(id: $id, typename: $typename) {
                      id
                      ...ReadminObjectLabelDescriptionsFragment
                    }
                  }
                  ${ReadminObjectLabelDescriptionsFragment}
                `,
                variables: {
                  id,
                  typename,
                },
                fetchPolicy: "network-only",
              }),
            );
            await onSubmit();
          },
        }))}
        {...others}
      />
    </>
  );
}

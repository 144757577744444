import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import NumberInput from "inputs/NumberInput";
import SwitchInput from "inputs/SwitchInput";
import { Pencil } from "mdi-material-ui";
import React from "react";

EditGameScoreDialog.buttonProps = {
  content: "Edit game score",
  icon: <Pencil />,
};

export default function EditGameScoreDialog({ gameId, onSubmit = noop, ...others }) {
  const [data] = useData(
    gql`
      query EditGameScoreDialog($gameId: ID!) {
        game(id: $gameId) {
          id
          homeScore
          awayScore
          homeForfeit
          awayForfeit
          home {
            id
            name
          }
          away {
            id
            name
          }
        }
      }
    `,
    { gameId },
  );

  const gameUpdateScore = useActionFragment(
    "gameUpdateScore",
    "game { id homeScore awayScore homeForfeit awayForfeit forfeit winner { id } }",
  );

  return (
    <FormDialog
      header="Change final scores"
      {...others}
      loading={!data}
      onSubmit={async (formData) => {
        await gameUpdateScore({
          input: {
            gameId,
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      <Field
        name="homeScore"
        label={`Score of ${data?.game.home?.name}`}
        defaultValue={data?.game.homeScore}
        input={<NumberInput min={0} />}
      />
      <Field
        name="awayScore"
        label={`Score of ${data?.game.away?.name}`}
        defaultValue={data?.game.awayScore}
        input={<NumberInput min={0} />}
      />
      <Field
        name="homeForfeit"
        label={`forfeit by ${data?.game.home?.name}`}
        defaultValue={data?.game.homeForfeit}
        input={<SwitchInput />}
      />
      <Field
        name="awayForfeit"
        label={`forfeit by ${data?.game.away?.name}`}
        defaultValue={data?.game.awayForfeit}
        input={<SwitchInput />}
      />
    </FormDialog>
  );
}

import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import UserForm from "./Form";

AddUserDialog.buttonProps = {
  icon: <Plus />,
  content: "Add User",
};

export default function AddUserDialog({ onSubmit = noop, onClose = noop }) {
  const addUser = useActionFragment("addUser");

  return (
    <FormDialog
      header="Add User"
      onSubmit={async (formData) => {
        await addUser({
          input: {
            ...formData,
          },
        });
        await onSubmit();
      }}
      onClose={onClose}
    >
      <UserForm />
    </FormDialog>
  );
}

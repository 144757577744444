import { dialogContext } from "helpers/contexts";
import { isEqual } from "lodash-es";
import { useContext, useState } from "react";

function getParams() {
  const hashStr = window.location.hash.replace(/^#/, "");
  if (!hashStr) return {};
  try {
    return JSON.parse(atob(hashStr));
  } catch {
    return {};
  }
}

function setParams(params) {
  const hash = Object.keys(params).length ? `#${btoa(JSON.stringify(params))}` : "";
  window.history.replaceState(window.history.state, "", location.href.replace(/#.*$/, "") + hash);
}

const DEFAULT = "___DEFAULT___";

export default function useRouteState(key, defaultValue = null) {
  const { isInDialog } = useContext(dialogContext);

  const [valueRaw, valueRawSet] = useState(() => {
    if (isInDialog) return DEFAULT;
    const params = getParams();
    return Object.keys(params).includes(key) ? params[key] : DEFAULT;
  });
  const value = valueRaw === DEFAULT ? defaultValue : valueRaw;

  const setter = (valueNew) => {
    if (valueNew?.constructor === Function) valueNew = valueNew(value);
    if (isEqual(valueNew, value)) return;

    const valueRawNew = isEqual(valueNew, defaultValue) ? DEFAULT : valueNew;
    valueRawSet(valueRawNew);

    if (isInDialog) return;

    const paramsNew = getParams();
    if (isEqual(valueRawNew, DEFAULT)) delete paramsNew[key];
    else paramsNew[key] = valueRawNew;

    setParams(paramsNew);
  };

  return [value, setter];
}

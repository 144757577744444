import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import CitiesForm, { CitiesFormFragment } from "./Form";

CitiesEditDialog.useButtonProps = () => {
  return {
    content: "Edit",
    icon: <Pencil />,
  };
};

export function CitiesEditDialog({ cityIds, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query CityEditDialogQuery($ids: [ID!]!) {
        cities(ids: $ids) {
          id
          ...CitiesFormFragment
        }
      }
      ${CitiesFormFragment}
    `,
    { ids: cityIds },
  );
  const citiesUpdate = useActionFragment("citiesUpdate");

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Cities"
      onClose={onClose}
      onSubmit={async (formData) => {
        await citiesUpdate({
          input: {
            cityIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <CitiesForm cities={data?.cities} />
    </FormDialog>
  );
}

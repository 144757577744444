import { gql } from "@apollo/client";
import Message from "controls/Message";
import useData from "hooks/useData";
import useTimeZone from "hooks/useTimeZone";
import React from "react";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";

export default function RRORecordingsTab({ recurringRecordingOccurrenceId }) {
  const timeZone = useTimeZone();

  const [data] = useData(
    gql`
      query RRORecordingsTab($recurringRecordingOccurrenceId: ID!, $timeZone: String!) {
        recurringRecordingOccurrence(id: $recurringRecordingOccurrenceId) {
          id
          sameDayRecordings(timeZone: $timeZone) {
            id
            ...ReadminObjectsTableFragment
          }
        }
      }
      ${ReadminObjectsTableFragment}
    `,
    {
      recurringRecordingOccurrenceId,
      timeZone,
    },
  );

  return (
    <>
      <Message type="info" content="These are court recordings made on the same date." />
      <ReadminObjectsTable rows={data?.recurringRecordingOccurrence.sameDayRecordings} />
    </>
  );
}

import { gql } from "@apollo/client";
import { InputAdornment } from "@mui/material";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import UploaderInput from "inputs/UploaderInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const PublicLeaguePagesFormFragment = gql`
  fragment PublicLeaguePagesFormFragment on PublicLeaguePage {
    title
    slug
    enabled
    logoUploader
    club {
      id
    }
    league {
      id
    }
  }
`;

export default function PublicLeaguePagesForm({ publicLeaguePages = [{}] }) {
  const [data] = useData(gql`
    query PublicLeaguePage {
      publicLeaguePageUrlTemplate
    }
  `);

  return (
    <>
      <MultiValuesField
        //
        name="club"
        label="Club"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.club)}
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <MultiValuesField
        //
        name="enabled"
        label="Enabled"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.enabled)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="league"
        label="League"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.league)}
        required
        input={<ReadminObjectInput optionsTypename="League" />}
      />
      <MultiValuesField
        name="slug"
        label="URL"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.slug)}
        required
        input={
          <TextInput
            inputProps={{
              pattern: "[a-z0-9\\-]+",
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {data?.publicLeaguePageUrlTemplate.replace(/.*public-league-page-slug/, "")}
                </InputAdornment>
              ),
            }}
          />
        }
        helperText="This will be used in sharable URL for the page. Only lower case alphabets, numbers and hyphens(-)."
      />
      <MultiValuesField
        name="title"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.title)}
        label="Page Title"
        required
        helperText="This is the title people will see"
        input={<TextInput />}
      />
      <MultiValuesField
        name="logoUploader"
        label="Page Logo"
        defaultValues={publicLeaguePages.map((publicLeaguePage) => publicLeaguePage.logoUploader)}
        input={<UploaderInput accept="image/*" />}
      />
    </>
  );
}

import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { Trophy } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const GamesEnablePotwDialog = makeConfirmActionDialog({
  mutationName: "gamesEnablePotw",
  fragment: "games { id potwEnabled }",
  paramName: "gameIds",
  action: "Enable Plays of the Week",
  useButtonProps: ({ gameIds }) => {
    const [data] = useData(
      gql`
        query GamesEnablePotwDialog($gameIds: [ID!]!) {
          games(ids: $gameIds) {
            id
            potwEnabled
            league {
              id
              potwEnabled
            }
          }
        }
      `,
      { gameIds },
    );

    return {
      icon: <Trophy />,
      hidden: !data?.games.some((game) => !game.potwEnabled && game.league.potwEnabled),
      content: "Enable Plays of the Week",
    };
  },
});

export const GamesDisablePotwDialog = makeConfirmActionDialog({
  mutationName: "gamesDisablePotw",
  fragment: "games { id potwEnabled }",
  paramName: "gameIds",
  action: "Disable Plays of the Week",
  useButtonProps: ({ gameIds }) => {
    const [data] = useData(
      gql`
        query GamesDisablePotwDialog($gameIds: [ID!]!) {
          games(ids: $gameIds) {
            id
            potwEnabled
          }
        }
      `,
      { gameIds },
    );

    return {
      icon: <Trophy />,
      hidden: !data?.games.some((game) => game.potwEnabled),
      content: "Disable Plays of the Week",
    };
  },
});

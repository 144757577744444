import { defer, remove } from "lodash-es";

class Queue {
  constructor({ concurrency = 1 } = {}) {
    Object.assign(this, { concurrency, runningList: [], waitingList: [] });
  }

  addTask(task) {
    if (this.runningList.length >= this.concurrency) {
      return this.waitingList.push(task);
    }

    // run task
    this.runningList.push(task);
    Promise.resolve()
      .then(async () => await task.func())
      .then((result) => defer(() => task.resolve?.(result)))
      .catch((error) => defer(() => task.reject?.(error)))
      .then(() => {
        remove(this.runningList, task);
        const waitingTask = this.waitingList.shift();
        if (!waitingTask) return;
        this.addTask(waitingTask);
      });
  }
}

export default Queue;

import ExtendedError from "./ExtendedError";

export default class AbortError extends ExtendedError {
  constructor(message = "Cancelled", metadata) {
    super(message);
    Object.assign(this, {
      ...metadata,
      metadata,
      isAbortError: true,
    });
  }
}

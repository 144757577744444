import { gql } from "@apollo/client";
import { CircularProgress, IconButton, Slider } from "@mui/material";
import Message from "controls/Message";
import VideoPlayer from "controls/VideoPlayer";
import useData from "hooks/useData";
import imgLogoNew from "images/logo-new.png";
import { Pause, Play, VolumeHigh, VolumeMedium, VolumeOff } from "mdi-material-ui";
import React, { useEffect, useRef, useState } from "react";

export default function LiveStreamWidget({ uuid }) {
  const [data] = useData(
    gql`
      query LiveStreamWidget($uuid: String!) {
        liveStreamWidget(uuid: $uuid) {
          id
          live
          upcoming
          finished
          cancelled
          liveHlsUrl
          audioEnabled
        }
      }
    `,
    { uuid },
    { pollInterval: 3000 },
  );

  const liveHlsUrl = data?.liveStreamWidget.liveHlsUrl;
  let message;
  if (data) {
    if (!data.liveStreamWidget) {
      message = "Invalid live stream link";
    } else {
      if (data.liveStreamWidget.cancelled) {
        message = "This live stream has been cancelled.";
      } else if (data.liveStreamWidget.finished) {
        message = "This live stream has finished.";
      } else if (data.liveStreamWidget.upcoming) {
        message = "This live stream has not started yet.";
      } else if (!liveHlsUrl) {
        message = "This live stream is starting.";
      }
    }
  }

  return (
    <div>
      {(message || !data) && (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {!data && <CircularProgress />}
          {message && <Message title="Live stream not available" content={message} />}
        </div>
      )}
      {!message && liveHlsUrl && (
        <LiveVideoPlayer
          audioEnabled={data?.liveStreamWidget.audioEnabled}
          style={{
            width: "100vw",
            height: "100vh",
          }}
          hlsUrl={liveHlsUrl}
        />
      )}
    </div>
  );
}

function LiveVideoPlayer({ hlsUrl, audioEnabled = false, ...others }) {
  const [playing, playingSet] = useState(false);
  const videoRef = useRef();
  const [volume, volumeSet] = useState(100);
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume / 100;
    }
  }, [volume]);
  const [waiting, waitingSet] = useState(false);

  return (
    <div
      {...others}
      style={{
        position: "relative",
        ...others.style,
      }}
    >
      <VideoPlayer
        videoRef={videoRef}
        onPlay={() => playingSet(true)}
        onPause={() => playingSet(false)}
        style={{
          backgroundColor: "#000",
          height: "100%",
          width: "100%",
          display: "block",
          objectFit: "contain",
          objectPosition: "center",
        }}
        onWaiting={() => waitingSet(true)}
        onPlaying={() => waitingSet(false)}
        autoPlay
        m3u8Url={hlsUrl}
        onClick={() => {
          if (playing) {
            videoRef.current.pause();
          } else {
            videoRef.current.play();
          }
        }}
      />
      <IconButton
        style={{
          opacity: playing ? 0 : 1,
          transition: "opacity 0.5s",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          color: "white",
          fontSize: "min(128px, 20vw, 20vh)",
          pointerEvents: "none",
        }}
      >
        {playing ? <Play fontSize="inherit" /> : <Pause fontSize="inherit" />}
      </IconButton>
      <div
        style={{
          position: "absolute",
          left: 0,
          bottom: 0,
          padding: 10,
          display: "flex",
          flexFlow: "row nowrap",
          alignItems: "center",
          gap: 5,
          color: "#fff",
        }}
      >
        <a href="/" title="Glory League" target="_blank">
          <img
            src={imgLogoNew}
            alt="Logo"
            style={{ height: "1.5em", width: "2em", objectFit: "contain", display: "block" }}
          />
        </a>

        {!playing && (
          <IconButton
            size="small"
            color="inherit"
            title="Play"
            onClick={() => {
              videoRef.current.play();
            }}
          >
            <Play />
          </IconButton>
        )}
        {playing && (
          <IconButton
            size="small"
            color="inherit"
            title="Pause"
            onClick={() => {
              videoRef.current.pause();
            }}
          >
            <Pause />
          </IconButton>
        )}
        {audioEnabled && (
          <>
            <IconButton
              size="small"
              color="inherit"
              title="Toggle volume"
              onClick={() => {
                if (volume === 0) {
                  volumeSet(100);
                } else {
                  volumeSet(0);
                }
              }}
            >
              {!volume && <VolumeOff />}
              {!!volume && volume >= 50 && <VolumeHigh />}
              {!!volume && volume < 50 && <VolumeMedium />}
            </IconButton>
            <Slider
              color="inherit"
              size="small"
              style={{ width: "5em", marginRight: 10 }}
              aria-label="Volume"
              value={volume}
              onChange={(event, newValue) => volumeSet(newValue)}
            />
          </>
        )}
        <CircularProgress
          color="inherit"
          size="1.5em"
          style={{
            color: "#fff",
            opacity: waiting ? 1 : 0,
            transition: "opacity 0.5s",
          }}
        />
      </div>
    </div>
  );
}

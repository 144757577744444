import { progressBar } from "hooks/useProgressBar";
import { useEffect, useRef, useState } from "react";

import loadImage from "./loadImage";

export default function useLoadedImage(url, { throwError = true, videoTime = 0 } = {}) {
  const loadingIdRef = useRef();
  const [loadedImage, loadedImageSet] = useState(null);
  const [loading, loadingSet] = useState(false);

  useEffect(() => {
    if (url) {
      progressBar(async () => {
        const loadingId = {};
        loadingIdRef.current = loadingId;
        loadedImageSet(null);
        loadingSet(true);
        try {
          const image = await loadImage(url, { throwError, videoTime });
          loadedImageSet(image);
        } finally {
          if (loadingId === loadingIdRef.current) loadingSet(false);
        }
      });
    } else {
      loadedImageSet(null);
    }
  }, [url, videoTime]);

  return [loadedImage, loading];
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import { Plus } from "mdi-material-ui";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddGamePersonDialog.useButtonProps = ({ gameId }) => {
  return {
    hidden: !gameId,
    content: "Add Game Role",
    icon: <Plus />,
  };
};

export default function AddGamePersonDialog({ gameId, onClose = noop, onSubmit = noop }) {
  const [role, roleSet] = useState(null);
  const addGamePerson = useActionFragment("addGamePerson");
  const [data, dataMeta] = useData(
    gql`
      query AddGamePersonDialog($gameId: ID!) {
        game(id: $gameId) {
          id
          home {
            id
          }
          away {
            id
          }
        }
      }
    `,
    { gameId },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      onClose={onClose}
      header="Add Game Role"
      onSubmit={async (formData) => {
        await addGamePerson({
          input: {
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      <Field
        name="game"
        label="Game"
        required
        defaultValue={data?.game}
        input={<ReadminObjectInput optionsTypename="Game" />}
      />
      <Field
        name="role"
        label="Role"
        onChange={roleSet}
        required
        input={
          <SelectInput
            options={[
              ["Player", "player"],
              ["Coach", "coach"],
              ["Referee", "referee"],
              ["Spectator", "spectator"],
            ]}
          />
        }
      />
      {!["referee"].includes(role) && (
        <Field
          name="team"
          label="Team"
          required
          input={<ReadminObjectInput optionsTypename="Team" options={[data?.game.home, data?.game.away]} />}
        />
      )}
      <Field name="person" label="Person" required input={<ReadminObjectInput optionsTypename="Person" />} />
      {role === "player" && <Field name="playerNumber" label="Player Number" input={<TextInput />} />}
      <Field name="notify" label="Send Game Notification?" defaultValue={true} input={<SwitchInput />} />
    </FormDialog>
  );
}

import AbortError from "helpers/AbortError";
import { cloneElement, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export default function useFormDialogs() {
  const [entries, entriesSet] = useState([]);

  const formDialogs = entries.map(({ id, resolve, reject, formDialog }) =>
    cloneElement(formDialog, {
      key: id,
      onSubmit: async (result) => {
        await formDialog.props.onSubmit?.(result);
        resolve(result);
      },
      onClose: () => {
        reject(new AbortError());
        entriesSet((entries) => entries.filter((e) => e.id !== id));
      },
    }),
  );
  const formDialogsOpen = (formDialog) =>
    new Promise((resolve, reject) => {
      const id = uuidv4();
      const entry = {
        formDialog,
        resolve,
        reject,
        id,
      };
      entriesSet((e) => [...e, entry]);
    });
  return [formDialogs, formDialogsOpen];
}

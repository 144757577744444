import { gql } from "@apollo/client";
import { Paper, Popper } from "@mui/material";
import DateTime from "controls/DateTime";
import Duration from "controls/Duration";
import Message from "controls/Message";
import useData from "hooks/useData";
import React, { useState } from "react";

export default function LiveStreamEncodingTimelineTab({ liveStreamId }) {
  const [popper, popperSet] = useState(null);
  const [data] = useData(
    gql`
      query LiveStreamEncodingTimeline($liveStreamId: ID!) {
        liveStream(id: $liveStreamId) {
          id
          startAt
          finishAt
          encodingTimelineItems {
            pid
            startedAt
            endedAt
            durationSeconds
            startedPercentage
            endedPercentage
          }
        }
      }
    `,
    { liveStreamId },
  );

  return (
    <>
      <Message
        content={
          <>
            This timeline shows status of encoding process through out the live stream duration.
            <br />
            If the process frequently interrupts, it may be due to the network bandwidth of the venue not being
            sufficient to support the streaming.
            <br />
          </>
        }
      />

      {data?.liveStream && (
        <div style={{ position: "relative", backgroundColor: "#ccc", border: "solid 1px", height: 50 }}>
          {data.liveStream.encodingTimelineItems.map((item) => (
            <div
              key={item.pid}
              style={{
                position: "absolute",
                left: `${item.startedPercentage}%`,
                width: `${item.endedPercentage - item.startedPercentage}%`,
                height: "100%",
                backgroundColor: "#63ee12",
                borderStyle: "none solid none solid",
                borderWidth: "1px",
                borderColor: "#f52424",
              }}
              onMouseEnter={(e) =>
                popperSet({
                  anchorEl: e.currentTarget,
                  content: (
                    <>
                      PID: {item.pid}
                      <br />
                      Started: <DateTime value={item.startedAt} />
                      <br />
                      Ended: <DateTime value={item.endedAt} />
                      <br />
                      Duration: <Duration seconds={item.durationSeconds} />
                    </>
                  ),
                })
              }
              onMouseLeave={() => popperSet(null)}
            />
          ))}
        </div>
      )}
      <Popper open={!!popper} anchorEl={popper?.anchorEl}>
        <Paper style={{ padding: 10 }}>{popper?.content}</Paper>
      </Popper>
    </>
  );
}

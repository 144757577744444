import { defer } from "lodash-es";
import React from "react";
import { createRoot } from "react-dom/client";

import ReactPage from "./ReactPage";

export default async function setupReact({ pages, components }) {
  components = { ...components, ReactPage: () => <ReactPage pages={pages} /> };

  document.addEventListener("DOMContentLoaded", (event) => mountComponents(event.target, components));
  // hack to support jquery stuff
  document.addEventListener("DOMContentLoaded", () => document.dispatchEvent(new Event("turbo:load")));
}

function mountComponents(body, components) {
  for (const element of body.querySelectorAll("[data-react-class]")) {
    const props = JSON.parse(element.dataset.reactProps);
    const componentName = element.dataset.reactClass;
    const Component = components[componentName];
    if (!Component) {
      defer(() => {
        throw new Error(`component: ${componentName} not defined.`);
      });
      continue;
    }
    const root = element._reactRoot || createRoot(element);
    root.render(<Component {...props} />);
    element._reactRoot = root;
  }
}

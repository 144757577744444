import { Badge, Tab as MUITab, Tabs as MUITabs } from "@mui/material";
import useRouteState from "hooks/useRouteState";
import { defer } from "lodash-es";
import React, { cloneElement } from "react";

export default function Tabs({ tabs = [] }) {
  tabs = tabs
    .filter(Boolean)
    .map((tab) => {
      if (tab.constructor !== Array) return tab;
      const [name, title, content, others] = tab;
      return { name, title, content, ...others };
    })
    .map(({ content, ...tab }) => ({
      ...tab,
      content,
      ...(content?.type?.useTabOptions && content.type.useTabOptions(content.props)),
    }));

  const visibleTabs = tabs.filter((t) => !t.hidden);
  const [activeTabName, activeTabNameSet] = useRouteState("tab", visibleTabs[0]?.name);
  const activeTab = tabs.find((t) => t.name === activeTabName);

  if (!activeTab)
    defer(() => {
      throw new Error(`Invalid tab: ${activeTabName} (${tabs.map((t) => t.name).join(",")})`);
    });

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column nowrap",
        gap: 10,
      }}
      data-tabs
    >
      {visibleTabs.length > 1 && (
        <MUITabs
          value={activeTab?.name}
          onChange={(e, v) => activeTabNameSet(v)}
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabs.map((tab) => (
            <MUITab
              style={{
                ...(tab.hidden && { display: "none" }),
              }}
              label={
                <Badge
                  color="primary"
                  {...(tab.label && {
                    variant: "string",
                    badgeContent: tab.label,
                  })}
                  {...(tab.dot && {
                    variant: "dot",
                  })}
                >
                  {tab.title}
                </Badge>
              }
              value={tab.name}
              key={tab.name}
              disabled={tab.disabled}
              data-tab
            />
          ))}
        </MUITabs>
      )}
      {activeTab && cloneElement(activeTab.content, { key: activeTab.name })}
    </div>
  );
}

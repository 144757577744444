import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useIsAdmin from "hooks/useIsAdmin";
import DateTimeInput from "inputs/DateTimeInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LiveStreamsFormFragment = gql`
  fragment LiveStreamsFormFragment on LiveStream {
    id
    name
    court {
      id
    }
    adPreset {
      id
    }
    outputResolution
    cancelled
    startAt
    finishAt
    motioncropEnabled
    logoVisible
  }
`;

export default function LiveStreamsForm({ liveStreams }) {
  liveStreams = liveStreams?.map((liveStream) => ({
    motioncropEnabled: true,
    logoVisible: true,
    outputResolution: "720p",
    ...liveStream,
  }));

  const isAdmin = useIsAdmin();

  return (
    <>
      <MultiValuesField
        label="Name"
        name="name"
        required
        defaultValues={liveStreams?.map((liveStream) => liveStream.name)}
        input={<TextInput />}
      />
      <MultiValuesField
        label="Court"
        name="court"
        required
        defaultValues={liveStreams?.map((liveStream) => liveStream.court)}
        input={
          <ReadminObjectInput
            optionsTypename="Court"
            optionsFilters={{
              allow_live_streams: true,
            }}
          />
        }
      />
      <MultiValuesField
        label="Start At"
        name="startAt"
        required
        defaultValues={liveStreams?.map((liveStream) => liveStream.startAt)}
        input={<DateTimeInput />}
      />
      <MultiValuesField
        label="Finish At"
        name="finishAt"
        required
        defaultValues={liveStreams?.map((liveStream) => liveStream.finishAt)}
        input={<DateTimeInput />}
      />
      <MultiValuesField
        label="Cancelled"
        name="cancelled"
        defaultValues={liveStreams?.map((liveStream) => liveStream.cancelled)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        label="Ad Preset"
        name="adPreset"
        defaultValues={liveStreams?.map((liveStream) => liveStream.adPreset)}
        input={<ReadminObjectInput optionsTypename="AdPreset" />}
      />
      {isAdmin && (
        <>
          <MultiValuesField
            label="Output Resolution"
            name="outputResolution"
            required
            defaultValues={liveStreams?.map((liveStream) => liveStream.outputResolution)}
            input={<SelectInput options={["1080p", "720p"]} />}
          />
          <MultiValuesField
            label="Motioncrop Enabled"
            name="motioncropEnabled"
            defaultValues={liveStreams?.map((liveStream) => liveStream.motioncropEnabled)}
            input={<SwitchInput />}
          />
          <MultiValuesField
            label="Logo Visible"
            name="logoVisible"
            defaultValues={liveStreams?.map((liveStream) => liveStream.logoVisible)}
            input={<SwitchInput />}
          />
        </>
      )}
    </>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import NetworkDevicesForm from "./Form";

AddNetworkDeviceDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    content: "Add Network Device",
    icon: <Plus />,
  };
};

export default function AddNetworkDeviceDialog({ venueServerId, onClose, onSubmit }) {
  const addNetworkDevice = useActionFragment("addNetworkDevice", "networkDevice { id }");

  return (
    <FormDialog
      header="Add Network Device"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addNetworkDevice({
          input: formData,
        });
        onSubmit(result.addNetworkDevice.networkDevice);
      }}
    >
      <NetworkDevicesForm
        networkDevices={[
          {
            venueServer: venueServerId && { id: venueServerId, __typename: "VenueServer" },
          },
        ]}
      />
    </FormDialog>
  );
}

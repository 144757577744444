// import CameraThumbnail from "readmin_pages/cameras/Thumbnail";
import LiveStreamThumbnail from "readmin_pages/live_streams/Thumbnail";
import SurfaceThumbnail from "readmin_pages/surfaces/Thumbnail";

const READMIN_THUMBNAIL_COMPONENTS = {
  // Camera: CameraThumbnail,
  Surface: SurfaceThumbnail,
  LiveStream: LiveStreamThumbnail,
};

export default READMIN_THUMBNAIL_COMPONENTS;

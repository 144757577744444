import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LiveStreamCustomOutputsFormFragment = gql`
  fragment LiveStreamCustomOutputsFormFragment on LiveStreamCustomOutput {
    id
    liveStream {
      id
    }
    rtmpUrl
  }
`;

export default function LiveStreamCustomOutputsForm({ liveStreamCustomOutputs }) {
  return (
    <>
      <MultiValuesField
        label="LiveStream"
        name="liveStream"
        required
        disabled={liveStreamCustomOutputs?.some((liveStreamCustomOutput) => liveStreamCustomOutput.id)}
        defaultValues={liveStreamCustomOutputs?.map((liveStreamCustomOutput) => liveStreamCustomOutput.liveStream)}
        input={<ReadminObjectInput optionsTypename="LiveStream" />}
      />
      <MultiValuesField
        label="RTMP URL"
        name="rtmpUrl"
        required
        defaultValues={liveStreamCustomOutputs?.map((liveStreamCustomOutput) => liveStreamCustomOutput.rtmpUrl)}
        input={<TextInput type="url" />}
      />
    </>
  );
}

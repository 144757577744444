import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import ClubsForm from "./Form";

AddClubDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Add Club",
    icon: <Plus />,
  };
};

export default function AddClubDialog({ onClose, onSubmit }) {
  const addClub = useActionFragment("addClub", "club { id }");

  return (
    <FormDialog
      header="Add Club"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addClub({ input: formData });
        await onSubmit?.(result.addClub.club);
      }}
    >
      <ClubsForm />
    </FormDialog>
  );
}

import { RAILS_ENV } from "helpers/constants";
import { routerContext } from "helpers/contexts";
import { defer } from "lodash-es";
import { useContext } from "react";
import { matchPath } from "react-router-dom";

import { progressBar } from "./useProgressBar";

async function navigateFallback(href, { replace = false } = {}) {
  // wait for any progress bar to run through before leaving the page
  // spec/support/js_helpers.rb
  if (RAILS_ENV === "test") {
    while (!window._wait_for_progress_bars) {
      await new Promise(defer);
    }
  }
  while (window._wait_for_progress_bars) {
    await new Promise(defer);
  }
  if (replace) window.location.replace(href);
  else window.location.href = href;
}

export default function useGotoUrl() {
  const { navigate, paths = [] } = useContext(routerContext) || {};
  return (href, { replace = false } = {}) => {
    if (!navigate) return navigateFallback(href, { replace });
    const url = new URL(href, window.location.href);
    if (url.origin !== window.location.origin) return navigateFallback(href, { replace });
    if (!paths.find((p) => matchPath(p, url.pathname))) return navigateFallback(href, { replace });
    // force progressbar to run before new component starts loading
    progressBar(() => new Promise((resolve) => setTimeout(resolve, 100)));
    navigate(url.pathname + url.search, { replace });
  };
}

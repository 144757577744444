import { alpha, Button, CircularProgress, Paper } from "@mui/material";
import Message from "controls/Message";
import { progressBar } from "hooks/useProgressBar";
import SwitchInput from "inputs/SwitchInput";
import { CursorMove } from "mdi-material-ui";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Rnd } from "react-rnd";
import { useComponentSize } from "react-use-size";
import * as THREE from "three";

const vertexShader = `
varying vec2 fragPos;
void main() {
    fragPos = position.xy;
    gl_Position = vec4(position.x, position.y, 0.0, 1.0);
}
`;

const fragmentShader = `
uniform mat4 cameraRotation;
uniform sampler2D previewTexture;
uniform mat4 inverseProjectionMatrix;
uniform vec2 physicalCameraFOVs;

varying vec2 fragPos;

void main() {
    // Compute direction vector for this pixel
    vec4 d = inverseProjectionMatrix * vec4(fragPos.xy, 0.0, 1.0);
    d = cameraRotation * d;

    // Convert direction vector to yaw and pitch
    float yaw = atan(d.x, -d.z);
    float pitch = atan(d.y, sqrt(d.x * d.x + d.z * d.z));

    // Normalize angles based on the camera's FOVs
    float normalizedYaw = yaw / physicalCameraFOVs.x;
    float normalizedPitch = pitch / physicalCameraFOVs.y;

    vec2 normalizedAngles = vec2(normalizedYaw, normalizedPitch);
    gl_FragColor = texture2D(previewTexture, normalizedAngles * 0.5 + 0.5);
}
`;

function degreesToRadians(degrees) {
  return (degrees * Math.PI) / 180;
}

export default function SphereCameraPreview({
  motioncropPreviewUrl,
  fovHorizontalDegrees,
  fovVerticalDegrees,
  pitchDegrees,
  rollDegrees,
  sphereCameraFovHorizontalDegrees,
  sphereCameraPitchDegrees,
  disabled = false,
  ...others
}) {
  const { width, ref } = useComponentSize();
  const [textureLoading, textureLoadingSet] = useState(false);
  const [texture, textureSet] = useState(null);

  const aspectRatio = 16 / 9;
  const height = width / aspectRatio;

  useEffect(() => {
    if (motioncropPreviewUrl)
      progressBar(async () => {
        const textureLoader = new THREE.TextureLoader();
        textureLoadingSet(true);
        const texture = await new Promise((resolve) =>
          textureLoader.load(motioncropPreviewUrl, resolve, undefined, (error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            resolve(null);
          }),
        );
        textureSet(texture);
        textureLoadingSet(false);
      });
  }, [motioncropPreviewUrl]);

  return (
    <div
      ref={ref}
      {...others}
      style={{
        position: "relative",
        display: "flex",
        aspectRatio: `${aspectRatio}`,
        justifyContent: "center",
        alignItems: "center",
        ...others.style,
      }}
    >
      {(!motioncropPreviewUrl || textureLoading) && <CircularProgress />}
      {!!motioncropPreviewUrl && !textureLoading && !texture && (
        <Message type="error" content="Failed to load texture" />
      )}
      {texture && !!width && (
        <SphereCameraPreviewLoaded
          disabled={disabled}
          width={width}
          height={height}
          aspectRatio={aspectRatio}
          texture={texture}
          fovHorizontalDegrees={fovHorizontalDegrees}
          fovVerticalDegrees={fovVerticalDegrees}
          pitchDegrees={pitchDegrees}
          rollDegrees={rollDegrees}
          sphereCameraFovHorizontalDegrees={sphereCameraFovHorizontalDegrees}
          sphereCameraPitchDegrees={sphereCameraPitchDegrees}
        />
      )}
    </div>
  );
}

function SphereCameraPreviewLoaded({
  disabled = false,
  width,
  height,
  aspectRatio,
  texture,
  fovHorizontalDegrees,
  fovVerticalDegrees,
  pitchDegrees,
  rollDegrees,
  sphereCameraFovHorizontalDegrees,
  sphereCameraPitchDegrees,
}) {
  const ref = useRef();
  const rendererRef = useRef();

  const yawDefault = 0;
  const viewPitchDefault = [null, undefined].includes(sphereCameraPitchDegrees)
    ? degreesToRadians(pitchDegrees)
    : degreesToRadians(sphereCameraPitchDegrees);

  const [yaw, yawSet] = useState(yawDefault);
  const [viewPitch, viewPitchSet] = useState(viewPitchDefault);

  // init viewPitch
  useEffect(() => {
    viewPitchSet(viewPitchDefault);
    yawSet(yawDefault);
  }, [viewPitchDefault, yawDefault]);

  // pitch lock
  const [pitchLocked, pitchLockedSet] = useState(true);
  useEffect(() => {
    if (pitchLocked && viewPitch !== viewPitchDefault) {
      viewPitchSet(viewPitchDefault);
    }
  }, [viewPitchDefault, viewPitch, pitchLocked]);

  // clamp yaw
  const yawMax =
    degreesToRadians(fovHorizontalDegrees) * 0.5 - degreesToRadians(sphereCameraFovHorizontalDegrees || 50) * 0.5;
  const yawMin = -yawMax;
  useEffect(() => {
    if (yaw < yawMin) {
      yawSet(yawMin);
    } else if (yaw > yawMax) {
      yawSet(yawMax);
    }
  }, [yaw, yawMax]);

  // clamp viewPitch
  const viewPitchMin = degreesToRadians(pitchDegrees) - degreesToRadians(fovVerticalDegrees) / 2;
  const viewPitchMax = degreesToRadians(pitchDegrees) + degreesToRadians(fovVerticalDegrees) / 2;
  useEffect(() => {
    if (viewPitch < viewPitchMin) {
      viewPitchSet(viewPitchMin);
    } else if (viewPitch > viewPitchMax) {
      viewPitchSet(viewPitchMax);
    }
  }, [viewPitch, viewPitchMin, viewPitchMax]);

  // init Three.js
  const { scene, camera, mesh } = useMemo(() => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(sphereCameraFovHorizontalDegrees || 50, aspectRatio, 0.1, 9999);
    const geometry = new THREE.PlaneGeometry(2, 2);
    const material = new THREE.ShaderMaterial({
      uniforms: {
        cameraRotation: { value: new THREE.Matrix4() },
        previewTexture: { value: texture },
        inverseProjectionMatrix: { value: camera.projectionMatrixInverse },
        physicalCameraFOVs: {
          value: [180 * 0.5, 90 * 0.5],
        },
      },
      vertexShader,
      fragmentShader,
    });
    const mesh = new THREE.Mesh(geometry, material);
    mesh.position.z = -5;
    mesh.frustumCulled = false;
    scene.add(mesh);

    return { scene, camera, mesh };
  }, []);

  // camera position
  useEffect(() => {
    camera.fov = sphereCameraFovHorizontalDegrees || 50;
    camera.updateProjectionMatrix();

    camera.quaternion.setFromEuler(new THREE.Euler(viewPitch, yaw, 0, "ZYX"));
    mesh.material.uniforms.inverseProjectionMatrix.value = camera.projectionMatrixInverse;

    const cameraPitchCorrection = new THREE.Quaternion();
    cameraPitchCorrection.setFromAxisAngle(new THREE.Vector3(-1, 0, 0), degreesToRadians(pitchDegrees));
    const cameraRollCorrection = new THREE.Quaternion();
    cameraRollCorrection.setFromAxisAngle(new THREE.Vector3(0, 0, 1), degreesToRadians(rollDegrees));

    mesh.material.uniforms.cameraRotation.value.makeRotationFromQuaternion(
      cameraPitchCorrection.multiply(cameraRollCorrection.multiply(camera.quaternion)),
    );
  }, [sphereCameraFovHorizontalDegrees, viewPitch, yaw, pitchDegrees, rollDegrees]);

  // mesh material
  useEffect(() => {
    // update mesh material
    mesh.material.uniforms.physicalCameraFOVs.value = [
      degreesToRadians(fovHorizontalDegrees) * 0.5,
      degreesToRadians(fovVerticalDegrees) * 0.5,
    ];
  }, [fovHorizontalDegrees, fovVerticalDegrees]);

  // renderer size
  useEffect(() => {
    if (rendererRef.current) {
      const renderer = rendererRef.current;
      renderer.setSize(width * 2, height * 2);
      renderer.domElement.style.width = "100%";
      renderer.domElement.style.height = "100%";
    }
  }, [width, height]);

  // render
  useEffect(() => {
    let unmounted = false;
    const renderer = new THREE.WebGLRenderer();
    rendererRef.current = renderer;
    renderer.setSize(width * 2, height * 2);
    ref.current.prepend(renderer.domElement);
    renderer.domElement.style.position = "absolute";
    renderer.domElement.style.left = 0;
    renderer.domElement.style.top = 0;
    renderer.domElement.style.width = "100%";
    renderer.domElement.style.height = "100%";
    const renderFunc = () => {
      if (unmounted) return;
      renderer.render(scene, camera);
      requestAnimationFrame(renderFunc);
    };
    requestAnimationFrame(renderFunc);

    return () => {
      renderer.dispose();
      unmounted = true;
    };
  }, []);

  return (
    <div ref={ref} style={{ position: "absolute", left: 0, top: 0, width, height }}>
      {!disabled && (
        <>
          <Rnd
            enableResizing={false}
            position={{
              x: (width * (yaw - yawMin)) / (yawMax - yawMin) - 20,
              y: (height * (viewPitch - viewPitchMin)) / (viewPitchMax - viewPitchMin) - 20,
            }}
            onDrag={(event, data) => {
              yawSet(((data.x + 20) * (yawMax - yawMin)) / width + yawMin);
              viewPitchSet(((data.y + 20) * (viewPitchMax - viewPitchMin)) / height + viewPitchMin);
            }}
            size={{ width: 40, height: 40 }}
            style={{
              borderRadius: 20,
              border: "1px solid #000",
              backgroundColor: alpha("#ccc", 0.5),
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CursorMove />
          </Rnd>
          <Paper
            style={{
              position: "absolute",
              top: 10,
              left: 10,
              padding: 10,
              transform: "scale(0.5)",
              transformOrigin: "top left",
              display: "flex",
              flexFlow: "column nowrap",
              gap: 10,
              opacity: 0.8,
            }}
          >
            <SwitchInput value={pitchLocked} onChange={pitchLockedSet} label="Lock Camera Pitch" />
            <Button
              disabled={viewPitchDefault === viewPitch && yaw === yawDefault}
              onClick={() => {
                yawSet(yawDefault);
                viewPitchSet(viewPitchDefault);
              }}
              variant="contained"
            >
              Reset to court centre
            </Button>
          </Paper>
        </>
      )}
    </div>
  );
}

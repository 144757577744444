import { Button, InputAdornment, TextField } from "@mui/material";
import { formatISO, isValid, parseISO } from "date-fns";
import { formatInTimeZone, fromZonedTime } from "date-fns-tz";
import useFormDialogs from "hooks/useFormDialogs";
import useTimeZone from "hooks/useTimeZone";
import React, { useId } from "react";
import { ChangeTimeZoneDialog } from "readmin_pages/shared/ReadminFooter";

const INPUT_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

function formatDateTime(utcStr, timeZone) {
  if (!utcStr) return "";
  const utcDate = parseISO(utcStr);
  if (!isValid(utcDate)) return "";
  const localStr = formatInTimeZone(utcDate, timeZone, INPUT_FORMAT);
  return localStr;
}

function parseDateTime(localStr, timeZone) {
  const localDate = parseISO(localStr);
  if (!isValid(localDate)) return "";
  const utcDate = fromZonedTime(localDate, timeZone);
  const utcStr = formatISO(utcDate);
  return utcStr;
}

DateTimeInput.defaultValue = null;

export default function DateTimeInput({ value, readOnly = false, onChange, ...others }) {
  const id = useId();
  const timeZone = useTimeZone();
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <>
      {formDialogs}
      <TextField
        fullWidth
        id={id}
        value={formatDateTime(value, timeZone)}
        inputProps={{
          readOnly,
          step: 1,
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button onClick={() => formDialogsOpen(<ChangeTimeZoneDialog />)} color="inherit">
                {timeZone}
              </Button>
            </InputAdornment>
          ),
        }}
        onChange={(event) => onChange(parseDateTime(event.target.value, timeZone))}
        type="datetime-local"
        {...others}
      />
    </>
  );
}

import { gql } from "@apollo/client";
import DateTime from "controls/DateTime";
import Duration from "controls/Duration";
import ReadminLink, { ReadminLinkFragment } from "controls/ReadminLink";
import Urls from "controls/Urls";
import { intlFormat, parseISO } from "date-fns";
import { filesize } from "filesize";
import { FONT_FAMILY_ROBOTO_MONO } from "helpers/fonts";
import useTimeZone from "hooks/useTimeZone";
import { Check, Close } from "mdi-material-ui";
import React, { Fragment } from "react";
import ReactJson from "react-json-view";

export const ReadminAttributeValueFragment = gql`
  fragment ReadminAttributeValueFragment on ReadminAttributeEntry {
    label
    value
    type
    readminObjects {
      id
      ...ReadminLinkFragment
    }
  }
  ${ReadminLinkFragment}
`;

export default function ReadminAttributeValue({ label, value, type, date = null, readminObjects, dense = false }) {
  const timeZone = useTimeZone();

  if (!type) return null;
  if (type === "null") return null;
  if (type === "number") {
    let description = null;
    if (value >= 1000) description = filesize(value).replace(/\s?B/g, "");
    return (
      <span>
        {value}
        {description && <span style={{ fontSize: "0.8em" }}> ({description})</span>}
      </span>
    );
  }
  if (type === "string") {
    if (label.match(/url$/i)) return <Urls dense={dense} url={value} />;
    return (
      <span
        style={{
          ...(String(value).includes("\n") && {
            whiteSpace: "break-spaces",
            fontFamily: FONT_FAMILY_ROBOTO_MONO,
          }),
        }}
      >
        {value}
      </span>
    );
  }
  if (type === "strings") {
    if (label.match(/urls$/i)) return <Urls dense={dense} urls={value} />;
    return value.join(", ");
  }
  if (type === "boolean")
    return value ? (
      <>
        <Check fontSize="inherit" color="success" />
        Yes
      </>
    ) : (
      <>
        <Close fontSize="inherit" color="error" />
        No
      </>
    );
  if (type === "duration_seconds") return <Duration seconds={value} />;
  if (type === "datetime") {
    if (date) {
      const dateStr = intlFormat(parseISO(date), {
        dateStyle: "full",
        timeZone,
      });
      const valueDateStr = intlFormat(parseISO(value), {
        dateStyle: "full",
        timeZone,
      });
      if (dateStr == valueDateStr) return <DateTime value={value} variant="time" />;
    }
    return <DateTime value={value} />;
  }
  if (type === "date") return <DateTime value={value} variant="date" />;
  if (type === "json") return <ReactJson name={false} src={value} collapsed />;
  if (type === "readmin_objects")
    return readminObjects.map((record, recordIndex) => (
      <Fragment key={recordIndex}>
        <ReadminLink to={record} dense={dense} />
        <br />
      </Fragment>
    ));
  return (
    <>
      Unknown type: {type}
      <br />
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </>
  );
}

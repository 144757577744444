import "react-datepicker/dist/react-datepicker.css";

import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  Menu,
  MenuItem,
  OutlinedInput,
} from "@mui/material";
import {
  add,
  differenceInDays,
  differenceInMonths,
  endOfMonth,
  endOfWeek,
  endOfYear,
  format,
  getDayOfYear,
  intlFormat,
  isFirstDayOfMonth,
  isFuture,
  isLastDayOfMonth,
  isPast,
  isSameDay,
  lastDayOfYear,
  parseISO,
  startOfMonth,
  startOfWeek,
  startOfYear,
  sub,
} from "date-fns";
import { times, uniqBy } from "lodash-es";
import { ArrowLeft, ArrowRight } from "mdi-material-ui";
import React, { useId, useRef, useState } from "react";
import DatePicker from "react-datepicker";

const ISO_DATE_FORMAT = "yyyy-MM-dd";

const formatRange = (value) => {
  if (!value) return "All time";

  const fromDate = parseISO(value.from);
  const toDate = parseISO(value.to);

  let intlFormatOptions = {
    dateStyle: "short",
  };
  if (isFirstDayOfMonth(fromDate) && isLastDayOfMonth(toDate))
    intlFormatOptions = {
      month: "short",
      year: "numeric",
    };
  if (getDayOfYear(fromDate) === 1 && isSameDay(lastDayOfYear(toDate), toDate))
    intlFormatOptions = {
      year: "numeric",
    };

  const fromStr = intlFormat(fromDate, intlFormatOptions);
  const toStr = intlFormat(toDate, intlFormatOptions);
  return fromStr === toStr ? fromStr : `${fromStr} - ${toStr}`;
};

export default function DateRangeFilter({ value, onChange, future = false, required = false }) {
  const ref = useRef();
  const [open, openSet] = useState(false);
  const id = useId();

  const options = OPTIONS.filter((o) => future || !o.value || isPast(parseISO(o.value.from))).filter(
    (o) => o.value || !required,
  );

  let selectedOption = options.find((o) => o.value?.from === value?.from && o.value?.to === value?.to);

  let valueLeft, valueRight;
  if (value) {
    const startDate = parseISO(value.from);
    const endDate = add(parseISO(value.to), { days: 1 });
    let diff = null;
    if (isFirstDayOfMonth(startDate) && isFirstDayOfMonth(endDate))
      diff = {
        months: differenceInMonths(endDate, startDate),
      };
    else
      diff = {
        days: differenceInDays(endDate, startDate),
      };
    valueLeft = {
      from: format(sub(startDate, diff), ISO_DATE_FORMAT),
      to: format(sub(sub(endDate, diff), { days: 1 }), ISO_DATE_FORMAT),
    };

    valueRight = {
      from: format(add(startDate, diff), ISO_DATE_FORMAT),
      to: format(sub(add(endDate, diff), { days: 1 }), ISO_DATE_FORMAT),
    };

    if (!future && isFuture(parseISO(valueRight.from))) valueRight = null;
  }

  const label = "Date";

  return (
    <>
      <FormControl data-date-range-filter size="small" ref={ref}>
        <InputLabel shrink htmlFor={id}>
          {label}
        </InputLabel>
        <OutlinedInput
          label={label}
          id={id}
          startAdornment={
            <InputAdornment position="start">
              <IconButton
                edge="start"
                title="Previous Period"
                disabled={!valueLeft}
                onClick={() => onChange?.(valueLeft)}
              >
                <ArrowLeft />
              </IconButton>
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end" title="Next Period" disabled={!valueRight} onClick={() => onChange?.(valueRight)}>
                <ArrowRight />
              </IconButton>
            </InputAdornment>
          }
          inputComponent="a"
          inputProps={{
            title: "Filter By Date Range",
            children: selectedOption?.label || formatRange(value),
            style: { cursor: "pointer", textDecoration: "none" },
            onClick: () => openSet(true),
            href: "#",
          }}
        />
      </FormControl>
      <Menu component="div" open={open} anchorEl={ref.current} onClose={() => openSet(false)}>
        {options
          .filter((o) => o.shown)
          .map((option) => (
            <MenuItem
              component="a"
              href="#"
              key={option.label}
              onClick={() => {
                onChange?.(option.value);
                openSet(false);
              }}
            >
              <ListItemText
                primary={option.label}
                secondary={option.value && formatRange(option.value) !== option.label && formatRange(option.value)}
              />
            </MenuItem>
          ))}
        {open && (
          <DateRangeModal
            range={value}
            onChange={(valueNew) => {
              onChange?.(valueNew);
              openSet(false);
            }}
            future={future}
          />
        )}
      </Menu>
    </>
  );
}

function DateRangeModal({ range, onChange, future }) {
  const [start, startSet] = useState(range?.from && parseISO(range.from));
  const [end, endSet] = useState(range?.to && parseISO(range.to));

  return (
    <DatePicker
      maxDate={future ? undefined : new Date()}
      inline
      onChange={([start, end]) => {
        startSet(start);
        endSet(end);
        if (start && end)
          onChange({
            from: format(start, ISO_DATE_FORMAT),
            to: format(end, ISO_DATE_FORMAT),
          });
      }}
      startDate={start}
      endDate={end}
      selectsRange
      selectsDisabledDaysInRange
      calendarStartDay={1}
      calendarContainer={(props) => <div style={{ position: "relative" }}>{props.children}</div>}
    />
  );
}

export const TODAY = {
  from: format(new Date(), ISO_DATE_FORMAT),
  to: format(new Date(), ISO_DATE_FORMAT),
};

export const TOMORROW = {
  from: format(add(new Date(), { days: 1 }), ISO_DATE_FORMAT),
  to: format(add(new Date(), { days: 1 }), ISO_DATE_FORMAT),
};

export const YESTERDAY = {
  from: format(sub(new Date(), { days: 1 }), ISO_DATE_FORMAT),
  to: format(sub(new Date(), { days: 1 }), ISO_DATE_FORMAT),
};

export const LAST_30DAYS = {
  from: format(sub(new Date(), { days: 30 }), ISO_DATE_FORMAT),
  to: format(new Date(), ISO_DATE_FORMAT),
};

export const NEXT_30DAYS = {
  from: format(add(new Date(), { days: 1 }), ISO_DATE_FORMAT),
  to: format(add(new Date(), { days: 31 }), ISO_DATE_FORMAT),
};

export const LAST_6MONTHS = {
  from: format(startOfMonth(sub(new Date(), { months: 6 })), ISO_DATE_FORMAT),
  to: format(endOfMonth(new Date()), ISO_DATE_FORMAT),
};

export const THIS_MONTH = {
  from: format(startOfMonth(new Date()), ISO_DATE_FORMAT),
  to: format(endOfMonth(new Date()), ISO_DATE_FORMAT),
};

export const LAST_MONTH = {
  from: format(startOfMonth(sub(new Date(), { months: 1 })), ISO_DATE_FORMAT),
  to: format(endOfMonth(sub(new Date(), { months: 1 })), ISO_DATE_FORMAT),
};

export const THIS_YEAR = {
  from: format(startOfYear(new Date()), ISO_DATE_FORMAT),
  to: format(endOfYear(new Date()), ISO_DATE_FORMAT),
};

export const THIS_WEEK = {
  from: format(startOfWeek(new Date(), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
  to: format(endOfWeek(new Date(), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
};

export const LAST_WEEK = {
  from: format(startOfWeek(sub(new Date(), { weeks: 1 }), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
  to: format(endOfWeek(sub(new Date(), { weeks: 1 }), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
};

export const NEXT_WEEK = {
  from: format(startOfWeek(add(new Date(), { weeks: 1 }), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
  to: format(endOfWeek(add(new Date(), { weeks: 1 }), { weekStartsOn: 1 }), ISO_DATE_FORMAT),
};

export const ALL_DATE_RANGE_VALUES = {
  TODAY,
  TOMORROW,
  YESTERDAY,
  LAST_30DAYS,
  NEXT_30DAYS,
  LAST_6MONTHS,
  THIS_MONTH,
  LAST_MONTH,
  THIS_YEAR,
  THIS_WEEK,
  LAST_WEEK,
  NEXT_WEEK,
};

const OPTIONS = uniqBy(
  [
    {
      shown: true,
      label: "All time",
      value: null,
    },
    {
      shown: true,
      label: "Today",
      value: TODAY,
    },
    {
      label: "Yesterday",
      value: YESTERDAY,
    },
    {
      label: "Tomorrow",
      value: TOMORROW,
    },
    ...times(7)
      .map((i) => add(startOfWeek(new Date(), { weekStartsOn: 1 }), { days: i }))
      .map((date) => ({
        label: intlFormat(date, {
          weekday: "long",
        }),
        value: {
          from: format(date, ISO_DATE_FORMAT),
          to: format(date, ISO_DATE_FORMAT),
        },
      })),
    {
      shown: true,
      label: "This Week",
      value: THIS_WEEK,
    },
    {
      label: "Last Week",
      value: LAST_WEEK,
    },
    {
      label: "Next Week",
      value: NEXT_WEEK,
    },
    {
      label: "Last Month",
      value: LAST_MONTH,
    },
    {
      shown: true,
      label: "This Month",
      value: THIS_MONTH,
    },
    {
      shown: true,
      label: "This Year",
      value: THIS_YEAR,
    },
    {
      shown: true,
      label: "Last 30 Days",
      value: LAST_30DAYS,
    },
    {
      shown: true,
      label: "Next 30 Days",
      value: NEXT_30DAYS,
    },
    {
      label: "Last 6 Months",
      value: LAST_6MONTHS,
    },
    ...times(6)
      .map((i) => add(startOfMonth(new Date()), { months: i - 3 }))
      .map((date) => ({
        value: {
          from: format(date, ISO_DATE_FORMAT),
          to: format(endOfMonth(date), ISO_DATE_FORMAT),
        },
      })),
    ...times(3)
      .map((i) => add(startOfYear(new Date()), { years: i - 1 }))
      .map((date) => ({
        value: {
          from: format(date, ISO_DATE_FORMAT),
          to: format(lastDayOfYear(date), ISO_DATE_FORMAT),
        },
      })),
  ],
  (o) => formatRange(o.value),
).map((option) => ({
  label: formatRange(option.value),
  ...option,
}));

import { gql } from "@apollo/client";
import { CircularProgress, Dialog, IconButton, Paper } from "@mui/material";
import VideoThumbnail from "controls/VideoThumbnail";
import b64ToBlob from "helpers/b64ToBlob";
import { dialogContext } from "helpers/contexts";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { progressBar } from "hooks/useProgressBar";
import useShowMessage from "hooks/useShowMessage";
import { AlertCircle } from "mdi-material-ui";
import React, { useEffect, useState } from "react";

import SphereCameraPreview from "./SphereCameraPreview";

export default function SurfaceThumbnail({ id, typename, ...others }) {
  const showMessage = useShowMessage();
  const [dialogOpen, dialogOpenSet] = useState(false);
  const [previewImageUrl, previewImageUrlSet] = useState(null);
  const [previewLoading, previewLoadingSet] = useState(false);
  const [previewErrorLogs, previewErrorLogsSet] = useState(null);
  const surfacePreviewMotionCrop = useActionFragment("surfacePreviewMotionCrop", "jpgImageBase64 previewErrorLogs");
  const [data] = useData(
    gql`
      query SurfaceThumbnail($id: ID!) {
        surface(id: $id) {
          id
          projectionShape
          fovHorizontalDegrees
          fovVerticalDegrees
          pitchDegrees
          rollDegrees
          sphereCameraFovHorizontalDegrees
          sphereCameraPitchDegrees
        }
      }
    `,
    { id },
  );

  useEffect(() => {
    progressBar(async () => {
      previewLoadingSet(true);
      const result = await surfacePreviewMotionCrop({
        input: {
          surfaceId: id,
        },
      });
      const { jpgImageBase64, previewErrorLogs } = result.surfacePreviewMotionCrop;
      previewLoadingSet(false);
      if (!jpgImageBase64) {
        previewErrorLogsSet(previewErrorLogs);
        return;
      }
      const blob = b64ToBlob(jpgImageBase64, "image/jpeg");
      const url = URL.createObjectURL(blob);
      previewImageUrlSet(url);
    });
  }, [id]);

  return (
    <Paper {...others} style={{ display: "flex", justifyContent: "center", alignItems: "center", ...others.style }}>
      {previewLoading ? (
        <CircularProgress size={20} />
      ) : (
        <>
          {previewImageUrl ? (
            <>
              {data?.surface.projectionShape === "sphere" ? (
                <SphereCameraPreview
                  disabled
                  motioncropPreviewUrl={previewImageUrl}
                  fovHorizontalDegrees={data.surface.fovHorizontalDegrees}
                  fovVerticalDegrees={data.surface.fovVerticalDegrees}
                  pitchDegrees={data.surface.pitchDegrees}
                  rollDegrees={data.surface.rollDegrees}
                  sphereCameraFovHorizontalDegrees={data.surface.sphereCameraFovHorizontalDegrees}
                  sphereCameraPitchDegrees={data.surface.sphereCameraPitchDegrees}
                  style={{ width: "100%", height: "100%", cursor: "pointer" }}
                  onClick={() => dialogOpenSet(true)}
                />
              ) : (
                <VideoThumbnail thumbnailUrl={previewImageUrl} style={{ width: "100%", height: "100%" }} />
              )}
            </>
          ) : (
            <IconButton
              size="small"
              onClick={() =>
                showMessage({
                  type: "error",
                  modal: true,
                  header: "Error loading VC preview",
                  content: (
                    <>
                      Error logs:
                      <pre>
                        <code>{previewErrorLogs}</code>
                      </pre>
                    </>
                  ),
                })
              }
            >
              <AlertCircle color="error" />
            </IconButton>
          )}
        </>
      )}
      <dialogContext.Provider value={{ isInDialog: true }}>
        <Dialog open={dialogOpen} onClose={() => dialogOpenSet(false)} fullWidth maxWidth="lg">
          {data && (
            <SphereCameraPreview
              motioncropPreviewUrl={previewImageUrl}
              fovHorizontalDegrees={data.surface.fovHorizontalDegrees}
              fovVerticalDegrees={data.surface.fovVerticalDegrees}
              pitchDegrees={data.surface.pitchDegrees}
              rollDegrees={data.surface.rollDegrees}
              sphereCameraFovHorizontalDegrees={data.surface.sphereCameraFovHorizontalDegrees}
              sphereCameraPitchDegrees={data.surface.sphereCameraPitchDegrees}
              style={{ width: "100%", cursor: "pointer" }}
            />
          )}
        </Dialog>
      </dialogContext.Provider>
    </Paper>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import SelectInput from "inputs/SelectInput";
import { VideoSwitchOutline } from "mdi-material-ui";
import React from "react";

VideosReEncodeDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    icon: <VideoSwitchOutline />,
    hidden: !isAdmin,
    content: "Re-encode",
  };
};

export default function VideosReEncodeDialog({ videoIds, onClose, onSubmit }) {
  const videosReEncode = useActionFragment("videosReEncode");

  return (
    <FormDialog
      header="Re-encode Video"
      onClose={onClose}
      onSubmit={async (formData) => {
        await videosReEncode({
          input: {
            videoIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <Field
        name="queue"
        label="Queue"
        required
        defaultValue="standard"
        input={<SelectInput options={["standard", "canary"]} />}
      />
    </FormDialog>
  );
}

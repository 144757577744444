import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import TeamsForm from "./Form";

AddTeamDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Team",
  };
};

export default function AddTeamDialog({ clubId, onClose, onSubmit }) {
  const addTeam = useActionFragment("addTeam");

  return (
    <FormDialog
      header="Add Team"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addTeam({ input: formData });
        await onSubmit?.();
      }}
    >
      <TeamsForm
        teams={[
          {
            club: clubId && { id: clubId, __typename: "Club" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { Button } from "@mui/material";
import FormFieldContainer from "controls/FormFieldContainer";
import { filesize } from "filesize";
import selectFiles from "helpers/selectFiles";
import useLoadedImage from "helpers/useLoadedImage";
import { progressBar } from "hooks/useProgressBar";
import React, { useState } from "react";

UploaderInput.defaultValue = null;
UploaderInput.toFormData = (value) =>
  value && {
    name: value.name,
    base64: value.base64,
    size: value.size,
  };

export default function UploaderInput({ value, onChange, disabled = false, accept, ...others }) {
  const handleFiles = ([file]) =>
    progressBar(async () => {
      const base64 = await readFileBase64(file);
      const blobUrl = URL.createObjectURL(file);
      const valueNew = { name: file.name, base64, size: file.size, blobUrl };
      onChange(valueNew);
    });

  const [loadedImage] = useLoadedImage(value?.blobUrl || value?.url, { throwError: false });

  const description = [];
  if (value?.size) description.push(filesize(value.size));
  if (loadedImage)
    description.push(`${loadedImage.width || loadedImage.videoWidth}x${loadedImage.height || loadedImage.videoHeight}`);
  const [draggingOver, draggingOverSet] = useState(false);

  return (
    <FormFieldContainer data-uploader-input disabled={disabled} {...others} {...(draggingOver && { focused: true })}>
      <div
        style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: 5, padding: 5 }}
        onDragOver={(event) => {
          if (disabled) return;
          if (event.dataTransfer.types.includes("Files")) event.preventDefault();
          draggingOverSet(true);
        }}
        onDragLeave={() => {
          if (disabled) return;
          draggingOverSet(false);
        }}
        onDrop={(event) => {
          if (disabled) return;
          if (!event.dataTransfer.files.length) return;
          event.preventDefault();
          if (disabled) return;

          handleFiles(event.dataTransfer.files);
        }}
      >
        {value && (
          <>
            <div>{value.name}</div>
            {!!description.length && <div>({description.join(", ")})</div>}
            <Button variant="outlined" color="inherit" href={value.url || value.blobUrl} target="_blank">
              Open
            </Button>
            <Button variant="outlined" color="inherit" onClick={() => onChange(null)}>
              Clear
            </Button>
          </>
        )}
        <Button variant="outlined" color="inherit" onClick={() => selectFiles({ accept }, handleFiles)}>
          Select file... {accept && `(${accept})`}
        </Button>
      </div>
    </FormFieldContainer>
  );
}

function readFileBase64(file) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.addEventListener("load", () => resolve(fileReader.result.split(",")[1]));
    fileReader.addEventListener("error", reject);
  });
}

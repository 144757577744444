export const SHORT_DURATION_MAX_SECONDS = 86400;

export default function formatShortDuration(seconds) {
  if (seconds > SHORT_DURATION_MAX_SECONDS) return "more than 24 hours";
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secondsLeft = Math.floor(seconds % 60);

  const hh = hours && hours.toString().padStart(2, "0");
  const mm = minutes.toString().padStart(2, "0");
  const ss = secondsLeft.toString().padStart(2, "0");

  return [hh, mm, ss].filter(Boolean).join(":");
}

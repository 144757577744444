import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import ClubsForm, { ClubsFormFragment } from "./Form";

ClubsEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function ClubsEditDialog({ clubIds, onClose, onSubmit }) {
  const clubsUpdate = useActionFragment("clubsUpdate");

  const [data, dataMeta] = useData(
    gql`
      query ClubsEditDialog($clubIds: [ID!]!) {
        clubs(ids: $clubIds) {
          id
          ...ClubsFormFragment
        }
      }
      ${ClubsFormFragment}
    `,
    { clubIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Club"
      onClose={onClose}
      onSubmit={async (formData) => {
        await clubsUpdate({ input: { ...formData, clubIds } });
        await onSubmit?.();
      }}
    >
      <ClubsForm clubs={data?.clubs} />
    </FormDialog>
  );
}

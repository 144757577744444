import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import Message from "controls/Message";
import useData from "hooks/useData";
import React from "react";

import ReadminObjectAttributesTable, { ReadminObjectAttributesTableFragment } from "./ReadminObjectAttributesTable";

export default function ReadminDetailsTab({ id, typename, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query ReadminObjetPageContent($id: ID!, $typename: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          ...ReadminObjectAttributesTableFragment
        }
      }
      ${ReadminObjectAttributesTableFragment}
    `,
    { id, typename },
  );

  return (
    <Paper>
      {data && !data.readminObject && (
        <Message
          type="error"
          title="Record Not Found"
          content="Could not find this record. It may have been deleted."
        />
      )}
      <ReadminObjectAttributesTable
        readminObject={data?.readminObject}
        onSubmit={async () => {
          if (data) await dataMeta.refetch();
          await onSubmit?.();
        }}
      />
    </Paper>
  );
}

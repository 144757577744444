import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const VenuesFormFragment = gql`
  fragment VenuesFormFragment on Venue {
    id
    name
    city {
      id
    }
    details
    awsRegion
    maxConcurrentLiveStreams
  }
`;

export default function VenuesForm({ venues = [{}] }) {
  const [data] = useData(gql`
    query VenuesForm {
      outputGlAwsRegions {
        awsRegion
        name
      }
    }
  `);
  venues = venues.map((venue) => ({
    maxConcurrentLiveStreams: 1,
    ...venue,
  }));

  return (
    <>
      <MultiValuesField
        defaultValues={venues.map((v) => v.name)}
        required
        label="Name"
        name="name"
        input={<TextInput />}
      />
      <MultiValuesField
        defaultValues={venues.map((v) => v.city)}
        required
        label="City"
        name="city"
        input={<ReadminObjectInput optionsTypename="City" />}
      />
      <MultiValuesField
        defaultValues={venues.map((v) => v.details)}
        label="Details"
        name="details"
        input={<TextInput multiline minRows={5} />}
      />
      <MultiValuesField
        defaultValues={venues.map((v) => v.awsRegion)}
        label="AWS Region"
        name="awsRegion"
        required
        input={
          <SelectInput
            options={data?.outputGlAwsRegions.map(({ name, awsRegion }) => ({ text: name, value: awsRegion }))}
          />
        }
      />
      <MultiValuesField
        defaultValues={venues.map((v) => v.maxConcurrentLiveStreams)}
        label="Max Concurrent Live Streams"
        name="maxConcurrentLiveStreams"
        required
        input={<NumberInput min={0} />}
      />
    </>
  );
}

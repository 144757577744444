import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const ScorekeeperUsersFormFragment = gql`
  fragment ScorekeeperUsersFormFragment on ScorekeeperUser {
    id
    club {
      id
    }
    email
    courts {
      id
    }
  }
`;

export default function ScorekeeperUsersForm({ scorekeeperUsers = [{}] }) {
  scorekeeperUsers = scorekeeperUsers.map((ca) => ({
    courts: [],
    ...ca,
  }));

  return (
    <>
      <MultiValuesField
        label="Club"
        name="club"
        required
        defaultValues={scorekeeperUsers.map((ca) => ca.club)}
        disabled={!!scorekeeperUsers.find((ca) => ca.id)}
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <MultiValuesField
        label="Email"
        name="email"
        required
        defaultValues={scorekeeperUsers.map((ca) => ca.email)}
        disabled={!!scorekeeperUsers.find((ca) => ca.id)}
        input={<TextInput autoComplete="email" type="email" />}
      />
      <MultiValuesField
        label="Courts"
        name="courts"
        defaultValues={scorekeeperUsers.map((ca) => ca.courts)}
        input={<ReadminObjectsInput optionsTypename="Court" />}
        helperText={<>Leave empty to allow access to all courts</>}
      />
    </>
  );
}

import { CircularProgress } from "@mui/material";
import React from "react";

export default function Loading({ loading, text = "Please wait" }) {
  return (
    <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
      {loading && <CircularProgress />}
      {text}
    </div>
  );
}

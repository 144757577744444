import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const LiveStreamCustomOutputsRemoveDialog = makeConfirmActionDialog({
  mutationName: "liveStreamCustomOutputsRemove",
  action: "Remove RTMP Output",
  paramName: "liveStreamCustomOutputIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import GdVenueForm from "./Form";

AddGdVenueDialog.buttonProps = {
  icon: <Plus />,
  content: "Add GameDay Venue",
};

export default function AddGdVenueDialog({ onSubmit = noop, gdAssociationId, ...others }) {
  const addGdVenue = useActionFragment("addGdVenue");

  return (
    <FormDialog
      {...others}
      header="Add GameDay Venue"
      onSubmit={async (formData) => {
        await addGdVenue({ input: formData });
        await onSubmit();
      }}
    >
      <GdVenueForm gdAssociationId={gdAssociationId} />
    </FormDialog>
  );
}

import { TextField } from "@mui/material";
import React, { useId } from "react";

TextInput.defaultValue = "";

export default function TextInput({
  value,
  onChange,
  type,
  autoComplete,
  minRows,
  maxRows,
  multiline = false,
  ...others
}) {
  const id = useId();

  return (
    <TextField
      fullWidth
      {...others}
      id={id}
      value={value || ""}
      onChange={(event) => onChange(event.target.value)}
      type={type}
      autoComplete={autoComplete}
      minRows={minRows}
      maxRows={maxRows}
      multiline={multiline}
      inputProps={{
        ...others.inputProps,
        style: {
          // TODO: MUI seems to have broken the scrollbar by overflow: hidden
          overflow: "auto",
          ...others.inputProps?.style,
        },
      }}
    />
  );
}

export function IpAdddressInput({ onChange, ...others }) {
  return (
    <TextInput
      {...others}
      inputProps={{
        pattern: "([0-9]+\\.){3}[0-9]+(/[0-9]+)?",
      }}
      onChange={(valueNew) => onChange?.(valueNew || null)}
    />
  );
}

export function MacAddressInput({ onChange, ...others }) {
  return (
    <TextInput
      {...others}
      inputProps={{
        pattern: "([0-9A-Fa-f]{2}:){5}[0-9A-Fa-f]{2}",
      }}
      onChange={(valueNew) => onChange?.(valueNew || null)}
    />
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import DateInput from "inputs/DateInput";
import PasswordInput from "inputs/PasswordInput";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";
import TextInput, { IpAdddressInput, MacAddressInput } from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const NetworkDevicesFormFragment = gql`
  fragment NetworkDevicesFormFragment on NetworkDevice {
    venueServer {
      id
    }
    model
    details
    purchaseDate
    ipAddress
    macAddress
    deviceUsername
    devicePassword
  }
`;

export default function NetworkDevicesForm({ networkDevices }) {
  const [data] = useData(gql`
    query NetworkDevicesForm {
      networkDeviceModels
    }
  `);

  return (
    <>
      <MultiValuesField
        name="venueServer"
        label="Venue Server"
        defaultValues={networkDevices?.map((nd) => nd.venueServer)}
        input={<ReadminObjectInput optionsTypename="VenueServer" />}
      />
      <MultiValuesField
        name="model"
        label="Model"
        required
        defaultValues={networkDevices?.map((nd) => nd.model)}
        input={<SuggestiveTextInput suggestions={data?.networkDeviceModels} />}
      />
      <MultiValuesField
        name="purchaseDate"
        label="Purchase Date"
        defaultValues={networkDevices?.map((nd) => nd.purchaseDate)}
        input={<DateInput />}
        helperText="Date when the device was purchased."
      />
      <MultiValuesField
        name="details"
        label="Details"
        defaultValues={networkDevices?.map((nd) => nd.details)}
        input={<TextInput multiline minRows={5} />}
      />
      <MultiValuesField
        name="ipAddress"
        label="IP Address"
        defaultValues={networkDevices?.map((nd) => nd.ipAddress)}
        input={<IpAdddressInput />}
        helperText={
          <>
            E.g 10.9.9.60
            <br />
            Should be within Venue Server&apos;s internal network range. Network devices normally numbering starting
            from 60.
          </>
        }
      />
      <MultiValuesField
        name="macAddress"
        label="MAC Address"
        defaultValues={networkDevices?.map((nd) => nd.macAddress)}
        input={<MacAddressInput />}
      />
      <MultiValuesField
        name="deviceUsername"
        label="Device Username"
        defaultValues={networkDevices?.map((nd) => nd.deviceUsername)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="devicePassword"
        label="Device Password"
        defaultValues={networkDevices?.map((nd) => nd.devicePassword)}
        input={<PasswordInput />}
      />
    </>
  );
}

import { Link } from "mdi-material-ui";
import React, { Fragment } from "react";

export default function Urls({ url, dense = false, urls = [url], groups = [{ title: null, urls }] }) {
  groups = groups.map((group) => {
    if (group.constructor == Array) {
      const [title, urls] = group;
      return { title, urls };
    }
    return group;
  });

  return (
    <>
      {groups.map((group, groupIndex) => (
        <Fragment key={groupIndex}>
          {group.title && (
            <>
              {group.title}:<br />
            </>
          )}
          {group.urls
            ?.filter((u) => u)
            .map((url, urlIndex) => (
              <Fragment key={urlIndex}>
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    whiteSpace: "nowrap",
                    width: dense ? "20%" : "100%",
                    maxWidth: dense ? "20vw" : "80vw",
                    display: "inline-block",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <Link fontSize="inherit" style={{ verticalAlign: "top" }} />
                  <span>{url}</span>
                </a>
                <br />
              </Fragment>
            ))}
        </Fragment>
      ))}
    </>
  );
}

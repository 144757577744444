import { Autocomplete, TextField } from "@mui/material";
import React, { useId } from "react";

SuggestiveTextInput.defaultValue = "";

export default function SuggestiveTextInput({
  //
  value,
  onChange,
  suggestions = [],
  ...others
}) {
  const id = useId();

  return (
    <Autocomplete
      fullWidth
      options={suggestions?.map((suggestion) => ({ id: suggestion, label: suggestion }))}
      id={id}
      inputValue={value || ""}
      freeSolo
      onInputChange={(event, inputValue) => onChange(inputValue)}
      renderInput={(inputProps) => <TextField {...inputProps} {...others} />}
    />
  );
}

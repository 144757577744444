import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import MultiValuesField from "controls/MultiValuesField";
import DateInput from "inputs/DateInput";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput, { IpAdddressInput } from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const VenueServersFormFragment = gql`
  fragment VenueServersFormFragment on VenueServer {
    id
    state
    enabled
    details
    purchaseDate
    machineId
    venue {
      id
    }
    locallyAccessible
    uploaderRateLimitKbytes
    internalNetwork
    internalNetworkIpAddress
    staticIpAddress
    staticGatewayAddress
    staticPrimaryDnsServer
    staticSecondaryDnsServer
  }
`;

export default function VenueServersForm({ venueServers }) {
  return (
    <>
      <MultiValuesField
        defaultValues={venueServers?.map((server) => server.enabled)}
        label="Enabled"
        name="enabled"
        input={<SwitchInput />}
        helperText="Only enabled venue servers can connect to Glory League platform."
      />
      <MultiValuesField
        defaultValues={venueServers?.map((server) => server.state)}
        label="State"
        name="state"
        required
        input={
          <SelectInput
            options={[
              {
                text: "Unused",
                value: "unused",
                description: "No hardware has been linked with this venue server record",
              },
              {
                text: "Provisioned",
                value: "provisioned",
                description: "Hardware configured, but not yet installed in a venue.",
              },
              {
                text: "Active",
                value: "active",
                description: "Currently installed at a venue.",
              },
              {
                text: "Inactive",
                value: "inactive",
                description: "Installed at a venue, but not being used.",
              },
              {
                text: "Decommissioned",
                value: "decommissioned",
                description: "No longer in use.",
              },
            ]}
          />
        }
      />
      <MultiValuesField
        name="machineId"
        label="Machine ID"
        defaultValues={venueServers?.map((server) => server.machineId)}
        input={<TextInput />}
        helperText={
          <>
            Unique identifier for the server populated during Linux installation. Can be found out by running:
            <br />
            <code>cat /etc/machine-id</code>
          </>
        }
      />
      <MultiValuesField
        name="purchaseDate"
        label="Purchase Date"
        defaultValues={venueServers?.map((server) => server.purchaseDate)}
        input={<DateInput />}
        helperText="Date when the hardware was purchased."
      />
      <MultiValuesField
        defaultValues={venueServers?.map((server) => server.details)}
        label="Details"
        name="details"
        input={<TextInput multiline minRows={5} />}
        helperText={<>Record transfer history of this server hardware.</>}
      />
      <FormSection header="Venue">
        <MultiValuesField
          name="venue"
          label="Venue"
          defaultValues={venueServers?.map((server) => server.venue)}
          input={<ReadminObjectInput optionsTypename="Venue" />}
          helperText="Leave blank until the server is assigned to a venue."
        />
        <MultiValuesField
          name="locallyAccessible"
          label="Locally Accessible"
          defaultValues={venueServers?.map((server) => server.locallyAccessible)}
          input={<SwitchInput />}
          helperText="Tick if we expect the iPad to be able to make a direct connection over the wireless network to this server. Improves video reliability."
        />
      </FormSection>
      <FormSection header="Uploader">
        <MultiValuesField
          name="uploaderRateLimitKbytes"
          label="Upload Rate Limit"
          defaultValues={venueServers?.map((server) => server.uploaderRateLimitKbytes)}
          input={<NumberInput unit="kBytes/s" />}
          helperText={
            <>
              Set after running a speed test at the venue. Use ~80% of the maximum available upload bandwidth.
              <br />
              E.g for a hour long game video (assuming 5GB):
              <br />* 5MB/s takes 17 minutes
              <br />* 1MB/s takes 83 minutes
            </>
          }
        />
      </FormSection>
      <FormSection header="Internal Network">
        <Message
          content={
            <>
              This defines the network that the cameras use, and which the venue server also has an address on.
              <br />
              10.9.9.0/24 is used in almost all venues, with the venue server occupying 10.9.9.50.
              <br />
              The cameras are given static IP addresses in the range 10.9.9.1 to 10.9.9.40. If there are other Glory
              League devices, such as switches, they are usually given an address such as 10.9.9.60.
            </>
          }
        />
        <MultiValuesField
          name="internalNetwork"
          label="Internal Network"
          defaultValues={venueServers?.map((server) => server.internalNetwork)}
          input={<IpAdddressInput />}
          helperText={
            <>
              E.g 10.9.9.0/24
              <br />
              Use default value unless it collides with venue network or other venue server on the same network
            </>
          }
        />
        <MultiValuesField
          name="internalNetworkIpAddress"
          label="Internal Network IP Address"
          defaultValues={venueServers?.map((server) => server.internalNetworkIpAddress)}
          input={<IpAdddressInput />}
          helperText={<>E.g 10.9.9.50/24, normally numbering from 50</>}
        />
      </FormSection>
      <FormSection header="Static Network">
        <Message
          content={
            <>
              If the venue server is required to have a static IP address on the venue&apos;s network, fill in these
              fields.
            </>
          }
        />
        <MultiValuesField
          name="staticIpAddress"
          label="Static IP Address"
          defaultValues={venueServers?.map((server) => server.staticIpAddress)}
          input={<IpAdddressInput />}
          helperText={<>E.g 192.168.0.101/24</>}
        />
        <MultiValuesField
          name="staticGatewayAddress"
          label="Static Gateway Address"
          defaultValues={venueServers?.map((server) => server.staticGatewayAddress)}
          input={<IpAdddressInput />}
          helperText={<>E.g 192.168.0.1/24</>}
        />
        <MultiValuesField
          name="staticPrimaryDnsServer"
          label="Static Primary DNS Server"
          defaultValues={venueServers?.map((server) => server.staticPrimaryDnsServer)}
          input={<IpAdddressInput />}
          helperText="Leave blank to use public DNS servers."
        />
        <MultiValuesField
          name="staticSecondaryDnsServer"
          label="Static Secondary DNS Server"
          defaultValues={venueServers?.map((server) => server.staticSecondaryDnsServer)}
          input={<IpAdddressInput />}
          helperText="Leave blank to use public DNS servers."
        />
      </FormSection>
    </>
  );
}

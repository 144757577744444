import { Button } from "@mui/material";
import React from "react";

export default function FilterButton({ content, href = "#", disabled = false, hidden = false, onClick, icon }) {
  return (
    !hidden && (
      <Button
        component="a"
        variant="outlined"
        href="#"
        {...{
          href,
          disabled,
          onClick,
        }}
        startIcon={icon}
      >
        {content}
      </Button>
    )
  );
}

import { CircularProgress, Dialog, Paper } from "@mui/material";
import b64ToBlob from "helpers/b64ToBlob";
import { dialogContext } from "helpers/contexts";
import useLoadedImage from "helpers/useLoadedImage";
import { progressBar } from "hooks/useProgressBar";
import { AlertOutline, CameraOff } from "mdi-material-ui";
import React, { useEffect, useState } from "react";

import VideoPlayer from "./VideoPlayer";

export default function VideoThumbnail({ videoUrl, thumbnailUrl, loading = false, errorMessage, ...others }) {
  const [videoDialogOpen, videoDialogOpenSet] = useState(false);
  useLoadedImage(thumbnailUrl, { throwError: false });

  return (
    <>
      <Paper
        square
        {...others}
        component="a"
        href={videoUrl || thumbnailUrl || "#"}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => {
          if (event.altKey || event.ctrlKey || event.metaKey) return;
          event.preventDefault();
          if (!videoUrl && !thumbnailUrl) return;
          videoDialogOpenSet(true);
        }}
        style={{
          backgroundColor: "#e6e6e6",
          ...(!videoUrl && !thumbnailUrl && { cursor: "default" }),
          ...others.style,
        }}
      >
        {!videoUrl && !thumbnailUrl ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 5,
              padding: 5,
              backgroundColor: "transparent",
            }}
          >
            {loading && <CircularProgress />}
            {errorMessage && (
              <>
                <AlertOutline />
                {errorMessage}
              </>
            )}
            {!loading && !errorMessage && <CameraOff />}
          </div>
        ) : (
          <VideoPlayer
            poster={thumbnailUrl}
            videoUrl={videoUrl}
            onMouseEnter={(event) => {
              if (!videoUrl) return;
              event.target.play();
            }}
            onMouseLeave={(event) => {
              if (!videoUrl) return;
              event.target.pause();
              event.target.currentTime = 0;
            }}
            loop
            muted
            playsInline
            style={{ width: "100%", height: "100%", objectFit: "contain" }}
          />
        )}
      </Paper>
      <dialogContext.Provider value={{ isInDialog: true }}>
        <Dialog open={videoDialogOpen} onClose={() => videoDialogOpenSet(false)} fullWidth maxWidth="lg">
          {videoUrl && (
            <VideoPlayer
              autoPlay
              controls
              onLoadStart={(e) => {
                e.target.volume = 0.3; // set low default to avoid unpleasant user experience
              }}
              videoUrl={videoUrl}
              poster={thumbnailUrl}
            />
          )}
          {!videoUrl && thumbnailUrl && (
            <img src={thumbnailUrl} style={{ width: "100%", minHeight: "3em" }} alt={thumbnailUrl} />
          )}
        </Dialog>
      </dialogContext.Provider>
    </>
  );
}

export function VideoThumbnailLoad({ loadCallback, ...others }) {
  const [loaded, loadedSet] = useState(false);
  const [loading, loadingSet] = useState(false);
  const [errorMessage, errorMessageSet] = useState(null);
  const [url, urlSet] = useState(null);

  useEffect(() => {
    if (!loaded && !loading && !!loadCallback) {
      loadingSet(true);
      progressBar(async () => {
        try {
          let { url, blob, base64, type } = await loadCallback();
          if (base64) blob = await b64ToBlob(base64, type);
          if (blob) url = URL.createObjectURL(blob);
          if (!url) throw new Error("No url, blob, or base64 returned from loadCallback.");
          urlSet(url);
        } catch (error) {
          errorMessageSet(error.message);
        }
        loadedSet(true);
        loadingSet(false);
      });
    }
  }, [loaded, loading, !!loadCallback]);

  return <VideoThumbnail {...others} thumbnailUrl={url} loading={loading} errorMessage={errorMessage} />;
}

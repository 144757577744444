import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import DataTable from "controls/DataTable";
import useData from "hooks/useData";
import React from "react";

export default function LeaderboardContentTab({ leaderboardTableId }) {
  const [data] = useData(
    gql`
      query LeaderboardContentTab($leaderboardTableId: ID!) {
        leaderboardTable(id: $leaderboardTableId) {
          id
          leaderboardTableSegments {
            title
            columns {
              name
              shortName
            }
            headColumns
            rows {
              rank
              rankDiff
              lastRoundRank
              cells {
                stringValue
              }
              headCells
              lastRoundCells {
                stringValue
              }
            }
          }
        }
      }
    `,
    { leaderboardTableId },
  );

  return (
    <>
      {data?.leaderboardTable.leaderboardTableSegments.map((segment) => (
        <Paper key={segment.title}>
          <DataTable
            key={segment.title}
            header={segment.title}
            columns={[
              ...segment.headColumns,
              ...segment.columns.map((column) => (
                <>
                  {column.shortName} - {column.name}
                </>
              )),
            ]}
            rows={segment.rows.map((row, rowIndex) => ({
              key: rowIndex,
              cells: [...row.headCells, ...row.cells.map((cell) => cell.stringValue)],
            }))}
          />
        </Paper>
      ))}
    </>
  );
}

import { gql } from "@apollo/client";
import { IconButton, List, ListItem, ListItemButton, ListItemText } from "@mui/material";
import FormFieldContainer from "controls/FormFieldContainer";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import { Delete, Information } from "mdi-material-ui";
import React, { useId } from "react";

import SelectReadminObjectDialog from "./SelectReadminObjectDialog";
import ReadminObjectViewDetailsDialog from "./ViewDetailsDialog";

ReadminObjectInput.toFormData = (value) => {
  return (
    value && {
      id: value.id,
      typename: value.__typename,
    }
  );
};

export default function ReadminObjectInput({
  formField,
  value,
  onChange,
  disabled = false,
  // DEPRECATED: use optionsFilters instead
  options,
  optionsTypename,
  optionsFilters = {},
  ...others
}) {
  const id = useId();
  const [data] = useData(
    gql`
      query ReadminObjectInput($id: ID, $typename: String) {
        readminObject(id: $id, typename: $typename) {
          id
          readminName
        }
      }
    `,
    {
      id: value?.id,
      typename: value?.__typename,
    },
  );
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <FormFieldContainer id={id} data-readmin-object-input {...others} disabled={disabled}>
      {formDialogs}
      <List disablePadding>
        <ListItem
          disablePadding
          secondaryAction={
            <>
              {value && (
                <IconButton
                  onClick={() =>
                    formDialogsOpen(<ReadminObjectViewDetailsDialog id={value.id} typename={value.__typename} />)
                  }
                >
                  <Information />
                </IconButton>
              )}
              {value && !disabled && (
                <IconButton edge="end" onClick={() => onChange(null)}>
                  <Delete />
                </IconButton>
              )}
            </>
          }
        >
          <ListItemButton
            data-readmin-object-input-select
            disabled={disabled}
            onClick={() =>
              formDialogsOpen(
                <SelectReadminObjectDialog
                  selectedReadminObjects={value ? [value] : []}
                  options={options}
                  optionsTypename={optionsTypename}
                  optionsFilters={optionsFilters}
                  onSubmit={(valueNew) => onChange(valueNew)}
                  {...(others.label && { header: `${others.label}...` })}
                />,
              )
            }
          >
            <ListItemText primary={value ? data?.readminObject?.readminName : "(not selected)"} />
          </ListItemButton>
        </ListItem>
      </List>
    </FormFieldContainer>
  );
}

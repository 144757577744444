import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import PeopleForm, { PeopleFormFragment } from "./Form";

PeopleEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit Profile",
};

export default function PeopleEditDialog({ personIds, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query EditPersonDialog($personIds: [ID!]!) {
        people(ids: $personIds) {
          id
          ...PeopleFormFragment
        }
      }
      ${PeopleFormFragment}
    `,
    { personIds },
  );

  const peopleUpdate = useActionFragment("peopleUpdate");

  return (
    <FormDialog
      header="Update Person Profile"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await peopleUpdate({
          input: {
            personIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <PeopleForm people={data?.people} />
    </FormDialog>
  );
}

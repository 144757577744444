import { TextField } from "@mui/material";
import React, { useEffect, useId, useRef, useState } from "react";

const REGEXP_DURATION = /^([0-9]{2}):([0-9]{2}):([0-9]{2})$/;

function formatDuration(number, { unit }) {
  const totalSeconds = Math.floor(
    number *
      {
        milliseconds: 0.001,
        seconds: 1,
        minutes: 60,
        hours: 60 * 60,
      }[unit],
  );
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor(totalSeconds % 60);
  const hoursStr = hours.toString().padStart(2, "0");
  const minutesStr = minutes.toString().padStart(2, "0");
  const secondsStr = seconds.toString().padStart(2, "0");
  const str = `${hoursStr}:${minutesStr}:${secondsStr}`;

  return str;
}

function parseDuration(timeStr, { unit }) {
  if (!timeStr) return null;
  const match = timeStr.match(REGEXP_DURATION);
  if (!match) return null;
  const totalSeconds = Number(match[1]) * 60 * 60 + Number(match[2]) * 60 + Number(match[3]);
  const value = Math.floor(
    totalSeconds /
      {
        milliseconds: 0.001,
        seconds: 1,
        minutes: 60,
        hours: 60 * 60,
      }[unit],
  );

  return value;
}

DurationInput.defaultValue = null;

export default function DurationInput({
  value,
  onChange,
  readOnly = false,
  unit = "seconds",
  required,
  min,
  max,
  ...others
}) {
  if (!["milliseconds", "seconds", "minutes", "hours"].includes(unit)) throw new Error(`invalid unit: ${unit}`);

  const inputRef = useRef();
  const valueFormated = formatDuration(value, { unit });
  const id = useId();
  const [str, strSet] = useState(valueFormated);
  const strParsed = parseDuration(str, { unit });

  useEffect(() => {
    if (valueFormated !== str) {
      strSet(valueFormated);
    }
  }, [valueFormated]);
  const errorMessage = [
    required && str === "" && "required",
    strParsed === null && !!str && "invalid format, must be HH:mm:ss",
    value !== null && min !== undefined && value < min && `must be at least ${min} ${unit}`,
    value !== null && max !== undefined && value > max && `must be at most ${max} ${unit}`,
  ]
    .filter((x) => x)
    .join(", ");

  useEffect(() => {
    inputRef.current.setCustomValidity(errorMessage);
    inputRef.current.reportValidity();
  }, [errorMessage]);

  return (
    <TextField
      fullWidth
      id={id}
      value={str}
      onChange={(event) => {
        const strNew = event.currentTarget.value;
        const valueNew = parseDuration(strNew, { unit });
        strSet(strNew);
        if (valueNew !== null || strNew === "") {
          onChange?.(valueNew);
        }
      }}
      inputRef={inputRef}
      inputProps={{
        readOnly,
      }}
      error={(!!str && strParsed === null) || (others.required && strParsed === null)}
      required={required}
      {...others}
    />
  );
}

import { gql } from "@apollo/client";
import Message from "controls/Message";
import Tabs from "controls/Tabs";
import useData from "hooks/useData";
import React, { cloneElement } from "react";
import ReadminPage from "readmin_pages/ReadminPage";

import ReadminAssociationTableTab from "./ReadminAssociationTableTab";
import ReadminDetailsTab from "./ReadminDetailsTab";
import ReadminObjectDialogToolbar from "./ReadminObjectDialogToolbar";
import ReadminObjectLabelDescriptions, {
  ReadminObjectLabelDescriptionsFragment,
} from "./ReadminObjectLabelDescriptions";
import ReadminObjectLabels, { ReadminObjectLabelsFragment } from "./ReadminObjectLabels";
import ReadminObjectThumbnail from "./ReadminObjectThumbnail";

export function makeReadminObjectPage(
  typename,
  paramName,
  typeSingular,
  { title = null, tabs = [], prependTabs = [], noDetailsTab = false } = {},
) {
  function PageContent({ id, typename, readminObject, onSubmit }) {
    return (
      <>
        <ReadminObjectLabelDescriptions readminObject={readminObject} />
        <Tabs
          tabs={[
            ...prependTabs.map(([name, title, component]) => [
              name,
              title,
              cloneElement(component, { [paramName]: id, id, typename }),
            ]),
            ...[
              //
              [
                "details",
                "Details",
                <ReadminDetailsTab
                  onSubmit={async () => {
                    await onSubmit?.();
                  }}
                />,
                { hidden: noDetailsTab },
              ],
              ...tabs,
              ["notes", "System Notes", <ReadminAssociationTableTab associationName="notes" />],
            ].map(([name, title, component, options]) => [
              name,
              title,
              cloneElement(component, { [paramName]: id, id, typename }),
              options,
            ]),
          ]}
        />
      </>
    );
  }

  return function ReadminObjectPage({ [paramName]: id, clubId, apiPartnerId, ...others }) {
    const [data, dataMeta] = useData(
      gql`
        query ReadminObjectPage($typename: String!, $id: ID!, $apiPartnerId: ID, $clubId: ID) {
          readminObject(typename: $typename, id: $id) {
            id
            readminName
            ...ReadminObjectLabelsFragment
            ...ReadminObjectLabelDescriptionsFragment
          }
          apiPartner(id: $apiPartnerId) {
            id
            name
          }
          club(id: $clubId) {
            id
            name
          }
        }
        ${ReadminObjectLabelDescriptionsFragment}
        ${ReadminObjectLabelsFragment}
      `,
      {
        typename,
        id,
        apiPartnerId,
        clubId,
      },
    );

    if (paramName === "apiPartnerId") apiPartnerId = id;
    if (paramName === "clubId") clubId = id;

    return (
      <ReadminPage
        id={id}
        typename={typename}
        title={title || data?.readminObject?.readminName}
        toolbar={<ReadminObjectDialogToolbar id={id} typename={typename} gotoReadminObjectUrl />}
        subheader={
          <>
            {typeSingular}
            {data?.apiPartner && <> in {data.apiPartner.name}</>}
            {data?.club && <> in {data.club.name}</>}
          </>
        }
        labels={
          <ReadminObjectLabels
            readminObject={data?.readminObject}
            onSubmit={async () => {
              if (data) await dataMeta.refetch();
            }}
          />
        }
        thumbnail={<ReadminObjectThumbnail id={id} typename={typename} />}
        clubId={clubId}
        apiPartnerId={apiPartnerId}
        {...others}
      >
        {data && !data.readminObject ? (
          <Message
            type="error"
            title="Record Not Found"
            content={`Could not find this ${typeSingular} record. It may have been deleted.`}
          />
        ) : (
          <PageContent
            id={id}
            typename={typename}
            readminObject={data?.readminObject}
            onSubmit={async () => {
              if (data) await dataMeta.refetch();
            }}
          />
        )}
      </ReadminPage>
    );
  };
}

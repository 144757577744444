import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import LiveStreamScorersForm, { LiveStreamScorersFormFragment } from "./Form";

LiveStreamScorersEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit Live Scorer",
  };
};

export default function LiveStreamScorersEditDialog({ liveStreamScorerIds, onClose, onSubmit }) {
  const liveStreamScorersUpdate = useActionFragment("liveStreamScorersUpdate");
  const [data, dataMeta] = useData(
    gql`
      query LiveStreamScorersEditDialogQuery($liveStreamScorerIds: [ID!]!) {
        liveStreamScorers(ids: $liveStreamScorerIds) {
          id
          ...LiveStreamScorersFormFragment
        }
      }
      ${LiveStreamScorersFormFragment}
    `,
    { liveStreamScorerIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Live Scorer"
      onClose={onClose}
      onSubmit={async (formData) => {
        await liveStreamScorersUpdate({
          input: {
            liveStreamScorerIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <LiveStreamScorersForm liveStreamScorers={data?.liveStreamScorers} />
    </FormDialog>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Merge } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

PersonMergeWithOtherDialog.buttonProps = {
  icon: <Merge />,
  content: "Merge With Another Person",
};

export default function PersonMergeWithOtherDialog({ personId, onSubmit, onClose }) {
  const personMergeWithOther = useActionFragment("personMergeWithOther", "person { id }");
  const [person, personSet] = React.useState({ id: personId, __typename: "Person" });
  return (
    <FormDialog
      header="Merge With Another Person"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await personMergeWithOther({ input: formData });
        await onSubmit?.(result.personMergeWithOther.person);
      }}
    >
      <Field
        name="person"
        label="Person"
        value={person}
        onChange={personSet}
        required
        input={<ReadminObjectInput optionsTypename="Person" />}
        helperText="This person's data will not be changed, and they will have access to the other person's games, teams etc."
      />
      <Field
        name="other"
        label="Other Person"
        required
        input={
          <ReadminObjectInput
            optionsTypename="Person"
            optionsFilters={{
              possible_merge_with_person_id: person?.id,
            }}
          />
        }
        helperText="This person will be deleted, and their games, teams etc. will be transferred to the other person."
      />
    </FormDialog>
  );
}

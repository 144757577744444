import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import DurationInput from "inputs/DurationInput";
import NumberInput from "inputs/NumberInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import { Cog } from "mdi-material-ui";
import React, { useLayoutEffect, useState } from "react";

LeagueEditScorekeeperSettingsDialog.useButtonProps = () => {
  return {
    icon: <Cog />,
    content: "Edit Scorekeeper Settings",
  };
};

const EXPECTED_FIBA_VALUES = {
  periodType: {
    value: "quarters",
    description: "Period type should be quarters",
  },
  periodLength: {
    value: 10,
    description: "Period length should be 10 minutes",
  },
  overtimePeriodLength: {
    value: 5,
    description: "Overtime period length should be 5 minutes",
  },
  maxPlayerFouls: {
    value: 5,
    description: "Max player fouls should be 5",
  },
  foulsPerPeriod: {
    value: 4,
    description: "Fouls per period should be 4",
  },
  numberOfStartingPlayers: {
    value: 5,
    description: "Number of starting players should be 5",
  },
};

export default function LeagueEditScorekeeperSettingsDialog({ leagueId, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query LeagueEditScorekeeperSettingsDialogQuery($leagueId: ID!) {
        league(id: $leagueId) {
          id
          sport
          periodType
          periodLength
          overtimePeriodLength
          foulsPerPeriod
          scorekeeperGameClockVisible
          scorekeeperGameClockStartMs

          scorekeeperScoringMode
          scorekeeperAllowNewTeamMembers
          scorekeeperAllowThreePointShots
          scorekeeperAllowMisses

          basketballLeagueSettings {
            id
            numberOfStartingPlayers
            tapInPlayersByDefault

            maxPlayerFouls

            fibaScoring
            fibaScoresheetLayoutHideProtestSection
            fibaScoresheetRecipientEmails
          }
        }
      }
    `,
    { leagueId },
  );
  const leagueUpdateScorekeeperSettings = useActionFragment("leagueUpdateScorekeeperSettings");
  const [scorekeeperGameClockVisible, scorekeeperGameClockVisibleSet] = useState(false);
  const [fibaScoring, fibaScoringSet] = useState(false);
  const [fibaValues, fibaValuesSet] = useState({});

  useLayoutEffect(() => {
    scorekeeperGameClockVisibleSet(data?.league.scorekeeperGameClockVisible || false);
    fibaScoringSet(data?.league.basketballLeagueSettings?.fibaScoring || false);
    fibaValuesSet({
      periodType: data?.league.periodType,
      periodLength: data?.league.periodLength,
      overtimePeriodLength: data?.league.overtimePeriodLength,
      maxPlayerFouls: data?.league.basketballLeagueSettings?.maxPlayerFouls,
      foulsPerPeriod: data?.league.foulsPerPeriod,
      numberOfStartingPlayers: data?.league.basketballLeagueSettings?.numberOfStartingPlayers,
    });
  }, [dataMeta.loading]);

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Scorekeeper Settings"
      onClose={onClose}
      onSubmit={async (formData) => {
        await leagueUpdateScorekeeperSettings({
          input: {
            leagueId,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <Field
        name="periodType"
        label="Period Type"
        defaultValue={data?.league?.periodType}
        onChange={(periodType) => fibaValuesSet((v) => ({ ...v, periodType }))}
        required
        input={
          <SelectInput
            options={[
              ["Single", "single"],
              ["Halves", "halves"],
              ["Thirds", "thirds"],
              ["Quarters", "quarters"],
            ]}
          />
        }
      />
      <Field
        name="periodLength"
        label="Period Length"
        defaultValue={data?.league?.periodLength}
        onChange={(periodLength) => fibaValuesSet((v) => ({ ...v, periodLength }))}
        required
        input={<NumberInput unit="minutes" />}
      />
      <Field
        name="overtimePeriodLength"
        label="Overtime Period Length"
        defaultValue={data?.league?.overtimePeriodLength}
        onChange={(overtimePeriodLength) => fibaValuesSet((v) => ({ ...v, overtimePeriodLength }))}
        required
        input={<NumberInput unit="minutes" />}
      />
      <Field
        name="scorekeeperGameClockVisible"
        label="Game Clock Visible"
        value={scorekeeperGameClockVisible}
        onChange={scorekeeperGameClockVisibleSet}
        input={<SwitchInput />}
      />
      {scorekeeperGameClockVisible && (
        <Field
          name="scorekeeperGameClockStartMs"
          label="Game Clock Start"
          defaultValue={data?.league?.scorekeeperGameClockStartMs}
          required
          input={<DurationInput unit="milliseconds" />}
        />
      )}
      <FormSection header="Participant Settings">
        <Field
          name="scorekeeperScoringMode"
          label="Scoring Mode"
          disabled={data?.league?.sport === "Miniball"}
          defaultValue={data?.league?.scorekeeperScoringMode}
          required
          input={
            <SelectInput
              options={[
                [
                  "No game players",
                  "team_no_game_people",
                  {
                    description: "Score without setting up game participants.",
                  },
                ],
                [
                  "Allow game players, scoring team",
                  "team_game_people_scoring_team",
                  {
                    description: "Allow setting up game participants if desired, but do not use for scoring.",
                  },
                ],
                [
                  "Scoring player positional",
                  "team_game_people_scoring_game_people_positional",
                  {
                    description:
                      "Require setting up game participants. Use the participants when scoring. Score by tapping on court to store shot location.",
                  },
                ],
                [
                  "Scoring player simple",
                  "team_game_people_scoring_game_people_simple",
                  {
                    description:
                      "Require setting up game participants. Use the participants when scoring. Score with buttons instead of tapping on court to store shot location.",
                  },
                ],
              ]}
            />
          }
        />
        {data?.league.basketballLeagueSettings && (
          <Field
            name="numberOfStartingPlayers"
            label="Number of starting players"
            defaultValue={data?.league?.basketballLeagueSettings?.numberOfStartingPlayers}
            onChange={(numberOfStartingPlayers) => fibaValuesSet((v) => ({ ...v, numberOfStartingPlayers }))}
            input={<NumberInput />}
          />
        )}
        <Field
          name="scorekeeperAllowNewTeamMembers"
          label="Allow new team members to be added on Scorekeeper"
          defaultValue={data?.league?.scorekeeperAllowNewTeamMembers}
          input={<SwitchInput />}
        />
        {data?.league.basketballLeagueSettings && !fibaScoring && (
          <Field
            name="tapInPlayersByDefault"
            label="Tap in players by default"
            defaultValue={data?.league?.basketballLeagueSettings?.tapInPlayersByDefault}
            input={<SwitchInput />}
            helperText="If ticked, players will default to being tapped in during game setup on the iPad."
          />
        )}
      </FormSection>
      <FormSection header="Scoring Settings">
        <Field
          name="scorekeeperAllowThreePointShots"
          label="Allow 3pt shots to be scored"
          defaultValue={data?.league?.scorekeeperAllowThreePointShots}
          input={<SwitchInput />}
        />
        <Field
          name="scorekeeperAllowMisses"
          label="Allow misses to be scored"
          defaultValue={data?.league?.scorekeeperAllowMisses}
          input={<SwitchInput />}
        />
        <Field
          name="foulsPerPeriod"
          label="Team fouls per period before bonus"
          defaultValue={data?.league?.foulsPerPeriod}
          onChange={(foulsPerPeriod) => fibaValuesSet((v) => ({ ...v, foulsPerPeriod }))}
          required
          input={<NumberInput />}
        />
        {data?.league?.basketballLeagueSettings && (
          <Field
            name="maxPlayerFouls"
            label="Max player fouls in game before they must leave the game"
            defaultValue={data?.league?.basketballLeagueSettings?.maxPlayerFouls}
            onChange={(maxPlayerFouls) => fibaValuesSet((v) => ({ ...v, maxPlayerFouls }))}
            required
            input={<NumberInput />}
            helperText="Max number of player fouls allowed. Players have a strike through on their number in the scoring app once they reach the maximum."
          />
        )}
      </FormSection>
      {data?.league?.basketballLeagueSettings && (
        <FormSection header="FIBA Scoring Mode" defaultExpanded={data?.league?.basketballLeagueSettings.fibaScoring}>
          {fibaScoring &&
            Object.entries(EXPECTED_FIBA_VALUES).map(
              ([key, { value, description }]) =>
                fibaValues[key] !== value && (
                  <Message type="warning" key={key} content={`${description} for FIBA games`} />
                ),
            )}

          <Field
            name="fibaScoring"
            label="Use FIBA Scoring mode"
            value={fibaScoring}
            onChange={fibaScoringSet}
            input={<SwitchInput />}
          />
          <Field
            disabled={!fibaScoring}
            name="fibaScoresheetLayoutHideProtestSection"
            label="FIBA scoresheet layout: hide bottom left protest section"
            defaultValue={data?.league?.basketballLeagueSettings?.fibaScoresheetLayoutHideProtestSection}
            input={<SwitchInput />}
          />
          <Field
            disabled={!fibaScoring}
            name="fibaScoresheetRecipientEmails"
            label="FIBA Scoresheet PDF Recipient Emails"
            defaultValue={data?.league?.basketballLeagueSettings?.fibaScoresheetRecipientEmails}
            input={<TextInput />}
            helperText="Separate with commas to include multiple email addresses"
          />
        </FormSection>
      )}
    </FormDialog>
  );
}

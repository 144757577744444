import apolloClient from "helpers/apolloClient";
import { useActionFragment } from "hooks/useAction";
import useGotoUrl from "hooks/useGotoUrl";
import { progressBar } from "hooks/useProgressBar";
import React, { useEffect } from "react";

import PublicForm from "./PublicForm";

export default function LogoutPage() {
  const logout = useActionFragment("logout");
  const gotoUrl = useGotoUrl();

  useEffect(() => {
    Promise.resolve().then(async () => {
      await logout({ input: {} });
      await progressBar(() => apolloClient.clearStore());
      gotoUrl("/", { replace: true });
    });
  }, []);

  return <PublicForm title="Logging out" dense loading />;
}

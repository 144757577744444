import { CircularProgress, Divider, Paper } from "@mui/material";
import imageLogo from "images/logo.svg";
import React from "react";
import FooterText from "shared/FooterText";

function Loading() {
  return (
    <div style={{ padding: 20, display: "flex", justifyContent: "center", alignItems: "center" }}>
      <CircularProgress />
    </div>
  );
}

export default function PublicForm({
  loading = false,
  children,
  title,
  dense = false,
  maxWidth = dense ? 460 : 800,
  paperProps = {},
}) {
  return (
    <div
      style={{
        width: "100vw",
        background: "linear-gradient(0deg, #273582, #101A50)",
      }}
    >
      <div
        style={{
          maxWidth,
          minHeight: "100vh",
          margin: "0 auto",
          padding: 20,
          gap: 10,
          display: "flex",
          flexFlow: "column nowrap",
          justifyContent: "center",
          alignItems: "stretch",
        }}
      >
        {!dense && (
          <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: 10, color: "#fefefe" }}>
            <a href="/">
              <img src={imageLogo} style={{ height: 30 }} />
            </a>
            <h1 style={{ fontSize: "1.3em", margin: 0, padding: 0 }}>{title}</h1>
          </div>
        )}
        <Paper
          {...paperProps}
          style={{ padding: 20, display: "flex", flexFlow: "column nowrap", gap: 10, ...paperProps.style }}
        >
          {dense && (
            <>
              <div style={{ textAlign: "center" }}>
                <a href="/">
                  <img src={imageLogo} style={{ height: 80 }} />
                </a>
              </div>
              <h1 style={{ margin: 0, padding: 0, fontSize: "1.4em", textAlign: "center" }}>{title}</h1>
              <Divider />
            </>
          )}
          <>{loading ? <Loading /> : children}</>
        </Paper>
        <footer
          style={{
            fontSize: "0.8em",
            color: "#ccc",
          }}
        >
          <FooterText />
        </footer>
      </div>
    </div>
  );
}

export function PublicFormHeader({ header, subheader }) {
  return (
    <>
      {header && <h2 style={{ margin: 0, padding: 0, fontSize: "1.2em" }}>{header}</h2>}
      {subheader && <div style={{ margin: 0, padding: 0, color: "#666" }}>{subheader}</div>}
    </>
  );
}

import { gql } from "@apollo/client";
import useData from "hooks/useData";
import { Refresh } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const ClubsRefetchFromGameDayDialog = makeConfirmActionDialog({
  mutationName: "clubsRefetchFromGameday",
  paramName: "clubIds",
  action: "Refetch from GameDay",
  successMessage: "Refetch has been issued. Please check back in about 60 seconds.",
  useButtonProps: ({ clubIds }) => {
    const [data] = useData(
      gql`
        query ClubsRefetchFromGameDayDialog($clubIds: [ID!]!) {
          clubs(ids: $clubIds) {
            id
            hasGdVenues
          }
        }
      `,
      { clubIds },
    );

    return {
      hidden: !data?.clubs.every((club) => club.hasGdVenues),
      icon: <Refresh />,
      content: "Refetch from GameDay",
    };
  },
});

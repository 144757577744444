import { CssBaseline, ThemeProvider } from "@mui/material";
import useDOMEvent from "hooks/useDOMEvent";
import React, { useEffect, useState } from "react";
import { BrowserRouter, matchPath, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";

import { initApolloClient } from "./apolloClient";
import { routerContext, timeZoneContext } from "./contexts";
import muiTheme from "./muiTheme";

export default function ReactPage({ pages }) {
  const paths = Object.keys(pages);
  const [timeZone, timeZoneSet] = useState(null);
  const [inited, initedSet] = useState(false);

  useEffect(() => {
    Promise.resolve().then(async () => {
      await initApolloClient();
      initedSet(true);
    });
  }, []);

  if (!inited) return null;

  const routes = (
    <Routes>
      {Object.entries(pages).map(([path, component]) => (
        <Route key={path} path={path} element={<RouteElement component={component} paths={paths} />} />
      ))}
      <Route path="/*" element={<RouteNotFound />} />
    </Routes>
  );

  return (
    <timeZoneContext.Provider value={{ timeZone, timeZoneSet }}>
      <CssBaseline />
      <ThemeProvider theme={muiTheme}>
        <BrowserRouter>{routes}</BrowserRouter>
      </ThemeProvider>
    </timeZoneContext.Provider>
  );
}

function RouteNotFound() {
  const location = useLocation();
  throw new Error(`ReactPage route not found for ${location.pathname}`);
}

function RouteElement({ component: Component, paths }) {
  const navigate = useNavigate();

  useDOMEvent(
    "click",
    (event) => {
      const a = event.target.closest("a[href]");
      if (!a) return;
      let href = a.getAttribute("href");
      const target = a.getAttribute("target");

      if (target && target !== "_self") return;
      if (href === "#") return event.preventDefault();
      const url = new URL(href, window.location.href);
      if (url.origin === window.location.origin) href = url.pathname + url.search;
      if (!href.startsWith("/")) return;
      if (event.ctrlKey || event.shiftKey || event.metaKey || event.altKey) return;

      if (paths.find((path) => matchPath(path, href))) {
        event.preventDefault();
        return navigate(href);
      }
    },
    [],
    () => document,
    { capture: true },
  );
  const params = useParams();

  return (
    <routerContext.Provider value={{ navigate, paths }}>
      <Component {...params} />
    </routerContext.Provider>
  );
}

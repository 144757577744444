import React from "react";

import SelectFilter from "./SelectFilter";

export default function PageSizeFilter({ ...others }) {
  return (
    <SelectFilter
      options={[10, 20, 50, 100].map((size) => [`${size} rows`, size])}
      required
      search={false}
      label="Page Size"
      {...others}
    />
  );
}

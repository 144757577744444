import { gql } from "@apollo/client";
import Field from "controls/Field";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";

export const UserFormFragment = gql`
  fragment UserFormFragment on User {
    id
    email
    admin
    unsubscribedCompetitions
    unsubscribedMarketing
    unsubscribedNotifications
  }
`;

export default function UserForm({ user = {} }) {
  return (
    <>
      <Field
        name="email"
        label="Email"
        defaultValue={user.email}
        disabled={!!user.id}
        input={<TextInput type="email" autoComplete="email" />}
      />
      <Field
        name="admin"
        label="Is full admin"
        defaultValue={user.admin}
        input={<SwitchInput />}
        helperText="Caution: only for Glory League employees."
      />
      <Field
        name="unsubscribedNotifications"
        label="Unsubscribe from game notification emails"
        defaultValue={user.unsubscribedNotifications}
        input={<SwitchInput />}
      />
      <Field
        name="unsubscribedCompetitions"
        label="Unsubscribe from competition emails"
        input={<SwitchInput />}
        defaultValue={user.unsubscribedCompetitions}
      />
      <Field
        name="unsubscribedMarketing"
        label="Unsubscribe from marketing emails"
        input={<SwitchInput />}
        defaultValue={user.unsubscribedMarketing}
      />
    </>
  );
}

import { Paper } from "@mui/material";
import { GraphiQL } from "graphiql";
import useProgressBar from "hooks/useProgressBar";
import React, { useMemo } from "react";

import ReadminPage from "./ReadminPage";

export default function ApiPartnerGraphiqlPage({ apiPartnerId }) {
  const progressBar = useProgressBar();
  const fetcher = useMemo(
    () => (params) =>
      progressBar(async () => {
        const csrfToken = document.querySelector("meta[name=csrf-token]")?.getAttribute("content");
        const response = await fetch("/api/v1/graphql", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
            "X-GLAPI-Partner-ID": apiPartnerId,
            "X-CSRF-Token": csrfToken,
          },
          body: JSON.stringify(params),
        });
        if (!response.ok) throw new Error(`HTTP Error ${response.status}: ${await response.text()}`);
        const data = await response.json();
        return data;
      }),
  );

  return (
    <ReadminPage apiPartnerId={apiPartnerId} title="GraphiQL" subheader={<>Debug GraphiQL queries</>}>
      <Paper
        style={{
          height: "100vh",
        }}
      >
        <GraphiQL fetcher={fetcher} isHeadersEditorEnabled={false} />
      </Paper>
    </ReadminPage>
  );
}

import { Button } from "@mui/material";
import { formContext } from "helpers/contexts";
import getFormData from "helpers/getFormData";
import noop from "helpers/noop";
import useShowMessage from "hooks/useShowMessage";
import React, { useContext, useState } from "react";

export default function Form({ onSubmit = noop, disabled = false, loading = false, children, ...props }) {
  const showMessage = useShowMessage();
  const [submitting, submittingSet] = useState(false);

  return (
    <formContext.Provider value={{ submitting, disabled, loading }}>
      <form
        onSubmit={async (event) => {
          event.preventDefault();
          let formData;
          try {
            formData = getFormData(event.currentTarget);
          } catch (error) {
            showMessage({ header: "Cannot submit", content: error.message, modal: true });
            return;
          }
          submittingSet(true);
          try {
            if (onSubmit) await onSubmit(formData);
          } finally {
            submittingSet(false);
          }
        }}
        {...props}
      >
        {children}
      </form>
    </formContext.Provider>
  );
}

export function FormSubmitButton({ icon, href, onClick, children, content = children || "Submit", ...others }) {
  const { submitting, disabled, loading } = useContext(formContext);

  if (loading) content = "loading...";
  if (submitting) content = `${content}...`;

  return (
    <Button
      size="large"
      type="submit"
      variant="contained"
      color="primary"
      fullWidth
      {...others}
      style={{ ...others.style }}
      disabled={others.disabled || disabled || loading || submitting}
      {...(href && {
        href,
        component: "a",
        variant: "outlined",
      })}
      {...(onClick && {
        type: "button",
        onClick,
        variant: "outlined",
      })}
      startIcon={icon}
    >
      {content}
    </Button>
  );
}

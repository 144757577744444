import { gql } from "@apollo/client";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Eye, EyeOff, TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const VideoVersionsDeleteFootageDialog = makeConfirmActionDialog({
  mutationName: "videoVersionsDeleteFootage",
  paramName: "videoVersionIds",
  action: "Delete Footage",
  useButtonProps: () => {
    const isAdmin = useIsAdmin();
    return {
      icon: <TrashCan />,
      content: "Delete Footage",
      hidden: !isAdmin,
    };
  },
});

export const VideoVersionsDisableDialog = makeConfirmActionDialog({
  mutationName: "videoVersionsDisable",
  fragment: "videoVersions { id disabled }",
  paramName: "videoVersionIds",
  action: "Disable Video Version",
  useButtonProps: ({ videoVersionIds }) => {
    const [data] = useData(
      gql`
        query VideoVersionsDisableDialog($videoVersionIds: [ID!]!) {
          videoVersions(ids: $videoVersionIds) {
            id
            disabled
          }
        }
      `,
      { videoVersionIds },
    );

    return {
      icon: <EyeOff />,
      hidden: !data || !data.videoVersions.find((v) => !v.disabled),
      content: "Disable Video Version",
    };
  },
});

export const VideoVersionsEnableDialog = makeConfirmActionDialog({
  mutationName: "videoVersionsEnable",
  fragment: "videoVersions { id disabled }",
  paramName: "videoVersionIds",
  action: "Enable Video Version",
  useButtonProps: ({ videoVersionIds }) => {
    const [data] = useData(
      gql`
        query VideoVersionsEnableDialog($videoVersionIds: [ID!]!) {
          videoVersions(ids: $videoVersionIds) {
            id
            disabled
          }
        }
      `,
      { videoVersionIds },
    );

    return {
      icon: <Eye />,
      hidden: !data || !data.videoVersions.find((v) => v.disabled),
      content: "Enable Video Version",
    };
  },
});

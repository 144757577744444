import { gql } from "@apollo/client";
import Markdown from "controls/Markdown";
import React from "react";

import ReadminAttributeValue, { ReadminAttributeValueFragment } from "./ReadminAttributeValue";

export const ReadminLabelDetailsFragement = gql`
  fragment ReadminLabelDetailsFragement on ReadminLabel {
    description
    attributesEntries {
      label
      description
      ...ReadminAttributeValueFragment
    }
  }
  ${ReadminAttributeValueFragment}
`;

export default function ReadminLabelDetails({ label }) {
  return (
    <>
      <Markdown source={label.description} />
      {!!label.attributesEntries.length && (
        <ul>
          {label.attributesEntries
            .filter((a) => !(a.type === "readmin_objects" && !a.readminObjects.length))
            .map((attribute) => (
              <li key={attribute.label}>
                <strong>{attribute.label}</strong>: <ReadminAttributeValue {...attribute} />
                {attribute.description && <Markdown source={attribute.description} />}
              </li>
            ))}
        </ul>
      )}
    </>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddCourtApiMappingDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    icon: <Plus />,
    content: "Add API Mapping",
    hidden: !isAdmin,
  };
};

export default function AddCourtApiMappingDialog({ courtId, onSubmit = noop, onClose = noop }) {
  const addCourtApiMapping = useActionFragment("addCourtApiMapping");
  const [apiOrganisation, apiOrganisationSet] = useState(null);

  return (
    <FormDialog
      header="Add Court API Mapping"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addCourtApiMapping({ input: formData });
        await onSubmit();
      }}
    >
      <Field
        label="Court"
        name="court"
        input={<ReadminObjectInput optionsTypename="Court" />}
        defaultValue={{ id: courtId, __typename: "Court" }}
      />
      <Field
        label="API Organisation"
        input={<ReadminObjectInput optionsTypename="ApiOrganisation" />}
        name="apiOrganisation"
        onChange={apiOrganisationSet}
      />
      <Field
        label="API Court"
        name="apiCourt"
        disabled={!apiOrganisation}
        input={
          <ReadminObjectInput
            optionsTypename="ApiCourt"
            optionsFilters={{
              possible_api_courts_for_api_organisation_id: apiOrganisation?.id,
            }}
          />
        }
      />
    </FormDialog>
  );
}

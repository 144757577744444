import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import UploaderInput from "inputs/UploaderInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const OnsenPopupPromosFormFragment = gql`
  fragment OnsenPopupPromosFormFragment on OnsenPopupPromo {
    club {
      id
    }
    assetUploader
  }
`;

export default function OnsenPopupPromosForm({ onsenPopupPromos = [{}] }) {
  onsenPopupPromos = onsenPopupPromos.map((onsenPopupPromo) => ({
    ...onsenPopupPromo,
  }));

  return (
    <>
      <MultiValuesField
        name="club"
        label="Club"
        defaultValues={onsenPopupPromos.map((c) => c.club)}
        required
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <MultiValuesField
        name="assetUploader"
        label="Asset"
        defaultValues={onsenPopupPromos.map((c) => c.assetUploader)}
        required
        input={<UploaderInput accept="image/*" />}
        helperText="Rendered on onsen as 450x600"
      />
    </>
  );
}

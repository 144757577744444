import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const RecordingBlockRemoveDialog = makeConfirmActionDialog({
  mutationName: "recordingBlockRemove",
  paramName: "recordingBlockId",
  action: "Remove Recording Block",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

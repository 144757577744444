import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import TextInput from "inputs/TextInput";
import { EmailFastOutline } from "mdi-material-ui";
import React from "react";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

AdAssetsPreviewEmailDialog.useButtonProps = ({ adAssetIds }) => {
  const [data] = useData(
    gql`
      query AdAssetsPreviewEmailDialogButtonQuery($adAssetIds: [ID!]!) {
        adAssets(ids: $adAssetIds) {
          id
          canPreviewEmail
        }
      }
    `,
    { adAssetIds },
  );

  return {
    hidden: !data?.adAssets.every((adAsset) => adAsset.canPreviewEmail),
    icon: <EmailFastOutline />,
    content: "Preview Email",
  };
};

export default function AdAssetsPreviewEmailDialog({ adAssetIds, onClose, onSubmit }) {
  const adAssetsPreviewEmail = useActionFragment("adAssetsPreviewEmail");
  const [data] = useData(gql`
    query AdAssetsPreviewEmailDialogQuery {
      currentUser {
        id
        email
      }
    }
  `);

  return (
    <FormDialog
      header="Preview Email"
      onClose={onClose}
      loading={!data}
      onSubmit={async (formData) => {
        await adAssetsPreviewEmail({
          input: {
            adAssetIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <Field
        label="adAssets"
        value={adAssetIds.map((id) => ({ id, __typename: "AdAsset" }))}
        disabled
        input={<ReadminObjectsInput />}
      />
      <Field
        name="previewEmail"
        label="Email"
        helperText="The email address to send the preview to"
        defaultValue={data?.currentUser.email}
        required
        input={<TextInput autoFocus type="email" autoComplete="email" />}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import NumberInput from "inputs/NumberInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const GdVenueFormFragment = gql`
  fragment GdVenueFormFragment on GdVenue {
    id
    enabled
    name
    gdVenueId
    retainMissingGames
    submitResultToCourtside
    gdAssociation {
      id
    }
  }
`;

export default function GdVenueForm({
  gdAssociationId = null,
  gdVenue = {
    gdAssociation: gdAssociationId && {
      id: gdAssociationId,
      __typename: "GdAssociation",
    },
    enabled: true,
  },
}) {
  return (
    <>
      <Field
        name="gdAssociation"
        disabled={!!gdVenue.id}
        label="GameDay Association"
        required
        defaultValue={gdVenue.gdAssociation}
        input={<ReadminObjectInput optionsTypename="GdAssociation" />}
      />
      <Field
        name="gdVenueId"
        label="GameDay Venue ID"
        disabled={!!gdVenue.id}
        input={<NumberInput />}
        helperText="From GameDay admin portal, under Venue details"
        required
        defaultValue={gdVenue.gdVenueId}
      />
      <Field
        name="name"
        label="Name"
        defaultValue={gdVenue.name}
        required
        input={<TextInput />}
        helperText="Name of the venue in GameDay. The value will be corrected when importing Courtside data from GameDay"
      />
      <Field name="enabled" label="Enabled" defaultValue={gdVenue.enabled} input={<SwitchInput />} />
      <Field
        name="retainMissingGames"
        label="Retain Missing Games"
        defaultValue={gdVenue.retainMissingGames}
        helperText="If ticked, cancelled / forfeit game schedules will not be removed from Glory League. Used by Basketball New Zealand when they need to temorarily switch Courtside scoring mode to FIBA LiveStat"
        input={<SwitchInput />}
      />
      <Field
        name="submitResultToCourtside"
        label="Submit Result to Courtside"
        defaultValue={gdVenue.submitResultToCourtside}
        helperText="If ticked, the result of the game will be submitted to Courtside API"
        input={<SwitchInput />}
      />
    </>
  );
}

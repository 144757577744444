import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import ReadminLink, { ReadminLinkFragment } from "controls/ReadminLink";
import { readminPageContext, timeZoneContext } from "helpers/contexts";
import useData from "hooks/useData";
import useFormDialogs from "hooks/useFormDialogs";
import useTimeZone from "hooks/useTimeZone";
import SelectInput from "inputs/SelectInput";
import React, { Fragment, useContext } from "react";
import FooterText from "shared/FooterText";

export default function ReadminFootor() {
  const timeZone = useTimeZone();
  const { clubId, apiPartnerId } = useContext(readminPageContext);
  const [data] = useData(
    gql`
      query ReadminFooter($clubId: ID, $apiPartnerId: ID) {
        club(id: $clubId) {
          id
          ...ReadminLinkFragment
        }
        apiPartner(id: $apiPartnerId) {
          id
          ...ReadminLinkFragment
        }
      }
      ${ReadminLinkFragment}
    `,
    { clubId, apiPartnerId },
  );
  const object = data?.club || data?.apiPartner;
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <footer style={{ fontSize: ".8em", opacity: 0.8 }}>
      {formDialogs}
      {object ? (
        <>
          readmin / <ReadminLink to={object} />
        </>
      ) : (
        "readmin"
      )}{" "}
      / time zone:{" "}
      <a href="#" onClick={() => formDialogsOpen(<ChangeTimeZoneDialog />)}>
        {timeZone}
      </a>
      <br />
      <FooterText />
    </footer>
  );
}

export function ChangeTimeZoneDialog({ onClose, onSubmit }) {
  const [data] = useData(gql`
    query ChangeTimeZoneDialog {
      timeZones
    }
  `);
  const { timeZone, defaultTimeZone, timeZoneSet } = useContext(timeZoneContext);

  return (
    <FormDialog
      header="Change display time zone"
      onSubmit={async ({ timeZone }) => {
        timeZoneSet(timeZone === defaultTimeZone ? null : timeZone);
        await onSubmit?.();
      }}
      loading={!data}
      onClose={onClose}
    >
      <Message
        content={
          <>
            This will only change the time zone for your browser for current session. It will not affect other users or
            the data stored in the database.
          </>
        }
      />
      <Field
        required
        name="timeZone"
        label="Current Time zone"
        defaultValue={timeZone || defaultTimeZone}
        input={
          <SelectInput
            options={data?.timeZones.map((timeZone) => [
              timeZone === defaultTimeZone ? `${timeZone} (default)` : timeZone,
              timeZone,
            ])}
          />
        }
        helperText={
          <>
            Quick select:
            <ul>
              {["Pacific/Auckland", "Australia/Sydney", "Australia/Perth", "Australia/Adelaide", "Asia/Manila"].map(
                (timeZone) => (
                  <li key={timeZone}>
                    <a
                      href="#"
                      onClick={async () => {
                        timeZoneSet(timeZone);
                        await onSubmit?.();
                        onClose();
                      }}
                    >
                      {timeZone}
                    </a>
                  </li>
                ),
              )}
            </ul>
          </>
        }
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import LoadingFormDialog from "controls/LoadingFormDialog";
import b64ToBlob from "helpers/b64ToBlob";
import downloadBlob from "helpers/downloadBlob";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Download } from "mdi-material-ui";
import React from "react";

GameDownloadScoresheetDialog.useButtonProps = ({ gameId }) => {
  const [data] = useData(
    gql`
      query game($gameId: ID!) {
        game(id: $gameId) {
          id
          gameScoresheet {
            id
          }
        }
      }
    `,
    { gameId },
  );

  return {
    icon: <Download />,
    content: "Download Scoresheet",
    hidden: !data?.game.gameScoresheet,
  };
};

export default function GameDownloadScoresheetDialog({ gameId, onSubmit = noop, ...others }) {
  const gameDownloadScoresheet = useActionFragment("gameDownloadScoresheet", "scoresheetPdfBase64");

  return (
    <LoadingFormDialog
      onSubmit={async () => {
        const result = await gameDownloadScoresheet({ input: { gameId } });
        const blob = b64ToBlob(result.gameDownloadScoresheet.scoresheetPdfBase64, "application/pdf");
        await downloadBlob(blob, "scoresheet.pdf");
        await onSubmit();
      }}
      {...others}
    />
  );
}

import useIsAdmin from "hooks/useIsAdmin";
import { Delete } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const CourtApiMappingRemoveDialog = makeConfirmActionDialog({
  action: "Remove API Mapping",
  mutationName: "courtApiMappingRemove",
  paramName: "courtApiMappingId",
  useButtonProps: () => {
    const isAdmin = useIsAdmin();
    return {
      icon: <Delete />,
      content: "Remove",
      hidden: !isAdmin,
    };
  },
});

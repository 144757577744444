import { useQuery } from "@apollo/client";
import apolloClient from "helpers/apolloClient";
import getApolloErrors from "helpers/getApolloErrors";
import { useEffect } from "react";

import useProgressBar from "./useProgressBar";
import useShowMessage from "./useShowMessage";

export default function useData(query, variables, { ...options } = {}) {
  const showMessage = useShowMessage();
  const useQueryOptions = {
    variables,
    client: apolloClient,
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
    partialRefetch: true,
    ...options,
  };

  const { data, ...others } = useQuery(query, useQueryOptions);

  const { error, loading } = others;
  useProgressBar(loading);

  useEffect(() => {
    if (error) {
      const errors = getApolloErrors(error);
      for (const error of errors) {
        showMessage(error.message, { color: "red" });
        // eslint-disable-next-line no-console
        console.error(error);
        // eslint-disable-next-line no-console
        if (error.metadata) console.error(error.metadata);
      }
    }
  }, [error]);

  return [data, others];
}

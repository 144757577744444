import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import GdVenueForm, { GdVenueFormFragment } from "./Form";

GdVenueEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function GdVenueEditDialog({ gdVenueId, onSubmit = noop, ...others }) {
  const addGdVenue = useActionFragment("addGdVenue");

  const [data, dataMeta] = useData(
    gql`
      query GdVenueEditDialog($gdVenueId: ID!) {
        gdVenue(id: $gdVenueId) {
          id
          gdVenueId
          ...GdVenueFormFragment
        }
      }
      ${GdVenueFormFragment}
    `,
    { gdVenueId },
  );

  return (
    <FormDialog
      {...others}
      loading={dataMeta.loading}
      header="Edit GameDay Venue"
      onSubmit={async (formData) => {
        await addGdVenue({
          input: {
            gdVenueId: data.gdVenue.gdVenueId,
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      <GdVenueForm gdVenue={data?.gdVenue} />
    </FormDialog>
  );
}

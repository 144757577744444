import { assign } from "lodash-es";

export default function selectFiles({ multiple = false, accept, folder = false }, callback) {
  if (window.selectFilesMock) return callback(window.selectFilesMock);

  const input = document.createElement("input");
  input.setAttribute("type", "file");
  if (accept) input.setAttribute("accept", accept);
  if (multiple) input.setAttribute("multiple", "true");
  if (folder) {
    input.setAttribute("webkitdirectory", true);
    input.setAttribute("allowdirs", true);
  }

  assign(input.style, {
    position: "fixed",
    bottom: "10px",
    right: "10px",
    opacity: "0.1;",
    visibility: "hidden",
  });

  document.body.appendChild(input);

  input.addEventListener("change", () => callback([...Array.from(input.files)]));
  input.click();
}

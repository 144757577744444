import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import ClubAdminsForm, { ClubAdminsFormFragment } from "./Form";

ClubAdminsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function ClubAdminsEditDialog({ clubAdminIds, onClose, onSubmit }) {
  const clubAdminsUpdate = useActionFragment("clubAdminsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query ClubAdminsEditDialogQuery($clubAdminIds: [ID!]!) {
        clubAdmins(ids: $clubAdminIds) {
          id
          ...ClubAdminsFormFragment
        }
      }
      ${ClubAdminsFormFragment}
    `,
    {
      clubAdminIds,
    },
  );

  return (
    <FormDialog
      header="Edit Club Admin"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await clubAdminsUpdate({
          input: {
            clubAdminIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <ClubAdminsForm clubAdmins={data?.clubAdmins} />
    </FormDialog>
  );
}

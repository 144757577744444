import { MenuItem, TextField } from "@mui/material";
import React, { useId } from "react";

export default function SelectFilter({ options = [], blankString = "(all)", required, label, value, onChange }) {
  const id = useId();
  options = options.map((option) => {
    if (option?.constructor === Object) return option;
    if (option?.constructor === Array) {
      const [text, value] = option;
      return {
        text,
        value,
      };
    }
    return {
      text: option,
      value: option,
    };
  });

  return (
    <TextField
      data-select-filter
      variant="outlined"
      size="small"
      id={id}
      select
      label={label}
      value={value === null ? "" : JSON.stringify(value)}
      onChange={(event) => {
        onChange(event.target.value === "" ? null : JSON.parse(event.target.value));
      }}
      style={{ minWidth: 150 }}
      required={required}
    >
      {!required && <MenuItem value="">{blankString}</MenuItem>}
      {options.map((option) => (
        <MenuItem key={option.value} value={JSON.stringify(option.value)} data-select-filter-option>
          {option.text}
        </MenuItem>
      ))}
    </TextField>
  );
}

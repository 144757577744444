import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const PotwEntriesRemoveDialog = makeConfirmActionDialog({
  mutationName: "potwEntriesRemove",
  paramName: "potwEntryIds",
  action: "Remove POTW Entries",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove",
    };
  },
});

import React, { useEffect, useRef } from "react";

export default function ImageCanvas({ image, ...others }) {
  const ref = useRef();
  useEffect(() => {
    const ctx = ref.current.getContext("2d");
    ctx.clearRect(0, 0, ref.current.width, ref.current.height);
    if (image) {
      ctx.drawImage(image, 0, 0);
    }
  }, [image]);

  return (
    <canvas
      ref={ref}
      width={image?.width || image?.videoWidth || 100}
      height={image?.height || image?.videoHeight || 100}
      {...others}
    />
  );
}

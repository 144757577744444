import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import FormFieldContainer from "controls/FormFieldContainer";
import b64ToBlob from "helpers/b64ToBlob";
import useLoadedImage from "helpers/useLoadedImage";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { progressBar } from "hooks/useProgressBar";
import NumberInput from "inputs/NumberInput";
import RectInput from "inputs/RectInput";
import TextInput from "inputs/TextInput";
import { isEqual } from "lodash-es";
import { CameraControl } from "mdi-material-ui";
import React, { useLayoutEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import { useAsyncMemo } from "use-async-memo";
import { useDebounce } from "use-debounce";

CameraFilterConfigDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Filter Config",
    icon: <CameraControl />,
  };
};

export default function CameraFilterConfigDialog({ cameraId, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query CameraEncodingConfigDialog($cameraId: ID!) {
        camera(id: $cameraId) {
          id
          filterDefishAmount
          filterDefishOutwidth
          filterRotateDegrees
          filterCropArea
        }
      }
    `,
    { cameraId },
  );
  const cameraFilterConfigUpdate = useActionFragment("cameraFilterConfigUpdate");
  const previewFilterChain = useActionFragment("previewFilterChain", "jpgImageBase64,ffmpegFilterChain");

  const [video, videoSet] = useState(null);
  const [filterDefishAmount, filterDefishAmountSet] = useState(null);
  const [filterDefishOutwidth, filterDefishOutwidthSet] = useState(null);
  const [filterRotateDegrees, filterRotateDegreesSet] = useState(null);
  const [filterCropArea, filterCropAreaSet] = useState(null);

  const [filterDefishAmountDebounced] = useDebounce(filterDefishAmount, 1000, { leading: true });
  const [filterDefishOutwidthDebounced] = useDebounce(filterDefishOutwidth, 1000, { leading: true });
  const [filterRotateDegreesDebounced] = useDebounce(filterRotateDegrees, 1000, { leading: true });
  const [filterCropAreaDebounced] = useDebounce(filterCropArea, 1000, {
    equalityFn: isEqual,
    leading: true,
  });

  useLayoutEffect(() => {
    if (data && !dataMeta.loading) {
      filterDefishAmountSet(data.camera.filterDefishAmount);
      filterDefishOutwidthSet(data.camera.filterDefishOutwidth);
      filterRotateDegreesSet(data.camera.filterRotateDegrees);
      filterCropAreaSet(data.camera.filterCropArea);
    }
  }, [dataMeta.loading]);

  const rotatePreviewInput = {
    cameraId,
    videoId: video?.id,
    filterDefishAmount: filterDefishAmountDebounced,
    filterDefishOutwidth: filterDefishOutwidthDebounced,
    filterRotateDegrees: filterRotateDegreesDebounced,
  };

  const rotatePreviewUrl = useAsyncMemo(
    () =>
      progressBar(async () => {
        const result = await previewFilterChain({
          input: rotatePreviewInput,
        });
        const jpgImageBase64 = result.previewFilterChain.jpgImageBase64;
        const blob = await b64ToBlob(jpgImageBase64);
        const url = URL.createObjectURL(blob);
        return url;
      }).catch(() => null),
    [JSON.stringify(rotatePreviewInput)],
  );

  const [rotatePreview] = useLoadedImage(rotatePreviewUrl);

  const previewInput = {
    ...rotatePreviewInput,
    filterCropArea: filterCropAreaDebounced,
  };

  const preview = useAsyncMemo(
    () =>
      progressBar(async () => {
        const result = await previewFilterChain({
          input: previewInput,
        });
        const jpgImageBase64 = result.previewFilterChain.jpgImageBase64;
        const ffmpegFilterChain = result.previewFilterChain.ffmpegFilterChain;
        const blob = await b64ToBlob(jpgImageBase64);
        const url = URL.createObjectURL(blob);
        return { url, ffmpegFilterChain };
      }).catch(() => null),
    [JSON.stringify(previewInput)],
  );

  return (
    <FormDialog
      header="Camera Filter Config"
      loading={dataMeta.loading}
      onClose={onClose}
      maxWidth="lg"
      onSubmit={async (formData) => {
        await cameraFilterConfigUpdate({ input: { cameraId, ...formData } });
        await onSubmit?.();
      }}
    >
      <Field
        label="Preview Using Video"
        value={video}
        onChange={videoSet}
        input={<ReadminObjectInput optionsTypename="Video" optionsFilters={{ for_camera_id: cameraId }} />}
        helperText={<>Select a video to preview instead of using live camera feed</>}
      />
      <Field
        name="filterDefishAmount"
        label="Filter Defish Amount"
        value={filterDefishAmount}
        onChange={filterDefishAmountSet}
        input={<NumberInput min={0} max={3} step={0.01} initValue={0.6} />}
        helperText={
          <>
            Use defish filter to remove fisheye effects from bullet cameras. <br />
            Do <b>not</b> use defish filter for Dome or 180 cameras, setup spherical projection in Encoding Settings
            instead.
          </>
        }
      />
      <Field
        name="filterDefishOutwidth"
        label="Filter Defish Outwidth"
        value={filterDefishOutwidth}
        onChange={filterDefishOutwidthSet}
        input={<NumberInput min={0} max={10000} initValue={rotatePreview?.width} />}
        helperText={
          <>
            How much to stretch the image to remove the fisheye effect. Should always be greater than the width of the
            original image ({rotatePreview?.width}).
          </>
        }
      />
      <Field
        name="filterRotateDegrees"
        label="Filter Rotate Degrees"
        value={filterRotateDegrees}
        onChange={filterRotateDegreesSet}
        input={<NumberInput min={-180} max={180} step={1} unit="degrees" />}
        helperText={
          <>Only use this for bullet cameras. For Dome or 180 cameras, setup spherical roll degrees instead.</>
        }
      />
      <Field
        name="filterCropArea"
        label="Filter Crop Area"
        value={filterCropArea}
        onChange={filterCropAreaSet}
        disabled={!rotatePreview}
        required
        input={
          <RectInput
            enableRawJSON
            backgroundImage={rotatePreview}
            round
            width={rotatePreview?.width}
            height={rotatePreview?.height}
            getInitValue={({ width, height }) => ({ x: 0, y: 0, width, height })}
          />
        }
        helperText={<>Select final area to feed into motioncrop.</>}
      />
      {preview && (
        <>
          <Field label="FFmpeg Filter Chain" disabled value={preview.ffmpegFilterChain} input={<TextInput />} />
          <FormFieldContainer label="Filter Result Preview">
            <img src={preview.url} alt="Filter Preview" style={{ maxWidth: "100%" }} />
          </FormFieldContainer>
        </>
      )}
    </FormDialog>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import TextInput from "inputs/TextInput";
import { Plus } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

AddSurfaceDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Add Surface",
    icon: <Plus />,
  };
};

export default function AddSurfaceDialog({ onClose, onSubmit, cameraId }) {
  const addSurface = useActionFragment("addSurface", "surface { id }");

  return (
    <FormDialog
      onClose={onClose}
      header="Add Surface"
      onSubmit={async (formData) => {
        const result = await addSurface({ input: formData });
        await onSubmit?.(result.addSurface.surface);
      }}
    >
      <Field
        name="camera"
        label="Camera"
        required
        defaultValue={cameraId && { id: cameraId, __typename: "Camera" }}
        input={<ReadminObjectInput optionsTypename="Camera" />}
      />
      <Field name="name" label="Name" required input={<TextInput />} />
    </FormDialog>
  );
}

import { Alert, AlertTitle } from "@mui/material";
import React from "react";

export default function Message({ type = "info", title, content }) {
  return (
    <Alert
      variant="outlined"
      severity={
        {
          info: "info",
          success: "success",
          error: "error",
          warning: "warning",
        }[type]
      }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {content}
    </Alert>
  );
}

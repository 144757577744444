import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import VenuesForm from "./Form";

AddVenueDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    content: "Add Venue",
    icon: <Plus />,
  };
};

export default function AddVenueDialog({ cityId, onSubmit, onClose }) {
  const addVenue = useActionFragment("addVenue", "venue { id }");

  return (
    <FormDialog
      header="Add Venue"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addVenue({ input: formData });
        await onSubmit(result.addVenue.venue);
      }}
    >
      <VenuesForm venues={[{ city: cityId && { id: cityId, __typename: "City" } }]} />
    </FormDialog>
  );
}

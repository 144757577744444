import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import LiveStreamCustomOutputsForm, { LiveStreamCustomOutputsFormFragment } from "./Form";

LiveStreamCustomOutputsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function LiveStreamCustomOutputsEditDialog({ liveStreamCustomOutputIds, onClose, onSubmit }) {
  const liveStreamCustomOutputsUpdate = useActionFragment("liveStreamCustomOutputsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query LiveStreamCustomOutputsEditDialogQuery($liveStreamCustomOutputIds: [ID!]!) {
        liveStreamCustomOutputs(ids: $liveStreamCustomOutputIds) {
          id
          ...LiveStreamCustomOutputsFormFragment
        }
      }
      ${LiveStreamCustomOutputsFormFragment}
    `,
    { liveStreamCustomOutputIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit RTMP Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        await liveStreamCustomOutputsUpdate({
          input: {
            liveStreamCustomOutputIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <LiveStreamCustomOutputsForm liveStreamCustomOutputs={data?.liveStreamCustomOutputs} />
    </FormDialog>
  );
}

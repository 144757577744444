import { Button } from "@mui/material";
import EditRawJSONDialog from "controls/EditRawJSONDialog";
import FormFieldContainer from "controls/FormFieldContainer";
import ImageCanvas from "controls/ImageCanvas";
import useLoadedImage from "helpers/useLoadedImage";
import useFormDialogs from "hooks/useFormDialogs";
import { isEqualWith } from "lodash-es";
import React, { useState } from "react";
import { Rnd } from "react-rnd";
import { useComponentSize } from "react-use-size";

export default function RectInput({
  value,
  onChange,
  disabled = false,
  width = 1,
  height = 1,
  aspectRatio = width && height ? width / height : 1,
  round = false,
  fillImage = null,
  fillImageUrl = null,
  fillImageVideoTime = 0,
  backgroundImage = null,
  backgroundImageUrl = null,
  backgroundImageVideoTime = 0,
  enableRawJSON = false,
  initValue,
  getInitValue,
  ...others
}) {
  const [loadedBackgroundImage] = useLoadedImage(backgroundImageUrl, { videoTime: backgroundImageVideoTime });
  const [loadedFillImage] = useLoadedImage(fillImageUrl, { videoTime: fillImageVideoTime });

  const valueValid =
    !!value && value.constructor === Object && isEqualWith(Object.keys(value).sort(), ["height", "width", "x", "y"]);
  const [editing, editingSet] = useState(false);
  const canvasContainerSize = useComponentSize();
  const [creatingClickedDownAt, creatingClickedDownAtSet] = useState(null);
  const [creatingMoveAt, creatingMoveAtSet] = useState(null);
  const creatingRect = creatingClickedDownAt &&
    creatingMoveAt && {
      left: Math.min(creatingClickedDownAt[0], creatingMoveAt[0]),
      top: Math.min(creatingClickedDownAt[1], creatingMoveAt[1]),
      width: Math.abs(creatingClickedDownAt[0] - creatingMoveAt[0]),
      height: Math.abs(creatingClickedDownAt[1] - creatingMoveAt[1]),
    };

  const onChangeWithRound = (value) => {
    value = { ...value };
    if (round) {
      value.x = Math.round(value.x);
      value.y = Math.round(value.y);
      value.width = Math.round(value.width);
      value.height = Math.round(value.height);
    }
    onChange?.(value);
  };
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  fillImage ||= loadedFillImage;
  backgroundImage ||= loadedBackgroundImage;

  return (
    <FormFieldContainer data-rect-input disabled={disabled} {...others}>
      {formDialogs}
      <div style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: 5, padding: 5 }}>
        {!!value && !valueValid && <>Invalid value</>}
        {enableRawJSON && (
          <Button
            variant="outlined"
            color="inherit"
            disabled={disabled}
            onClick={async () => {
              const valueNew = await formDialogsOpen(<EditRawJSONDialog value={value} />);
              onChange(valueNew);
            }}
          >
            Raw JSON
          </Button>
        )}
        <Button variant="outlined" disabled={disabled} onClick={() => editingSet((e) => !e)}>
          {editing ? "Finish Edit" : "Edit"}
        </Button>
        <Button variant="outlined" disabled={disabled} onClick={() => onChange(null)}>
          Clear
        </Button>
        {!value && (initValue || getInitValue) && (
          <Button
            variant="outlined"
            disabled={disabled}
            onClick={() => {
              const valueNew = initValue || getInitValue({ width, height });
              onChange(valueNew);
            }}
          >
            Init
          </Button>
        )}
        {editing && valueValid && <>You can move or resize the rectangle area.</>}
        {editing && !valueValid && <>Area not set. Click and drag to create a rectangle area.</>}
        <div
          data-rect-input-canvas
          ref={canvasContainerSize.ref}
          style={{
            overflow: "hidden",
            userSelect: "none",
            position: "relative",
            width: editing ? "100%" : 120,
            height: canvasContainerSize.width / aspectRatio || 1,
            outline: "1px solid black",
            ...(!valueValid &&
              editing && {
                cursor: "crosshair",
              }),
            backgroundColor: "#f0f0f0",
          }}
          {...(!valueValid &&
            editing && {
              onMouseDown: (event) => {
                const boundingClientRect = event.currentTarget.getBoundingClientRect();
                const [x, y] = [event.clientX - boundingClientRect.left, event.clientY - boundingClientRect.top];
                creatingClickedDownAtSet([x, y]);
                creatingMoveAtSet([x, y]);
              },
              onMouseMove: (event) => {
                const boundingClientRect = event.currentTarget.getBoundingClientRect();
                const [x, y] = [event.clientX - boundingClientRect.left, event.clientY - boundingClientRect.top];
                creatingMoveAtSet([x, y]);
              },
              onMouseUp: () => {
                creatingClickedDownAtSet(null);
                creatingMoveAtSet(null);
                onChangeWithRound({
                  x: (creatingRect.left / canvasContainerSize.width) * width,
                  y: (creatingRect.top / canvasContainerSize.height) * height,
                  width: (creatingRect.width / canvasContainerSize.width) * width,
                  height: (creatingRect.height / canvasContainerSize.height) * height,
                });
              },
            })}
        >
          {backgroundImage && (
            <ImageCanvas
              image={backgroundImage}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                pointerEvents: "none",
                userSelect: "none",
              }}
            />
          )}
          {!valueValid && creatingRect && (
            <ImageCanvas
              image={fillImage}
              style={{
                position: "absolute",
                ...creatingRect,
                outline: "1px dashed red",
                pointerEvents: "none",
              }}
            />
          )}
          {valueValid && (
            <Rnd
              enableResizing={editing}
              disableDragging={!editing}
              bounds="parent"
              style={{
                outline: "1px dashed red",
              }}
              position={{
                x: (value.x / width) * canvasContainerSize.width,
                y: (value.y / height) * canvasContainerSize.height,
              }}
              size={{
                width: (value.width / width) * canvasContainerSize.width,
                height: (value.height / height) * canvasContainerSize.height,
              }}
              onDragStop={(event, position) => {
                onChangeWithRound({
                  x: (position.x / canvasContainerSize.width) * width,
                  y: (position.y / canvasContainerSize.height) * height,
                  width: value.width,
                  height: value.height,
                });
              }}
              onResize={(event, direction, ref, delta, position) => {
                onChangeWithRound({
                  x: (position.x / canvasContainerSize.width) * width,
                  y: (position.y / canvasContainerSize.height) * height,
                  width: (ref.offsetWidth / canvasContainerSize.width) * width,
                  height: (ref.offsetHeight / canvasContainerSize.height) * height,
                });
              }}
            >
              <ImageCanvas
                image={fillImage}
                style={{
                  pointerEvents: "none",
                  userSelect: "none",
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  ...(!fillImage && {
                    backgroundColor: "rgba(255, 255, 255, 0.5)",
                  }),
                }}
              />
            </Rnd>
          )}
        </div>
      </div>
    </FormFieldContainer>
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import React from "react";

export const CitiesFormFragment = gql`
  fragment CitiesFormFragment on City {
    name
    state
    countryCode
    timeZone
  }
`;

export default function CitiesForm({ cities = [{}] }) {
  const [data] = useData(gql`
    query CitiesForm {
      timeZones
      glCountries {
        countryCode
        name
      }
    }
  `);

  return (
    <>
      <MultiValuesField
        name="name"
        label="Name"
        required
        defaultValues={cities.map((city) => city.name)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="countryCode"
        label="Country"
        required
        defaultValues={cities.map((city) => city.countryCode)}
        input={<SelectInput options={data?.glCountries.map(({ countryCode, name }) => [name, countryCode])} />}
      />
      <MultiValuesField
        name="state"
        label="State"
        required
        defaultValues={cities.map((city) => city.state)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="timeZone"
        label="Time Zone"
        required
        defaultValues={cities.map((city) => city.timeZone)}
        input={<SelectInput options={data?.timeZones} />}
      />
    </>
  );
}

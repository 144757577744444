import "core-js/stable";
import "regenerator-runtime/runtime";
import "styles/browser_upgrade.css";
import "styles/recaptcha.css";
import "styles/ngrogressfix.css";
import "styles/progressbar-spinner.css";
import "graphiql/graphiql.css";
import "styles/stylefix.css";

import Rails from "@rails/ujs";
import setupReact from "helpers/setupReact";
import LiveScorer from "public_pages/LiveScorer";
import LiveStreamWidget from "public_pages/LiveStreamWidget";
import LoginPage from "public_pages/LoginPage";
import LogoutPage from "public_pages/LogoutPage";
import OriginalVideoFormPage from "public_pages/OriginalVideoFormPage";
import RequestResetPasswordPage from "public_pages/RequestResetPasswordPage";
import ResetPasswordPage from "public_pages/ResetPasswordPage";
import RootPage from "public_pages/RootPage";
import TeamRegistrationFormPage from "public_pages/TeamRegistrationFormPage";
import ApiPartnerGraphiqlPage from "readmin_pages/ApiPartnerGraphiqlPage";
import CurrentUserPage from "readmin_pages/current_user/CurrentUserPage";
import GamesRefereeManagementPage from "readmin_pages/games/GamesRefereeManagementPage";
import {
  AdAssetPage,
  AdPresetPage,
  ApiPartnerApiCompetitionPage,
  ApiPartnerApiCourtPage,
  ApiPartnerApiGameSchedulePage,
  ApiPartnerApiGradePage,
  ApiPartnerApiGraphqlRequestPage,
  ApiPartnerApiOrganisationPage,
  ApiPartnerApiProfilePage,
  ApiPartnerApiTeamPage,
  ApiPartnerPage,
  BetaFeaturePage,
  CameraModelPage,
  CameraPage,
  CityPage,
  ClubAdminPage,
  ClubPage,
  CourtPage,
  DeliveryPage,
  DevicePortMappingPage,
  EventPage,
  EventVideoPage,
  GamePage,
  GameSubmissionPage,
  GdAssociationPage,
  GdVenuePage,
  IncidentPage,
  LeaderboardTablePage,
  LeaguePage,
  LiveStreamPage,
  MotioncropTestPage,
  MotioncropTestReportPage,
  NetworkDevicePage,
  OnsenPopupPromoPage,
  OpsWarningPage,
  OriginalVideoRequestPage,
  PersonPage,
  PlayHqOrganisationPage,
  PotwCompetitionPage,
  PotwEntryPage,
  PotwRatingPage,
  PotwVideoPage,
  PublicLeaguePagePage,
  RecordingBlockPage,
  RecordingPage,
  RecurringRecordingOccurrencePage,
  ScorekeeperDevicePage,
  ScorekeeperUserPage,
  SendgridEventPage,
  SurfacePage,
  TeamPage,
  TeamRegistrationPage,
  UsageReportEntryPage,
  UserPage,
  VenueAdminPage,
  VenuePage,
  VenueServerChefRunPage,
  VenueServerCommandPage,
  VenueServerPage,
  VenueServerVideoFilePage,
  VideoCutAttemptPage,
  VideoEncodingJobRequestPage,
  VideoObjectPage,
  VideoPage,
  VideoVersionPage,
} from "readmin_pages/shared/readminObjectPages";
import {
  AdAssetsPage,
  AdPresetsPage,
  ApiPartnerApiCompetitionsPage,
  ApiPartnerApiCourtsPage,
  ApiPartnerApiGameSchedulesPage,
  ApiPartnerApiGraphqlRequestsPage,
  ApiPartnerApiOrganisationsPage,
  ApiPartnerApiProfilesPage,
  ApiPartnerApiTeamsPage,
  ApiPartnersPage,
  BetaFeaturesPage,
  CameraModelsPage,
  CamerasPage,
  CitiesPage,
  ClubAdminsPage,
  ClubsPage,
  CourtsPage,
  DeliveriesPage,
  DevicePortMappingsPage,
  EventsPage,
  EventVideosPage,
  GamesPage,
  GameSubmissionsPage,
  GdAssociationsPage,
  GdVenuesPage,
  IncidentsPage,
  LeaguesPage,
  LiveStreamsPage,
  MotioncropTestReportsPage,
  MotioncropTestsPage,
  NetworkDevicesPage,
  NotesPage,
  OnsenPopupPromosPage,
  OpsWarningsPage,
  OriginalVideoRequestsPage,
  PaperTrailVersionsPage,
  PeoplePage,
  PlayHqOrganisationsPage,
  PotwCompetitionsPage,
  PotwEntriesPage,
  PotwRatingsPage,
  PotwVideosPage,
  PublicLeaguePagesPage,
  RecordingBlocksPage,
  RecordingsPage,
  RecurringRecordingOccurrencesPage,
  ScorekeeperDevicesPage,
  ScorekeeperUsersPage,
  SendgridEventsPage,
  SurfacesPage,
  TeamRegistrationsPage,
  TeamsPage,
  UsageReportPage,
  UsersPage,
  VenueAdminsPage,
  VenueServerChefRunsPage,
  VenueServerCommandsPage,
  VenueServersPage,
  VenuesPage,
  VideoCutAttemptsPage,
  VideoObjectsPage,
  VideosPage,
} from "readmin_pages/shared/readminObjectsPages";

setupReact({
  pages: {
    // public pages
    "/": RootPage,
    "/team_registrations/:token": TeamRegistrationFormPage,
    "/live_scorer/:token": LiveScorer,
    "/live_stream_widget/:uuid": LiveStreamWidget,
    "/original_videos/:uuid": OriginalVideoFormPage,
    "/passwords/edit": ResetPasswordPage,

    // readmin, spectial pages
    "/readmin/account/edit": CurrentUserPage,
    "/readmin/login": LoginPage,
    "/readmin/logout": LogoutPage,
    "/readmin/reset-password": RequestResetPasswordPage,

    // readmin root-level pages
    "/readmin/ad_assets": AdAssetsPage,
    "/readmin/ad_presets": AdPresetsPage,
    "/readmin/beta_features": BetaFeaturesPage,
    "/readmin/beta_features/:betaFeatureId": BetaFeaturePage,
    "/readmin/cameras": CamerasPage,
    "/readmin/cameras/:cameraId": CameraPage,
    "/readmin/camera_models": CameraModelsPage,
    "/readmin/camera_models/:cameraModelId": CameraModelPage,
    "/readmin/cities": CitiesPage,
    "/readmin/cities/:cityId": CityPage,
    "/readmin/clubs": ClubsPage,
    "/readmin/club_admins": ClubAdminsPage,
    "/readmin/courts": CourtsPage,
    "/readmin/deliveries": DeliveriesPage,
    "/readmin/deliveries/:deliveryId": DeliveryPage,
    "/readmin/video_encoding_job_requests/:videoEncodingJobRequestId": VideoEncodingJobRequestPage,
    "/readmin/events": EventsPage,
    "/readmin/event_videos": EventVideosPage,
    "/readmin/event_videos/:eventVideoId": EventVideoPage,
    "/readmin/games": GamesPage,
    "/readmin/game_submissions": GameSubmissionsPage,
    "/readmin/game_submissions/:gameSubmissionId": GameSubmissionPage,
    "/readmin/incidents": IncidentsPage,
    "/readmin/leagues": LeaguesPage,
    "/readmin/live_streams": LiveStreamsPage,
    "/readmin/network_devices": NetworkDevicesPage,
    "/readmin/network_devices/:networkDeviceId": NetworkDevicePage,
    "/readmin/motioncrop_tests": MotioncropTestsPage,
    "/readmin/motioncrop_tests/:motioncropTestId": MotioncropTestPage,
    "/readmin/motioncrop_test_reports": MotioncropTestReportsPage,
    "/readmin/motioncrop_test_reports/:motioncropTestReportId": MotioncropTestReportPage,
    "/readmin/notes": NotesPage,
    "/readmin/original_video_requests": OriginalVideoRequestsPage,
    "/readmin/original_video_requests/:originalVideoRequestId": OriginalVideoRequestPage,
    "/readmin/onsen_popup_promos": OnsenPopupPromosPage,
    "/readmin/onsen_popup_promos/:onsenPopupPromoId": OnsenPopupPromoPage,
    "/readmin/ops_warnings": OpsWarningsPage,
    "/readmin/ops_warnings/:opsWarningId": OpsWarningPage,
    "/readmin/people": PeoplePage,
    "/readmin/paper_trail_versions": PaperTrailVersionsPage,
    "/readmin/play_hq_organisations": PlayHqOrganisationsPage,
    "/readmin/play_hq_organisations/:playHqOrganisationId": PlayHqOrganisationPage,
    "/readmin/potw_competitions": PotwCompetitionsPage,
    "/readmin/potw_entries": PotwEntriesPage,
    "/readmin/potw_ratings": PotwRatingsPage,
    "/readmin/potw_videos": PotwVideosPage,
    "/readmin/potw_videos/:potwVideoId": PotwVideoPage,
    "/readmin/public_league_pages": PublicLeaguePagesPage,
    "/readmin/recording_blocks": RecordingBlocksPage,
    "/readmin/recording_blocks/:recordingBlockId": RecordingBlockPage,
    "/readmin/recurring_recording_occurrences": RecurringRecordingOccurrencesPage,
    "/readmin/scorekeeper_devices": ScorekeeperDevicesPage,
    "/readmin/scorekeeper_devices/:scorekeeperDeviceId": ScorekeeperDevicePage,
    "/readmin/scorekeeper_users": ScorekeeperUsersPage,
    "/readmin/sendgrid_events": SendgridEventsPage,
    "/readmin/sendgrid_events/:sendgridEventId": SendgridEventPage,
    "/readmin/surfaces": SurfacesPage,
    "/readmin/surfaces/:surfaceId": SurfacePage,
    "/readmin/teams": TeamsPage,
    "/readmin/team_registrations": TeamRegistrationsPage,
    "/readmin/users": UsersPage,
    "/readmin/users/:userId": UserPage,
    "/readmin/venue_admins": VenueAdminsPage,
    "/readmin/venue_admins/:venueAdminId": VenueAdminPage,
    "/readmin/venue_server_commands": VenueServerCommandsPage,
    "/readmin/venue_server_commands/:venueServerCommandId": VenueServerCommandPage,
    "/readmin/venue_server_chef_runs": VenueServerChefRunsPage,
    "/readmin/venue_server_chef_runs/:venueServerChefRunId": VenueServerChefRunPage,
    "/readmin/venue_servers": VenueServersPage,
    "/readmin/venue_servers/:venueServerId": VenueServerPage,
    "/readmin/venue_server_video_files/:venueServerVideoFileId": VenueServerVideoFilePage,
    "/readmin/venues": VenuesPage,
    "/readmin/venues/:venueId": VenuePage,
    "/readmin/videos": VideosPage,
    "/readmin/videos/:videoId": VideoPage,
    "/readmin/video_cut_attempts": VideoCutAttemptsPage,
    "/readmin/video_cut_attempts/:videoCutAttemptId": VideoCutAttemptPage,
    "/readmin/video_objects": VideoObjectsPage,
    "/readmin/video_objects/:videoObjectId": VideoObjectPage,
    "/readmin/video_versions/:videoVersionId": VideoVersionPage,
    "/readmin/gd_associations": GdAssociationsPage,
    "/readmin/gd_associations/:gdAssociationId": GdAssociationPage,
    "/readmin/gd_venues": GdVenuesPage,
    "/readmin/gd_venues/:gdVenueId": GdVenuePage,
    "/readmin/device_port_mappings": DevicePortMappingsPage,
    "/readmin/device_port_mappings/:devicePortMappingId": DevicePortMappingPage,

    // readmin club-level pages
    "/readmin/clubs/:clubId": ClubPage,
    "/readmin/clubs/:clubId/ad_assets": AdAssetsPage,
    "/readmin/clubs/:clubId/ad_assets/:adAssetId": AdAssetPage,
    "/readmin/clubs/:clubId/ad_presets": AdPresetsPage,
    "/readmin/clubs/:clubId/ad_presets/:adPresetId": AdPresetPage,
    "/readmin/clubs/:clubId/deliveries": DeliveriesPage,
    "/readmin/clubs/:clubId/deliveries/:deliveryId": DeliveryPage,
    "/readmin/clubs/:clubId/teams": TeamsPage,
    "/readmin/clubs/:clubId/teams/:teamId": TeamPage,
    "/readmin/clubs/:clubId/team_registrations": TeamRegistrationsPage,
    "/readmin/clubs/:clubId/team_registrations/:teamRegistrationId": TeamRegistrationPage,
    "/readmin/clubs/:clubId/games": GamesPage,
    "/readmin/clubs/:clubId/games/manage_referees": GamesRefereeManagementPage,
    "/readmin/clubs/:clubId/games/:gameId": GamePage,
    "/readmin/clubs/:clubId/game_submissions": GameSubmissionsPage,
    "/readmin/clubs/:clubId/game_submissions/:gameSubmissionId": GameSubmissionPage,
    "/readmin/clubs/:clubId/videos": VideosPage,
    "/readmin/clubs/:clubId/videos/:videoId": VideoPage,
    "/readmin/clubs/:clubId/incidents": IncidentsPage,
    "/readmin/clubs/:clubId/incidents/:incidentId": IncidentPage,
    "/readmin/clubs/:clubId/leagues": LeaguesPage,
    "/readmin/clubs/:clubId/leagues/:leagueId": LeaguePage,
    "/readmin/clubs/:clubId/live_streams": LiveStreamsPage,
    "/readmin/clubs/:clubId/live_streams/:liveStreamId": LiveStreamPage,
    "/readmin/clubs/:clubId/leaderboard_tables/:leaderboardTableId": LeaderboardTablePage,
    "/readmin/clubs/:clubId/people": PeoplePage,
    "/readmin/clubs/:clubId/people/:personId": PersonPage,
    "/readmin/clubs/:clubId/potw_competitions": PotwCompetitionsPage,
    "/readmin/clubs/:clubId/potw_competitions/:potwCompetitionId": PotwCompetitionPage,
    "/readmin/clubs/:clubId/potw_entries": PotwEntriesPage,
    "/readmin/clubs/:clubId/potw_entries/:potwEntryId": PotwEntryPage,
    "/readmin/clubs/:clubId/potw_ratings": PotwRatingsPage,
    "/readmin/clubs/:clubId/potw_ratings/:potwRatingId": PotwRatingPage,
    "/readmin/clubs/:clubId/public_league_pages": PublicLeaguePagesPage,
    "/readmin/clubs/:clubId/public_league_pages/:publicLeaguePageId": PublicLeaguePagePage,
    "/readmin/clubs/:clubId/recurring_recording_occurrences": RecurringRecordingOccurrencesPage,
    "/readmin/clubs/:clubId/recurring_recording_occurrences/:recurringRecordingOccurrenceId":
      RecurringRecordingOccurrencePage,
    "/readmin/clubs/:clubId/courts": CourtsPage,
    "/readmin/clubs/:clubId/courts/:courtId": CourtPage,
    "/readmin/clubs/:clubId/recordings": RecordingsPage,
    "/readmin/clubs/:clubId/recordings/:recordingId": RecordingPage,
    "/readmin/clubs/:clubId/scorekeeper_users": ScorekeeperUsersPage,
    "/readmin/clubs/:clubId/scorekeeper_users/:scorekeeperUserId": ScorekeeperUserPage,
    "/readmin/clubs/:clubId/events": EventsPage,
    "/readmin/clubs/:clubId/events/:eventId": EventPage,
    "/readmin/clubs/:clubId/notes": NotesPage,
    "/readmin/clubs/:clubId/usage_report": UsageReportPage,
    "/readmin/clubs/:clubId/usage_report_entries/:usageReportEntryId": UsageReportEntryPage,
    "/readmin/clubs/:clubId/club_admins": ClubAdminsPage,
    "/readmin/clubs/:clubId/club_admins/:clubAdminId": ClubAdminPage,

    // readmin api-level pages
    "/readmin/api_partners": ApiPartnersPage,
    "/readmin/api_partners/:apiPartnerId": ApiPartnerPage,
    "/readmin/api_partners/:apiPartnerId/graphiql": ApiPartnerGraphiqlPage,
    "/readmin/api_partners/:apiPartnerId/api_organisations": ApiPartnerApiOrganisationsPage,
    "/readmin/api_partners/:apiPartnerId/api_organisations/:apiOrganisationId": ApiPartnerApiOrganisationPage,
    "/readmin/api_partners/:apiPartnerId/api_courts": ApiPartnerApiCourtsPage,
    "/readmin/api_partners/:apiPartnerId/api_courts/:apiCourtId": ApiPartnerApiCourtPage,
    "/readmin/api_partners/:apiPartnerId/api_teams": ApiPartnerApiTeamsPage,
    "/readmin/api_partners/:apiPartnerId/api_teams/:apiTeamId": ApiPartnerApiTeamPage,
    "/readmin/api_partners/:apiPartnerId/api_competitions": ApiPartnerApiCompetitionsPage,
    "/readmin/api_partners/:apiPartnerId/api_competitions/:apiCompetitionId": ApiPartnerApiCompetitionPage,
    "/readmin/api_partners/:apiPartnerId/api_game_schedules": ApiPartnerApiGameSchedulesPage,
    "/readmin/api_partners/:apiPartnerId/api_game_schedules/:apiGameScheduleId": ApiPartnerApiGameSchedulePage,
    "/readmin/api_partners/:apiPartnerId/api_profiles": ApiPartnerApiProfilesPage,
    "/readmin/api_partners/:apiPartnerId/api_profiles/:apiProfileId": ApiPartnerApiProfilePage,
    "/readmin/api_partners/:apiPartnerId/api_grades/:apiGradeId": ApiPartnerApiGradePage,
    "/readmin/api_partners/:apiPartnerId/api_graphql_requests": ApiPartnerApiGraphqlRequestsPage,
    "/readmin/api_partners/:apiPartnerId/api_graphql_requests/:apiGraphqlRequestId": ApiPartnerApiGraphqlRequestPage,
  },
});

Rails.start();

window.GL_JS_PACK_LOADED = true;

import { progressBar } from "hooks/useProgressBar";

import apolloClient from "./apolloClient";

window.addEventListener("message", (event) => {
  if (event.data !== "gloryleague_oauth_popup_callback") return;
  progressBar(() => apolloClient.refetchQueries({ include: "active" }));
});

export default function oauthAuthorise(provider, { width = 720, height = 480 } = {}) {
  window.open("", "oauth_popup", `popup=yes,width=${width},height=${height}`);
  const csrfToken = document.querySelector("meta[name=csrf-token]")?.getAttribute("content");
  const csrfParam = document.querySelector("meta[name=csrf-param]")?.getAttribute("content");
  const form = document.createElement("form");
  form.method = "POST";
  form.target = "oauth_popup";
  form.action = `/auth/${provider}`;
  form.innerHTML = `<input type="hidden" name="${csrfParam}" value="${csrfToken}" />`;
  document.body.appendChild(form);
  form.submit();
  document.body.removeChild(form);
}

import { intlFormat, parseISO } from "date-fns";
import useTimeZone from "hooks/useTimeZone";
import React from "react";

function DateTime({ value, variant, defaultValue = "?", ...others }) {
  const timeZone = useTimeZone();
  return (
    <span title={timeZone} {...others}>
      {!value && defaultValue}
      {value &&
        intlFormat(parseISO(value), {
          ...({
            time: {
              timeStyle: "medium",
            },
            date: {
              dateStyle: "long",
            },
          }[variant] || {
            timeStyle: "medium",
            dateStyle: "short",
          }),
          timeZone,
        })}
    </span>
  );
}

export default DateTime;

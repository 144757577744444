import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const LiveStreamScorersFormFragment = gql`
  fragment LiveStreamScorersFormFragment on LiveStreamScorer {
    id
    liveStream {
      id
    }
    team1Display
    team2Display
  }
`;

export default function LiveStreamScorersForm({ liveStreamScorers }) {
  return (
    <>
      <MultiValuesField
        label="LiveStream"
        name="liveStream"
        required
        disabled={liveStreamScorers?.some((liveStreamScorer) => liveStreamScorer.id)}
        defaultValues={liveStreamScorers?.map((liveStreamScorer) => liveStreamScorer.liveStream)}
        input={<ReadminObjectInput optionsTypename="LiveStream" />}
      />
      <MultiValuesField
        name="team1Display"
        label="Team 1"
        required
        input={<TextInput />}
        defaultValues={liveStreamScorers?.map((liveStreamScorer) => liveStreamScorer.team1Display)}
      />
      <MultiValuesField
        name="team2Display"
        label="Team 2"
        required
        input={<TextInput />}
        defaultValues={liveStreamScorers?.map((liveStreamScorer) => liveStreamScorer.team2Display)}
      />
    </>
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import ReadminObjectsInput from "readmin_pages/shared/ReadminObjectsInput";

export const TeamsFormFragment = gql`
  fragment TeamsFormFragment on Team {
    id
    name
    denyAccess
    videoRecordingPermitted
    leagues {
      id
    }
  }
`;

export default function TeamsForm({ teams }) {
  teams = teams?.map((team) => ({
    videoRecordingPermitted: true,
    ...team,
  }));

  return (
    <>
      {teams?.every((t) => !t.id) && (
        <MultiValuesField
          label="Club"
          name="club"
          defaultValues={teams?.map((t) => t.club)}
          required
          input={<ReadminObjectInput optionsTypename="Club" />}
        />
      )}
      <MultiValuesField
        label="Leagues"
        name="leagues"
        defaultValues={teams?.map((t) => t.leagues)}
        input={<ReadminObjectsInput optionsTypename="League" />}
      />
      <MultiValuesField
        label="Name"
        name="name"
        defaultValues={teams?.map((t) => t.name)}
        required
        input={<TextInput autoFocus />}
      />
      <MultiValuesField
        label="Deny Access"
        name="denyAccess"
        defaultValues={teams?.map((t) => t.denyAccess)}
        input={<SwitchInput />}
        helperText="Deny team members' access to game videos"
      />
      <MultiValuesField
        label="Video Recording Permitted"
        name="videoRecordingPermitted"
        defaultValues={teams?.map((t) => t.videoRecordingPermitted)}
        input={<SwitchInput />}
        helperText={
          <>
            Untick to disable videos for this team.
            <br />
            This feature is to be used when there are privacy concerns surrounding a player who can not be recorded. No
            video will be recorded or available for any future games of the team.
            <br />
            If you meant to disable a single game due to an incident, please do this from the game page and click the{" "}
            <b>Disable Video</b> button.
          </>
        }
      />
    </>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import OnsenPopupPromosForm, { OnsenPopupPromosFormFragment } from "./Form";

OnsenPopupPromosEditDialog.useButtonProps = () => {
  return {
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function OnsenPopupPromosEditDialog({ onsenPopupPromoIds, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query OnsenPopupPromosEditDialogQuery($onsenPopupPromoIds: [ID!]!) {
        onsenPopupPromos(ids: $onsenPopupPromoIds) {
          id
          ...OnsenPopupPromosFormFragment
        }
      }
      ${OnsenPopupPromosFormFragment}
    `,
    { onsenPopupPromoIds },
  );

  const onsenPopupPromosUpdate = useActionFragment("onsenPopupPromosUpdate");

  return (
    <FormDialog
      header="Edit Onsen Popup Promo"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await onsenPopupPromosUpdate({
          input: {
            onsenPopupPromoIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <OnsenPopupPromosForm onsenPopupPromos={data?.onsenPopupPromos} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import useData from "hooks/useData";
import SuggestiveTextInput from "inputs/SuggestiveTextInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";

export const CameraModelsFormFragment = gql`
  fragment CameraModelsFormFragment on CameraModel {
    model
    manufacturer
    resolution
    category
    supportsH265
  }
`;

export default function CameraModelsForm({ cameraModels = [{}] }) {
  const [data] = useData(gql`
    query CameraModelsForm {
      cameraModelManufacturers
      cameraModelResolutions
      cameraModelCategories
    }
  `);

  return (
    <>
      <MultiValuesField
        name="manufacturer"
        label="Manufacturer"
        required
        defaultValues={cameraModels.map((cameraModel) => cameraModel.manufacturer)}
        input={<SuggestiveTextInput suggestions={data?.cameraModelManufacturers} />}
      />
      <MultiValuesField
        name="model"
        label="Model"
        required
        defaultValues={cameraModels.map((cameraModel) => cameraModel.model)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="category"
        label="Category"
        defaultValues={cameraModels.map((cameraModel) => cameraModel.category)}
        input={<SuggestiveTextInput suggestions={data?.cameraModelCategories} />}
      />
      <MultiValuesField
        name="resolution"
        label="Resolution"
        defaultValues={cameraModels.map((cameraModel) => cameraModel.resolution)}
        input={<SuggestiveTextInput suggestions={data?.cameraModelResolutions} />}
        helperText="must be in the format WIDTHxHEIGHT"
      />
      <MultiValuesField
        name="supportsH265"
        label="Supports H265"
        defaultValues={cameraModels.map((cameraModel) => cameraModel.supportsH265)}
        input={<SwitchInput />}
      />
    </>
  );
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import { addSeconds, differenceInSeconds, formatISO, parseISO } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import useData from "hooks/useData";
import DateInput from "inputs/DateInput";
import HiddenInput from "inputs/HiddenInput";
import MultiSelectInput from "inputs/MultiSelectInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import TimeInput from "inputs/TimeInput";
import React, { useLayoutEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const RecordingBlockFormFragment = gql`
  fragment RecordingBlockFormFragment on RecordingBlock {
    id
    name
    recordingBlockType
    startTime
    untilDate
    weekdays
    durationSeconds
    venue {
      id
    }
  }
`;

export default function RecordingBlockForm({ recordingBlock = {} }) {
  const [startDate, startDateSet] = useState(null);
  const [startTime, startTimeSet] = useState(null);
  const [endTime, endTimeSet] = useState(null);
  recordingBlock = {
    recordingBlockType: "ensure_recording",
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    startTime: formatISO(new Date()),
    durationSeconds: 3600,
    ...recordingBlock,
  };

  const [venue, venueSet] = useState(recordingBlock.venue);
  const durationSeconds = endTime && startTime && differenceInSeconds(parseISO(endTime), parseISO(startTime));
  const [data] = useData(
    gql`
      query RecordingBlockForm($venueId: ID) {
        venue(id: $venueId) {
          id
          timeZone
        }
      }
    `,
    { venueId: venue?.id },
  );
  const timeZone = data?.venue?.timeZone || "Pacific/Auckland";

  useLayoutEffect(() => {
    const { startTime, durationSeconds } = recordingBlock;
    const startDate = startTime && formatInTimeZone(parseISO(startTime), timeZone, "yyyy-MM-dd");
    const endTime = startTime && formatISO(addSeconds(parseISO(startTime), durationSeconds || 0));

    startDateSet(startDate);
    startTimeSet(startTime);
    endTimeSet(endTime);
  }, [timeZone]);

  return (
    <>
      <Field
        disabled={!!recordingBlock.id}
        name="venue"
        label="Venue"
        required
        defaultValue={recordingBlock.venue}
        onChange={venueSet}
        input={<ReadminObjectInput optionsTypename="Venue" />}
      />
      <Field label="Name" name="name" required defaultValue={recordingBlock.name} input={<TextInput />} />
      <Field
        label="Type"
        name="recordingBlockType"
        required
        defaultValue={recordingBlock.recordingBlockType}
        input={
          <SelectInput
            options={[
              ["Ensure Recording", "ensure_recording"],
              ["Prevent Recording", "prevent_recording"],
            ]}
          />
        }
      />
      <Field label="Start Date" required value={startDate} onChange={startDateSet} input={<DateInput />} />
      <Field label="Until Date" name="untilDate" defaultValue={recordingBlock.untilDate} input={<DateInput />} />
      <Field
        label="Days"
        name="weekdays"
        defaultValue={recordingBlock.weekdays}
        input={
          <MultiSelectInput
            options={[
              ["Monday", 1],
              ["Tuesday", 2],
              ["Wednesday", 3],
              ["Thursday", 4],
              ["Friday", 5],
              ["Saturday", 6],
              ["Sunday", 0],
            ]}
          />
        }
        helperText="You can add exception dates later"
      />
      <Field
        label="Start Time"
        name="startTime"
        required
        value={startTime}
        onChange={startTimeSet}
        input={<TimeInput forceTimeZone={timeZone} date={startDate} />}
      />
      <Field
        label="End Time"
        required
        value={endTime}
        onChange={endTimeSet}
        input={<TimeInput forceTimeZone={timeZone} date={startDate} min={startTime} />}
      />
      <Field name="durationSeconds" label="Duration" value={durationSeconds} input={<HiddenInput />} />
    </>
  );
}

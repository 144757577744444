import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import AbortError from "helpers/AbortError";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

import SelectPersonDialog from "./SelectPersonDialog";

export default function SelectRefereeDialog({
  clubId,
  personFragment = "id fullName isReferee",
  onSubmit,
  loading = false,
  isAssign = false,
  ...others
}) {
  const [confirmDialog, confirmDialogOpen] = useAddAsRefereeConfirmDialog({ isAssign: isAssign });

  return (
    <>
      {confirmDialog}
      <SelectPersonDialog
        {...others}
        clubId={clubId}
        header="Select referee"
        personFragment={personFragment}
        loading={loading}
        onSubmit={async (person) => {
          if (!person.isReferee) await confirmDialogOpen(person);
          await onSubmit(person);
        }}
      />
    </>
  );
}

function useAddAsRefereeConfirmDialog({ isAssign = true }) {
  const [entries, entriesSet] = useState([]);

  const confirmDialogOpen = async (person) => {
    const id = uuidv4();
    let { id: personId, fullName } = person;
    try {
      await new Promise((resolve, reject) => {
        const entry = {
          id,
          personId,
          fullName,
          resolve,
          reject,
        };
        entriesSet((entries) => [...entries, entry]);
      });
    } finally {
      entriesSet((entries) => entries.filter((e) => e.id !== id));
    }
  };

  const confirmDialog = (
    <>
      {entries.map(({ id, fullName, reject, resolve }) => (
        <Dialog key={id} open onClose={() => reject(new AbortError())}>
          <DialogTitle>
            {isAssign ? "Warning: Giving a non-referee referee access" : "Warning: Selecting a non-referee"}
          </DialogTitle>
          <DialogContent>
            {isAssign ? (
              <DialogContentText>
                We will make <b>{fullName}</b> a referee for your club as well as give them access?
              </DialogContentText>
            ) : (
              <DialogContentText>
                We will make <b>{fullName}</b> a referee for your club
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={async () => resolve()}>{isAssign ? "Add and assign" : "Add to club"}</Button>
            <Button onClick={() => reject(new AbortError())}>Cancel</Button>
          </DialogActions>
        </Dialog>
      ))}
    </>
  );
  return [confirmDialog, confirmDialogOpen];
}

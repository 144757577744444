import { gql } from "@apollo/client";
import { Button } from "@mui/material";
import DefinitionTable from "controls/DefinitionTable";
import { readminPageContext } from "helpers/contexts";
import { every, isEqual, pick } from "lodash-es";
import React, { useContext } from "react";

import ReadminAttributeValue, { ReadminAttributeValueFragment } from "./ReadminAttributeValue";
import ReadminObjectDialogToolbar from "./ReadminObjectDialogToolbar";
import ReadminObjectLabels from "./ReadminObjectLabels";
import ReadminObjectThumbnail from "./ReadminObjectThumbnail";

export const ReadminObjectAttributesTableFragment = gql`
  fragment ReadminObjectAttributesTableFragment on ReadminObject {
    id
    readminName
    readminUrl
    readminAttributeEntries {
      label
      ...ReadminAttributeValueFragment
    }
  }
  ${ReadminAttributeValueFragment}
`;

export default function ReadminObjectAttributesTable({
  readminObject,
  ignoreAssociationObjects = [],
  showDialogsToolbar = false,
  showLabels = false,
  showheader = false,
  showLink = false,
  showMedia = false,
  onSubmit,
  ...others
}) {
  const { clubId, apiPartnerId } = useContext(readminPageContext);
  if (clubId) ignoreAssociationObjects = [...ignoreAssociationObjects, { id: clubId, __typename: "Club" }];
  if (apiPartnerId)
    ignoreAssociationObjects = [...ignoreAssociationObjects, { id: apiPartnerId, __typename: "ApiPartner" }];

  return (
    <DefinitionTable
      {...others}
      {...(showheader && {
        header: readminObject?.readminName,
      })}
      {...(showDialogsToolbar &&
        readminObject && {
          toolbar: (
            <ReadminObjectDialogToolbar
              id={readminObject?.id}
              typename={readminObject?.__typename}
              onSubmit={onSubmit}
            />
          ),
        })}
      {...(showMedia && {
        thumbnail: <ReadminObjectThumbnail id={readminObject?.id} typename={readminObject?.__typename} />,
      })}
      {...(showLabels && {
        labels: <ReadminObjectLabels id={readminObject?.id} typename={readminObject?.__typename} />,
      })}
      footers={[
        ...(others.footers || []),
        ...((showLink &&
          readminObject?.readminUrl && [
            <Button variant="contained" style={{ margin: 10 }} href={readminObject?.readminUrl}>
              View more details
            </Button>,
          ]) ||
          []),
      ]}
      rows={readminObject?.readminAttributeEntries
        .filter(
          (e) =>
            !(e.type === "readmin_objects" && !e.readminObjects.length) &&
            !(
              e.type === "readmin_objects" &&
              every(e?.readminObjects, (readminObject) =>
                ignoreAssociationObjects.find((i) =>
                  isEqual(pick(i, ["id", "__typename"]), pick(readminObject, ["id", "__typename"])),
                ),
              )
            ),
        )
        .map((readminAttributeEntry) => [
          readminAttributeEntry.label,
          <ReadminAttributeValue {...readminAttributeEntry} />,
        ])}
    />
  );
}

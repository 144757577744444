import { ConfirmFormDialog } from "controls/FormDialog";
import downloadBlob from "helpers/downloadBlob";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Cog } from "mdi-material-ui";
import React from "react";

GenerateVenueServerSshConfigDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    icon: <Cog />,
    content: "Generate SSH Config",
  };
};

export default function GenerateVenueServerSshConfigDialog({ onSubmit = noop, ...others }) {
  const generateVenueServersSshConfig = useActionFragment("generateVenueServersSshConfig", "sshConfig filename");

  return (
    <ConfirmFormDialog
      title="Generate SSH Config"
      onSubmit={async () => {
        const result = await generateVenueServersSshConfig({ input: {} });
        const blob = new Blob([result.generateVenueServersSshConfig.sshConfig], {
          type: "text/plain;charset=utf-8",
        });
        await downloadBlob(blob, result.generateVenueServersSshConfig.filename);
        await onSubmit();
      }}
      {...others}
    />
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import RecordingBlockForm, { RecordingBlockFormFragment } from "./Form";

RecordingBlockEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function RecordingBlockEditDialog({ recordingBlockId, onSubmit, onClose }) {
  const recordingBlockUpdate = useActionFragment("recordingBlockUpdate");
  const [data, dataMeta] = useData(
    gql`
      query RecordingBlockEditDialog($recordingBlockId: ID!) {
        recordingBlock(id: $recordingBlockId) {
          id
          ...RecordingBlockFormFragment
        }
      }
      ${RecordingBlockFormFragment}
    `,
    { recordingBlockId },
  );

  return (
    <FormDialog
      header="Edit Recording Block"
      onSubmit={async (formData) => {
        await recordingBlockUpdate({
          input: {
            recordingBlockId,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
      onClose={onClose}
      loading={dataMeta.loading}
    >
      <RecordingBlockForm recordingBlock={data?.recordingBlock} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Plus } from "mdi-material-ui";
import React from "react";

import LiveStreamScorersForm from "./Form";

AddLiveStreamScorerDialog.useButtonProps = ({ liveStreamId }) => {
  const [data] = useData(
    gql`
      query AddLiveStreamScorerDialogButton($liveStreamId: ID) {
        liveStream(id: $liveStreamId) {
          id
          liveStreamScorer {
            id
          }
        }
      }
    `,
    { liveStreamId },
  );

  return {
    hidden: !data || !!data.liveStream?.liveStreamScorer,
    icon: <Plus />,
    content: "Enable Live Scorer",
  };
};

export default function AddLiveStreamScorerDialog({ liveStreamId = null, onSubmit, onClose }) {
  const addLiveStreamScorer = useActionFragment("addLiveStreamScorer", "liveStreamScorer { id }");

  return (
    <FormDialog
      header="Enable Live Scorer"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLiveStreamScorer({
          input: formData,
        });
        await onSubmit?.(result.addLiveStreamScorer.liveStreamScorer.id);
      }}
    >
      <LiveStreamScorersForm
        liveStreamScorers={[
          {
            liveStream: liveStreamId && { id: liveStreamId, __typename: "LiveStream" },
          },
        ]}
      />
    </FormDialog>
  );
}

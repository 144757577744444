import { gql } from "@apollo/client";
import FormSection from "controls/FormSection";
import MultiValuesField from "controls/MultiValuesField";
import DateInput from "inputs/DateInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const PeopleFormFragment = gql`
  fragment PeopleFormFragment on Person {
    id
    club {
      id
    }
    firstName
    lastName
    emailWithMask
    emailMasked
    gender
    birthdate
    isReferee
    anonymised
  }
`;

export default function PeopleForm({ people }) {
  return (
    <>
      {people?.every((p) => !p.id) && (
        <MultiValuesField
          name="club"
          defaultValues={people?.map((person) => person.club)}
          label="Club"
          input={<ReadminObjectInput optionsTypename="Club" />}
        />
      )}
      <MultiValuesField
        autoFocus
        name="firstName"
        defaultValues={people?.map((person) => person.firstName)}
        label="First Name"
        required
        input={<TextInput autoComplete="given-name" />}
      />
      <MultiValuesField
        name="lastName"
        defaultValues={people?.map((person) => person.lastName)}
        label="Last Name"
        input={<TextInput autoComplete="family-name" />}
      />
      <MultiValuesField
        name="email"
        defaultValues={people?.map((person) => person.emailWithMask)}
        label="Email"
        disabled={!!people?.find((p) => p.emailMasked)}
        input={<TextInput autoComplete="email" />}
      />
      <FormSection header="More Details">
        <MultiValuesField
          name="gender"
          defaultValues={people?.map((p) => p.gender)}
          label="Gender"
          input={<SelectInput options={["male", "female"]} />}
        />
        <MultiValuesField
          name="birthdate"
          defaultValues={people?.map((p) => p.birthdate)}
          label="Birthdate"
          input={<DateInput />}
        />
      </FormSection>
      <FormSection header="Settings">
        <MultiValuesField
          name="isReferee"
          defaultValues={people?.map((p) => p.isReferee)}
          label="This person is a referee"
          input={<SwitchInput />}
        />
        <MultiValuesField
          name="anonymised"
          defaultValues={people?.map((p) => p.anonymised)}
          label="Anonymise this person. This will hide their name from all games and teams they are associated with."
          input={<SwitchInput />}
        />
      </FormSection>
    </>
  );
}

import React, { useEffect, useState } from "react";
import { useComponentSize } from "react-use-size";

export default function ZoomPan({ aspectRatio = 1, zoom = 1, children, ...others }) {
  const size = useComponentSize();
  const height = size.width / aspectRatio;
  const childrenWidth = size.width * zoom;
  const childrenHeight = height * zoom;

  // try to retain current position when changing zoom
  const [position, positionSet] = useState([0.5, 0.5]);
  useEffect(() => {
    size.ref.current.scrollTop = position[0] * size.ref.current.scrollHeight - size.height / 2;
    size.ref.current.scrollLeft = position[1] * size.ref.current.scrollWidth - size.width / 2;
  }, [zoom]);

  return (
    <div
      ref={size.ref}
      {...others}
      style={{ height, overflow: "auto", ...others.style }}
      onScroll={() => {
        const position = [
          (size.ref.current.scrollTop + size.height / 2) / size.ref.current.scrollHeight,
          (size.ref.current.scrollLeft + size.width / 2) / size.ref.current.scrollWidth,
        ];
        positionSet(position);
      }}
    >
      {children(childrenWidth, childrenHeight)}
    </div>
  );
}

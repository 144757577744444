import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import NetworkDevicesForm, { NetworkDevicesFormFragment } from "./Form";

NetworkDevicesEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function NetworkDevicesEditDialog({ networkDeviceIds, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query NetworkDevicesEditDialog($networkDeviceIds: [ID!]!) {
        networkDevices(ids: $networkDeviceIds) {
          id
          ...NetworkDevicesFormFragment
        }
      }
      ${NetworkDevicesFormFragment}
    `,
    { networkDeviceIds },
  );
  const networkDevicesUpdate = useActionFragment("networkDevicesUpdate");

  return (
    <FormDialog
      header="Edit Network Devices"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await networkDevicesUpdate({
          input: {
            networkDeviceIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <NetworkDevicesForm networkDevices={data?.networkDevices} />
    </FormDialog>
  );
}

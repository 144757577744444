import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import VenueAdminsForm, { VenueAdminsFormFragment } from "./Form";

VenueAdminsEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function VenueAdminsEditDialog({ venueAdminIds, onClose, onSubmit }) {
  const venueAdminsUpdate = useActionFragment("venueAdminsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query VenueAdminsEditDialogQuery($venueAdminIds: [ID!]!) {
        venueAdmins(ids: $venueAdminIds) {
          id
          ...VenueAdminsFormFragment
        }
      }
      ${VenueAdminsFormFragment}
    `,
    {
      venueAdminIds,
    },
  );

  return (
    <FormDialog
      header="Edit Venue Admin"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await venueAdminsUpdate({
          input: {
            venueAdminIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <VenueAdminsForm venueAdmins={data?.venueAdmins} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import CamerasForm, { CamerasFormFragment } from "./Form";

CamerasEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    icon: <Pencil />,
    hidden: !isAdmin,
    content: "Edit Settings",
  };
};

export default function CamerasEditDialog({ cameraIds, onSubmit, onClose }) {
  const camerasUpdate = useActionFragment("camerasUpdate");
  const [data, dataMeta] = useData(
    gql`
      query CameraBatchEditDialogQuery($cameraIds: [ID!]!) {
        cameras(ids: $cameraIds) {
          id
          ...CamerasFormFragment
        }
      }
      ${CamerasFormFragment}
    `,
    { cameraIds },
  );

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Camera Settings"
      onClose={onClose}
      onSubmit={async (formData) => {
        await camerasUpdate({
          input: {
            cameraIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <CamerasForm cameras={data?.cameras} />
    </FormDialog>
  );
}

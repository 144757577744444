import { CircularProgress, Dialog, DialogContent, DialogTitle } from "@mui/material";
import noop from "helpers/noop";
import React, { useEffect } from "react";

export default function LoadingFormDialog({ loading = false, title = "Loading...", onSubmit = noop, onClose }) {
  useEffect(() => {
    if (!loading)
      Promise.resolve().then(async () => {
        try {
          await onSubmit();
        } finally {
          await onClose();
        }
      });
  }, [loading]);

  return (
    <Dialog open>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress variant="indeterminate" style={{ margin: "10px 50px" }} />
        </div>
      </DialogContent>
    </Dialog>
  );
}

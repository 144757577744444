import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useGotoUrl from "hooks/useGotoUrl";
import { Plus } from "mdi-material-ui";
import React from "react";
import IncidentForm from "readmin_pages/incidents/IncidentForm";

AddIncidentDialog.buttonProps = {
  icon: <Plus />,
  content: "Add Incident",
};

export default function AddIncidentDialog({ gameId, onSubmit = noop, onClose = noop }) {
  const addIncident = useActionFragment("addIncident", "incident { id readminUrl }");
  const gotoUrl = useGotoUrl();

  return (
    <FormDialog
      header="Add incident"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addIncident({
          input: formData,
        });
        onSubmit();
        gotoUrl(result.addIncident.incident.readminUrl);
      }}
    >
      <IncidentForm
        incident={{
          game: gameId && { id: gameId, __typename: "Game" },
        }}
      />
    </FormDialog>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import OriginalVideoRequestsForm from "./Form";

AddOriginalVideoRequestDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add Original Video Request",
  };
};

export default function AddOriginalVideoRequestDialog({ onSubmit, onClose }) {
  const addOriginalVideoRequest = useActionFragment("addOriginalVideoRequest", "originalVideoRequest { id }");

  return (
    <FormDialog
      header="Add Original Video Request"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addOriginalVideoRequest({ input: formData });
        await onSubmit?.(result.addOriginalVideoRequest.originalVideoRequest);
      }}
    >
      <OriginalVideoRequestsForm />
    </FormDialog>
  );
}

import { pick } from "lodash-es";
import React, { cloneElement, useMemo, useState } from "react";

function noop() {}

export default function Field({
  input,
  name,
  value,
  onChange = noop,
  defaultValue = input.type.defaultValue === undefined ? null : input.type.defaultValue,
  disabled = false,
  helperText,
  ...others
}) {
  const controled = value !== undefined;
  const controledInitial = useMemo(() => controled, []);
  if (controled && !controledInitial)
    throw new Error(`Cannot change '${name || others.label}' from uncontrolled to controlled.`);
  if (!controled && controledInitial)
    throw new Error(`Cannot change '${name || others.label}' from controlled to uncontrolled.`);

  if (!controled)
    return (
      <UncontrolledField
        input={input}
        name={name}
        defaultValue={defaultValue}
        onChange={onChange}
        disabled={disabled}
        helperText={helperText}
        {...others}
      />
    );

  return (
    <ControlledField
      name={name}
      input={input}
      value={value}
      onChange={onChange}
      disabled={disabled}
      helperText={helperText}
      {...others}
    />
  );
}

function UncontrolledField({ input, name, disabled, defaultValue, onChange, ...others }) {
  const [value, valueSet] = useState(defaultValue);

  return (
    <ControlledField
      input={input}
      name={name}
      value={value}
      onChange={(value) => {
        valueSet(value);
        onChange(value);
      }}
      disabled={disabled}
      {...others}
    />
  );
}

function ControlledField({ input, name, disabled, value, onChange, ...others }) {
  const {
    //
    toFormData = (value) => value,
  } = input.type;

  return (
    <>
      {cloneElement(input, {
        value,
        onChange,
        disabled,
        ...others,
      })}
      {name && !disabled && (
        <input
          type="hidden"
          name={name}
          value={JSON.stringify({ value: toFormData(value), ...pick(others, "label", "required") })}
        />
      )}
    </>
  );
}

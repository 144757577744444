import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import CameraModelsForm from "./Form";

AddCameraModelDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    icon: <Plus />,
    content: "Add Camera Model",
  };
};

export default function AddCameraModelDialog({ onClose, onSubmit }) {
  const addCameraModel = useActionFragment("addCameraModel", "cameraModel { id }");

  return (
    <FormDialog
      header="Add Camera Model"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addCameraModel({ input: formData });
        await onSubmit?.(result.addCameraModel.cameraModel);
      }}
    >
      <CameraModelsForm />
    </FormDialog>
  );
}

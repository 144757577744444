import { print as printGraphQL } from "graphql/language/printer";
import { get } from "lodash-es";

import ExtendedError from "./ExtendedError";

export default function getApolloErrors(apolloError) {
  const operation = apolloError.operation && {
    query: printGraphQL(apolloError.operation.query),
    variables: apolloError.operation.variables,
  };

  const errors = [];
  for (const graphQLError of apolloError.graphQLErrors || []) {
    const path = get(graphQLError, "path") || [];
    const extensions = get(graphQLError, "extensions");

    errors.push(
      new ExtendedError(graphQLError.message, {
        apolloErrorType: "GraphQLError",
        operation,
        path: path.join(" -> "),
        ...extensions,
      }),
    );
  }

  if (apolloError.networkError) {
    const { statusCode, bodyText, name, message } = apolloError.networkError;
    errors.push(
      new ExtendedError(message, {
        apolloErrorType: "NetworkError",
        operation,
        statusCode,
        bodyText,
        errorName: name,
      }),
    );
  }

  return errors;
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import AbortError from "helpers/AbortError";
import useShowMessage from "hooks/useShowMessage";
import TextInput from "inputs/TextInput";
import React from "react";

export default function EditRawJSONDialog({ onClose, onSubmit, value }) {
  const showMessage = useShowMessage();
  return (
    <FormDialog
      header="Raw JSON"
      onClose={onClose}
      onSubmit={async ({ json }) => {
        let valueNew = null;
        try {
          valueNew = JSON.parse(json);
        } catch (e) {
          showMessage(e.message, { header: "JSON Error", modal: true });
          throw new AbortError();
        }
        await onSubmit(valueNew);
      }}
    >
      <Field
        label="Raw JSON"
        name="json"
        required
        defaultValue={JSON.stringify(value, null, "  ")}
        input={<TextInput multiline />}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import IncidentForm, { IncidentFormFragment } from "./IncidentForm";

IncidentEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function IncidentEditDialog({ incidentId, onSubmit = noop, ...others }) {
  const [data, dataMeta] = useData(
    gql`
      query IncidentEditDialog($incidentId: ID!) {
        incident(id: $incidentId) {
          id
          ...IncidentFormFragment
        }
      }
      ${IncidentFormFragment}
    `,
    { incidentId },
  );

  const incidentUpdate = useActionFragment("incidentUpdate");

  return (
    <FormDialog
      header="Edit Incident"
      loading={dataMeta.loading}
      {...others}
      onSubmit={async (formData) => {
        await incidentUpdate({
          input: {
            incidentId,
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      {data && <IncidentForm incident={data.incident} />}
    </FormDialog>
  );
}

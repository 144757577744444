import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import DefinitionTable from "controls/DefinitionTable";
import Message from "controls/Message";
import useData from "hooks/useData";
import React, { cloneElement } from "react";

import ReadminObjectAttributesTable, { ReadminObjectAttributesTableFragment } from "./ReadminObjectAttributesTable";
import ReadminObjectsDialogToolbar from "./ReadminObjectsDialogToolbar";
import useReadminObjectTypedef from "./useReadminObjectTypedef";

ReadminAssociationDetailsTab.useTabOptions = ({ id, typename, associationName }) => {
  const readminObjectTypedef = useReadminObjectTypedef(typename, { loadAssociations: true });
  const association = readminObjectTypedef?.associations?.find((a) => a.name === associationName);
  const associationTypedef = useReadminObjectTypedef(association?.typename);

  const [data] = useData(
    gql`
      query ReadminAssociationDetailsTabOptions($id: ID!, $typename: String!, $associationName: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          readminAssociationObjectsAny(associationName: $associationName)
        }
      }
    `,
    {
      id,
      typename,
      associationName,
    },
    {
      // Do not have permission to view this type, skip querying
      skip: !associationTypedef,
    },
  );

  return {
    dot: !!data?.readminObject?.readminAssociationObjectsAny,
    // Hide the tab if there's no permission or association not available
    hidden: !associationTypedef || data?.readminObject?.readminAssociationObjectsAny === null,
  };
};

export default function ReadminAssociationDetailsTab({ id, typename, associationName, subAssociationTables }) {
  const readminObjectTypedef = useReadminObjectTypedef(typename, { loadAssociations: true });
  const association = readminObjectTypedef?.associations?.find((a) => a.name === associationName);
  const associationTypedef = useReadminObjectTypedef(association?.typename);

  const [data, dataMeta] = useData(
    gql`
      query ReadminAssociationDetailsTab($id: ID!, $typename: String!, $associationName: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          readminAssociationObjects(associationName: $associationName) {
            id
            ...ReadminObjectAttributesTableFragment
          }
        }
      }
      ${ReadminObjectAttributesTableFragment}
    `,
    {
      id,
      typename,
      associationName,
    },
    {
      // Do not have permission to view this type, skip querying
      skip: !associationTypedef,
    },
  );

  return (
    <>
      <ReadminObjectsDialogToolbar
        parentId={id}
        parentTypename={typename}
        typename={association?.typename}
        gotoReadminObjectUrl
        onSubmit={async () => {
          if (data) await dataMeta.refetch();
        }}
      />
      {data?.readminObject?.readminAssociationObjects.length === 0 && (
        <Message info content="There is no data to display." />
      )}
      {!data && (
        <Paper>
          <DefinitionTable />
        </Paper>
      )}
      {data?.readminObject?.readminAssociationObjects.map((readminAssociationObject) => (
        <Paper elevation={5} key={readminAssociationObject.id}>
          <ReadminObjectAttributesTable
            readminObject={readminAssociationObject}
            ignoreAssociationObjects={[data?.readminObject]}
            showMedia
            showheader
            showDialogsToolbar
            showLabels
            showLink
            onSubmit={() => dataMeta.refetch()}
            footers={subAssociationTables?.map((subAssociationTable, subAssociationTableIndex) => (
              <div
                key={subAssociationTableIndex}
                style={{
                  padding: 10,
                }}
              >
                {cloneElement(subAssociationTable, {
                  id: readminAssociationObject.id,
                  typename: readminAssociationObject.__typename,
                  ignoreAssociationObjects: [data?.readminObject, readminAssociationObject],
                })}
              </div>
            ))}
          />
        </Paper>
      ))}
    </>
  );
}

import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import DefinitionTable from "controls/DefinitionTable";
import { DialogToolbarButton } from "controls/DialogsToolbar";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import Toolbar, { ToolbarButton } from "controls/Toolbar";
import oauthAuthorise from "helpers/oauthAuthorise";
import useData from "hooks/useData";
import useEnforceAuth from "hooks/useEnforceAuth";
import useFormDialogs from "hooks/useFormDialogs";
import useShowMessage from "hooks/useShowMessage";
import { Link, Pencil } from "mdi-material-ui";
import React from "react";
import ReadminPage from "readmin_pages/ReadminPage";
import ReadminObjectsTable, { ReadminObjectsTableFragment } from "readmin_pages/shared/ReadminObjectsTable";

import ChangePasswordDialog from "./ChangePasswordDialog";
import { CurrentUserRemoveLiveStreamFbAuthDialog, CurrentUserRemoveLiveStreamYtAuthDialog } from "./dialogs";

export default function CurrentUserPage() {
  const showMessage = useShowMessage();
  const enforceAuth = useEnforceAuth();
  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const [data, dataMeta] = useData(gql`
    query CurrentUserPage {
      currentUser {
        id
        clubAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        venueAdmins {
          id
          ...ReadminObjectsTableFragment
        }
      }
    }
    ${ReadminObjectsTableFragment}
  `);

  return (
    <ReadminPage
      title="My Account"
      subheader="My Account in Glory League"
      toolbar={
        <Toolbar>
          <ToolbarButton
            icon={<Pencil />}
            content="Change Password"
            onClick={async () => {
              await formDialogsOpen(<ChangePasswordDialog />);
              await showMessage({
                header: "Password Changed",
                content: "Password changed successfully. You wil be prompted to login with the new password.",
                modal: true,
              });
              await enforceAuth.refetch();
            }}
          />
        </Toolbar>
      }
    >
      {formDialogs}
      {!!data?.currentUser?.clubAdmins.length && (
        <>
          <Message title="Club Admin Roles" content="You have administrative access to these clubs." />
          <Paper>
            <ReadminObjectsTable
              typename="ClubAdmin"
              rows={data?.currentUser?.clubAdmins}
              onSubmit={() => dataMeta.refetch()}
            />
          </Paper>
        </>
      )}
      {!!data?.currentUser?.venueAdmins.length && (
        <>
          <Message
            title="Club Admin Roles"
            content="You have administrative access to infrastructure of these venues."
          />
          <Paper>
            <ReadminObjectsTable
              header="Venue Admin Roles"
              typename="VenueAdmin"
              rows={data?.currentUser?.venueAdmins}
              onSubmit={() => dataMeta.refetch()}
            />
          </Paper>
        </>
      )}
      <FormSection header="Live Stream Facebook Account" defaultExpanded>
        <CurrentUserLiveStreamFbAuthStatus />
      </FormSection>
      <FormSection header="Live Stream Youtube Account" defaultExpanded>
        <CurrentUserLiveStreamYtAuthStatus />
      </FormSection>
    </ReadminPage>
  );
}

export function CurrentUserLiveStreamFbAuthStatus() {
  const [data, dataMeta] = useData(gql`
    query CurrentUserPage {
      currentUser {
        id
        clubAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        venueAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        liveStreamFbAuth {
          id
          name
          email
        }
      }
    }
    ${ReadminObjectsTableFragment}
  `);

  return (
    <>
      {data?.currentUser && !data?.currentUser?.liveStreamFbAuth && (
        <Message content="You have not connected Facebook account for live streaming." />
      )}
      {data?.currentUser?.liveStreamFbAuth && (
        <DefinitionTable
          style={{ margin: "0 -10px" }}
          rows={[
            ["Name", data.currentUser.liveStreamFbAuth.name],
            ["Email", data.currentUser.liveStreamFbAuth.email],
          ]}
        />
      )}
      <Toolbar>
        <ToolbarButton
          icon={<Link />}
          content={data?.currentUser?.liveStreamFbAuth ? "Re-connect Facebook Account" : "Connect Facebook Account"}
          onClick={() => oauthAuthorise("facebook_live_stream_auth")}
        />
        <DialogToolbarButton dialog={<CurrentUserRemoveLiveStreamFbAuthDialog />} onSubmit={() => dataMeta.refetch()} />
      </Toolbar>
    </>
  );
}

export function CurrentUserLiveStreamYtAuthStatus() {
  const [data, dataMeta] = useData(gql`
    query CurrentUserPage {
      currentUser {
        id
        clubAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        venueAdmins {
          id
          ...ReadminObjectsTableFragment
        }
        liveStreamYtAuth {
          id
          name
          email
        }
      }
    }
    ${ReadminObjectsTableFragment}
  `);

  return (
    <>
      {data?.currentUser && !data?.currentUser?.liveStreamYtAuth && (
        <Message content="You have not connected YouTube account for live streaming." />
      )}
      {data?.currentUser?.liveStreamYtAuth && (
        <DefinitionTable
          style={{ margin: "0 -10px" }}
          rows={[
            ["Name", data.currentUser.liveStreamYtAuth.name],
            ["Email", data.currentUser.liveStreamYtAuth.email],
          ]}
        />
      )}
      <Toolbar>
        <ToolbarButton
          icon={<Link />}
          content={data?.currentUser?.liveStreamYtAuth ? "Re-connect YouTube Account" : "Connect YouTube Account"}
          onClick={() => oauthAuthorise("youtube_live_stream_auth")}
        />
        <DialogToolbarButton dialog={<CurrentUserRemoveLiveStreamYtAuthDialog />} onSubmit={() => dataMeta.refetch()} />
      </Toolbar>
    </>
  );
}

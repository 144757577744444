import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import TextInput from "inputs/TextInput";
import React from "react";

export default function ChangePasswordDialog({ onSubmit, onClose }) {
  const changePassword = useActionFragment("changePassword");

  return (
    <FormDialog
      header="Change Password"
      onClose={onClose}
      onSubmit={async ({ newPassword, newPasswordConfirmation }) => {
        if (newPassword !== newPasswordConfirmation) {
          throw new Error("Passwords do not match");
        }
        await changePassword({ input: { newPassword } });
        await onSubmit?.();
      }}
    >
      <Field
        label="New Password"
        name="newPassword"
        required
        input={<TextInput type="password" autoComplete="new-password" />}
      />
      <Field
        label="Confirm New Password"
        name="newPasswordConfirmation"
        required
        input={<TextInput type="password" autoComplete="new-password" />}
      />
    </FormDialog>
  );
}

import makeReadminObjectsPage from "./makeReadminObjectsPage";

export const VenuesPage = makeReadminObjectsPage("Venue", "Venues");
export const NetworkDevicesPage = makeReadminObjectsPage("NetworkDevice", "Network Devices");
export const CitiesPage = makeReadminObjectsPage("City", "Cities");
export const CameraModelsPage = makeReadminObjectsPage("CameraModel", "Camera Models");
export const RecordingBlocksPage = makeReadminObjectsPage("RecordingBlock", "Recording Blocks");
export const VenueAdminsPage = makeReadminObjectsPage("VenueAdmin", "Venue Admins");
export const VideoCutAttemptsPage = makeReadminObjectsPage("VideoCutAttempt", "Video Cut Attemps");
export const VideoObjectsPage = makeReadminObjectsPage("VideoObject", "Video Objects");
export const ClubsPage = makeReadminObjectsPage("Club", "Clubs");
export const UsersPage = makeReadminObjectsPage("User", "Users");
export const MotioncropTestsPage = makeReadminObjectsPage("MotioncropTest", "Motioncrop Tests");
export const MotioncropTestReportsPage = makeReadminObjectsPage("MotioncropTestReport", "Motioncrop Test Reports");
export const OnsenPopupPromosPage = makeReadminObjectsPage("OnsenPopupPromo", "Onsen Popup Promos");
export const OpsWarningsPage = makeReadminObjectsPage("OpsWarning", "Operation Warning");
export const CamerasPage = makeReadminObjectsPage("Camera", "Cameras", {
  tableDefaultMode: "grid",
});
export const VenueServersPage = makeReadminObjectsPage("VenueServer", "Venue Servers");
export const VenueServerCommandsPage = makeReadminObjectsPage("VenueServerCommand", "Venue Server Commands");
export const VenueServerChefRunsPage = makeReadminObjectsPage("VenueServerChefRun", "Venue Server Chef Runs");
export const PotwVideosPage = makeReadminObjectsPage("PotwVideo", "POTW Videos");
export const PotwEntriesPage = makeReadminObjectsPage("PotwEntry", "POTW Entries");
export const PotwRatingsPage = makeReadminObjectsPage("PotwRating", "POTW Ratings");
export const EventVideosPage = makeReadminObjectsPage("EventVideo", "Event Videos");
export const PaperTrailVersionsPage = makeReadminObjectsPage("PaperTrailVersion", "Audit Events");
export const GdVenuesPage = makeReadminObjectsPage("GdVenue", "GameDay Venues");
export const GdAssociationsPage = makeReadminObjectsPage("GdAssociation", "GameDay Associations");
export const PlayHqOrganisationsPage = makeReadminObjectsPage("PlayHqOrganisation", "PlayHQ Organisations");

// ApiPartner pages
export const ApiPartnersPage = makeReadminObjectsPage("ApiPartner", "API Partners");
export const ApiPartnerApiOrganisationsPage = makeReadminObjectsPage("ApiOrganisation", "API Organisations");
export const ApiPartnerApiTeamsPage = makeReadminObjectsPage("ApiTeam", "API Teams");
export const ApiPartnerApiProfilesPage = makeReadminObjectsPage("ApiProfile", "API Profiles");
export const ApiPartnerApiCompetitionsPage = makeReadminObjectsPage("ApiCompetition", "API Competitions");
export const ApiPartnerApiCourtsPage = makeReadminObjectsPage("ApiCourt", "API Courts");
export const ApiPartnerApiGameSchedulesPage = makeReadminObjectsPage("ApiGameSchedule", "API Game Schedules");
export const ApiPartnerApiGraphqlRequestsPage = makeReadminObjectsPage("ApiGraphqlRequest", "API GraphQL Requests");

// Club pages
export const AdAssetsPage = makeReadminObjectsPage("AdAsset", "Ad Assets");
export const AdPresetsPage = makeReadminObjectsPage("AdPreset", "Ad Presets");
export const IncidentsPage = makeReadminObjectsPage("Incident", "Incidents");
export const RecurringRecordingOccurrencesPage = makeReadminObjectsPage(
  "RecurringRecordingOccurrence",
  "Scheduled Games",
);
export const GamesPage = makeReadminObjectsPage("Game", "Games");
export const VideosPage = makeReadminObjectsPage("Video", "Videos");
export const RecordingsPage = makeReadminObjectsPage("Recording", "Recordings");
export const CourtsPage = makeReadminObjectsPage("Court", "Courts");
export const PeoplePage = makeReadminObjectsPage("Person", "People");
export const PotwCompetitionsPage = makeReadminObjectsPage("PotwCompetition", "POTW Competitions");
export const PublicLeaguePagesPage = makeReadminObjectsPage("PublicLeaguePage", "Public League");
export const LeaguesPage = makeReadminObjectsPage("League", "Leagues");
export const TeamsPage = makeReadminObjectsPage("Team", "Teams");
export const TeamRegistrationsPage = makeReadminObjectsPage("TeamRegistration", "Team Registration Forms");
export const EventsPage = makeReadminObjectsPage("Event", "Highlights");
export const NotesPage = makeReadminObjectsPage("Note", "System Notes");
export const UsageReportPage = makeReadminObjectsPage("UsageReportEntry", "Leagues", {
  title: "Usage Report",
});
export const ClubAdminsPage = makeReadminObjectsPage("ClubAdmin", "Club Admins");
export const BetaFeaturesPage = makeReadminObjectsPage("BetaFeature", "Beta Features");
export const DeliveriesPage = makeReadminObjectsPage("Delivery", "Deliveries");
export const GameSubmissionsPage = makeReadminObjectsPage("GameSubmission", "Game Submissions");
export const SendgridEventsPage = makeReadminObjectsPage("SendgridEvent", "SendGrid Events");
export const ScorekeeperDevicesPage = makeReadminObjectsPage("ScorekeeperDevice", "Scorekeeper Devices");
export const ScorekeeperUsersPage = makeReadminObjectsPage("ScorekeeperUser", "Scorekeeper Users");
export const LiveStreamsPage = makeReadminObjectsPage("LiveStream", "Live Streams");
export const SurfacesPage = makeReadminObjectsPage("Surface", "Surfaces", {
  tableDefaultMode: "grid",
});
export const OriginalVideoRequestsPage = makeReadminObjectsPage("OriginalVideoRequest", "Original Video Requests");
export const DevicePortMappingsPage = makeReadminObjectsPage("DevicePortMapping", "Device Port Mappings");

import { ListItemIcon, ListItemText, MenuItem, TextField } from "@mui/material";
import { uniqBy } from "lodash-es";
import React, { useId, useMemo } from "react";

SelectInput.defaultValue = null;

export default function SelectInput({
  value,
  onChange,
  options = [],
  required = false,
  blankString = "(empty)",
  label,
  name,
  ...others
}) {
  const id = useId();

  options = options.map((option) => {
    if (option?.constructor === Object) return option;
    if (option?.constructor === Array) {
      const [text, value, others] = option;
      return {
        ...others,
        text,
        value,
      };
    }
    return {
      text: option,
      value: option,
    };
  });

  options = uniqBy(
    [
      {
        text: blankString,
        value: null,
      },
      ...options.map((option) => ({
        ...option,
      })),
    ],
    (o) => o.value,
  )
    .filter((option) => !!option.value || !required)
    .map((option) => ({
      ...option,
      valueStr: String(option.value),
    }));

  // add existing value to options if it's not there
  const existingValue = useMemo(() => value, []);
  if (existingValue && !options.find((o) => o.value === existingValue)) {
    options.push({
      text: `unexpected value (${existingValue})`,
      value: existingValue,
      valueStr: String(existingValue),
    });
  }

  const selectedOption = options.find((option) => option.value === value);

  return (
    <TextField
      id={id}
      select
      data-select-input
      fullWidth
      required={required}
      {...others}
      label={label}
      value={selectedOption?.valueStr ?? ""}
      onChange={(event) => {
        const selectedOptionNew = options.find((option) => option.valueStr === event.target.value);
        onChange(selectedOptionNew?.value || null);
      }}
    >
      {options.map((option) => (
        <MenuItem dense data-select-input-option key={option.value} value={option.value}>
          {option.icon && <ListItemIcon>{option.icon}</ListItemIcon>}
          <ListItemText
            primary={
              <>
                {option.avatar && (
                  <img
                    src={option.avatar}
                    alt="avatar"
                    style={{
                      width: "2em",
                      height: "2em",
                      objectFit: "contain",
                      verticalAlign: "middle",
                      marginRight: "0.5em",
                    }}
                  />
                )}
                {option.text}
              </>
            }
            secondary={option.description}
          />
        </MenuItem>
      ))}
    </TextField>
  );
}

import { gql } from "@apollo/client";
import Message from "controls/Message";
import VideoThumbnail from "controls/VideoThumbnail";
import { readminPageContext, timeZoneContext } from "helpers/contexts";
import useData from "hooks/useData";
import useEnforceAuth from "hooks/useEnforceAuth";
import useFormDialogs from "hooks/useFormDialogs";
import { LOCAL_TIMEZONE } from "hooks/useTimeZone";
import PublicForm from "public_pages/PublicForm";
import React, { cloneElement, useContext } from "react";
import { default as Helmet } from "react-helmet";

import ReadminMenu from "./ReadminMenu";
import ReadminFootor from "./shared/ReadminFooter";
import ReadminObjectViewDetailsDialog from "./shared/ViewDetailsDialog";

export default function ReadminPage({
  clubId,
  apiPartnerId,
  id,
  typename,
  title,
  toolbar,
  thumbnailUrl,
  videoUrl,
  thumbnail = (thumbnailUrl || videoUrl) && <VideoThumbnail videoUrl={videoUrl} thumbnailUrl={thumbnailUrl} />,
  subheader,
  labels,
  children,
}) {
  const [data] = useData(
    gql`
      query ReadminPage($clubId: ID, $apiPartnerId: ID) {
        club(id: $clubId) {
          id
          name
          timeZone
        }
        apiPartner(id: $apiPartnerId) {
          id
          name
          timeZone
        }
        currentUser {
          id
          isAdmin
        }
      }
    `,
    { clubId, apiPartnerId },
  );

  const { timeZone, timeZoneSet } = useContext(timeZoneContext);
  const defaultTimeZone = data?.club?.timeZone || data?.apiPartner?.timeZone || LOCAL_TIMEZONE;
  const enforceAuth = useEnforceAuth({ enforceAuthenticated: true });

  const [formDialogs, formDialogsOpen] = useFormDialogs();
  const openViewDetailsDialog = (to) =>
    to && formDialogsOpen(<ReadminObjectViewDetailsDialog id={to.id} typename={to.__typename} />);

  if (enforceAuth.loading) {
    return <PublicForm dense loading />;
  }

  return (
    <readminPageContext.Provider value={{ clubId, apiPartnerId, id, typename, openViewDetailsDialog }}>
      <timeZoneContext.Provider value={{ timeZone, timeZoneSet, defaultTimeZone }}>
        {formDialogs}
        {title && (
          <Helmet>
            <title>
              {[title, data?.club?.name, data?.apiPartner?.name, "Glory League"].filter(Boolean).join(" - ")}
            </title>
          </Helmet>
        )}
        <ReadminMenu clubId={clubId} apiPartnerId={apiPartnerId} />
        <div
          style={{
            padding: "10px 20px",
            display: "flex",
            flexFlow: "column nowrap",
            gap: 10,
          }}
        >
          <div
            style={{
              display: "flex",
              flexFlow: "row wrap",
              gap: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                gap: 10,
                flex: "1 1 auto",
              }}
            >
              <h1
                style={{
                  padding: 0,
                  margin: 0,
                  display: "flex",
                  flexFlow: "row wrap",
                  gap: 3,
                  alignItems: "flex-end",
                }}
              >
                {title}
                {labels}
              </h1>
              {subheader && <p style={{ padding: 0, margin: 0 }}>{subheader}</p>}
              {data?.currentUser?.isAdmin === false && (
                <Message
                  title="Need Help?"
                  content={
                    <>
                      Visit our dedicated{" "}
                      <a href="https://www.gloryleague.basketball/admin/welcome" target="_blank" rel="noreferrer">
                        admin support page
                      </a>{" "}
                      for guides, videos, and a brand new chat bot to assist you.
                      <br /> <br />
                      If further support is needed, please contact{" "}
                      <a href="mailto:adminsupport@gloryleague.com">adminsupport@gloryleague.com</a>. For player support
                      issues please direct your members to{" "}
                      <a href="mailto:support@gloryleague.com">support@gloryleague.com</a>.
                    </>
                  }
                />
              )}
              {toolbar}
            </div>
            {thumbnail &&
              cloneElement(thumbnail, {
                style: {
                  height: "5em",
                  aspectRatio: "16/9",
                  float: "right",
                  ...thumbnail.props.style,
                },
              })}
          </div>
          {children}
          <ReadminFootor />
        </div>
      </timeZoneContext.Provider>
    </readminPageContext.Provider>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import { Plus } from "mdi-material-ui";
import React from "react";

import LiveStreamYtOutputsForm from "./Form";

AddLiveStreamYtOutputDialog.useButtonProps = () => {
  return {
    icon: <Plus />,
    content: "Add YouTube Output",
  };
};

export default function AddLiveStreamYtOutputDialog({ liveStreamId = null, onSubmit, onClose }) {
  const addLiveStreamYtOutput = useActionFragment("addLiveStreamYtOutput", "liveStreamYtOutput { id }");

  return (
    <FormDialog
      header="Add Live Stream YouTube Output"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addLiveStreamYtOutput({
          input: formData,
        });
        await onSubmit?.(result.addLiveStreamYtOutput.liveStreamYtOutput.id);
      }}
    >
      <LiveStreamYtOutputsForm
        liveStreamYtOutputs={[
          {
            liveStream: liveStreamId && { id: liveStreamId, __typename: "LiveStream" },
          },
        ]}
      />
    </FormDialog>
  );
}

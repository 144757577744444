import { gql } from "@apollo/client";
import { Button, InputAdornment, TextField } from "@mui/material";
import FormDialog from "controls/FormDialog";
import Message from "controls/Message";
import useData from "hooks/useData";
import useShowMessage from "hooks/useShowMessage";
import { Clipboard, Link, LinkVariant } from "mdi-material-ui";
import React from "react";

PersonGetPlayerPortalLinkDialog.buttonProps = {
  icon: <Link />,
  content: "Get Player Portal Link",
};

export default function PersonGetPlayerPortalLinkDialog({ personId, ...others }) {
  const showMessage = useShowMessage();
  const [data] = useData(
    gql`
      query GetPlayerPortalLinkDialog($personId: ID!) {
        person(id: $personId) {
          id
          userOnsenAuthUrl
        }
      }
    `,
    { personId },
  );
  const url = data?.person.userOnsenAuthUrl;

  return (
    <FormDialog loading={!data} header="Get Player Portal Link" {...others}>
      <Message
        content={
          <>
            This link will login as this person into the Player Portal. It is recommended to use Incognito Mode to open
            this link.
          </>
        }
      />
      <TextField
        label="Player Portal Link"
        value={url}
        fullWidth
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <Button
                onClick={async () => {
                  await navigator.clipboard?.writeText(url);
                  await showMessage("URL copied to clipboard.", { modal: true });
                }}
              >
                <Clipboard />
                Copy
              </Button>
              <Button target="_blank" href={url}>
                <LinkVariant />
                Link
              </Button>
            </InputAdornment>
          ),
        }}
      />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import MultiValuesField from "controls/MultiValuesField";
import useLoadedImage from "helpers/useLoadedImage";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import RectInput from "inputs/RectInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import { Pencil } from "mdi-material-ui";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

MotioncropTestsEditDialog.useButtonProps = () => {
  return {
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function MotioncropTestsEditDialog({ motioncropTestIds, onClose, onSubmit }) {
  const motioncropTestsUpdate = useActionFragment("motioncropTestsUpdate");
  const [data, dataMeta] = useData(
    gql`
      query MotioncropTestsEdit($motioncropTestIds: [ID!]!) {
        motioncropTests(ids: $motioncropTestIds) {
          sourceVideo {
            id
          }
          name
          sport
          description
          testVideoUrl
          expectedRect
          expectedRectMs
        }
      }
    `,
    { motioncropTestIds },
  );

  let testVideoUrl, expectedRect, expectedRectMs;

  if (data?.motioncropTests.length === 1) {
    testVideoUrl = data.motioncropTests[0].testVideoUrl;
    expectedRect = data.motioncropTests[0].expectedRect;
    expectedRectMs = data.motioncropTests[0].expectedRectMs;
  }

  const [loadedTestVideo] = useLoadedImage(testVideoUrl);

  return (
    <FormDialog
      header="Edit Motioncrop Test"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await motioncropTestsUpdate({
          input: {
            motioncropTestIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <MultiValuesField
        disabled
        label="Source Video"
        defaultValues={data?.motioncropTests.map((m) => m.sourceVideo)}
        input={<ReadminObjectInput />}
      />
      <MultiValuesField
        name="name"
        label="Name"
        defaultValues={data?.motioncropTests.map((m) => m.name)}
        required
        input={<TextInput />}
      />
      <MultiValuesField
        name="sport"
        label="Sport"
        defaultValues={data?.motioncropTests.map((m) => m.sport)}
        required
        input={
          <SelectInput
            options={[
              { value: "basketball", text: "Basketball" },
              { value: "futsal", text: "Futsal" },
              { value: "netball", text: "Netball" },
              { value: "volleyball", text: "Volleyball" },
            ]}
          />
        }
      />
      <MultiValuesField
        name="active"
        label="Active"
        defaultValues={data?.motioncropTests.map((m) => m.active)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="description"
        label="Description"
        defaultValues={data?.motioncropTests.map((m) => m.description)}
        input={<TextInput multiline minRows={3} />}
      />
      {loadedTestVideo && (
        <Field
          name="expectedRect"
          label="Expected Rect"
          defaultValue={expectedRect}
          required
          input={
            <RectInput
              enableRawJSON
              aspectRatio={loadedTestVideo.videoWidth / loadedTestVideo.videoHeight}
              backgroundImageUrl={testVideoUrl}
              backgroundImageVideoTime={expectedRectMs / 1000}
            />
          }
        />
      )}
    </FormDialog>
  );
}

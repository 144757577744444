import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import UserForm, { UserFormFragment } from "./Form";

UserEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function UserEditDialog({ userId, onSubmit = noop, ...others }) {
  const [data, dataMeta] = useData(
    gql`
      query UserEditDialog($userId: ID) {
        user(id: $userId) {
          id
          ...UserFormFragment
        }
      }
      ${UserFormFragment}
    `,
    { userId },
  );

  const userUpdate = useActionFragment("userUpdate");

  return (
    <FormDialog
      header="Update user"
      loading={dataMeta.loading}
      onSubmit={async (formData) => {
        await userUpdate({
          input: {
            userId,
            ...formData,
          },
        });
        await onSubmit();
      }}
      {...others}
    >
      <UserForm user={data?.user} />
    </FormDialog>
  );
}

import { Button } from "@mui/material";
import React from "react";

export default function Toolbar({ children, ...others }) {
  return (
    <div {...others} style={{ display: "flex", flexFlow: "row wrap", alignItems: "center", gap: 10, ...others.style }}>
      {children}
    </div>
  );
}

export function ToolbarButton({ icon, content, hidden = false, onClick, disabled = false, target, href = "#" }) {
  if (hidden) return null;
  return (
    <Button
      variant="outlined"
      component="a"
      href={href}
      disabled={disabled}
      onClick={onClick}
      target={target}
      startIcon={icon}
    >
      {content}
    </Button>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Plus } from "mdi-material-ui";
import React from "react";

import OnsenPopupPromosForm from "./Form";

AddOnsenPopupPromoDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    content: "Add Onsen Popup Promo",
    icon: <Plus />,
  };
};

export default function AddOnsenPopupPromoDialog({ onClose, onSubmit }) {
  const addOnsenPopupPromo = useActionFragment("addOnsenPopupPromo", "onsenPopupPromo { id }");

  return (
    <FormDialog
      header="Add Onsen Popup Promo"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addOnsenPopupPromo({
          input: formData,
        });

        await onSubmit?.(result.addOnsenPopupPromo.onsenPopupPromo);
      }}
    >
      <OnsenPopupPromosForm />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import DataTableLabel from "controls/DataTableLabel";
import Queue from "helpers/Queue";
import { useIsMounted } from "hooks/useIsMounted";
import useLazyData from "hooks/useLazyData";
import React, { useEffect } from "react";

import ReadminLabelDetails, { ReadminLabelDetailsFragement } from "./ReadminLabelDetails";

export const ReadminObjectLabelsFragment = gql`
  fragment ReadminObjectLabelsFragment on ReadminObject {
    readminLabels {
      content
      detail
      type
      ...ReadminLabelDetailsFragement
    }
  }
  ${ReadminLabelDetailsFragement}
`;

const _dataFetchQueue = new Queue();

export default function ReadminObjectLabels({ id, typename, readminObject }) {
  const [dataFetch, data] = useLazyData(
    gql`
      query ReadminObjectLabels_${typename}($typename: String!, $id: ID!) {
        readminObject(typename: $typename, id: $id) {
          id
          ...ReadminObjectLabelsFragment
        }
      }
      ${ReadminObjectLabelsFragment}
    `,
    {
      typename,
      id,
    },
  );
  const skip = !!readminObject || !id || !typename;

  readminObject ||= data?.readminObject;
  const isMounted = useIsMounted();
  useEffect(() => {
    _dataFetchQueue.addTask({
      func: async () => {
        if (!isMounted.current || skip) return;
        await dataFetch();
      },
    });
  }, [skip]);

  return (
    <>
      {readminObject?.readminLabels?.map((label, labelIndex) => (
        <DataTableLabel
          key={labelIndex}
          label={[label.content, label.detail].filter(Boolean).join(": ")}
          description={label.description}
          detailedDescription={<ReadminLabelDetails label={label} />}
          type={label.type}
        />
      ))}
    </>
  );
}

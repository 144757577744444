import { gql } from "@apollo/client";
import { Button } from "@mui/material";
import Field from "controls/Field";
import FormDialog, { FormDialogButtons, FormDialogColumn, FormDialogColumns } from "controls/FormDialog";
import FormSection from "controls/FormSection";
import Message from "controls/Message";
import b64ToBlob from "helpers/b64ToBlob";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { progressBar } from "hooks/useProgressBar";
import NumberInput from "inputs/NumberInput";
import PolygonInput from "inputs/PolygonInput";
import SelectInput from "inputs/SelectInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import { Pencil } from "mdi-material-ui";
import React, { useLayoutEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";
import { useAsyncMemo } from "use-async-memo";

import SphereCameraPreview from "./SphereCameraPreview";

SurfaceEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    content: "Edit",
    icon: <Pencil />,
  };
};

export default function SurfaceEditDialog({ surfaceId, onSubmit, onClose }) {
  const [data, dataMeta] = useData(
    gql`
      query SurfaceEditDialog($surfaceId: ID!) {
        surface(id: $surfaceId) {
          id
          name
          camera {
            id
          }
          scaleInput
          motionArea
          projectionShape
          projectionInterpolation
          fovHorizontalDegrees
          fovVerticalDegrees
          pitchDegrees
          rollDegrees
          sphereCameraPitchDegrees
          sphereCameraFovHorizontalDegrees
          courtCorners
          maxZoom
          macroFrameVerticalBalance
          advertisingBillboardHeight
          advertisingBillboardAngle
          advertisingBillboardScrollSpeed
          advertisingBillboardAlwaysForeground
          advertisingBillboardBottomEdge
          advertisingCentreCourt
          netLeftPolygon
          netRightPolygon
          courtLength
          courtWidth
          cameraHeight
          cameraDistance
          canary
          encodeHighQualityOutputs
          encodeAudio
        }
      }
    `,
    { surfaceId },
  );
  const surfaceUpdate = useActionFragment("surfaceUpdate");

  const [video, videoSet] = useState(null);
  const [projectionShape, projectionShapeSet] = useState(null);
  const [fovHorizontalDegrees, fovHorizontalDegreesSet] = useState(null);
  const [fovVerticalDegrees, fovVerticalDegreesSet] = useState(null);
  const [pitchDegrees, pitchDegreesSet] = useState(null);
  const [rollDegrees, rollDegreesSet] = useState(null);
  const [sphereCameraPitchDegrees, sphereCameraPitchDegreesSet] = useState(null);
  const [sphereCameraFovHorizontalDegrees, sphereCameraFovHorizontalDegreesSet] = useState(null);

  useLayoutEffect(() => {
    if (data && !dataMeta.loading) {
      projectionShapeSet(data.surface.projectionShape);
      fovHorizontalDegreesSet(data.surface.fovHorizontalDegrees);
      fovVerticalDegreesSet(data.surface.fovVerticalDegrees);
      pitchDegreesSet(data.surface.pitchDegrees);
      rollDegreesSet(data.surface.rollDegrees);
      sphereCameraPitchDegreesSet(data.surface.sphereCameraPitchDegrees);
      sphereCameraFovHorizontalDegreesSet(data.surface.sphereCameraFovHorizontalDegrees);
    }
  }, [dataMeta.loading]);
  const cameraId = data?.surface.camera.id;
  const sphereCameraConfigPresent =
    fovHorizontalDegrees !== null &&
    fovVerticalDegrees !== null &&
    pitchDegrees !== null &&
    rollDegrees !== null &&
    sphereCameraPitchDegrees !== null;

  const cameraPreviewFilterChain = useActionFragment("cameraPreviewFilterChain", "jpgImageBase64");

  const filterChainPreviewUrl = useAsyncMemo(
    () =>
      progressBar(async () => {
        if (!cameraId) return null;
        const result = await cameraPreviewFilterChain({ input: { cameraId, videoId: video?.id } });
        const jpgImageBase64 = result.cameraPreviewFilterChain.jpgImageBase64;

        const blob = b64ToBlob(jpgImageBase64);
        const url = URL.createObjectURL(blob);
        return url;
      }).catch(() => null),
    [cameraId, video?.id],
  );

  return (
    <FormDialog
      header="Surface Encoding Config"
      loading={dataMeta.loading}
      onClose={onClose}
      maxWidth="lg"
      onSubmit={async (formData) => {
        await surfaceUpdate({ input: { surfaceId, ...formData } });
        await onSubmit?.();
      }}
    >
      <Field name="name" label="Name" defaultValue={data?.surface.name} input={<TextInput />} required />
      <Field
        name="scaleInput"
        label="Scale Input"
        defaultValue={data?.surface.scaleInput}
        input={<SwitchInput />}
        helperText="Allow input video to be downsized when processing"
      />
      <Field
        label="Motion Area"
        name="motionArea"
        defaultValue={data?.surface.motionArea}
        input={<PolygonInput backgroundUrl={filterChainPreviewUrl} stroke fill />}
      />
      <Field
        label="Projection Shape"
        name="projectionShape"
        value={projectionShape}
        onChange={projectionShapeSet}
        input={
          <SelectInput
            options={[
              ["Plane", "plane", { description: "(default) for bullet cameras." }],
              ["Sphere", "sphere", { description: "for 180 or dome cameras." }],
            ]}
          />
        }
      />
      <Field
        name="projectionInterpolation"
        label="Projection Interpolation"
        defaultValue={data?.surface.projectionInterpolation}
        input={<SelectInput options={["nearest", "bilinear", "area"]} />}
        helperText={
          <>
            Defaults to nearest neighbor for bullet cameras, and bilinear for 180 cameras.
            <br />
            Bilinear may be required for some bullet cameras to fix visual artifacts, but in most cases nearest neighbor
            is sufficient.
            <br />
            Area is slower, but further reduces moire effects compared to bilinear.
          </>
        }
      />
      {projectionShape === "sphere" && (
        <>
          <FormDialogButtons>
            <Button
              variant="outlined"
              disabled={sphereCameraConfigPresent}
              onClick={() => {
                fovHorizontalDegreesSet(180);
                fovVerticalDegreesSet(90);
                pitchDegreesSet(-40);
                rollDegreesSet(0);
                sphereCameraPitchDegreesSet(-25);
              }}
            >
              Initialise Config for 180 Camera
            </Button>
            <Button
              variant="outlined"
              disabled={sphereCameraConfigPresent}
              onClick={() => {
                fovHorizontalDegreesSet(130);
                fovVerticalDegreesSet(90);
                pitchDegreesSet(-20);
                rollDegreesSet(0);
                sphereCameraPitchDegreesSet(-20);
              }}
            >
              Initialise Config for Dome Camera
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                fovHorizontalDegreesSet(null);
                fovVerticalDegreesSet(null);
                pitchDegreesSet(null);
                rollDegreesSet(null);
                sphereCameraPitchDegreesSet(null);
                sphereCameraFovHorizontalDegreesSet(null);
              }}
            >
              Clear Values
            </Button>
          </FormDialogButtons>
          <FormDialogColumns>
            <FormDialogColumn span={2}>
              {!sphereCameraConfigPresent && (
                <Message content="Spherical camera config not present. Please fill in the fields below to enable spherical camera preview." />
              )}
              {sphereCameraConfigPresent && (
                <SphereCameraPreview
                  motioncropPreviewUrl={filterChainPreviewUrl}
                  fovHorizontalDegrees={fovHorizontalDegrees}
                  fovVerticalDegrees={fovVerticalDegrees}
                  pitchDegrees={pitchDegrees}
                  rollDegrees={rollDegrees}
                  sphereCameraPitchDegrees={sphereCameraPitchDegrees}
                  sphereCameraFovHorizontalDegrees={sphereCameraFovHorizontalDegrees}
                />
              )}
            </FormDialogColumn>
            <FormDialogColumn>
              <Field
                name="fovHorizontalDegrees"
                label="FOV Horizontal"
                value={fovHorizontalDegrees}
                onChange={fovHorizontalDegreesSet}
                required
                input={<NumberInput min={0} max={360} unit="degrees" />}
              />
              <Field
                name="fovVerticalDegrees"
                label="FOV Vertical"
                value={fovVerticalDegrees}
                onChange={fovVerticalDegreesSet}
                required
                input={<NumberInput min={0} max={360} unit="degrees" />}
              />
              <Field
                name="pitchDegrees"
                label="Pitch"
                value={pitchDegrees}
                onChange={pitchDegreesSet}
                required
                input={<NumberInput min={-90} max={90} step={0.01} unit="degrees" />}
              />
              <Field
                name="rollDegrees"
                label="Roll"
                value={rollDegrees}
                onChange={rollDegreesSet}
                required
                input={<NumberInput min={-90} max={90} step={0.01} unit="degrees" />}
              />
              <Field
                name="sphereCameraPitchDegrees"
                label="Sphere Camera Pitch"
                value={sphereCameraPitchDegrees}
                onChange={sphereCameraPitchDegreesSet}
                required
                input={<NumberInput min={-90} max={90} step={0.01} unit="degrees" />}
              />
              <Field
                name="sphereCameraFovHorizontalDegrees"
                label="Sphere Camera FOV Horizontal"
                value={sphereCameraFovHorizontalDegrees}
                onChange={sphereCameraFovHorizontalDegreesSet}
                input={<NumberInput min={0} max={90} unit="degrees" initValue={50} />}
                helperText="optional, default as 50"
              />
            </FormDialogColumn>
          </FormDialogColumns>
        </>
      )}
      <Field
        name="courtCorners"
        label="Court Corners"
        defaultValue={data?.surface.courtCorners}
        input={<PolygonInput backgroundUrl={filterChainPreviewUrl} />}
        helperText="4 points: top left, top right, bottom left, bottom right"
      />
      <Field
        name="maxZoom"
        label="Max Zoom"
        defaultValue={data?.surface.maxZoom}
        input={<NumberInput step={0.1} min={0} max={5} />}
      />
      <Field
        name="macroFrameVerticalBalance"
        label="Macro Frame Vertical Balance"
        defaultValue={data?.surface.macroFrameVerticalBalance}
        input={<NumberInput step={0.1} min={0} max={1} />}
        helperText="Where to favour placing macro frames vertically. 0.0 favours the top, 1.0 favours the bottom."
      />
      <FormSection header="Advertising">
        <Field
          name="advertisingBillboardHeight"
          label="Advertising Billboard Height"
          defaultValue={data?.surface.advertisingBillboardHeight}
          input={<NumberInput unit="pixels" min={0} max={120} initValue={80} />}
        />
        <Field
          name="advertisingBillboardAngle"
          label="Advertising Billboard Angle"
          defaultValue={data?.surface.advertisingBillboardAngle}
          input={<NumberInput unit="??" step={0.01} min={0} max={1} />}
        />
        <Field
          name="advertisingBillboardScrollSpeed"
          label="Advertising Billboard Scroll Speed"
          defaultValue={data?.surface.advertisingBillboardScrollSpeed}
          input={<NumberInput unit="??" step={0.01} min={0} max={1} />}
        />
        <Field
          name="advertisingBillboardAlwaysForeground"
          label="Advertising Billboard Always Foreground"
          defaultValue={data?.surface.advertisingBillboardAlwaysForeground}
          input={<SwitchInput />}
        />
        <Field
          name="advertisingBillboardBottomEdge"
          label="Advertising Billboard Bottom Edge"
          defaultValue={data?.surface.advertisingBillboardBottomEdge}
          input={<PolygonInput backgroundUrl={filterChainPreviewUrl} stroke />}
          helperText="n points (usually ~5) along bottom edge of billboard advertising (left to right)"
        />
        <Field
          name="advertisingCentreCourt"
          label="Advertising Centre Court"
          defaultValue={data?.surface.advertisingCentreCourt}
          input={<PolygonInput backgroundUrl={filterChainPreviewUrl} stroke fill />}
          helperText="4 poirts defining the centre court. (TL, TR, BR, BL)"
        />
      </FormSection>
      <Field
        name="netLeftPolygon"
        label="Net Left Polygon"
        defaultValue={data?.surface.netLeftPolygon}
        input={<PolygonInput backgroundUrl={filterChainPreviewUrl} stroke fill />}
      />
      <Field
        name="netRightPolygon"
        label="Net Right Polygon"
        defaultValue={data?.surface.netRightPolygon}
        input={<PolygonInput backgroundUrl={filterChainPreviewUrl} stroke fill />}
      />
      <Field
        name="courtLength"
        label="Court Length"
        defaultValue={data?.surface.courtLength}
        input={<NumberInput unit="metres" step={0.01} min={5} max={40} />}
      />
      <Field
        name="courtWidth"
        label="Court Width"
        defaultValue={data?.surface.courtWidth}
        input={<NumberInput unit="metres" step={0.01} min={5} max={20} />}
      />
      <Field
        name="cameraHeight"
        label="Camera Height"
        defaultValue={data?.surface.cameraHeight}
        input={<NumberInput unit="metres" step={0.01} />}
      />
      <Field
        name="cameraDistance"
        label="Camera Distance"
        defaultValue={data?.surface.cameraDistance}
        input={<NumberInput unit="metres" step={0.01} />}
      />
      <Field
        name="canary"
        label="Canary"
        defaultValue={data?.surface.canary}
        input={<SwitchInput />}
        helperText="Encode using canary version of VC"
      />
      <Field
        name="encodeHighQualityOutputs"
        label="Encode High Quality Outputs"
        defaultValue={data?.surface.encodeHighQualityOutputs}
        input={<SwitchInput />}
      />
      <Field
        name="encodeAudio"
        label="Encode Audio"
        defaultValue={data?.surface.encodeAudio}
        input={<SwitchInput />}
        helperText="Audio is included in encoded games and events if a microphone is installed."
      />
      <Field
        label="Preview Using Video"
        value={video}
        onChange={videoSet}
        input={<ReadminObjectInput optionsTypename="Video" optionsFilters={{ for_camera_id: cameraId }} />}
        helperText={
          <>Select a video to preview instead of using live camera feed, this is not saved as surface configuration</>
        }
      />
    </FormDialog>
  );
}

import useFormDialogs from "hooks/useFormDialogs";
import React from "react";

import Toolbar, { ToolbarButton } from "./Toolbar";

export default function DialogsToolbar({ dialogs = [], onSubmit, ...others }) {
  return (
    <Toolbar {...others}>
      {dialogs.map((dialog, dialogIndex) => (
        <DialogToolbarButton key={dialogIndex} dialog={dialog} onSubmit={onSubmit} />
      ))}
    </Toolbar>
  );
}

export function DialogToolbarButton({ dialog, onSubmit }) {
  const buttonProps = {
    content: dialog.type.name || "UNKNOWN DIALOG",
    ...dialog.type.buttonProps,
    ...(dialog.type.useButtonProps && dialog.type.useButtonProps(dialog.props)),
  };
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <>
      {formDialogs}
      <ToolbarButton
        {...buttonProps}
        onClick={async () => {
          const result = await formDialogsOpen(dialog);
          await onSubmit?.(result);
        }}
      />
    </>
  );
}

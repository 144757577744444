import React from "react";

export default function FooterText() {
  return (
    <>
      Copyright © {window.YEAR} Glory League Stats
      <a href="/" style={{ margin: "0 1em" }}>
        more...
      </a>
      <br />
      protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      <br />
    </>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import CameraModelsForm, { CameraModelsFormFragment } from "./Form";

CameraModelsEditDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();
  return {
    hidden: !isAdmin,
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function CameraModelsEditDialog({ cameraModelIds, onClose, onSubmit }) {
  const [data, dataMeta] = useData(
    gql`
      query CameraModelsEditDialog($cameraModelIds: [ID!]!) {
        cameraModels(ids: $cameraModelIds) {
          id
          ...CameraModelsFormFragment
        }
      }
      ${CameraModelsFormFragment}
    `,
    { cameraModelIds },
  );
  const cameraModelsUpdate = useActionFragment("cameraModelsUpdate");

  return (
    <FormDialog
      loading={dataMeta.loading}
      header="Edit Camera Model"
      onClose={onClose}
      onSubmit={async (formData) => {
        await cameraModelsUpdate({
          input: {
            cameraModelIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <CameraModelsForm cameraModels={data?.cameraModels} />
    </FormDialog>
  );
}

import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useIsAdmin from "hooks/useIsAdmin";
import { Pencil } from "mdi-material-ui";
import React from "react";

import CourtsForm from "./Form";

AddCourtDialog.useButtonProps = () => {
  const isAdmin = useIsAdmin();

  return {
    hidden: !isAdmin,
    icon: <Pencil />,
    content: "Add Court",
  };
};

export default function AddCourtDialog({ clubId, cameraId, venueId, onSubmit, onClose }) {
  const addCourt = useActionFragment("addCourt", "court { id }");

  return (
    <FormDialog
      header="Add Court"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await addCourt({
          input: {
            ...formData,
          },
        });
        await onSubmit?.(result.addCourt.court);
      }}
    >
      <CourtsForm
        courts={[
          {
            club: clubId && { id: clubId, __typename: "Club" },
            camera: cameraId && { id: cameraId, __typename: "Camera" },
            venue: venueId && { id: venueId, __typename: "Venue" },
          },
        ]}
      />
    </FormDialog>
  );
}

import { IconButton, InputAdornment, TextField } from "@mui/material";
import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useFormDialogs from "hooks/useFormDialogs";
import useShowMessage from "hooks/useShowMessage";
import { ClipboardText, Refresh } from "mdi-material-ui";
import React, { useId } from "react";

import NumberInput from "./NumberInput";
import SelectInput from "./SelectInput";

PasswordInput.defaultValue = "";

export default function PasswordInput({ value, onChange, ...others }) {
  const id = useId();
  const showMessage = useShowMessage();
  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <>
      {formDialogs}
      <TextField
        fullWidth
        {...others}
        id={id}
        value={value || ""}
        onChange={(event) => onChange(event.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={async () => {
                  await navigator.clipboard.writeText(value || "");
                  await showMessage("Password copied to clipboard", { modal: true });
                }}
              >
                <ClipboardText />
              </IconButton>
              <IconButton
                onClick={async () => {
                  const passphrase = await formDialogsOpen(<GeneratePassphraseDialog />);
                  onChange(passphrase);
                }}
              >
                <Refresh />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

function GeneratePassphraseDialog({ onClose, onSubmit }) {
  const generatePassphrase = useActionFragment("generatePassphrase", "passphrase");
  return (
    <FormDialog
      header="Generate Passphrase"
      onClose={onClose}
      onSubmit={async (formData) => {
        const result = await generatePassphrase({
          input: {
            ...formData,
          },
        });
        await onSubmit?.(result.generatePassphrase.passphrase);
      }}
    >
      <Field
        name="numberOfWords"
        label="Number of Words"
        defaultValue={3}
        required
        input={<NumberInput min={1} max={8} unit="words" />}
      />
      <Field
        name="separator"
        label="Separator"
        defaultValue="-"
        required
        input={
          <SelectInput
            options={[
              ["- (dash)", "-"],
              ["_ (underscore)", "_"],
              [" (space)", " "],
            ]}
          />
        }
      />
    </FormDialog>
  );
}

import { MenuItem, TextField } from "@mui/material";
import React from "react";

export default function ScopesFilter({ scopes = [], value, onChange, blankString = "(all)" }) {
  scopes = scopes
    .map((scope) => {
      if (scope?.constructor === Object) return scope;
      if (scope?.constructor === Array) {
        const [text, value] = scope;
        return { text, value };
      }
      return {
        text: String(scope),
        value: scope,
      };
    })
    .map(({ value, text, ...scope }) => ({
      ...scope,
      value,
      text,
    }));

  return (
    <TextField
      data-scopes-filter
      size="small"
      select
      label="Scopes"
      InputLabelProps={{ shrink: true }}
      value={value || ""}
      onChange={(event) => onChange(event.target.value || null)}
      inputProps={{
        displayEmpty: true,
        renderValue: (value) => value || blankString,
      }}
    >
      <MenuItem data-scope value="" component="a" href="#">
        {blankString}
      </MenuItem>
      {scopes.map((scope) => (
        <MenuItem data-scope key={scope.value} value={scope.value} component="a" href="#">
          {scope.text}
        </MenuItem>
      ))}
    </TextField>
  );
}

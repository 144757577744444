import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const AdAssetsDeleteDialog = makeConfirmActionDialog({
  mutationName: "adAssetsDelete",
  action: "Delete Ad Asset",
  paramName: "adAssetIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Delete",
    };
  },
});

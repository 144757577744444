import { gql } from "@apollo/client";
import { Paper } from "@mui/material";
import DataTable from "controls/DataTable";
import ReadminLink, { ReadminLinkFragment } from "controls/ReadminLink";
import { VideoThumbnailLoad } from "controls/VideoThumbnail";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import React from "react";

AdAssetCourtPreviewTab.useTabOptions = ({ adAssetId }) => {
  const [data] = useData(
    gql`
      query AdAssetCourtPreviewTab($adAssetId: ID!) {
        adAsset(id: $adAssetId) {
          id
          canPreviewMotioncrop
        }
      }
    `,
    { adAssetId },
  );

  return {
    hidden: !data?.adAsset?.canPreviewMotioncrop,
  };
};

export default function AdAssetCourtPreviewTab({ adAssetId }) {
  const [data, dataMeta] = useData(
    gql`
      query AdAssetCourtPreviewTab($adAssetId: ID!) {
        adAsset(id: $adAssetId) {
          id
          club {
            id
            courts {
              id
              advertisingActive
              ...ReadminLinkFragment
            }
          }
        }
      }
      ${ReadminLinkFragment}
    `,
    { adAssetId },
  );
  const adAssetPreviewMotioncrop = useActionFragment("adAssetPreviewMotioncrop", "jpgImageBase64");

  return (
    <>
      <Paper>
        <DataTable
          defaultMode="grid"
          columns={["Court"]}
          rows={data?.adAsset.club.courts.map((court) => ({
            key: court.id,
            thumbnail: (
              <VideoThumbnailLoad
                loadCallback={
                  !dataMeta.loading &&
                  data &&
                  (async () => {
                    if (!court.advertisingActive) throw new Error("Advertising is disabled on this court.");

                    const result = await adAssetPreviewMotioncrop({
                      input: {
                        adAssetId,
                        courtId: court.id,
                      },
                    });

                    const jpgImageBase64 = result.adAssetPreviewMotioncrop.jpgImageBase64;

                    if (!jpgImageBase64) throw new Error("No video available for preview.");

                    return { base64: jpgImageBase64, type: "image/jpeg" };
                  })
                }
              />
            ),
            cells: [<ReadminLink to={court} />],
          }))}
        />
      </Paper>
    </>
  );
}

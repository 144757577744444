import LoadingFormDialog from "controls/LoadingFormDialog";
import { useActionFragment } from "hooks/useAction";
import useGotoUrl from "hooks/useGotoUrl";
import useShowMessage from "hooks/useShowMessage";
import { Video } from "mdi-material-ui";
import React from "react";

EventsDownloadVideoDialog.useButtonProps = () => {
  return {
    icon: <Video />,
    content: "Download Video",
  };
};

export default function EventsDownloadVideoDialog({ eventIds, onClose, onSubmit }) {
  const eventsGetStitchedUrl = useActionFragment("eventsGetStitchedUrl", "stitchedUrl");
  const gotoUrl = useGotoUrl();
  const showMessage = useShowMessage();

  return (
    <LoadingFormDialog
      onClose={onClose}
      onSubmit={async () => {
        const result = await eventsGetStitchedUrl({
          input: {
            eventIds,
          },
        });
        const { stitchedUrl } = result.eventsGetStitchedUrl;
        if (stitchedUrl) await gotoUrl(stitchedUrl);
        else await showMessage({ content: "There is no video to download.", modal: true });
        await onSubmit?.();
      }}
      header="Download Video"
    />
  );
}

import { useLazyQuery } from "@apollo/client";
import Message from "controls/Message";
import AbortError from "helpers/AbortError";
import apolloClient from "helpers/apolloClient";
import getApolloErrors from "helpers/getApolloErrors";
import React from "react";

import useProgressBar from "./useProgressBar";
import useShowMessage from "./useShowMessage";

export default function useLazyData(query, variables, { ...options } = {}) {
  const showMessage = useShowMessage();
  const [execute, { data, ...others }] = useLazyQuery(query, {
    variables,
    client: apolloClient,
    fetchPolicy: "cache-and-network",
    errorPolicy: "none",
    partialRefetch: true,
    ...options,
  });

  const { loading } = others;
  useProgressBar(loading);

  const dataFetch = async (variables, options) => {
    const result = await execute({ variables, ...options });
    if (result.error) {
      const errors = getApolloErrors(result.error);
      for (const error of errors) {
        if (error.apolloErrorType === "GraphQLError")
          // eslint-disable-next-line no-console
          console.error(`[GraphQLError/${error.path}]`, error.message);
      }
      await showMessage("We are unable to complete request", {
        modal: true,
        header: "Unable to Complete Request",
        content: (
          <>
            We are unable to complete request for the reason(s) listed below:
            <ul>
              {errors.map((error, errorIndex) => (
                <li key={errorIndex}>{error.message}</li>
              ))}
            </ul>
            <Message
              content={
                <>
                  If you think this is an error please notify us at{" "}
                  <a href="mailto:support@gloryleague.com">support@gloryleague.com</a> with details and screenshot.
                </>
              }
            />
          </>
        ),
      });
      throw new AbortError();
    }
    return result.data;
  };

  return [dataFetch, data, others];
}

import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const LiveStreamScorersRemoveDialog = makeConfirmActionDialog({
  mutationName: "liveStreamScorersRemove",
  action: "Remove Live Scorer",
  paramName: "liveStreamScorerIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove Live Scorer",
    };
  },
});

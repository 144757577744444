import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import noop from "helpers/noop";
import { useActionFragment } from "hooks/useAction";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import { Plus } from "mdi-material-ui";
import React from "react";

AddIncidentNoteDialog.useButtonProps = ({ incidentId }) => {
  return {
    hidden: !incidentId,
    icon: <Plus />,
    content: "Add Note",
  };
};

export default function AddIncidentNoteDialog({ incidentId, onSubmit = noop, onClose = noop }) {
  const incidentAddIncidentNote = useActionFragment("incidentAddIncidentNote");

  return (
    <FormDialog
      onClose={onClose}
      header="Add Incident Note"
      onSubmit={async (formData) => {
        await incidentAddIncidentNote({
          input: {
            incidentId,
            ...formData,
          },
        });
        await onSubmit();
      }}
    >
      <Field
        name="category"
        label="Category"
        required
        input={
          <SelectInput
            options={[
              ["Note", "note"],
              ["Document", "document"],
            ]}
          />
        }
      />
      <Field name="text" label="Text" required input={<TextInput multiline minRows={5} />} />
    </FormDialog>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import PublicLeaguePagesForm, { PublicLeaguePagesFormFragment } from "./Form";

PublicLeaguePagesEditDialog.buttonProps = {
  icon: <Pencil />,
  content: "Edit",
};

export default function PublicLeaguePagesEditDialog({ publicLeaguePageIds, onClose, onSubmit }) {
  const publicLeaguePagesUpdate = useActionFragment("publicLeaguePagesUpdate");
  const [data, dataMeta] = useData(
    gql`
      query PublicLeaguePagesEditDialog($publicLeaguePageIds: [ID!]!) {
        publicLeaguePages(ids: $publicLeaguePageIds) {
          id
          ...PublicLeaguePagesFormFragment
        }
      }
      ${PublicLeaguePagesFormFragment}
    `,
    { publicLeaguePageIds },
  );

  return (
    <FormDialog
      header="Edit Public League Page"
      onClose={onClose}
      loading={dataMeta.loading}
      onSubmit={async (formData) => {
        await publicLeaguePagesUpdate({
          input: {
            publicLeaguePageIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <PublicLeaguePagesForm publicLeaguePages={data?.publicLeaguePages} />
    </FormDialog>
  );
}

import Field from "controls/Field";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import DateInput from "inputs/DateInput";
import TextInput from "inputs/TextInput";
import { Plus } from "mdi-material-ui";
import React from "react";

AddRecordingBlockExceptionDateDialog.useButtonProps = ({ recordingBlockId }) => {
  return {
    //
    hidden: !recordingBlockId,
    icon: <Plus />,
    content: "Add",
  };
};

export default function AddRecordingBlockExceptionDateDialog({ recordingBlockId, onSubmit, onClose }) {
  const addRecordingBlockExceptionDate = useActionFragment("addRecordingBlockExceptionDate");

  return (
    <FormDialog
      header="Add Exception Date"
      onClose={onClose}
      onSubmit={async (formData) => {
        await addRecordingBlockExceptionDate({
          input: {
            recordingBlock: { id: recordingBlockId, typename: "RecordingBlock" },
            ...formData,
          },
        });

        await onSubmit?.();
      }}
    >
      <Field name="name" label="Name" required input={<TextInput />} />
      <Field name="date" label="Date" required input={<DateInput />} />
    </FormDialog>
  );
}

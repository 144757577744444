import { gql } from "@apollo/client";
import VideoThumbnail from "controls/VideoThumbnail";
import useData from "hooks/useData";
import React from "react";

import READMIN_THUMBNAIL_COMPONENTS from "./READMIN_OBJECT_THUMBNAIL_COMPONENTS";

export default function ReadminObjectThumbnail({ id, typename, ...others }) {
  const ThumbnailComponent = READMIN_THUMBNAIL_COMPONENTS[typename];

  const [data] = useData(
    gql`
      query ReadminObjectThumbnail($id: ID!, $typename: String!) {
        readminObject(id: $id, typename: $typename) {
          id
          __typename
          readminThumbnailUrl
          readminVideoUrl
        }
      }
    `,
    { id, typename },
    { skip: !!ThumbnailComponent || !id || !typename },
  );

  if (ThumbnailComponent) return <ThumbnailComponent id={id} typename={typename} {...others} />;

  if (data?.readminObject?.readminThumbnailUrl || data?.readminObject?.readminVideoUrl)
    return (
      <VideoThumbnail
        videoUrl={data.readminObject.readminVideoUrl}
        thumbnailUrl={data.readminObject.readminThumbnailUrl}
        {...others}
      />
    );

  return null;
}

import { Button, InputAdornment, TextField } from "@mui/material";
import { getDate, getHours, getMinutes, getMonth, getSeconds, getYear, isValid, parse, set } from "date-fns";
import { formatInTimeZone, fromZonedTime, toDate } from "date-fns-tz";
import useFormDialogs from "hooks/useFormDialogs";
import useTimeZone from "hooks/useTimeZone";
import React, { useEffect, useId } from "react";
import { ChangeTimeZoneDialog } from "readmin_pages/shared/ReadminFooter";

function formatTime(datetimeStr, { timeZone }) {
  if (!datetimeStr) return "";
  const datetime = toDate(datetimeStr);
  const timeStr = formatInTimeZone(datetime, timeZone, "HH:mm:ss");
  return timeStr;
}

function parseTime(timeStr, { timeZone, dateStr = null }) {
  if (!timeStr) return null;
  const date = dateStr ? toDate(dateStr) : new Date();

  //
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#time_value_format
  //
  // The value of the time input is always in 24-hour format that includes leading zeros: hh:mm, regardless of the input format, which is likely to be selected based on the user's locale (or by the user agent). If the time includes seconds (see Using the step attribute), the format is always hh:mm:ss
  //
  let time = parse(timeStr, "HH:mm:ss", new Date());
  if (!isValid(time)) {
    time = parse(timeStr, "HH:mm", new Date());
    if (!isValid(time)) return null;
  }

  const timeValues = {
    year: getYear(date),
    month: getMonth(date),
    date: getDate(date),
    hours: getHours(time),
    minutes: getMinutes(time),
    seconds: getSeconds(time),
    milliseconds: 0,
  };
  const datetime = set(new Date(), timeValues);

  const dateUtc = fromZonedTime(datetime, timeZone);
  const str = dateUtc.toISOString();

  return str;
}

TimeInput.defaultValue = null;

export default function TimeInput({ value, onChange, readOnly = false, forceTimeZone, date, min, max, ...others }) {
  const id = useId();
  let timeZone = useTimeZone();
  if (forceTimeZone) timeZone = forceTimeZone;

  useEffect(() => {
    const valueNormalised = parseTime(formatTime(value, { timeZone }), { timeZone, dateStr: date });
    if (valueNormalised !== value) onChange(valueNormalised);
  }, [value, date]);

  const [formDialogs, formDialogsOpen] = useFormDialogs();

  return (
    <>
      {formDialogs}
      <TextField
        fullWidth
        id={id}
        value={formatTime(value, { timeZone })}
        inputProps={{
          readOnly,
          step: 1,
          min: formatTime(min, { timeZone }),
          max: formatTime(max, { timeZone }),
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Button
                onClick={() => formDialogsOpen(<ChangeTimeZoneDialog />)}
                disabled={!!forceTimeZone}
                color="inherit"
              >
                {timeZone}
              </Button>
            </InputAdornment>
          ),
        }}
        InputLabelProps={{ shrink: true }}
        onChange={(event) => onChange(parseTime(event.target.value, { timeZone, dateStr: date }))}
        type="time"
        {...others}
      />
    </>
  );
}

import HlsJs from "hls.js";
import React, { useEffect, useRef } from "react";

const MP4_MIME_TYPE = "video/mp4";
const M3U8_MIME_TYPE = "application/vnd.apple.mpegurl";

export default function VideoPlayer({ videoUrl, mp4Url, m3u8Url, mkvUrl, videoRef, ...others }) {
  const videoUrlParsed = videoUrl && new URL(videoUrl, window.location.href);
  if (videoUrl && videoUrlParsed.pathname.toLowerCase().endsWith(".m3u8")) {
    m3u8Url = videoUrl;
    videoUrl = null;
  }
  videoRef ||= useRef();
  useEffect(() => {
    const videoEl = videoRef.current;
    const hlsJsSupport = HlsJs.isSupported();
    if (hlsJsSupport && m3u8Url && videoEl) {
      const hls = new HlsJs();
      hls.loadSource(m3u8Url);
      hls.attachMedia(videoEl);
      return () => {
        hls.detachMedia();
        hls.destroy();
      };
    }
  }, [m3u8Url]);

  return (
    <>
      <video ref={videoRef} {...others}>
        {m3u8Url && <source src={m3u8Url} type={M3U8_MIME_TYPE} />}
        {mp4Url && <source src={mp4Url} type={MP4_MIME_TYPE} />}
        {videoUrl && <source src={videoUrl} />}
      </video>
    </>
  );
}

import { gql } from "@apollo/client";
import FormDialog from "controls/FormDialog";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import { Pencil } from "mdi-material-ui";
import React from "react";

import ScorekeeperUsersForm, { ScorekeeperUsersFormFragment } from "./Form";

ScorekeeperUsersEditDialog.useButtonProps = () => {
  return {
    icon: <Pencil />,
    content: "Edit",
  };
};

export default function ScorekeeperUsersEditDialog({ scorekeeperUserIds, onClose, onSubmit }) {
  const scorekeeperUsersUpdate = useActionFragment("scorekeeperUsersUpdate");
  const [data, dataMeta] = useData(
    gql`
      query ScorekeeperUsersEditDialogQuery($scorekeeperUserIds: [ID!]!) {
        scorekeeperUsers(ids: $scorekeeperUserIds) {
          id
          ...ScorekeeperUsersFormFragment
        }
      }
      ${ScorekeeperUsersFormFragment}
    `,
    {
      scorekeeperUserIds,
    },
  );

  return (
    <FormDialog
      header="Edit Scorekeeper User"
      loading={dataMeta.loading}
      onClose={onClose}
      onSubmit={async (formData) => {
        await scorekeeperUsersUpdate({
          input: {
            scorekeeperUserIds,
            ...formData,
          },
        });
        await onSubmit?.();
      }}
    >
      <ScorekeeperUsersForm scorekeeperUsers={data?.scorekeeperUsers} />
    </FormDialog>
  );
}

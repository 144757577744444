import { gql } from "@apollo/client";
import { Button, Divider, IconButton, TextField } from "@mui/material";
import DateTime from "controls/DateTime";
import Message from "controls/Message";
import Toolbar from "controls/Toolbar";
import { timeZoneContext } from "helpers/contexts";
import { useActionFragment } from "hooks/useAction";
import useData from "hooks/useData";
import useShowMessage from "hooks/useShowMessage";
import { isEqual, pick } from "lodash-es";
import { ContentSave, Minus, Plus } from "mdi-material-ui";
import React, { useEffect, useState } from "react";

import PublicForm, { PublicFormHeader } from "./PublicForm";

const ENTRY_KEYS = ["firstName", "lastName", "playerNumber", "email", "phoneNumber", "birthdate"];

export default function TeamRegistrationFormPage({ token }) {
  const showMessage = useShowMessage();

  const [data, dataMeta] = useData(
    gql`
      query TeamRegistrationFormPage($token: String!) {
        teamRegistrationForm(token: $token) {
          id
          expiresAt
          submittedAt
          teamName
          leagueNames
          clubTimeZone
          players {
            ...TeamRegistrationFormEntryFragment
          }
          historicPlayers {
            ...TeamRegistrationFormEntryFragment
          }
          supporters {
            ...TeamRegistrationFormEntryFragment
          }
        }
      }
      fragment TeamRegistrationFormEntryFragment on TeamRegistrationFormEntry {
        playerNumber
        firstName
        lastName
        email
        phoneNumber
        birthdate
      }
    `,
    { token },
    {
      fetchPolicy: "network-only",
    },
  );

  const teamRegistrationFormSubmit = useActionFragment("teamRegistrationFormSubmit");

  const [formData, formDataSet] = useState();

  const formDataReset = () => {
    if (!data?.teamRegistrationForm) return;
    formDataSet({
      players: data?.teamRegistrationForm.players.map((p) => pick(p, ENTRY_KEYS)),
      supporters: data?.teamRegistrationForm.supporters.map((p) => pick(p, ENTRY_KEYS)),
    });
  };

  useEffect(() => {
    formDataReset();
  }, [data?.teamRegistrationForm]);

  const renderEntries = ({ dataKey, addLabel, entryProps }) => (
    <>
      {formData &&
        formData[dataKey]?.map((entry, entryIndex) => (
          <Entry
            key={entryIndex}
            entry={entry}
            submitting={teamRegistrationFormSubmit.loading}
            onRemove={() =>
              formDataSet((formData) => ({
                ...formData,
                [dataKey]: formData[dataKey].filter((p, i) => i !== entryIndex),
              }))
            }
            onChange={(entry) =>
              formDataSet((formData) => ({
                ...formData,
                [dataKey]: formData[dataKey].map((p, i) => (i === entryIndex ? entry : p)),
              }))
            }
            {...entryProps}
          />
        ))}
      {formData && (
        <Toolbar>
          <Button
            variant="outlined"
            disabled={teamRegistrationFormSubmit.loading}
            onClick={() =>
              formDataSet((formData) => ({
                ...formData,
                [dataKey]: [
                  ...formData[dataKey],
                  {
                    playerNumber: "",
                    firstName: "",
                    lastName: "",
                    email: "",
                    phoneNumber: "",
                    birthdate: null,
                  },
                ],
              }))
            }
          >
            <Plus />
            {addLabel}
          </Button>
        </Toolbar>
      )}
    </>
  );

  return (
    <timeZoneContext.Provider value={data?.teamRegistrationForm?.clubTimeZone}>
      <PublicForm
        loading={dataMeta.loading}
        title={`Registration Form for ${data?.teamRegistrationForm?.teamName}`}
        maxWidth={1100}
        paperProps={{
          component: "form",
          onSubmit: async (event) => {
            event.preventDefault();
            await teamRegistrationFormSubmit({
              input: {
                teamRegistrationToken: token,
                ...formData,
              },
            });

            showMessage("Registration form submitted.");
            await dataMeta.refetch();
          },
        }}
      >
        {data && !data.teamRegistrationForm && (
          <Message type="error" content="This registration form is either expired or removed." />
        )}
        <PublicFormHeader
          header={data?.teamRegistrationForm && data.teamRegistrationForm?.leagueNames.join(" ")}
          subheader={
            <>
              {data?.teamRegistrationForm?.submittedAt && (
                <div>
                  Submitted at: <DateTime value={data.teamRegistrationForm.submittedAt} />
                </div>
              )}
              {data?.teamRegistrationForm?.expiresAt && (
                <div>
                  Expires at: <DateTime value={data.teamRegistrationForm.expiresAt} />
                </div>
              )}
            </>
          }
        />
        <Divider />
        <PublicFormHeader header="Active Players" subheader="Players you are registering for the upcoming season" />
        {renderEntries({
          dataKey: "players",
          addLabel: "Add player",
          entryProps: { playerNumberEnabled: true },
        })}
        <PublicFormHeader header="Historical players" />
        {data?.teamRegistrationForm?.historicPlayers
          .filter(
            (p) =>
              !formData?.players.find((pp) =>
                isEqual(pick(pp, ["firstName", "lastName"]), pick(p, ["firstName", "lastName"])),
              ),
          )
          .map((player, playerIndex) => (
            <div
              key={playerIndex}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                flexFlow: "row nowrap",
                gap: 10,
              }}
            >
              <div>{player.playerNumber === null ? "-" : player.playerNumber}</div>
              <div style={{ flex: "1 1 auto" }}>
                {player.firstName} {player.lastName} {player.email}
              </div>
              <IconButton
                disabled={teamRegistrationFormSubmit.loading}
                onClick={() =>
                  formDataSet((formData) => ({
                    ...formData,
                    players: [...formData.players, pick(player, ENTRY_KEYS)],
                  }))
                }
                title="Add"
              >
                <Plus />
              </IconButton>
            </div>
          ))}
        <Divider />
        <PublicFormHeader
          header="Supporters"
          subheader="Any supporters of the team can be added, and receive stats & video for every game"
        />
        {renderEntries({
          dataKey: "supporters",
          addLabel: "Add supporter",
        })}
        <Divider />
        {data?.teamRegistrationForm && (
          <Toolbar>
            <Button variant="contained" type="submit" disabled={teamRegistrationFormSubmit.loading}>
              <ContentSave />
              {data?.teamRegistrationForm.submittedAt ? "Re-submit" : "Submit"}
            </Button>
            <Button disabled={data?.teamRegistrationForm === formData} onClick={formDataReset}>
              Reset
            </Button>
          </Toolbar>
        )}
      </PublicForm>
    </timeZoneContext.Provider>
  );
}

function Entry({ entry, onRemove, playerNumberEnabled, onChange, submitting }) {
  return (
    <div style={{ overflowX: "auto", scrollbarWidth: "none" }}>
      <div
        style={{
          display: "flex",
          flexFlow: "row nowrap",
          justifyContent: "stretch",
          alignItems: "center",
          gap: 10,
          minWidth: 1000,
        }}
      >
        {[
          playerNumberEnabled && ["No.", "playerNumber", { style: { width: 50 } }],
          ["First name *", "firstName", { required: true }],
          ["Last name", "lastName", {}],
          ["Email", "email", { type: "email", style: { width: 300 } }],
          ["Phone", "phoneNumber", { type: "tel" }],
          ["Birthdate", "birthdate", { type: "date" }],
        ]
          .filter((g) => g)
          .map(([label, name, props]) => (
            <TextField
              key={name}
              size="small"
              disabled={submitting}
              placeholder={label}
              value={entry[name] || ""}
              onChange={(event) => onChange({ ...entry, [name]: event.target.value })}
              autoComplete="off"
              {...props}
              style={{
                flex: "1 1 auto",
                ...props.style,
              }}
            />
          ))}
        <IconButton tabIndex={-1} color="inherit" title="Remove" size="small" onClick={onRemove} disabled={submitting}>
          <Minus />
        </IconButton>
      </div>
    </div>
  );
}

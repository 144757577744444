import { TrashCan } from "mdi-material-ui";
import React from "react";
import makeConfirmActionDialog from "readmin_pages/shared/makeConfirmActionDialog";

export const ClubAdminsRemoveDialog = makeConfirmActionDialog({
  mutationName: "clubAdminsRemove",
  action: "Remove Club Admin",
  paramName: "clubAdminIds",
  useButtonProps: () => {
    return {
      icon: <TrashCan />,
      content: "Remove Club Admin",
    };
  },
});
